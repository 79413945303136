import React from "react";
import { Box, Button } from "@mui/material";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import _ from "underscore";
import useEvents from "../../hooks/useEvents";

const PhotoGroup = ({
  group,
  showButton,
  onMakeStartingGroup,
  onDeletePhotoGroup,
}) => {
  const [index, setIndex] = React.useState(-1);
  const events = useEvents();
  const handleClick = (index) => {
    events.logButtonClick({
      buttonName: "View Progress Photo",
      pageName: "Clients",
      tabName: "Client Summary Progress Photos",
    });
    setIndex(index);
  };

  const images = React.useMemo(() => {
    const photoArray = group.photos.map((photo) => {
      if (photo) {
        return {
          src: photo,
          // width: 155,
          // height: 155 * (16 / 9),
        };
      }
      return null;
    });

    return _.filter(photoArray, (p) => {
      return p !== undefined && p !== null;
    });
  }, [group]);

  const slides = images.map(({ src, width, height }) => ({
    src: src,
    width,
    height,
  }));

  console.log("slides", slides);

  const currentImage = images[index];

  return (
    <Box>
      <Gallery
        style={{ height: 155 * (16 / 9) }}
        rowHeight={155 * (16 / 9)}
        margin={8}
        enableImageSelection={false}
        images={images}
        onClick={handleClick}
      />
      {!!currentImage && (
      <Lightbox
          slides={slides}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
      />
      )}

      <p style={{ fontSize: 16, fontWeight: "500", alignItems: "center" }}>
        {group.createdOn.format("LL")}

        {showButton && (
          <span
            style={{
              display: "inline",
            }}
          >
            <Button
              style={{}}
              variant="text"
              onClick={() => {
                // Make this the starting photo.
                if (onMakeStartingGroup) {
                  onMakeStartingGroup(group.groupId);
                }
              }}
            >
              <span style={{ textDecoration: "underline", marginBottom: 4 }}>
                Set as starting photo group
              </span>
            </Button>
          </span>
        )}

        {showButton && (
          <span
            style={{
              display: "inline",
            }}
          >
            <Button
              style={{}}
              variant="text"
              onClick={() => {
                // Make this the starting photo.
                if (onDeletePhotoGroup) {
                  onDeletePhotoGroup(group.groupId);
                }
              }}
            >
              <span style={{ textDecoration: "underline", marginBottom: 4 }}>
                delete
              </span>
            </Button>
          </span>
        )}
      </p>
    </Box>
  );
};

export default PhotoGroup;
