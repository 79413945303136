import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import "firebase/compat/firestore";
// import "firebase/compat/storage";

import { getRecoil } from "recoil-nexus";
import _ from "underscore";

import organizationIdState from "../atoms/organizationIdSelector";
import usersState from "../atoms/usersAtom";
import { consola } from "consola";

export const RoleString = {
  admin: "Admin",
  coach: "Coach",
};

function getFileExtension(file) {
  if (file.type === "image/png") {
    return "png";
  }
  if (file.type === "image/jpeg") {
    return "jpg";
  }
  return "jpg";
}

export async function registerNewUser(email, password) {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  const credentials = await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password);

  // localStorage.setItem("uid", credentials.user.uid);
  localStorage.setItem("credentials.user.uid", credentials.user.uid);
  localStorage.setItem("email", credentials.user.email);

  return credentials;
}

export async function getAccountHolderUser({ organizationId }) {
  const organizationRef = firebase
    .firestore()
    .collection("organizations")
    .doc(organizationId);
  consola.info("++++++ READ -> UserData: getAccountHolderUser");
  const organizationData = await organizationRef.get();
  if (!organizationData.exists) {
    consola.error(new Error("Organization not found"));
  }
  const organizationValue = organizationData.data();

  const {
    account: { accountHolderUID },
  } = organizationValue;

  const userRef = firebase
    .firestore()
    .collection("users")
    .doc(accountHolderUID);
  consola.info("++++++ READ -> UserData: getUserData");
  const userData = await userRef.get();
  if (!userData.exists) {
    consola.error(new Error("User not found"));
  }
  const userValue = userData.data();

  return userValue;
}

export const uploadUserAvatar = async ({ uid, file, progressFunction }) => {
  const ref = firebase.firestore().collection("users").doc(uid);
  consola.info("++++++ READ -> UserData: uploadUserAvatar");
  const userData = await ref.get();
  if (userData.exists) {
    const path = `/avatars/${uid}/${uid}.${getFileExtension(file)}`;
    const uploadTask = firebase.storage().ref(path).put(file);

    await new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress function ...
          if (progressFunction) {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            );
            progressFunction(progress);
          }
        },
        (error) => {
          reject(error);
        },
        () => {
          firebase
            .storage()
            .ref(path)
            .getDownloadURL()
            .then(async (url) => {
              // Update the user to include the image.
              consola.info("+++++ WRITE => userData: uploadUserAvatar");
              await ref.set(
                {
                  avatarUrl: url,
                  updatedOn: new Date(),
                },
                { merge: true },
              );

              await firebase.storage().ref(path).updateMetadata({
                cacheControl: "private, max-age=3600",
              });

              resolve(true);
            })
            .catch((e) => {
              reject(e);
            });
        },
      );
    });
  }
};

function findUser(uid) {
  const usersData = getRecoil(usersState);
  return _.findWhere(usersData, { uid: uid });
}

const UserData = {
  getUserDataByAuth: async (uid) => {
    const ref = firebase
      .firestore()
      .collection("users")
      .where("authId", "==", uid);
    consola.info("++++++ READ -> UserData: getUserDataByAuth");
    const data = await ref.get();
    if (data.docs.length > 0) {
      return data.docs[0].data();
    }

    return null;
  },
  getUserData: async (userId) => {
    if (!userId) {
      return null;
    }
    const userRef = firebase.firestore().collection("users").doc(userId);
    consola.info("++++++ READ -> UserData: getUserData");
    const userData = await userRef.get();
    if (userData.exists) {
      return userData.data();
    }
    return null;
  },
  addUser: async (uid, input) => {
    const userRef = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> UserData: addUser");
    const userData = await userRef.get();
    if (userData.exists) {
      return false;
    }
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("+++++ WRITE => userData: addUser");
    ref.set(input, { merge: true });
    return true;
  },
  getUsersData: async (userIds) => {
    const promises = userIds.map((uid) => {
      const cachedUser = findUser(uid);
      if (cachedUser) {
        return new Promise((resolve) => {
          resolve(cachedUser);
        });
      }

      const ref = firebase.firestore().collection("users").doc(uid);
      return new Promise((resolve) => {
        consola.info("++++++ READ -> userData: getUsersData");
        ref.get().then((doc) => {
          resolve(doc.data());
        });
      });
    });
    const usersData = await Promise.all(promises);
    return usersData;
  },
  getOrganizationUsers: async () => {
    const organizationId = getRecoil(organizationIdState);
    const usersData = getRecoil(usersState);

    if (usersData) {
      return usersData;
    }

    const usersRef = firebase
      .firestore()
      .collection("users")
      .where("organizationId", "==", organizationId)
      .orderBy("firstName");
    consola.info("++++++ READ -> UserData: getOrganizationUsers");
    const users = await usersRef.get();

    return users.docs.map((d) => {
      return d.data();
    });
  },
  updateUser: async (uid, data) => {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.debug("++++++ READ -> updateUser");
    const userData = await ref.get();
    if (userData.exists) {
      consola.info("+++++ WRITE => UserData: updateUser");
      await ref.set(
        {
          ...data,
          updatedOn: new Date(),
        },
        { merge: true },
      );
    }
  },
};

export default UserData;
