import React from "react";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ExportIcon from "@mui/icons-material/ImportExport";
import PrintIcon from "@mui/icons-material/PrintRounded";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IconButton } from "@mui/material";

export const MoreButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="more button"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <MoreVertIcon />
    </IconButton>
  );
};

export const DropDownButton = ({ onClick, show }) => {
  if (show) {
    return (
      <IconButton
        disableRipple
        disableFocusRipple
        color="editButton"
        aria-label="edit"
        onClick={(event) => {
          if (onClick) {
            onClick(event);
          }
        }}
      >
        <ArrowDropUpIcon sx={{ fontSize: 72 }} />
      </IconButton>
    );
  } else
    return (
      <IconButton
        color="editButton"
        aria-label="edit"
        disableRipple
        disableFocusRipple
        onClick={(event) => {
          if (onClick) {
            onClick(event);
          }
        }}
      >
        <ArrowDropDownIcon sx={{ fontSize: 72 }} />
      </IconButton>
    );
};

export const CameraButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="camera button"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <AddAPhotoIcon />
    </IconButton>
  );
};

export const EditButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="edit"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <EditIcon />
    </IconButton>
  );
};

export const DeleteButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="delete"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
};

export const RestoreButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="restore"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <RestoreFromTrashIcon />
    </IconButton>
  );
};

export const FileCopyButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="file copy"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <FileCopyIcon />
    </IconButton>
  );
};

export const CheckCircleButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="check circle"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <CheckCircle />
    </IconButton>
  );
};

export const ArrowUpButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="arrow up"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <ArrowUpwardIcon />
    </IconButton>
  );
};

export const ArrowDownButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="arrow up"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <ArrowDownwardIcon />
    </IconButton>
  );
};

export const ExportButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="export button"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <ExportIcon />
    </IconButton>
  );
};

export const PrintButton = ({ onClick }) => {
  return (
    <IconButton
      color="editButton"
      aria-label="print button"
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <PrintIcon />
    </IconButton>
  );
};
