import React from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { nanoid } from "nanoid";
import _ from "underscore";

import SplitExerciseRow from "./SplitExerciseRow";
import { WorkoutTypes } from "../../../../data/Types";

import BlueButton from "../../../../components/BlueButton";
import LabelSelect from "../../../../components/molecules/LabelSelect";

const titlePadding = 185;

const SplitExerciseDialog = (props) => {
  const { t } = useTranslation();

  const [id, setId] = React.useState(props.id);
  const [open, setOpen] = React.useState(false);

  let storedLastType = localStorage.getItem("SplitExerciseDialog_type");
  if (!storedLastType) {
    storedLastType = WorkoutTypes.normalSet;
  }

  const [showError, setShowError] = React.useState(false);
  const [error, setError] = React.useState("");

  const [type, setType] = React.useState(storedLastType);
  const [labels, setLabels] = React.useState([]);
  const [superSetName, setSuperSetName] = React.useState("");

  const [exerciseData, setExerciseData] = React.useState({
    exercise: null,
    reps: 8,
    repsMax: 8,
    tempo: "",
    rest: "",
  });

  const [sets, setSets] = React.useState(3);
  const [setsToFailure, setSetsToFailure] = React.useState(0);
  const [notes, setNotes] = React.useState("");
  const [exerciseList, setExerciseList] = React.useState([]);

  const isEdit = React.useMemo(() => {
    if (props.selectedExercise) {
      return true;
    } else {
      return false;
    }
  }, [props]);

  React.useEffect(() => {
    setOpen(props.open);

    if (!props.open) {
      return;
    }

    if (!props.id) {
      setId(nanoid());
    } else {
      setId(props.id);
    }

    if (props.selectedExercise) {
      setType(props.selectedExercise.type);
      setLabels(
        props.selectedExercise.labels ? props.selectedExercise.labels : [],
      );
      setSuperSetName(
        props.selectedExercise.superSetName
          ? props.selectedExercise.superSetName
          : "",
      );

      setExerciseData({
        exercise: props.selectedExercise.exercise,
        reps: props.selectedExercise.reps,
        repsMax: props.selectedExercise.repsMax,
        tempo: props.selectedExercise.tempo,
        rest: props.selectedExercise.rest,
      });

      setSets(props.selectedExercise.sets);
      setSetsToFailure(
        props.selectedExercise.setsToFailure
          ? props.selectedExercise.setsToFailure
          : 0,
      );
      setNotes(
        props.selectedExercise.notes ? props.selectedExercise.notes : "",
      );

      if (props.selectedExercise.type === WorkoutTypes.superSet) {
        const newList = props.selectedExercise.exercises;
        if (newList.length === 0) {
          newList.push({
            id: nanoid(),
            exercise: null,
            reps: 8,
            repsMax: 8,
            tempo: "",
            rest: "",
          });
        }
        setExerciseList(newList);
      }
    } else {
      setType(storedLastType);
      setLabels([]);
      setSuperSetName("");

      setExerciseData({
        exercise: null,
        reps: 8,
        repsMax: 8,
        tempo: "",
        rest: "",
      });
      setSets(3);
      setSetsToFailure(0);

      setNotes("");
      setExerciseList([]);
    }
  }, [props.open]); // eslint-disable-line

  function handleAddExercise() {
    const copy = _.clone(exerciseList);

    copy.push({
      id: nanoid(),
      exercise: null,
      reps: 8,
      repsMax: 8,
      tempo: "",
      rest: "",
    });
    setExerciseList(copy);
  }

  function handleDeleteExercise(exercise) {
    const copy = _.clone(exerciseList);
    const removed = _.reject(copy, (e) => {
      return e.id === exercise.id;
    });

    if (removed.length === 0) {
      removed.push({
        id: nanoid(),
        exercise: null,
        reps: 8,
        repsMax: 8,
        tempo: "",
        rest: "",
      });
    }
    setExerciseList(removed);
  }

  function validate() {
    // check if exercise is selected
    if (type === WorkoutTypes.superSet) {
      if (exerciseList.length === 0) {
        return false;
      }
      for (let i = 0; i < exerciseList.length; i++) {
        if (!exerciseList[i].exercise) {
          return false;
        }
      }
    } else {
      if (!exerciseData.exercise) {
        return false;
      }
    }
    return true;
  }

  if (!props.open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>{isEdit ? "Edit Exercise" : "Add Exercise"}</DialogTitle>
      <DialogContent>
        <Stack spacing={1} style={{ marginBottom: 12 }}>
          <Typography>Set Type</Typography>
          <Select
            style={{ width: "100%" }}
            variant="outlined"
            value={type}
            onChange={(event) => {
              setType(event.target.value);

              localStorage.setItem(
                "SplitExerciseDialog_type",
                event.target.value,
              );

              if (event.target.value === WorkoutTypes.superSet) {
                if (exerciseList.length === 0) {
                  handleAddExercise();
                }
                // Remove the exercsie from the normal set
                setExerciseData({
                  exercise: null,
                  reps: 8,
                  repsMax: 8,
                  tempo: "",
                  rest: "",
                });
              } else {
                // Remove superset exercises if they exist
                setExerciseList([]);
                setSuperSetName("");
              }
            }}
          >
            <MenuItem value={WorkoutTypes.normalSet}>
              {t("normal_set")}
            </MenuItem>
            <MenuItem value={WorkoutTypes.superSet}>{t("super_set")}</MenuItem>
          </Select>
        </Stack>
        <Stack spacing={1} style={{ marginBottom: 12 }}>
          <Typography>Labels</Typography>
          <LabelSelect
            id="SplitExerciseDialog_LabelSelect"
            useTimer={false}
            currentLabels={labels}
            width={"100%"}
            onLabelsChanged={(selectedLabels) => {
              setLabels(selectedLabels.protocolLabels);
            }}
          />
        </Stack>
        {type === WorkoutTypes.normalSet && (
          <Stack style={{ marginBottom: 12 }}>
            <SplitExerciseRow
              titlePadding={titlePadding}
              exerciseData={exerciseData}
              onChange={(data) => {
                setExerciseData(data);
              }}
            />
          </Stack>
        )}
        {type === WorkoutTypes.superSet && (
          <>
            <Stack style={{ marginBottom: 12 }}>
              <Box>
                <Typography>Name the Superset</Typography>
              </Box>
              <TextField
                style={{ width: "100%" }}
                value={superSetName}
                variant="outlined"
                onChange={(event) => {
                  setSuperSetName(event.target.value);
                }}
              />
            </Stack>
            <Stack spacing={1}>
              {exerciseList.map((e, index) => {
                const data = {
                  id: e.id,
                  exercise: e.exercise,
                  reps: e.reps,
                  repsMax: e.repsMax,
                  tempo: e.tempo,
                  rest: e.rest,
                };

                return (
                  <SplitExerciseRow
                    key={e.id}
                    showDelete
                    titlePadding={titlePadding}
                    exerciseData={data}
                    onChange={(data) => {
                      exerciseList[index] = {
                        ...data,
                      };
                    }}
                    handleDeleteExercise={(exerciseData) => {
                      handleDeleteExercise(exerciseData);
                    }}
                    onMoveUp={() => {
                      if (index > 0) {
                        const copy = _.clone(exerciseList);
                        const temp = copy[index - 1];
                        copy[index - 1] = copy[index];
                        copy[index] = temp;
                        setExerciseList(copy);
                      }
                    }}
                    onMoveDown={() => {
                      if (index < exerciseList.length - 1) {
                        const copy = _.clone(exerciseList);
                        const temp = copy[index + 1];
                        copy[index + 1] = copy[index];
                        copy[index] = temp;
                        setExerciseList(copy);
                      }
                    }}
                  />
                );
              })}
            </Stack>

            <Box style={{ marginTop: 12, marginBottom: 12 }}>
              <Button
                variant="outlined"
                onClick={() => {
                  handleAddExercise();
                }}
                color="primary"
              >
                Add Exercise
              </Button>
            </Box>
          </>
        )}

        <Stack direction="row" spacing={1}>
          <Stack>
            <Typography>Sets</Typography>
            <TextField
              style={{ width: 100 }}
              value={sets}
              variant="outlined"
              onChange={(event) => {
                let value = parseInt(event.target.value);
                if (isNaN(value)) {
                  value = 0;
                }

                setSets(value);

                if (setsToFailure > value) {
                  setSetsToFailure(value);
                }
              }}
            />
          </Stack>
          <Stack>
            <Typography>Sets to failure</Typography>
            <TextField
              style={{ width: 100 }}
              value={setsToFailure}
              variant="outlined"
              onChange={(event) => {
                let value = parseInt(event.target.value);
                if (isNaN(value)) {
                  value = 0;
                }

                if (value > sets) {
                  value = sets;
                }
                setSetsToFailure(value);
              }}
            />
          </Stack>
        </Stack>

        <Stack
          spacing={1}
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <Typography>Instructions</Typography>

          <div data-color-mode="light">
            <MDEditor
              preview="edit"
              style={{ width: "100%", minWidth: 800 }}
              placeholder="Enter healthy recipe description or video link here"
              value={notes ? notes : ""}
              onChange={(value) => {
                setNotes(value);
              }}
            />
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        {showError && (
          <Box style={{ color: "red", marginRight: 8 }}>
            <Typography>{error}</Typography>
          </Box>
        )}
        <Button
          onClick={() => {
            if (props.onDialogClosed) {
              props.onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (!validate()) {
              setShowError(true);
              setError("You must choose an exercise before saving.");
              return;
            }

            if (props.onDialogClosed) {
              const filteredExerciseList = _.filter(exerciseList, (e) => {
                return e.exercise !== null;
              });
              const data = {
                id: id,
                splitId: props.splitId,
                protocolId: props.protocolId,
                type: type,
                labels: labels,
                superSetName: superSetName,
                exercise:
                  type === WorkoutTypes.superSet
                    ? WorkoutTypes.superSet
                    : exerciseData.exercise,
                sets: sets,
                setsToFailure: setsToFailure,
                reps: exerciseData.reps,
                repsMax: exerciseData.repsMax ? exerciseData.repsMax : 8,
                tempo: exerciseData.tempo ? exerciseData.tempo : "",
                rest: exerciseData.rest ? exerciseData.rest : "",
                exercises: filteredExerciseList,
                notes: notes,
              };
              if (props.selectedExercise) {
                data.index = props.selectedExercise.index;
              }
              props.onDialogClosed(data);
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default SplitExerciseDialog;
