var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { getRecoil } from "recoil-nexus";
import Bugsnag from "@bugsnag/js";
import _ from "underscore";
import organizationIdSelector from "../atoms/organizationIdSelector";
import { nanoid } from "nanoid";
import { consola } from "consola";
var useQuestions = function () {
    var _this = this;
    var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "daily";
    var organizationId = getRecoil(organizationIdSelector);
    var _a = React.useState(), questions = _a[0], setQuestions = _a[1];
    var saveQuestions = React.useCallback(function (newQuestions) {
        return __awaiter(this, void 0, void 0, function () { var ref, e_1, e_2; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ref = firebase.firestore().collection("organizations").doc(organizationId);
                    if (!(type == "weekly")) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    consola.info("+++++ WRITE => useQuestions: saveWeeklyQuestion");
                    return [4 /*yield*/, ref.set({ weeklyOptional: newQuestions }, { merge: true })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    Bugsnag.notify(new Error("Error saving Report Question data"));
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 9];
                case 5:
                    if (!(type == "daily")) return [3 /*break*/, 9];
                    _a.label = 6;
                case 6:
                    _a.trys.push([6, 8, , 9]);
                    consola.info("+++++ WRITE => useQuestions: saveDailyQuestion");
                    return [4 /*yield*/, ref.set({ optional: newQuestions }, { merge: true })];
                case 7:
                    _a.sent();
                    return [3 /*break*/, 9];
                case 8:
                    e_2 = _a.sent();
                    Bugsnag.notify(new Error("Error saving Daily Question data"));
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        }); });
    }, [organizationId, type]);
    var addQuestion = React.useCallback(function (name, subTitle, minValue, maxValue, isRequired, tier1, tier2, tier3, type) {
        return __awaiter(this, void 0, void 0, function () { var questionsCopy; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    questionsCopy = _.clone(questions);
                    questionsCopy === null || questionsCopy === void 0 ? void 0 : questionsCopy.push({ id: nanoid(), name: name, subTitle: subTitle, minValue: minValue, maxValue: maxValue, isRequired: isRequired, tier1: tier1, tier2: tier2, tier3: tier3, type: type, index: questionsCopy.length + 1 });
                    return [4 /*yield*/, saveQuestions(questionsCopy)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        }); });
    }, [questions, saveQuestions]); // TODO: Deleting a question should activate a cloud function to remove references.
    var deleteQuestion = React.useCallback(function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var questionsCopy, index, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!questions) return [3 /*break*/, 2];
                        questionsCopy = _.clone(questions);
                        index = _.findIndex(questionsCopy, { id: id });
                        if (!(index !== -1)) return [3 /*break*/, 2];
                        questionsCopy.splice(index, 1); // Reindex
                        for (i = 0; i < questionsCopy.length; i++) {
                            questionsCopy[i].index = i + 1;
                        }
                        return [4 /*yield*/, saveQuestions(questionsCopy)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }, [organizationId, questions, saveQuestions]);
    var getQuestionById = React.useCallback(function (id) { if (!questions) {
        return null;
    } var value = _.findWhere(questions, { id: id }); return value; }, [questions]);
    var moveQuestion = React.useCallback(function (id, direction) { return __awaiter(_this, void 0, void 0, function () {
        var index, prevQuestion, currQuestion, prevQuestionIndex, index, prevQuestion, currQuestion, prevQuestionIndex;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!questions) {
                        return [2 /*return*/];
                    }
                    if (!(direction === "up")) return [3 /*break*/, 3];
                    index = _.findIndex(questions, { id: id });
                    if (!(index !== -1)) return [3 /*break*/, 2];
                    if (!(index > 0)) return [3 /*break*/, 2];
                    prevQuestion = questions[index - 1];
                    currQuestion = questions[index];
                    prevQuestionIndex = prevQuestion.index;
                    prevQuestion.index = currQuestion.index;
                    currQuestion.index = prevQuestionIndex; // Save them
                    return [4 /*yield*/, saveQuestions(questions)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [3 /*break*/, 5];
                case 3:
                    index = _.findIndex(questions, { id: id });
                    if (!(index !== -1)) return [3 /*break*/, 5];
                    if (!(index < questions.length - 1)) return [3 /*break*/, 5];
                    prevQuestion = questions[index + 1];
                    currQuestion = questions[index];
                    prevQuestionIndex = prevQuestion.index;
                    prevQuestion.index = currQuestion.index;
                    currQuestion.index = prevQuestionIndex; // Save them
                    return [4 /*yield*/, saveQuestions(questions)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [questions, saveQuestions]);
    React.useEffect(function () { var ref = firebase.firestore().collection("organizations").doc(organizationId); var subscription = ref.onSnapshot(function (snapshot) { consola.info("++++++ SNAPSHOT -> useQuestions"); var data = snapshot.data(); if (!data) {
        setQuestions([]);
    }
    else {
        if (type == "weekly") {
            var sorted = _.sortBy(data.weeklyOptional, "index");
            setQuestions(sorted);
        }
        else if (type == "daily") {
            var sorted = _.sortBy(data.optional, "index");
            setQuestions(sorted);
        }
    } }); return function () { subscription(); }; }, [organizationId, type]);
    return { questions: questions, saveQuestions: saveQuestions, deleteQuestion: deleteQuestion, moveQuestion: moveQuestion, getQuestionById: getQuestionById, addQuestion: addQuestion };
};
export default useQuestions;
