import React from "react";
import { useTranslation } from "react-i18next";
import {
  TableCell,
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import PrintableFieldValue from "./PrintableFieldValue";

const SuperSetExerciseRowPrintable = ({ exercise, exerciseKeys }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Exercise</TableCell>
            <TableCell>Reps</TableCell>
            <TableCell>{t("exercise_columns.tempo")}</TableCell>
            <TableCell>Rest</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exercise &&
            exercise.exercises &&
            exercise.exercises.map((e) => {
              if (e.exercise in exerciseKeys) {
                return (
                  <TableRow key={e.id}>
                    <TableCell style={{ borderBottom: 0 }}>
                      {exerciseKeys[e.exercise].name}
                    </TableCell>
                    <TableCell style={{ borderBottom: 0 }}>
                      <PrintableFieldValue
                        type="normal_set"
                        field="reps"
                        value={e.reps}
                        isSuperSet={true}
                        id={e.id}
                      />
                    </TableCell>
                    <TableCell style={{ borderBottom: 0 }}>
                      <PrintableFieldValue
                        type="normal_set"
                        field="tempo"
                        value={e.tempo}
                        isSuperSet={true}
                        id={e.id}
                      />
                    </TableCell>
                    <TableCell style={{ borderBottom: 0 }}>
                      <PrintableFieldValue
                        type="normal_set"
                        field="rest"
                        value={e.rest}
                        isSuperSet={true}
                        id={e.id}
                      />
                    </TableCell>
                  </TableRow>
                );
              } else {
                return null;
              }
            })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SuperSetExerciseRowPrintable;
