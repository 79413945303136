import moment from "moment";
import { nanoid } from "nanoid";

export function getAveragesFromReport(report) {
  const healthData = report.healthData;

  const totals = {
    carbs: 0,
    fats: 0,
    proteins: 0,
    kCal: 0,
    weight: 0,
    steps: 0,
    hr: 0,
  };

  let carbsLength = 0;
  let fatsLength = 0;
  let proteinsLength = 0;
  let kCalLength = 0;
  let weightLength = 0;
  let stepsLength = 0;
  let hrLength = 0;

  const macroData = healthData.map((report) => {
    const health = report.reportData;

    if (health.carbs > 0) {
      totals.carbs = totals.carbs + health.carbs;
      carbsLength += 1;
    }
    if (health.fats > 0) {
      totals.fats = totals.fats + health.fats;
      fatsLength += 1;
    }
    if (health.proteins > 0) {
      totals.proteins = totals.proteins + health.proteins;
      proteinsLength += 1;
    }

    if (health.weight > 0) {
      totals.weight = totals.weight + health.weight;
      weightLength += 1;
    }
    if (health.steps > 0) {
      totals.steps = totals.steps + health.steps;
      stepsLength += 1;
    }
    if (health.averageHeartRate > 0) {
      totals.hr = totals.hr + health.averageHeartRate;
      hrLength += 1;
    }

    if (health.carbs > 0 || health.fats > 0 || health.proteins > 0) {
      totals.kCal =
        totals.kCal +
        getkCal({
          carbs: health.carbs,
          fats: health.fats,
          proteins: health.proteins,
        });

      kCalLength += 1;
    }

    return {
      carbs: health.carbs,
      fats: health.fats,
      proteins: health.proteins,
      kCal: health.kCal,
      weight: health.weight,
      steps: health.steps,
      hr: health.averageHeartRate,
    };
  });

  const result = {
    macros: macroData,
    totals: totals,
    averages: {
      carbs: Math.round(totals.carbs / carbsLength),
      fats: Math.round(totals.fats / fatsLength),
      proteins: Math.round(totals.proteins / proteinsLength),
      kCal: Math.round(totals.kCal / kCalLength),
      weight: Math.round(totals.weight / weightLength),
      steps: Math.round(totals.steps / stepsLength),
      hr: Math.round(totals.hr / hrLength),
    },
  };

  return result;
}

export function getGramsFromPercent(kCal, percent, type) {
  const percentkCal = kCal * (percent / 100);
  let modifier = 4;
  if (type === "fats") {
    modifier = 9;
  }
  const grams = Math.round(percentkCal / modifier);
  return grams;
}

export function getPercentFromGrams(kCal, grams, type) {
  try {
    if (grams === 0) {
      return 0;
    }
    let modifier = 4;
    if (type === "fats") {
      modifier = 9;
    }
    const kCalByGram = grams * modifier;

    const kCalPercent = kCalByGram / kCal;

    return Math.round(kCalPercent * 100);
  } catch {
    return 0;
  }
}

export function getPeriod(report) {
  try {
    let start = "";
    let end = "";

    if (report.startDate) {
      start = moment(report.startDate?.toDate());
    }
    if (report.endDate) {
      end = moment(report.endDate?.toDate());
    }
    return `${start.format("LL")} - ${end.format("LL")}`;
  } catch {
    return "";
  }
}

export function getReportPeriod(report) {
  try {
    const start = moment(report.startDate?.toDate());
    const end = moment(report.endDate?.toDate());

    return {
      start: start.toDate(),
      end: end.toDate(),
    };
  } catch {
    return {
      start: null,
      end: null,
    };
  }
}

export function getPreviousReportPeriod(report) {
  try {
    const start = moment(report.startDate?.toDate()).subtract(7, "days");
    const end = moment(report.startDate?.toDate());

    return {
      start: start.toDate(),
      end: end.toDate(),
    };
  } catch {
    return {
      start: null,
      end: null,
    };
  }
}

export const getActiveNutritionGoal = (nutritionGoals) => {
  if (!nutritionGoals) {
    return {
      id: nanoid(),
      name: "Default Goals",
      carbs: 0,
      fats: 0,
      proteins: 0,
      water: 0,
      active: true,
    };
  }
  let active;
  nutritionGoals.forEach((nutritionGoal) => {
    if (nutritionGoal.active) {
      active = nutritionGoal;
    }
  });
  if (!active) {
    active = nutritionGoals[0];
  }
  return active;
};

export function getkCal(nutritionGoal) {
  const proteinskCal = nutritionGoal.proteins * 4;
  const carbskCal = nutritionGoal.carbs * 4;
  const fatskCal = nutritionGoal.fats * 9;

  return Math.round(proteinskCal + carbskCal + fatskCal);
}

export function ago(date) {
  return moment(date).calendar(null, {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: function (now) {
      const daysString = `${now.diff(this, "Days")}`;
      if (daysString > 365) {
        if (daysString > 730) {
          return `[${Math.floor(daysString / 365)} Years Ago]`;
        }
        return `[1 Year Ago]`;
      }
      const returnValue = `[${daysString} Days Ago]`;
      return returnValue;
    },
  });
}
export function getHistoryString(historyOpen) {
  if (historyOpen) {
    return "Hide History";
  } else {
    return "Show History";
  }
}

export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}
