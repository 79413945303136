import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { getAveragesFromReport } from "../../../../components/LibraryFunctions";

export default React.memo((props) => {
  const averages = React.useMemo(() => {
    return getAveragesFromReport(props.report);
  }, [props.report]);

  function getAveragekCal(averages) {
    if (isNaN(averages.averages.kCal)) {
      return "-";
    }

    return `${averages.averages.kCal} kCal`;
  }

  function getAverageSteps(averages) {
    if (isNaN(averages.averages.steps)) {
      return "-";
    }

    return `${averages.averages.steps} steps`;
  }

  function getAverageHR(averages) {
    if (isNaN(averages.averages.hr)) {
      return "-";
    }

    return `${averages.averages.hr} bpm`;
  }
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Box
    // display="flex"
    // boxShadow={3}
    // borderRadius={2}
    // style={{ backgroundColor: "#F6F7FB", padding: 20 }}
    >
      <Accordion
        display="flex"
        boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
        >
          <img
            width="26"
            height="26"
            src="https://img.icons8.com/ios-filled/50/combo-chart--v1.png"
            alt="combo-chart--v1"
          />

          <Typography
            style={{
              color: "black",
              fontSize: 24,
              fontWeight: "600",
              marginLeft: 10,
            }}
          >
            Averages
          </Typography>
        </AccordionSummary>
        {/* <Box sx={{ marginLeft: 3.5 }}>
        <Typography style={{ color: "black", fontSize: 24, fontWeight: "600" }}>
          Averages
        </Typography>
      </Box> */}
        <AccordionDetails>
          <Grid
            style={{ marginTop: -20, width: "100%" }}
            spacing={0}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  width: 300,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 8,
                  borderRadius: 8,
                }}
              >
                <Typography style={{ fontSize: 25, fontWeight: "bold" }}>
                  {getAveragekCal(averages)}
                </Typography>
                <Typography style={{ fontSize: 16 }}>kCal Average</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  width: 300,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 8,
                  borderRadius: 8,
                }}
              >
                <Typography
                  style={{ fontSize: 25, fontWeight: "bold" }}
                >{`${getAverageSteps(averages)}`}</Typography>
                <Typography style={{ fontSize: 16 }}>Steps Average</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  width: 300,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 8,
                  borderRadius: 8,
                }}
              >
                <Typography
                  style={{ fontSize: 25, fontWeight: "bold" }}
                >{`${getAverageHR(averages)}`}</Typography>
                <Typography style={{ fontSize: 16 }}>HR Average</Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});
