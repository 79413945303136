import React from "react";
import { Box } from "@mui/material";
import { nanoid } from "nanoid";
import NutritionProgress from "../../../../components/organisms/NutritionProgress";

const getActiveNutritionGoal = (nutritionGoals) => {
  if (!nutritionGoals) {
    return {
      id: nanoid(),
      name: "Default Goals",
      carbs: 0,
      fats: 0,
      proteins: 0,
      water: 0,
      active: true,
    };
  }
  let active;
  nutritionGoals.forEach((nutritionGoal) => {
    if (nutritionGoal.active) {
      active = nutritionGoal;
    }
  });
  if (!active) {
    active = nutritionGoals[0];
  }
  return active;
};

const NutritionData = (props) => {
  const nutritionGoal = React.useMemo(() => {
    return getActiveNutritionGoal(props.client.nutritionGoals);
  }, [props.client]);

  if (
    !props.report ||
    !props.report.nutrition ||
    !props.report.nutrition.reportData
  ) {
    return null;
  }

  function getkCal(nutritionGoal) {
    const proteinskCal = nutritionGoal.proteins * 4;
    const carbskCal = nutritionGoal.carbs * 4;
    const fatskCal = nutritionGoal.fats * 9;

    return proteinskCal + carbskCal + fatskCal;
  }

  return (
    <Box
      style={{ height: "100%" }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <NutritionProgress
        title="kCal"
        showPercentage={false}
        target={getkCal(nutritionGoal)}
        actual={
          props.report.nutrition && props.report.nutrition.reportData
            ? props.report.nutrition.reportData.kCal
            : 0
        }
        value={
          props.report.nutrition && props.report.nutrition.reportData
            ? (props.report.nutrition.reportData.kCal /
                getkCal(nutritionGoal)) *
              100.0
            : 0
        }
        color="#E28413"
      />
      <NutritionProgress
        title="Carbs"
        measure="g"
        actual={
          props.report.nutrition ? props.report.nutrition.reportData.carbs : 0
        }
        target={nutritionGoal.carbs}
        value={
          props.report.nutrition
            ? (props.report.nutrition.reportData.carbs / nutritionGoal.carbs) *
              100.0
            : 0
        }
      />
      <NutritionProgress
        title="Proteins"
        measure="g"
        actual={
          props.report.nutrition
            ? props.report.nutrition.reportData.proteins
            : 0
        }
        target={nutritionGoal.proteins}
        value={
          props.report.nutrition
            ? (props.report.nutrition.reportData.proteins /
                nutritionGoal.proteins) *
              100.0
            : 0
        }
      />
      <NutritionProgress
        title="Fats"
        measure="g"
        actual={
          props.report.nutrition ? props.report.nutrition.reportData.fats : 0
        }
        target={nutritionGoal.fats}
        value={
          props.report.nutrition
            ? (props.report.nutrition.reportData.fats / nutritionGoal.fats) *
              100.0
            : 0
        }
      />
      <NutritionProgress
        title="Water"
        actual={
          props.report.nutrition ? props.report.nutrition.reportData.water : 0
        }
        target={nutritionGoal.water}
        measure="mL"
        value={
          props.report.nutrition
            ? (props.report.nutrition.reportData.water / nutritionGoal.water) *
              100.0
            : 0
        }
      />
    </Box>
  );
};

export default NutritionData;
