import React from "react";
import "firebase/compat/auth";
import {
  Box,
  Typography,
  TextField,
  Snackbar,
  Link,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Bugsnag from "@bugsnag/js";
import { nanoid } from "nanoid";
import InputAdornment from "@mui/material/InputAdornment";
import RedButton from "../../components/RedButton";
import OrganizationData from "../../data/OrganizationData";
import UserData, { registerNewUser } from "../../data/UserData";
import { UserType } from "../../data/OrganizationData";
import { AccountType } from "../../data/Types";
import colors from "../../themes/Colors";
// import CircleNumberText from "../../components/molecules/CircleNumberText";
import logoImage from "../../assets/Logo.png";
import gymImage from "../../assets/onboarding/noun-gym-4042045-CD202A.png";
import loginImage from "../../assets/onboarding/noun-login-2347597-CD202A.png";
import personImage from "../../assets/onboarding/noun-person-4046839-CD202A.png";
import useEvents from "../../hooks/useEvents";

const styles = {
  pageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 24,
    backgroundColor: colors.lightGray,
  },
  dialogContainer: {
    opacity: 0.92,
    width: "913px",
    height: "auto",
    backgroundColor: "white",
    borderRadius: 12,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: 24,
  },
};

const OnboardingPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [organizationName, setOrganizationName] = React.useState("");
  const [organizationDescription, setOrganizationDescription] =
    React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isCreating, setIsCreating] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const validate = () => {
    if (firstName.trim().length <= 0) {
      return "Enter your first name to continue.";
    }
    if (lastName.trim().length <= 0) {
      return "Enter your last name to continue.";
    }
    if (organizationName.trim().length <= 0) {
      return "Enter the name of your organization to continue.";
    }
    if (email.trim().length <= 0) {
      return "Enter your email address to continue.";
    }
    if (password.trim().length <= 0) {
      return "You need a password to continue.";
    }
    return null;
  };

  async function handleOnboarding() {
    const validation = validate();
    if (validation) {
      showError(validation);
      return;
    }

    setIsCreating(true);

    try {
      const credentials = await registerNewUser(email, password);
      const uid = credentials.user.uid;

      // build new organization.
      const result = await UserData.addUser(uid, {
        firstName: firstName,
        lastName: lastName,
        authId: uid,
        uid: uid,
        type: UserType.coach,
        role: UserType.admin,
        isAccountHolder: true,
        email: credentials.user.email,
      });

      if (result) {
        localStorage.setItem("uid", uid);
        const newOrganization = await OrganizationData.addOrganization(uid, {
          name: organizationName,
          description: organizationDescription,
          email: credentials.user.email,
          coaches: [uid],
          optional: [],
          weeklyOptional: [],
          account: {
            accountHolderUID: uid,
            accountType: AccountType.trial,
            dateCreated: new Date(),
            trialPeriod: 30,
          },
          progressPhotoConfiguration: [
            {
              id: nanoid(),
              name: "Front",
              index: 0,
            },
            {
              id: nanoid(),
              name: "Side",
              index: 1,
            },
            {
              id: nanoid(),
              name: "Back",
              index: 2,
            },
          ],
        });

        if (newOrganization) {
          localStorage.setItem("organizationId", newOrganization);
          navigate("/home");
          setIsCreating(false);
        } else {
          showError("There was an error starting the organization.");
          Bugsnag.notify(
            new Error("There was a problem creating the new organization."),
          );
          setIsCreating(false);
          return;
        }
      } else {
        showError("There was an error starting the organization.");
        Bugsnag.notify(
          new Error(
            "The user was created, but already existed in the database.",
          ),
        );
        setIsCreating(false);
        return;
      }
    } catch (e) {
      setIsCreating(false);
      Bugsnag.notify(e);
      showError(`There was an error starting the organization. ${e.message}`);
    }
  }

  function showError(message) {
    setErrorMessage(message);
    setErrorOpen(true);
  }

  function clearError() {
    setErrorMessage("");
    setErrorOpen(false);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Box style={styles.pageContainer}>
        <Box style={styles.dialogContainer}>
          <a href="https://mighty45.com">
            <img
              alt="Mighty45 Logo"
              style={{}}
              width="146px"
              height="78px"
              src={logoImage}
            />
          </a>
          <Typography
            style={{ marginBottom: 12, marginTop: 12 }}
            variant={"h3"}
          >
            Create your New Organization
          </Typography>
          <Typography
            style={{ marginBottom: 50, marginTop: 0, textAlign: "center" }}
            variant={"h6"}
          >
            Creating an organization? Easily manage your clients, training
            plans, and see all of your clients progress and communication in one
            place. You&apos;re three steps away from managing your fitness
            business in a new way and completely free.
          </Typography>

          {/* <CircleNumberText text="1" /> */}
          <Box style={{ marginTop: 24 }}>
            <img
              alt="Mighty45 Logo"
              style={{}}
              width="100px"
              height="100px"
              src={loginImage}
            />
          </Box>

          <Typography style={{ marginBottom: 0, marginTop: 20 }} variant={"h3"}>
            Provide account information.
          </Typography>

          <Box style={{ marginTop: 30 }}>
            <Typography variant={"h6"}>Email address</Typography>
            <TextField
              variant="outlined"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              style={{ width: 600 }}
            />
          </Box>
          <Box style={{ marginTop: 30, marginBottom: 50 }}>
            <Typography variant={"h6"}>Password</Typography>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              style={{ width: 600 }}
            />
          </Box>

          {/* <CircleNumberText text="2" /> */}
          <Box style={{ marginTop: 24 }}>
            <img
              alt="Mighty45 Logo"
              style={{}}
              width="100px"
              height="100px"
              src={personImage}
            />
          </Box>

          <Typography style={{ marginBottom: 0, marginTop: 20 }} variant={"h3"}>
            Tell us about yourself.
          </Typography>

          <Box style={{ marginTop: 30 }}>
            <Typography variant={"h6"}>First name</Typography>
            <TextField
              variant="outlined"
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              style={{ width: 600 }}
            />
          </Box>

          <Box style={{ marginTop: 30, marginBottom: 50 }}>
            <Typography variant={"h6"}>Last name</Typography>
            <TextField
              variant="outlined"
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              style={{ width: 600 }}
            />
          </Box>

          {/* <CircleNumberText text="3" /> */}
          <Box style={{ marginTop: 24 }}>
            <img
              alt="Mighty45 Logo"
              style={{}}
              width="100px"
              height="100px"
              src={gymImage}
            />
          </Box>

          <Typography style={{ marginBottom: 0, marginTop: 20 }} variant={"h3"}>
            What do you want to call your new organization?
          </Typography>

          <Box style={{ marginTop: 30 }}>
            <Typography variant={"h6"}>Your organizations name</Typography>
            <TextField
              variant="outlined"
              onChange={(event) => {
                setOrganizationName(event.target.value);
              }}
              style={{ width: 600 }}
            />
          </Box>

          <Box style={{ marginTop: 30 }}>
            <Typography variant={"h6"}>Your organizations slogan</Typography>
            <TextField
              variant="outlined"
              onChange={(event) => {
                setOrganizationDescription(event.target.value);
              }}
              style={{ width: 600 }}
            />
          </Box>

          {isCreating && (
            <Box
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: 50,
                textAlign: "center",
              }}
            >
              <CircularProgress />
              <Typography variant={"h6"}>
                Building your organization. We will take you to your home page
                in a moment.
              </Typography>
            </Box>
          )}

          {!isCreating && (
            <Box style={{ marginTop: 50, opacity: "100%" }}>
              <RedButton
                style={{
                  width: 268,
                  height: 60,
                }}
                variant="outlined"
                onClick={() => {
                  handleOnboarding();
                  useEvents().logButtonClick({
                    buttonName: "Create Organization",
                    pageName: "Onboarding",
                  });
                }}
              >
                Continue
              </RedButton>
            </Box>
          )}
          <Typography
            style={{ marginBottom: 50, marginTop: 12, textAlign: "center" }}
            variant={"h6"}
          >
            Need to join an existing organization and have a connect code?{" "}
            <Link style={{ textDecoration: "underline" }} href="/connect">
              Join here.
            </Link>
          </Typography>

          <Typography
            style={{ marginBottom: 50, marginTop: 0, textAlign: "center" }}
            variant={"h6"}
          >
            Already have an account?{" "}
            <Link style={{ textDecoration: "underline" }} href="/signin">
              Sign in.
            </Link>
          </Typography>
        </Box>
      </Box>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => {
          clearError();
        }}
      >
        <Alert
          onClose={() => {
            clearError();
          }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OnboardingPage;
