import { useState, useEffect, useRef } from "react";
import { consola } from "consola";
import { useSetRecoilState, useRecoilValue } from "recoil";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import organizationIdState from "../atoms/organizationIdAtom";
import usersState from "../atoms/usersAtom";

const useUsers = () => {
  const organizationId = useRecoilValue(organizationIdState);
  const setUsersState = useSetRecoilState(usersState);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const unsubscribe = useRef(null);

  useEffect(() => {
    window.addEventListener(
      "beforeunload",
      function () {
        consola.info("****** UNLOAD useUsers - beforeunload");
        if (unsubscribe.current) {
          consola.info("****** UNLOAD useUsers - Calling unsubscribe");
          unsubscribe.current();
        }
      },
      {
        once: true,
      },
    );
  }, []);

  useEffect(() => {
    consola.debug("++++++ useEffect -> useUsers");

    if (!organizationId) {
      return;
    }
    if (unsubscribe.current) {
      consola.debug("++++++ useEffect -> useUsers UNSUBSCRIBE to remake");
      unsubscribe.current();
    }

    unsubscribe.current = firebase
      .firestore()
      .collection("users")
      .where("organizationId", "==", organizationId)
      .orderBy("firstName")
      .onSnapshot((snapshot) => {
        consola.info("++++++ SNAPSHOT -> useUsers");
        const users = snapshot.docs.map((doc) => doc.data());
        setUsers(users);
        setUsersState(users);
        setLoading(false);
      });

    return () => unsubscribe.current();
  }, [organizationId]);

  return { users, loading };
};

export default useUsers;
