import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import { consola } from "consola";

import { nanoid } from "nanoid";

import {
  getFirebaseRecordFromRecipe,
  getIngredientsFromRecipe,
} from "../data/RecipeData";

const useRecipes = ({ organizationId }) => {
  const subscription = React.useRef();
  const subscriptionMealPlans = React.useRef();
  const subscriptionIngredients = React.useRef();

  const [recipes, setRecipes] = useState([]);
  const [mealPlans, setMealPlans] = useState([]);
  const [ingredients, setIngredients] = useState([]);

  /**
   * Fetches the recipes from the database
   */
  useEffect(() => {
    if (!organizationId) {
      return;
    }

    if (subscription.current) {
      subscription.current();
    }

    const subscriptionRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("recipes")
      .where("isArchived", "==", false)
      .orderBy("createdOn", "desc");

    subscription.current = subscriptionRef.onSnapshot((snapshot) => {
      consola.info("++++++ SNAPSHOT -> useRecipes: recipesSubscription")
      const data = snapshot.docs;

      const finalData = data.map((d) => {
        return d.data();
      });

      setRecipes(finalData);
    });
  }, [organizationId]);

  /**
   * Fetches the meal Plans from the database
   */
  useEffect(() => {
    if (!organizationId) {
      return;
    }

    if (subscriptionMealPlans.current) {
      subscriptionMealPlans.current();
    }

    const subscriptionRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("mealPlans")
      .where("isArchived", "==", false)
      .orderBy("createdOn", "desc");

    subscriptionMealPlans.current = subscriptionRef.onSnapshot((snapshot) => {
      consola.info("++++++ SNAPSHOT -> useRecipes: subscriptionMealPlans")
      const data = snapshot.docs;

      const finalData = data.map((d) => {
        return d.data();
      });
      setMealPlans(finalData);
    });
  }, [organizationId]);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    if (subscriptionIngredients.current) {
      subscriptionIngredients.current();
    }

    const subscriptionIngredientsRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("ingredients")
      .orderBy("name", "asc");

    subscriptionIngredients.current = subscriptionIngredientsRef.onSnapshot(
      (snapshot) => {
        consola.info("++++++ SNAPSHOT -> useRecipes: subscriptionIngredients")
        const data = snapshot.docs;

        const finalData = data.map((d) => {
          return d.data();
        });
        setIngredients(finalData);
      },
    );
  }, [organizationId]);

  const ingredientDictionary = React.useMemo(() => {
    const dictionary = {};
    ingredients.forEach((ingredient) => {
      dictionary[ingredient.id] = ingredient;
    });
    return dictionary;
  }, [ingredients]);

  const recipeDictionary = React.useMemo(() => {
    const dictionary = {};
    recipes.forEach((recipe) => {
      dictionary[recipe.id] = recipe;
    });
    return dictionary;
  }, [recipes]);

  const mealPlanDictionary = React.useMemo(() => {
    const dictionary = {};
    mealPlans.forEach((mealPlan) => {
      dictionary[mealPlan.id] = mealPlan;
    });
    return dictionary;
  }, [mealPlans]);

  const editRecipe = React.useCallback(
    async function (recipeData) {
      // Add the log data here.
      const ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("recipes")
        .doc(recipeData.id);

      try {
        consola.info("+++++ WRITE => useRecipes: editRecipe")
        await ref.set(recipeData, { merge: true });
      } catch (e) {
        Bugsnag.notify(new Error("Error saving recipe data"));
      }
    },
    [organizationId],
  );

  const editMealPlan = React.useCallback(
    async function (mealPlanData) {
      // Add the log data here.
      const ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("mealPlans")
        .doc(mealPlanData.id);

      try {
        consola.info("+++++ WRITE => useRecipes: editMealPlan")
        await ref.set(mealPlanData, { merge: true });
      } catch (e) {
        Bugsnag.notify(new Error("Error saving meal plan data"));
      }
    },
    [organizationId],
  );

  const archiveRecipe = React.useCallback(
    async function (recipeId) {
      // Add the log data here.
      const ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("recipes")
        .doc(recipeId);

      try {
        consola.info("+++++ WRITE => useRecipes: archiveRecipe")
        await ref.set({ isArchived: true }, { merge: true });
      } catch (e) {
        Bugsnag.notify(new Error("Error saving recipe data"));
      }
    },
    [organizationId],
  );

  const archiveMealPlan = React.useCallback(
    async function (mealPlanId) {
      // Add the log data here.
      const ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("mealPlans")
        .doc(mealPlanId);

      try {
        consola.info("+++++ WRITE => useRecipes: archiveMealPlan")
        await ref.set({ isArchived: true }, { merge: true });
      } catch (e) {
        Bugsnag.notify(new Error("Error saving meal plan data"));
      }
    },
    [organizationId],
  );

  const editIngredient = React.useCallback(
    async function (ingredientData) {
      // Add the log data here.
      const ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("ingredients")
        .doc(ingredientData.id);

      try {
        consola.info("+++++ WRITE => useRecipes: editIngredient")
        await ref.set(ingredientData, { merge: true });
      } catch (e) {
        Bugsnag.notify(new Error("Error saving ingredient data"));
      }
    },
    [organizationId],
  );

  const getRecipesFromList = React.useCallback(
    async function (recipes) {
      // Add the log data here.
      consola.info("++++++ READ -> useRecipes: getRecipesFromList")
      const result = await Promise.all(
        recipes.map((recipe) => {
          return firebase
            .firestore()
            .collection("organizations")
            .doc(organizationId)
            .collection("recipes")
            .doc(recipe.recipeId)
            .get()
            .then((doc) => {
              if (doc.exists) {
                return {
                  ...doc.data(),
                  recipeId: recipe.id,
                  days: recipe.days,
                };
              }
            });
        }),
      );

      return result;
    },
    [organizationId],
  );

  function getFileExtension(file) {
    if (file.type === "image/png") {
      return "png";
    }
    if (file.type === "image/jpeg") {
      return "jpg";
    }
    return "jpg";
  }

  const uploadImage = React.useCallback(
    async function ({ image, recipe, onStart, onProgress, onComplete }) {
      // Add the log data here.
      if (!image) {
        return;
      }
      if (!recipe) {
        return;
      }
      if (!recipe.id) {
        return;
      }
      if (!organizationId) {
        return;
      }
      if (onStart) {
        onStart();
      }
      const path = `/${organizationId}/recipe_images/${
        recipe.id
      }.${getFileExtension(image)}`;
      const uploadTask = firebase.storage().ref(path).put(image, {
        cacheControl: "private, max-age=3600",
      });

      await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // progress function ...
            if (onProgress) {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
              );
              onProgress(progress);
            }
          },
          (error) => {
            reject(error);
            if (onComplete) {
              onComplete();
            }
          },
          () => {
            firebase
              .storage()
              .ref(path)
              .getDownloadURL()
              .then(async (url) => {
                const ref = firebase
                  .firestore()
                  .collection("organizations")
                  .doc(organizationId)
                  .collection("recipes")
                  .doc(recipe.id);

                // Update the user to include the image.
                consola.info("+++++ WRITE => useRecipes: uploadImage")
                await ref.set(
                  {
                    imageUrl: url,
                    updatedOn: new Date(),
                  },
                  { merge: true },
                );

                if (onComplete) {
                  onComplete();
                }
                resolve(true);
              })
              .catch((e) => {
                reject(e);
              });
          },
        );
      });
    },
    [organizationId],
  );

  const getIngredientById = React.useCallback(
    (id) => {
      if (!id) {
        return null;
      }
      return ingredientDictionary[id];
    },
    [ingredientDictionary],
  );

  const getRecipeById = React.useCallback(
    (id) => {
      if (!id) {
        return null;
      }
      return recipeDictionary[id];
    },
    [recipeDictionary],
  );

  const getMealPlanById = React.useCallback(
    (id) => {
      if (!id) {
        return null;
      }
      return mealPlanDictionary[id];
    },
    [mealPlanDictionary],
  );

  const findIngredientByName = async (organizationId, name) => {
    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("ingredients")
      .where("name", "==", name);
      consola.info("++++++ READ -> useRecipes: findIngredientByName")
    const snapshot = await ref.get();
    if (snapshot.empty) {
      return null;
    }

    const data = snapshot.docs[0].data();
    return data;
  };

  const addIngredientByObject = async (organizationId, ingredient) => {
    // check to see if ingredient exists
    const existingIngredient = await findIngredientByName(
      organizationId,
      ingredient.name,
    );
    if (existingIngredient) {
      return existingIngredient;
    }

    // Add the log data here.
    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("ingredients")
      .doc(ingredient.id);

    const baseIngredient = {
      id: ingredient.id,
      name: ingredient.name,
      unit: ingredient.unit,
      proteins: ingredient.proteins,
      carbs: ingredient.carbs,
      fats: ingredient.fats,
      createdOn: new Date(),
      updatedOn: new Date(),
    };

    try {
      consola.info("+++++ WRITE => useRecipes: addingIngredientByObject")
      await ref.set(baseIngredient, { merge: true });
      
    } catch (e) {
      Bugsnag.notify(new Error("Error saving ingredient data"));
    }

    return baseIngredient;
  };

  const addRecipeFromObject = async (organizationId, recipeData) => {
    // Add ingredients to the database.
    const ingredients = getIngredientsFromRecipe(recipeData);

    // Add them to the ingredient collection
    const promises = ingredients.map((ingredient) => {
      return addIngredientByObject(organizationId, ingredient);
    });
    await Promise.all(promises);

    // Add new recipe
    const id = nanoid();
    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("recipes")
      .doc(id);

    const recipe = getFirebaseRecordFromRecipe(recipeData);

    // add ingredients
    recipe.ingredients = ingredients.map((ingredient) => {
      const id = nanoid();
      return {
        ...ingredient,
        id,
        ingredientId: ingredient.id,
      };
    });
    consola.info("+++++ WRITE => useRecipes: addRecipeFromObject")
    await ref.set(recipe, { merge: true });
  };

  return {
    recipes,
    mealPlans,
    ingredients,
    editRecipe,
    editMealPlan,
    archiveRecipe,
    archiveMealPlan,
    editIngredient,
    uploadImage,
    getRecipesFromList,
    getIngredientById,
    getRecipeById,
    getMealPlanById,
    addRecipeFromObject,
  };
};

export default useRecipes;
