import React from "react";
import { useRecoilValue } from "recoil";
import { confirmAlert } from "react-confirm-alert";
import {
  Box,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import ClientData, {
  deleteClient,
  ClientSubscriptionFilters,
} from "../../../data/ClientData";
import ClientTable from "./clients/ClientTable";
import ClientSummary from "./clients/ClientSummary";
import ClientEditDialog from "./clients/ClientEditDialog";
import userDataState from "../../../atoms/userDataAtom";
import useEvents from "../../../hooks/useEvents";
import organizationIdState from "../../../atoms/organizationIdAtom";

const FilterSelection = ({ filter, onFilterChanged }) => {
  return (
    <RadioGroup
      row
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={filter}
      onChange={(event) => {
        onFilterChanged(event.target.value);
      }}
    >
      <FormControlLabel
        value={ClientSubscriptionFilters.all}
        control={<Radio />}
        label="All"
      />
      <FormControlLabel
        value={ClientSubscriptionFilters.active}
        control={<Radio />}
        label="Active"
      />
      <FormControlLabel
        value={ClientSubscriptionFilters.disabled}
        control={<Radio />}
        label="Disabled"
      />
    </RadioGroup>
  );
};

const ClientsPage = () => {
  const navigate = useNavigate();
  const userDataValues = useRecoilValue(userDataState);
  const organizationId = useRecoilValue(organizationIdState);

  const [filter, setFilter] = React.useState(ClientSubscriptionFilters.active);
  const [mode, setMode] = React.useState("list");

  const [editClientOpen, setClientEditOpen] = React.useState(false);
  const events = useEvents();
  const selectedClientRef = React.useRef(null);

  React.useEffect(() => {
    return () => {
      if (navigate.action === "POP") {
        navigate("/home");
      }
    };
  }, [navigate]);

  const onBackClickCallback = React.useCallback(() => {
    selectedClientRef.current = null;
    localStorage.setItem("clientsPageMode", "list");
    setMode("list");
  }, []);

  const confirmAlertCallback = React.useCallback(() => {
    confirmAlert({
      title: `Maximum number of clients reached`,
      message:
        "You have reached the maximum number of allowed clients on your account. You can view our plan options to increase your allowed clients.",
      buttons: [
        {
          label: "OK",
          onClick: () => {},
        },
        {
          label: "Upgrade Plan",
          onClick: () => {
            navigate("/subscribe");
          },
        },
      ],
    });
  }, [history]);

  const addClientCallback = React.useCallback(
    (isOk) => {
      if (isOk) {
        setClientEditOpen(true);
        return;
      }

      confirmAlertCallback();
    },
    [confirmAlertCallback],
  );

  if (mode === "list") {
    localStorage.setItem("clientsPageMode", "list");

    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {userDataValues.role === "admin" && (
          <Box
            style={{
              marginRight: 24,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FilterSelection filter={filter} onFilterChanged={setFilter} />
            </Box>
          </Box>
        )}
        <ClientTable
          filter={filter}
          onAddClientClicked={(isOk) => {
            addClientCallback(isOk);
            const logEvent = {
              buttonName: "Added Client",
              pageName: "Clients",
            };
            events.logButtonClick(logEvent);
          }}
          onClientClicked={(client) => {
            selectedClientRef.current = client.uid;
            localStorage.setItem("clientsPageMode", "detail");
            setMode("detail");
            const logEvent = {
              subpage: "Client Summary",
              pageName: "Clients",
              uid: client.uid,
            };
            events.logSubpageAccess(logEvent);
          }}
          deleteClientClicked={(client) => {
            confirmAlert({
              title: `Delete the client ${client.firstName} ${client.lastName}?`,
              message:
                "This process cannot be undone and is permanent. All client data and history will be lost! Might be better to keep them disabled. Continue with delete?",
              buttons: [
                {
                  label: "Yes",
                  onClick: async () => {
                    deleteClient({
                      organizationId: organizationId,
                      uid: client.uid,
                    });
                    const logEvent = {
                      buttonName: "Deleted Client",
                      pageName: "Clients",
                    };
                    events.logButtonClick(logEvent);
                  },
                },
                {
                  label: "No",
                  onClick: () => {
                    const logEvent = {
                      buttonName: "Canceled Client Delete",
                      pageName: "Clients",
                    };
                    events.logButtonClick(logEvent);
                  },
                },
              ],
            });
          }}
        />

        {editClientOpen && (
          <ClientEditDialog
            open={editClientOpen}
            onDialogClosed={async (data) => {
              setClientEditOpen(false);

              if (data) {
                setMode("edit");
                ClientData.addClient({
                  ...data,
                  coachId: localStorage.getItem("uid"),
                }).then((uid) => {
                  selectedClientRef.current = uid;
                  setMode("detail");
                });
              }
            }}
          />
        )}
      </Box>
    );
  }

  if (mode === "edit") {
    return (
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: 500,
        }}
      >
        <CircularProgress style={{ marginTop: 24 }}></CircularProgress>
      </Box>
    );
  }

  if (mode === "detail") {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <ClientSummary
          uid={selectedClientRef.current}
          onBackClick={onBackClickCallback}
        />
      </Box>
    );
  }

  return null;
};

export default ClientsPage;
