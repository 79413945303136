import React from "react";
import "./data/FirebaseApp";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

serviceWorker.unregister();
