import React, { useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Checkbox,
  Stack,
  MenuItem,
  Select,
  Divider,
  CircularProgress,
} from "@mui/material";
import Image from "react-cool-img";
import MDEditor from "@uiw/react-md-editor";
import { useTranslation } from "react-i18next";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CreatableSelect from "react-select/creatable";
import { nanoid } from "nanoid";
import { useRecoilValue } from "recoil";
import BlueButton from "../../../../components/BlueButton";
import { consola } from "consola";

import {
  getOpenAIResponse,
  getChatGPTResponse,
} from "../../../../data/GlobalData";
import { DeleteButton } from "../../../../atoms/EditButton";
import units from "../../../../data/Units";
import { myParseFloat } from "../../../../data/Library";
import fileUploadLimitSelector from "../../../../atoms/fileUploadLimitSelector";

const RecipeDialog = ({
  open,
  recipe,
  ingredientsDB,
  editIngredient,
  onClose,
}) => {
  const { t } = useTranslation();
  const fileUploadLimit = useRecoilValue(fileUploadLimitSelector);

  const [id, setId] = React.useState(recipe ? recipe.id : nanoid());
  const [name, setName] = React.useState("");
  const [mealType, setMealType] = React.useState("dinner");
  const [isPublic, setIsPublic] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [instructions, setInstructions] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [servings, setServings] = React.useState(0);
  const [kCals, setkCals] = React.useState(0);
  const [proteins, setProteins] = React.useState(0);
  const [carbs, setCarbs] = React.useState(0);
  const [fats, setFats] = React.useState(0);

  const [imageUrl, setImageUrl] = React.useState(null);
  const [objectURL, setObjectURL] = React.useState(recipe?.imageUrl || null);

  const [ingredients, setIngredients] = React.useState([]);

  const selectIngredients = useMemo(() => {
    return ingredientsDB.map((ingredient) => {
      return {
        value: ingredient.id,
        label: ingredient.name,
        ingredient: ingredient,
      };
    });
  }, [ingredientsDB]);

  React.useEffect(() => {
    if (!recipe) {
      setId(nanoid());
      setName("");
      setMealType("dinner");
      setIsPublic(false);
      setDescription("");
      setInstructions("");
      setkCals(0);
      setServings(1);
      setProteins(0);
      setCarbs(0);
      setFats(0);
      setIngredients([]);
    } else {
      setId(recipe.id);
      setName(recipe.name);
      setMealType(recipe.mealType);
      setIsPublic(recipe.isPublic);
      setDescription(recipe.description);
      setInstructions(recipe.instructions);
      setkCals(recipe.kCals);
      setServings(recipe.servings || 0);
      setProteins(recipe.proteins);
      setCarbs(recipe.carbs);
      setFats(recipe.fats);
      setIngredients(recipe.ingredients);
    }
  }, [recipe]); // eslint-disable-line

  const handleCalculateMacros = () => {
    let totalProteins = 0;
    let totalCarbs = 0;
    let totalFats = 0;
    let totalkCals = 0;
    ingredients.forEach((ingredient) => {
      const proteins = myParseFloat(ingredient.proteins);
      const carbs = myParseFloat(ingredient.carbs);
      const fats = myParseFloat(ingredient.fats);

      if (proteins) {
        totalProteins += proteins * ingredient.amount;
      }
      if (carbs) {
        totalCarbs += carbs * ingredient.amount;
      }
      if (fats) {
        totalFats += fats * ingredient.amount;
      }
    });
    totalkCals = totalProteins * 4 + totalCarbs * 4 + totalFats * 9;
    setProteins((totalProteins / servings).toFixed(2));
    setCarbs((totalCarbs / servings).toFixed(2));
    setFats((totalFats / servings).toFixed(2));
    setkCals((totalkCals / servings).toFixed(2));
  };

  React.useEffect(() => {
    handleCalculateMacros();
  }, [servings, ingredients]); // eslint-disable-line

  function formatWithAI2() {
    setLoading(true);
    getChatGPTResponse({
      messages: [
        {
          role: "system",
          content: "You are a markdown syntax expert that knows JSON format",
        },
        {
          role: "system",
          content: "You correct grammar and spelling mistakes where you can",
        },
        {
          role: "assistant",
          content: `{"description": "Here is a description", "ingredients": [{"name": "chicken breast", "amount": "1", "unit": "kg"}, {"name": "salt", "amount": "1", "unit": "tsp"}], "instructions": "Here are some instructions"}`,
        },
        {
          role: "user",
          content:
            "Respond with JSON. The description and instructions should be markdown.",
        },
        {
          role: "user",
          content:
            "If there are ingredients in the description, convert the ingredients to a separate JSON array of values.",
        },
        {
          role: "user",
          content:
            "Create a useful description of the recipe using the ingredients and the instructions and title. Add the description to the JSON as its own key and value.",
        },
        {
          role: "user",
          content:
            "The description is between the START and END. Convert the description to markdown. Generate headers and subheaders based on the description using markdown",
        },
        {
          role: "user",
          content: "START",
        },
        {
          role: "user",
          content: description,
        },
        {
          role: "user",
          content: "END",
        },
        {
          role: "user",
          content: "Respond with JSON",
        },
      ],
    })
      .then((response) => {
        if (response && response.response) {
          const description = response.response[0].message.content;
          const objectValue = JSON.parse(description);

          setName(objectValue.title);
          setDescription(objectValue.description);
          setInstructions(objectValue.instructions);
        }
        setLoading(false);
      })
      .catch((error) => {
        consola.log(error);
        setLoading(false);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function formatWithAI() {
    setLoading(true);
    getOpenAIResponse(
      `Take the description between the capitalized words --START-- and --END-- and create markdown syntax given the content of the text.
  Do not add any more text to the description or take away text. The text between the capitalized words is the description.
  
  --START-- ${description} --END--`,
      0.25,
    )
      .then((response) => {
        if (response && response.response) {
          // remove leading new lines
          response.response = response.response.replace(/^\s+/, "");
          setDescription(response.response);
        }
        setLoading(false);
      })
      .catch((error) => {
        consola.log(error);
        setLoading(false);
      });
  }

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
    >
      <DialogTitle>{"Recipe"}</DialogTitle>
      <DialogContent>
        <Box style={{ marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Name:</Typography>
          <TextField
            size="small"
            variant="outlined"
            style={{ width: "100%" }}
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </Box>
        <Box style={{ marginTop: 18 }}>
          <Stack direction="row" spacing={4} sx={{ alignItems: "center" }}>
            <Box>
              <Typography style={{ width: 200 }}>Meal Type:</Typography>

              <Select
                size="small"
                style={{ width: 512 }}
                variant="outlined"
                value={mealType}
                onChange={(event) => {
                  setMealType(event.target.value);
                }}
              >
                <MenuItem value="breakfast">{t("breakfast")}</MenuItem>
                <MenuItem value="lunch">{t("lunch")}</MenuItem>
                <MenuItem value="dinner">{t("dinner")}</MenuItem>
                <MenuItem value="snack">{t("snack")}</MenuItem>
                <MenuItem value="other">{t("other")}</MenuItem>
              </Select>
            </Box>

            <Stack direction="row" sx={{ height: 45, alignItems: "center" }}>
              <Stack>
                <Typography style={{ marginRight: 12 }}>
                  Public Recipe?
                </Typography>
                <Typography
                  style={{ marginRight: 10, color: "GrayText", maxWidth: 250 }}
                >
                  (Public recipes are accessible by all clients)
                </Typography>
              </Stack>
              <Checkbox
                checked={isPublic || false}
                onChange={() => setIsPublic(!isPublic)}
              />
            </Stack>

            <Stack
              direction={"row"}
              spacing={3}
              alignItems="center"
              style={{ marginTop: 12 }}
            >
              <Box>
                <label>
                  <AddAPhotoIcon
                    color="primary"
                    style={{
                      color: "black",
                      height: 45,
                      transform: "scale(1.5)",
                    }}
                  />
                  <input
                    hidden={true}
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => {
                      //
                      if (e.target.files[0]) {
                        const image = e.target.files[0];

                        if (image.size > fileUploadLimit) {
                          alert(
                            `Image must be less than ${
                              fileUploadLimit / 1000000
                            }MB`,
                          );
                          return;
                        }
                        // get image url
                        const objectURL = URL.createObjectURL(image);
                        setObjectURL(objectURL);
                        setImageUrl(image);
                      }
                    }}
                  />
                </label>
              </Box>
              <Box
                style={{
                  width: 100,
                  height: 100,
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                <Image
                  src={objectURL}
                  alt="receipe image"
                  height={100}
                  width={100}
                  style={{ objectFit: "cover", objectPosition: "center" }}
                />
                {/* <img id="target" src={objectURL} height={100} /> */}
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box style={{ marginTop: 24 }}>
          <Typography style={{ width: 200 }}>Add a Description:</Typography>

          <div data-color-mode="light">
            <MDEditor
              preview="live"
              placeholder="Enter healthy recipe description or video link here"
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>
          <Stack direction={"row"} spacing={1} alignItems={"center"} mt={1}>
            <Button variant="outlined" onClick={formatWithAI2}>
              Format to markdown automatically
            </Button>
            {loading && <CircularProgress size={32} variant="indeterminate" />}
          </Stack>
        </Box>

        <Divider sx={{ mt: 2 }} />

        <Box style={{ marginTop: 18 }}>
          <Grid container spacing={2}>
            <Grid item>
              <Stack>
                <Typography>Servings</Typography>
                <TextField
                  size="small"
                  value={servings}
                  variant="outlined"
                  type={"number"}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      setServings(1);
                      return;
                    }
                    if (myParseFloat(event.target.value) < 1) {
                      setServings(1);
                      return;
                    }
                    setServings(myParseFloat(event.target.value));
                  }}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack>
                <Typography>Calories (kCal) / serving</Typography>
                <TextField
                  size="small"
                  value={kCals}
                  variant="outlined"
                  type={"number"}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      setkCals(0);
                      return;
                    }
                    setkCals(myParseFloat(event.target.value));
                  }}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack>
                <Typography>Proteins / serving</Typography>
                <TextField
                  size="small"
                  value={proteins}
                  variant="outlined"
                  type={"number"}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      setProteins(0);
                      return;
                    }
                    setProteins(myParseFloat(event.target.value));
                  }}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack>
                <Typography>Carbs / serving</Typography>
                <TextField
                  size="small"
                  value={carbs}
                  variant="outlined"
                  type={"number"}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      setCarbs(0);
                      return;
                    }
                    setCarbs(myParseFloat(event.target.value));
                  }}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack>
                <Typography>Fats / serving</Typography>
                <TextField
                  size="small"
                  value={fats}
                  variant="outlined"
                  type={"number"}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      setFats(0);
                      return;
                    }
                    setFats(myParseFloat(event.target.value));
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ mt: 2 }} />

        <Box>
          <Typography style={{ width: 200 }}>Add Ingredients:</Typography>
          {ingredients.map((ingredient) => {
            const value = {
              label: ingredient.name,
              value: ingredient.ingredientId,
            };
            return (
              <Box
                key={ingredient.id}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Box style={{ width: 600, marginRight: 8 }}>
                  <CreatableSelect
                    options={selectIngredients}
                    value={value}
                    maxMenuHeight={100}
                    onChange={(event) => {
                      ingredient.ingredientId = event.value;
                      ingredient.name = event.label;

                      if (event.ingredient.unit) {
                        ingredient.unit = event.ingredient.unit;
                      }
                      if (event.ingredient.proteins) {
                        ingredient.proteins = event.ingredient.proteins;
                      }
                      if (event.ingredient.carbs) {
                        ingredient.carbs = event.ingredient.carbs;
                      }
                      if (event.ingredient.fats) {
                        ingredient.fats = event.ingredient.fats;
                      }

                      setIngredients([...ingredients]);
                    }}
                    onCreateOption={async (inputValue) => {
                      const newIngredient = {
                        id: nanoid(),
                        name: inputValue,
                        createdOn: new Date(),
                      };

                      editIngredient(newIngredient).then(() => {
                        ingredient.ingredientId = newIngredient.id;
                        ingredient.name = newIngredient.name;
                        ingredient.unit = null;
                        ingredient.proteins = 0;
                        ingredient.carbs = 0;
                        ingredient.fats = 0;
                        setIngredients([...ingredients]);
                      });
                    }}
                  />
                </Box>
                <TextField
                  size="small"
                  type="number"
                  placeholder="e.g. 0.5"
                  value={ingredient.amount}
                  variant="outlined"
                  onChange={(event) => {
                    if (event.target.value === "") {
                      ingredient.amount = 0;
                      setIngredients([...ingredients]);
                      return;
                    }

                    ingredient.amount = myParseFloat(event.target.value);
                    setIngredients([...ingredients]);
                  }}
                />
                <Select
                  sx={{ width: 100 }}
                  size="small"
                  value={ingredient.unit || "none"}
                  onChange={(event) => {
                    ingredient.unit = event.target.value;
                    setIngredients([...ingredients]);
                  }}
                >
                  {Object.keys(units).map((unit) => {
                    return (
                      <MenuItem key={unit} value={unit}>
                        {t(`units.${unit}`)}
                      </MenuItem>
                    );
                  })}
                </Select>
                <DeleteButton
                  onClick={() => {
                    setIngredients(
                      ingredients.filter((item) => item.id !== ingredient.id),
                    );
                  }}
                />
              </Box>
            );
          })}

          <Box style={{ marginTop: 20 }}>
            <Stack direction={"row"} spacing={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  setIngredients([
                    ...ingredients,
                    {
                      id: nanoid(),
                      ingredientId: undefined,
                      name: "",
                      amount: "",
                      unit: null,
                    },
                  ]);
                }}
                color="primary"
              >
                Add Ingredient
              </Button>

              <Button
                variant="outlined"
                onClick={handleCalculateMacros}
                color="primary"
              >
                Calculate Macros
              </Button>
            </Stack>
          </Box>
        </Box>

        <Divider sx={{ mt: 2 }} />

        <Box style={{ marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Add Instructions:</Typography>
          <div data-color-mode="light">
            <MDEditor
              preview="live"
              placeholder="e.g. Step 1: Preheat oven to 350F."
              value={instructions}
              onChange={(value) => {
                setInstructions(value);
              }}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (onClose) {
              onClose(
                {
                  id,
                  name,
                  mealType,
                  isPublic,
                  description,
                  instructions,
                  kCals,
                  servings,
                  proteins,
                  carbs,
                  fats,
                  ingredients,
                  isArchived: false,
                  updatedOn: new Date(),
                  createdOn: recipe ? recipe.createdOn : new Date(),
                },
                imageUrl,
              );
            }
          }}
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default RecipeDialog;
