import React from "react";
import {
  TableCell,
  Box,
  Grid,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VideocamIcon from "@mui/icons-material/Videocam";
import ImageIcon from "@mui/icons-material/Image";

import SuperSetExerciseRow from "./SuperSetExerciseRow";

const ExerciseNameTableCell = ({
  exercise,
  exerciseKeys,
  onFieldClick = undefined,
}) => {
  const [open, setOpen] = React.useState(true);

  if (exercise.type === "super_set") {
    return (
      <TableCell>
        <Box>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Grid>
            <Grid item>
              <Typography style={{ fontSize: "1.15em", fontWeight: "600" }}>
                {exercise.superSetName ? exercise.superSetName : "Super Set"}
              </Typography>
            </Grid>
          </Grid>
          <SuperSetExerciseRow
            open={open}
            exercise={exercise}
            exerciseKeys={exerciseKeys}
            onFieldClick={onFieldClick}
          />
        </Box>
      </TableCell>
    );
  }
  if (exercise.exercise in exerciseKeys) {
    return (
      <TableCell>
        <Stack>
          <Typography>{exerciseKeys[exercise.exercise].name}</Typography>

          <Stack direction="row">
            {exerciseKeys[exercise.exercise].videos &&
              exerciseKeys[exercise.exercise].videos.length > 0 && (
                <VideocamIcon />
              )}

            {exerciseKeys[exercise.exercise].images &&
              exerciseKeys[exercise.exercise].images.length > 0 && (
                <ImageIcon />
              )}
          </Stack>
        </Stack>
      </TableCell>
    );
  }
  return null;
};

export default ExerciseNameTableCell;
