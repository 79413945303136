import React from "react";
import { consola } from "consola";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// export type OrganizationDataProps = {
//   uid,
// };

const useOrganizationSubscription = ({ uid }) => {
  const [organizationData, setOrganizationData] = React.useState();
  const subscribeRef = React.useRef();

  React.useEffect(() => {
    if (!uid) {
      return;
    }

    const getData = async () => {
      const userRef = firebase.firestore().collection("users").doc(uid);
      consola.info("++++++ READ -> useOrganizationSubscription: getData")
      const userData = await userRef.get();
      if (userData.exists) {
        const userDataValues = userData.data();

        if (!userDataValues) {
          return;
        }

        const ref = firebase
          .firestore()
          .collection("organizations")
          .doc(userDataValues.organizationId);

        subscribeRef.current = ref.onSnapshot((doc) => {
          consola.info("++++++ SNAPSHOT -> useOrganizationSubscription: getData")
          if (doc.exists) {
            setOrganizationData(doc.data());
          }
        });
      }
    };

    getData();

    return () => {
      if (subscribeRef.current) {
        subscribeRef.current();
      }
    };
  }, [uid]);

  return organizationData;
};

export default useOrganizationSubscription;
