import React, { useEffect } from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import { remoteConfig } from "../../data/FirebaseApp";
import { getNumber } from "firebase/remote-config";

import useIdle from "../../hooks/useIdle";

function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  if (minutes === 0) {
    return seconds + " seconds ";
  }
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds + " seconds ";
}

function StillTherePage({ onManualLogout, onForcedLogout }) {
  const test = false;
  const idleTimeout = test ? 1 : getNumber(remoteConfig, "idleTimeoutProd"); // in minutes

  function handleIdle() {
    setShowModal(true);
  }

  const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: idleTimeout });

  const [remainingTime, setRemainingTime] = React.useState(60);
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    let interval;

    if (isIdle && showModal) {
      interval = setInterval(() => {
        setRemainingTime(
          (prevRemainingTime) =>
            prevRemainingTime > 0 ? prevRemainingTime - 1 : 0, //reduces the second by 1
        );
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isIdle, showModal]);

  useEffect(() => {
    if (remainingTime === 0 && showModal) {
      // alert("Time out!");
      setShowModal(false);
      onForcedLogout();
    }
  }, [remainingTime, showModal]); // this is responsoble for logging user out after timer is down to zero and they have not clicked anything

  return (
    <Dialog
      maxWidth="lg"
      open={showModal}
      onClose={() => {
        if (!showModal) {
          setShowModal(false);
        }
      }}
    >
      <DialogTitle>Still There?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to logged out due to inactivity. You will return to the
          sign-in screen in: {millisToMinutesAndSeconds(remainingTime * 1000)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setShowModal(false);
          }}
        >
          {" "}
          Stay Logged In
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onManualLogout();
          }}
        >
          {" "}
          Log Out
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StillTherePage;
