import React from "react";
import { Box, Typography, Menu, MenuItem, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// const SubTitle = ({ subTitle }) => {
//   if (subTitle) {
//     return (
//       <Typography style={{ fontSize: 12, fontWeight: "normal", marginTop: 8 }}>
//         {subTitle}
//       </Typography>
//     );
//   }
//   return null;
// };

function getIcon(id) {
  switch (id) {
    case "protocol":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="30"
          viewBox="0 0 28 30"
          fill="none"
        >
          <path
            d="M14 5.66665C12.52 5.66665 11.3333 6.85331 11.3333 8.33331C11.3333 9.81331 12.52 11 14 11C15.48 11 16.6667 9.81331 16.6667 8.33331C16.6667 6.85331 15.48 5.66665 14 5.66665ZM27.3333 0.333313V6.99998H24.6667V4.33331H3.33332V6.99998H0.666656V0.333313H3.33332V2.99998H24.6667V0.333313H27.3333ZM18 14.0133V29.6666H15.3333V23H12.6667V29.6666H9.99999V14.0133C7.23999 12.56 5.33332 9.66665 5.33332 6.33331V5.66665H7.99999V6.33331C7.99999 9.66665 10.6667 12.3333 14 12.3333C17.3333 12.3333 20 9.66665 20 6.33331V5.66665H22.6667V6.33331C22.6667 9.66665 20.76 12.56 18 14.0133Z"
            fill="black"
          />
        </svg>
      );

    case "mealplan":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M26.6667 13.3333C29.3333 17.3333 22.6667 29.3333 20 29.3333C17.3333 29.3333 17.3333 28 16 28C14.6667 28 14.6667 29.3333 12 29.3333C9.33333 29.3333 2.66666 17.3333 5.33333 13.3333C8 9.33332 12 9.33332 14.6667 10.6667V6.66665C7.17333 10.76 5.48 5.03999 5.48 5.03999C5.48 5.03999 9.02666 0.253319 14.6667 6.66665V3.99999H17.3333V10.6667C20 9.33332 24 9.33332 26.6667 13.3333Z"
            fill="black"
          />
        </svg>
      );

    case "coach":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M16 16C16.5305 16 17.0392 16.2107 17.4142 16.5858C17.7893 16.9609 18 17.4696 18 18C18 18.5305 17.7893 19.0392 17.4142 19.4142C17.0392 19.7893 16.5305 20 16 20C15.4696 20 14.9609 19.7893 14.5858 19.4142C14.2107 19.0392 14 18.5305 14 18C14 17.4696 14.2107 16.9609 14.5858 16.5858C14.9609 16.2107 15.4696 16 16 16ZM16 2.66669C17.751 2.66669 19.4848 3.01156 21.1025 3.68163C22.7201 4.35169 24.19 5.33382 25.4281 6.57193C26.6662 7.81004 27.6484 9.2799 28.3184 10.8976C28.9885 12.5152 29.3334 14.2491 29.3334 16C29.3334 19.5362 27.9286 22.9276 25.4281 25.4281C22.9276 27.9286 19.5362 29.3334 16 29.3334C14.2491 29.3334 12.5152 28.9885 10.8976 28.3184C9.2799 27.6484 7.81004 26.6662 6.57193 25.4281C4.07144 22.9276 2.66669 19.5362 2.66669 16C2.66669 12.4638 4.07144 9.07241 6.57193 6.57193C9.07241 4.07144 12.4638 2.66669 16 2.66669ZM16 21.3334C16.96 21.3334 17.8667 21.5334 18.72 22C19.5734 22.4 20 22.9334 20 23.56V25.88C21.7867 25.08 22.6667 24.1067 22.6667 22.9334V17.0667C22.6667 16 22 15.1334 20.6 14.4C19.2 13.68 17.6667 13.3334 16 13.3334C14.3334 13.3334 12.8 13.68 11.4 14.4C10 15.1334 9.33335 16 9.33335 17.0667V22.9334C9.33335 24 10.04 24.92 11.5067 25.6267C12.96 26.3334 14.4534 26.6667 16 26.6667L17.3334 26.56V23.88L16 24C14.6667 24 13.3334 23.7334 12.0667 23.1867C12.2267 22.6667 12.7067 22.2534 13.5067 21.88C14.2934 21.5067 15.12 21.3334 16 21.3334ZM16 5.33335C15.116 5.33335 14.2681 5.68454 13.643 6.30966C13.0179 6.93479 12.6667 7.78263 12.6667 8.66669C12.6667 9.55074 13.0179 10.3986 13.643 11.0237C14.2681 11.6488 15.116 12 16 12C16.8841 12 17.7319 11.6488 18.357 11.0237C18.9822 10.3986 19.3334 9.55074 19.3334 8.66669C19.3334 7.78263 18.9822 6.93479 18.357 6.30966C17.7319 5.68454 16.8841 5.33335 16 5.33335Z"
            fill="black"
          />
        </svg>
      );
  }
}

const TitledBox = ({
  id,
  title,
  summary,
  editName,
  menuItems,
  style,
  summaryComponent,
  callToAction,
  onEdit,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      boxShadow={3}
      borderRadius={2}
      style={{
        backgroundColor: "#F6F7FB",
        minHeight: 180,
        minWidth: 300,
        padding: 20,
        ...style,
      }}
    >
      <Box>
        <Box
          display="flex"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Box
            display="flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            {getIcon(id)}
            <Typography
              display={"inline-flex"}
              style={{ marginLeft: 10, fontSize: 24, fontWeight: 600 }}
            >
              {title}
            </Typography>
          </Box>
          <MoreVertIcon style={{}} onClick={handleClick} />
        </Box>
        <Divider />
        {summaryComponent && summaryComponent()}
        {!summaryComponent && summary && summary.length > 0 && (
          <Typography
            textAlign={"center"}
            style={{
              fontSize: 18,
              marginTop: 25,
            }}
          >
            {summary}
          </Typography>
        )}
        {callToAction && callToAction()}
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            if (onEdit) {
              onEdit(id);
            }
          }}
        >
          {`${editName ? editName : "Edit"}`}
        </MenuItem>

        {menuItems &&
          menuItems.map((menuItem, index) => {
            if (menuItem.type === "menu") {
              return (
                <MenuItem
                  key={menuItem.menuName}
                  onClick={() => {
                    setAnchorEl(null);
                    if (menuItem.menuFunction) {
                      menuItem.menuFunction(id);
                    }
                  }}
                >
                  {`${menuItem.menuName}`}
                </MenuItem>
              );
            } else {
              return <Divider key={index} />;
            }
          })}
      </Menu>
    </Box>
  );
};

export default TitledBox;
