import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";

import exerciseDBKeysSelector from "../../atoms/exerciseDBkeysSelector";

function getRepsString(exercise) {
  if (!exercise.repsMax) {
    return `Reps: ${exercise.reps}`;
  }
  return `Reps: ${exercise.reps}-${exercise.repsMax}`;
}

function getTempo(exercise, t) {
  if (exercise.tempo) {
    return (
      <Typography>{`${t("exercise_columns.tempo")}: ${
        exercise.tempo
      }`}</Typography>
    );
  }
  return null;
}

function getRest(exercise) {
  if (exercise.rest) {
    return <Typography>{`Rest: ${exercise.rest}`}</Typography>;
  }
  return null;
}

export default React.memo(({ exercises }) => {
  const exerciseKeys = useRecoilValue(exerciseDBKeysSelector);
  const { t } = useTranslation();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 30,
        marginTop: 20,
      }}
    >
      {exercises &&
        exercises.map((e) => {
          return (
            <Box
              key={e.id}
              boxShadow={2}
              style={{
                padding: 23,
                width: 300,
                backgroundColor: "white",
                marginTop: 8,
                marginLeft: 2,
                marginBottom: 8,
                marginRight: 8,
                borderRadius: 8,
              }}
            >
              <Box
                style={{ display: "flex", flexDirection: "row" }}
                justifyContent="space-between"
              >
                <Typography style={{ fontWeight: "500" }}>
                  {exerciseKeys[e.exercise]
                    ? exerciseKeys[e.exercise].name
                    : "Unknown"}
                </Typography>
              </Box>
              <Box
                style={{ display: "flex", flexDirection: "row" }}
                justifyContent="space-between"
              >
                <Typography>{getRepsString(e)}</Typography>
                {getTempo(e, t)}
                {getRest(e)}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
});
