import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { EditButton, DeleteButton } from "../../../../atoms/EditButton";

const RecipesDataGrid = ({ recipes, onClick, onEdit, onDelete }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const storedModel = localStorage.getItem("recipesDataGridColumns");
  const [visibleModel, setVisibilityModel] = React.useState(
    storedModel ? JSON.parse(storedModel) : {},
  );

  const columns = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
      },
      {
        field: "mealType",
        headerName: "Meal Type",
        headerAlign: "center",
        align: "center",
        width: 125,
        renderCell: (params) => {
          return t(params.value);
        },
      },
      {
        field: "isPublic",
        headerName: "Public?",
        type: "boolean",
        headerAlign: "center",
        align: "center",
        width: 85,
        renderCell: (params) => {
          if (!params.value) {
            return <Box>No</Box>;
          }

          return <Box style={{ color: theme.palette.secondary.main }}>Yes</Box>;
        },
      },
      {
        field: "ingredients",
        headerName: "Ingredients",
        headerAlign: "center",
        align: "center",
        width: 100,
        valueGetter: (params) => {
          return params.row.ingredients.length;
        },
      },
      {
        field: "servings",
        headerName: "Serves",
        width: 90,
        type: "number",
        headerAlign: "center",
        align: "center",
      },
      {
        field: "kCals",
        headerName: "Calories",
        width: 90,
        type: "number",
        headerAlign: "center",
        align: "center",
      },
      {
        field: "proteins",
        headerAlign: "center",
        align: "center",
        headerName: "Proteins (g)",
        width: 90,
        type: "number",
      },
      {
        field: "carbs",
        headerName: "Carbs (g)",
        width: 90,
        type: "number",
        headerAlign: "center",
        align: "center",
      },
      {
        field: "fats",
        headerName: "Fats (g)",
        width: 90,
        type: "number",
        headerAlign: "center",
        align: "center",
      },
      {
        headerAlign: "center",
        align: "right",
        minWidth: 120,
        field: "action",
        headerName: "",
        sortable: false,
        hideable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Stack spacing={1} direction="row">
              <EditButton
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(params.row);
                }}
              />
              <DeleteButton
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(params.row);
                }}
              />
            </Stack>
          );
        },
      },
    ];
  }, []);

  return (
    <Box sx={{ flexGrow: 1, height: 200 }}>
      <DataGrid
        sx={{
          ".MuiDataGrid-sortIcon": {
            color: theme.palette.primary.main,
          },
          ".MuiDataGrid-filterIcon": {
            color: theme.palette.primary.main,
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          ".MuiDataGrid-menuIconButton": {
            color: theme.palette.primary.main,
          },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
          height: "100%",
        }}
        columnVisibilityModel={visibleModel}
        disableSelectionOnClick
        rows={recipes}
        columns={columns}
        getRowHeight={() => {
          return "auto";
        }}
        onRowClick={(params) => {
          onClick(params.row);
        }}
        onColumnVisibilityModelChange={(model) => {
          localStorage.setItem("recipesDataGridColumns", JSON.stringify(model));
          setVisibilityModel(model);
        }}
      />
    </Box>
  );
};

export default RecipesDataGrid;
