import React from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Button,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { nanoid } from "nanoid";
import { useRecoilValue } from "recoil";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

import SplitDialog from "./SplitDialog";
import ExerciseReorderDialog from "./ExerciseReorderDialog";
import SplitExerciseDialog from "./SplitExerciseDialog";
import SplitList from "./SplitList";
import SplitReorderDialog from "./SplitReorderDialog";
import ProtocolDialog from "./ProtocolDialog";
import ProtocolData from "../../../../data/ProtocolData";
import { consola } from "consola";

import LabelChips from "../../../../components/molecules/LabelChips";
import useLabels from "../../../../hooks/useLabels";
import ChooseExistingWorkoutDialog from "./ChooseExistingWorkoutDialog";
import useAllWorkouts from "../../../../hooks/useAllWorkouts";

import {
  EditButton,
  PrintButton,
  ExportButton,
} from "../../../../atoms/EditButton";

import organizationIdSelector from "../../../../atoms/organizationIdSelector";
import useEvents from "../../../../hooks/useEvents";

const ViewMode = {
  list: "list",
  list_details: "list_details",
  details: "details",
};

const ProtocolDetails = (props) => {
  const { labels } = useLabels();
  const organizationId = useRecoilValue(organizationIdSelector);
  const { workouts } = useAllWorkouts({ protocolId: props.protocol.id });

  const [protocol, setProtocol] = React.useState(props.protocol);
  const [splitDialogOpen, setSplitDialogOpen] = React.useState(false);
  const [chooseExistingWorkoutDialogOpen, setChooseExistingWorkoutDialogOpen] =
    React.useState(false);
  const [splitExerciseDialogOpen, setSplitExerciseDialogOpen] =
    React.useState(false);
  const [exerciseReorderDialogOpen, setExerciseReorderDialogOpen] =
    React.useState(false);
  const [splitReorderDialogOpen, setSplitReorderDialogOpen] =
    React.useState(false);

  const [protocolEditDialogOpen, setProtocolEditDialogOpen] =
    React.useState(false);

  const [selectedSplit, setSelectedSplit] = React.useState(null);
  const events = useEvents();

  // const storedViewMode = localStorage.getItem("workoutViewMode");

  // const [mode, setMode] = React.useState(
  //   storedViewMode ? storedViewMode : ViewMode.list,
  // );
  React.useEffect(() => {
    let subscriptionStored;

    ProtocolData.getProtocolSubscription({
      protocolId: protocol.id,
      onChange: (data) => {
        setProtocol(data);
      },
      onSubscription: (subscription) => {
        subscriptionStored = subscription;
      },
    });

    return () => {
      if (subscriptionStored) {
        subscriptionStored();
      }
    };
  }, [props.protocol, protocol.id]);

  const handlePrintViewCallback = React.useCallback(() => {
    window.open(`/plan/${organizationId}/${protocol.id}`, "_blank");
  }, [organizationId, protocol]);

  const handleExportCallback = React.useCallback(() => {
    const downloadFile = (data, protocolId) => {
      // create file in browser
      const fileName = `training_plan_${protocolId}`;
      const json = JSON.stringify(data, null, 2);
      const blob = new Blob([json], { type: "application/json" });
      const href = URL.createObjectURL(blob);

      // create "a" HTLM element with href to file
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName + ".json";
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    };

    downloadFile(workouts, protocol.id);
  }, [workouts, protocol.id]);

  function handleSplitEdit(split) {
    events.logButtonClick({
      buttonName: "Edit Split",
      pageName: "Protocols",
      tabName: protocol,
    });
    setSelectedSplit(split);
    setSplitDialogOpen(true);
  }

  async function createRestSplit() {
    const values = {
      id: nanoid(),
      protocolId: protocol.id,
      name: "Rest Day",
      description: "Take it easy today.",
      isRestSplit: true,
      restDays: 1,
    };
    ProtocolData.addSplitFlat(values, true);
  }

  return (
    <Box style={{ padding: 20 }}>
      <Breadcrumbs separator=">">
        <Link
          onClick={() => {
            if (props.onBackClick) {
              props.onBackClick();
            }
          }}
        >
          {props.breadCrumb ? props.breadCrumb : "Training Plans"}
        </Link>
        <Typography>{`${protocol.name}`}</Typography>
      </Breadcrumbs>

      <Box style={{ marginTop: 20 }}>
        <Grid container justifyContent="space-between">
          <Grid item style={{ width: "40%" }}>
            <Typography style={{ fontSize: 30, fontWeight: "bold" }}>
              {protocol.name}
              <EditButton
                onClick={() => {
                  setProtocolEditDialogOpen(true);
                  events.logButtonClick({
                    buttonName: "Edit Protocol",
                    pageName: "Protocols",
                    tabName: protocol,
                  });
                }}
              />
            </Typography>
            <Typography style={{ fontSize: 15, fontWeight: "normal" }}>
              {protocol.description}
            </Typography>
            <Typography style={{ fontSize: 15, fontWeight: "normal" }}>
              {protocol.id}
            </Typography>
            <Box style={{ marginTop: 12 }}>
              <LabelChips labels={protocol.protocolLabels} allLabels={labels} />
            </Box>
          </Grid>

          <Grid item style={{ marginTop: 12 }}>
            <PrintButton
              onClick={() => {
                events.logButtonClick({
                  buttonName: "Print Protocol",
                  pageName: "Protocols",
                  tabName: protocol,
                });
                handlePrintViewCallback();
              }}
            ></PrintButton>

            <ExportButton
              style={{ marginRight: 4 }}
              onClick={() => {
                events.logButtonClick({
                  buttonName: "Download Protocol",
                  pageName: "Protocols",
                  tabName: protocol,
                });
                handleExportCallback();
              }}
            ></ExportButton>
            <Button
              style={{ marginRight: 12, marginLeft: 12 }}
              variant="contained"
              color="primary"
              onClick={() => {
                events.logButtonClick({
                  buttonName: "Reorder Workouts",
                  pageName: "Protocols",
                  tabName: protocol,
                });
                setSplitReorderDialogOpen(true);
              }}
            >
              Reorder Workouts
            </Button>
            <Button
              style={{ marginRight: 12 }}
              variant="contained"
              color="primary"
              onClick={() => {
                events.logButtonClick({
                  buttonName: "Add Rest Day",
                  pageName: "Protocols",
                  tabName: protocol,
                });
                setSelectedSplit(null);
                createRestSplit();
              }}
            >
              Add Rest Day
            </Button>
            <Button
              style={{ marginRight: 12 }}
              variant="contained"
              color="primary"
              onClick={() => {
                events.logButtonClick({
                  buttonName: "Add Existing Workout",
                  pageName: "Protocols",
                  tabName: protocol,
                });
                setChooseExistingWorkoutDialogOpen(true);
              }}
            >
              Add Existing Workout
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                events.logButtonClick({
                  buttonName: "Add New Workout",
                  pageName: "Protocols",
                  tabName: protocol,
                });
                setSelectedSplit(null);
                setSplitDialogOpen(true);
              }}
            >
              Add New Workout
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Divider style={{ marginTop: 20, marginBottom: 20 }}></Divider>
      <SplitList
        mode={ViewMode.list}
        labels={labels}
        protocol={protocol}
        addExerciseToSplit={(split) => {
          events.logButtonClick({
            buttonName: "Add Exercise to Split",
            pageName: "Protocols",
            tabName: protocol,
          });
          setSelectedSplit(split);
          setSplitExerciseDialogOpen(true);
        }}
        editSplit={(split) => {
          handleSplitEdit(split);
        }}
        duplicateSplit={(split, count) => {
          confirmAlert({
            title: `Duplicate ${split.name}`,
            message: "Are you sure you want to duplicate this split?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  ProtocolData.duplicateSplit({
                    protocolId: split.protocolId,
                    split: split,
                    count: count,
                  });
                  events.logButtonClick({
                    buttonName: "Duplicate Split",
                    pageName: "Protocols",
                    tabName: protocol,
                  });
                },
              },
              {
                label: "No",
                onClick: () => {},
              },
            ],
          });
        }}
        deleteSplit={(split) => {
          confirmAlert({
            title: `Delete ${split.name}`,
            message: "This cannot be undone. Continue with delete?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  ProtocolData.deleteSplit(split.protocolId, split.id);
                  events.logButtonClick({
                    buttonName: "Delete Split",
                    pageName: "Protocols",
                    tabName: protocol,
                  });
                },
              },
              {
                label: "No",
                onClick: () => {},
              },
            ],
          });
        }}
        reorderSplitExercises={(split) => {
          events.logButtonClick({
            buttonName: "Reorder Splits",
            pageName: "Protocols",
            tabName: protocol,
          });
          setSelectedSplit(split);
          setExerciseReorderDialogOpen(true);
        }}
      />

      <Grid
        style={{ marginTop: 24 }}
        container
        justifyContent="center"
        spacing={6}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setChooseExistingWorkoutDialogOpen(true);
              events.logButtonClick({
                buttonName: "Add Existing Workout",
                pageName: "Protocols",
                tabName: protocol,
              });
            }}
          >
            Add Existing Workout
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setSelectedSplit(null);
              setSplitDialogOpen(true);
              events.logButtonClick({
                buttonName: "Add New Workout",
                pageName: "Protocols",
                tabName: protocol,
              });
            }}
          >
            Add New Workout
          </Button>
        </Grid>
      </Grid>

      {splitDialogOpen && (
        <SplitDialog
          id={selectedSplit ? selectedSplit.id : null}
          selectedSplit={selectedSplit}
          protocolId={protocol.id}
          open={splitDialogOpen}
          onDialogClosed={(values) => {
            if (values) {
              ProtocolData.addSplitFlat(values, selectedSplit ? false : true);
            }
            setSplitDialogOpen(false);
          }}
        />
      )}

      {splitExerciseDialogOpen && (
        <SplitExerciseDialog
          splitId={selectedSplit ? selectedSplit.id : null}
          protocolId={selectedSplit ? selectedSplit.protocolId : null}
          open={splitExerciseDialogOpen}
          onDialogClosed={(values) => {
            if (values) {
              ProtocolData.addSplitExerciseFlat(values);
            }
            setSplitExerciseDialogOpen(false);
          }}
        />
      )}

      {exerciseReorderDialogOpen && (
        <ExerciseReorderDialog
          split={selectedSplit}
          protocolId={selectedSplit ? selectedSplit.protocolId : null}
          open={exerciseReorderDialogOpen}
          onDialogClosed={(protocolId, splitId, list) => {
            //
            if (list) {
              //
              ProtocolData.setExerciseOrder(protocolId, list);
            }
            setExerciseReorderDialogOpen(false);
          }}
        />
      )}

      {splitReorderDialogOpen && (
        <SplitReorderDialog
          protocolId={protocol.id}
          open={splitReorderDialogOpen}
          onDialogClosed={(protocolId, list) => {
            //
            if (list) {
              consola.log("Before update: ", list);
              ProtocolData.setSplitOrder(protocolId, list);
              consola.log("Updated order: ", list);
            }
            setSplitReorderDialogOpen(false);
          }}
        />
      )}

      {protocolEditDialogOpen && (
        <ProtocolDialog
          id={protocol ? protocol.id : null}
          selectedProtocol={protocol}
          allowClientSelection={true}
          onDialogClosed={async (values) => {
            setProtocolEditDialogOpen(false);

            if (values) {
              await ProtocolData.addProtocol(values);
              if (props.protocolEdited) {
                props.protocolEdited();
              }
            }
          }}
        />
      )}

      {chooseExistingWorkoutDialogOpen && (
        <ChooseExistingWorkoutDialog
          protocol={protocol}
          labels={labels}
          open={chooseExistingWorkoutDialogOpen}
          onDialogClosed={async () => {
            setChooseExistingWorkoutDialogOpen(false);
          }}
          onAddToPlan={async (split) => {
            await ProtocolData.duplicateSplit({
              protocolId: protocol.id,
              split: split.originalSplit,
              count: protocol.splitCount,
              showCopyAppend: false,
            });
          }}
        />
      )}
    </Box>
  );
};

export default ProtocolDetails;
