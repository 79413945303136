/* eslint-disable no-undef */
import firebase from "firebase/compat/app";
// import "firebase/compat/analytics";
// import "firebase/compat/storage";
// import "firebase/compat/firestore";

import _ from "underscore";
import moment from "moment";
import { getRecoil } from "recoil-nexus";
import { getAPIBasePath } from "./StripeData";

import organizationIdState from "../atoms/organizationIdSelector";
import { nanoid } from "nanoid";
import { consola } from "consola";

export const ViewMode = {
  list: "list",
  list_details: "list_details",
  details: "details",
};

export const pages = {
  dashboard: "dashboard",
  reports: "reports",
  clients: "clients",
  coaches: "coaches",
  protocols: "protocols",
  messages: "messages",
  shared_videos: "shared_videos",
  exercises: "exercises",
  recipes: "recipes",
  library: "library",
  admin: "admin",
};

export const CoachStatus = {
  active: "active",
  disabled: "disabled",
};

export const QuestionType = {
  weekly: "weekly",
  daily: "daily",
  progress: "progress",
};

export const DataType = {
  multiline: "multiline",
  number: "number",
  rating: "rating",
  scale: "scale",
  yesno: "yesno",
};

export const WorkoutStatus = {
  complete: "complete",
  incomplete: "incomplete",
};

export const DataTypeString = {
  multiline: "Multiline",
  number: "Number",
  rating: "Rating",
  scale: "Scale",
  yesno: "Yes/No",
};

export const Difficulty = {
  beginner: "beginner",
  intermediate: "intermediate",
  advanced: "advanced",
  expert: "expert",
};

export const DaysOfWeek = {
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday",
  sunday: "sunday",
};

export const DaysOfWeekString = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
};

export const ago = (date) => {
  return moment(date).calendar(null, {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: function (now) {
      const diff = now.diff(this, "days");
      const result = `[${diff} days ago]`;
      return result;
    },
  });
};

export const convertHeightToCm = (height) => {
  if (!height || height.trim() === "") {
    return 0;
  }
  if (height.indexOf("'") === -1 && height.indexOf("’") === -1) {
    const parsed = parseFloat(height);
    if (!isNaN(parsed)) {
      return parseFloat(height);
    }
  }

  let heightClean = height.replace(/\s/g, "");
  heightClean = heightClean.replace(/’/g, "'");
  // check if height is in cm
  if (heightClean.indexOf("cm") > -1) {
    return parseFloat(heightClean.replace("cm", ""));
  }
  // check if height is float
  if (heightClean.indexOf(".") > -1) {
    return parseFloat(heightClean);
  }

  const [feet, inches] = heightClean.split("'");
  const totalInches = parseInt(feet) * 12 + parseInt(inches);
  return totalInches * 2.54;
};

export const convertWeightToKg = (weight) => {
  return Math.round((weight * 0.453592 + Number.EPSILON) * 100) / 100;
};

export const getAgeFromBirthday = (birthday) => {
  if (!birthday) {
    return 0;
  }

  const today = new Date();
  const birthDate = new Date(birthday);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

async function getCustomExercises(organizationId) {
  let orgId = organizationId;
  if (!orgId) {
    orgId = getRecoil(organizationIdState);
  }

  const customExerciseRef = firebase
    .firestore()
    .collection("organizations")
    .doc(orgId)
    .collection("customExercise");
  consola.info("++++++ READ -> GlobalData: getCustomExercises");
  const data = await customExerciseRef.get();
  return data.docs.map((d) => {
    return d.data();
  });
}

export const getOpenAIResponse = async (prompt, temperature = 0.5) => {
  if (!prompt) {
    consola.error(new Error("Incorrect parameters"));
  }

  const message = {
    prompt,
    temperature,
    // model: "gpt-3.5-turbo",
  };

  const path = getAPIBasePath();
  const response = await fetch(`${path}/api/v1/openai`, {
    method: "POST",
    body: JSON.stringify(message),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 200) {
    const jsonData = await response.json();
    return jsonData;
  }
  consola.error(new Error("Error getting openai response"));
};

export const getChatGPTResponse = async (messages) => {
  if (!messages) {
    consola.error(new Error("Incorrect parameters"));
  }

  const path = getAPIBasePath();
  const sendBody = JSON.stringify(messages);

  const response = await fetch(`${path}/api/v1/chatgpt`, {
    method: "POST",
    body: sendBody,
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 200) {
    const jsonData = await response.json();
    return jsonData;
  }
  consola.error(new Error("Error getting openai response"));
};

const GlobalData = {
  getMuscleGroups: async () => {
    const ref = firebase
      .firestore()
      .collection("global/constants/muscle_groups")
      .orderBy("index");
    consola.info("++++++ READ -> GlobalData: getMuscleGroups");
    const data = await ref.get();
    return data.docs;
  },
  getEquipment: async () => {
    const ref = firebase.firestore().collection("global/constants/equipment");
    consola.info("++++++ READ -> GlobalData: getEquipment");
    const data = await ref.get();
    return data.docs;
  },
  getEquipmentKey: async () => {
    const ref = firebase.firestore().collection("global/constants/equipment");
    consola.info("++++++ READ -> GlobalData: getEquipmentKey");
    const data = await ref.get();
    const groups = {};
    data.docs.forEach((g) => {
      groups[g.data().id] = g.data().name;
    });

    return groups;
  },
  getConstantsDoc: async () => {
    consola.info("++++++ READ -> GlobalData: getConstantsDoc");
    const ref = firebase.firestore().doc("global/constants");
    const data = await ref.get();
    return data.data();
  },
  getDifficultyKey: async () => {
    const ref = firebase.firestore().collection("global/constants/difficulty");
    consola.info("++++++ READ -> GlobalData: getDifficultyKey");
    const data = await ref.get();
    const groups = {};
    data.docs.forEach((g) => {
      groups[g.data().id] = g.data().name;
    });

    return groups;
  },
  getMuscleGroupsKey: async () => {
    const ref = firebase
      .firestore()
      .collection("global/constants/muscle_groups");
    consola.info("++++++ READ -> GlobalData: getMuscleGroupsKey");
    const data = await ref.get();
    const groups = {};
    data.docs.forEach((g) => {
      groups[g.data().id] = g.data().name;
    });

    return groups;
  },
  getExercises: async ({ filter, organizationId } = {}) => {
    let ref;

    const customExercises = await getCustomExercises(organizationId);

    if (!filter) {
      ref = firebase
        .firestore()
        .collection("global/data/exercises")
        .orderBy("name");
    } else {
      ref = firebase
        .firestore()
        .collection("global/data/exercises")
        .where("mainMuscleGroups", "array-contains", filter)
        .orderBy("name");
    }
    consola.info("++++++ READ -> GlobalData: getExercises");
    const data = await ref.get();
    const exercises = data.docs.map((d) => {
      const value = d.data();
      const found = _.findWhere(customExercises, { id: value.id });
      if (found) {
        return found;
      }
      return value;
    });
    customExercises.forEach((c) => {
      const found = _.findWhere(exercises, { id: c.id });
      if (!found) {
        exercises.push(c);
      }
    });
    const sorted = _.sortBy(exercises, "name");

    return sorted;
  },
  getExerciseSubscription: (id, onChange) => {
    const ref = firebase
      .firestore()
      .collection("global/data/exercises")
      .doc(id);

    return ref.onSnapshot(async (snapshot) => {
      consola.info("++++++ SNAPSHOT -> Global Data: getExerciseSubscription");
      const customExercises = await getCustomExercises();
      const value = snapshot.data();
      const found = _.findWhere(customExercises, { id: value.id });
      if (found) {
        onChange(found);
      } else {
        onChange(value);
      }
    });
  },
  getCustomExerciseSubscription: async (id, onChange, onSubscription) => {
    consola.info(
      "++++++ SNAPSHOT -> GlobalData: getCustomExerciseSubscription",
    );
    const organizationId = getRecoil(organizationIdState);

    const customExerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("customExercise")
      .doc(id);

    const subscription = customExerciseRef.onSnapshot(async (snapshot) => {
      consola.info(
        "++++++ SNAPSHOT -> GlobalData: getCustomExerciseSubscription",
      );
      const value = snapshot.data();
      onChange(value);
    });
    onSubscription(subscription);
  },
  getCustomExercisesSubscription: async (onChange, onSubscription) => {
    const organizationId = getRecoil(organizationIdState);
    if (!organizationId) {
      return null;
    }

    const customExerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("customExercise");

    const subscription = customExerciseRef.onSnapshot(async (snapshot) => {
      consola.info(
        "++++++ SNAPSHOT -> GlobalData: getCustomExercisesSubscription",
      );
      onChange(snapshot);
    });
    onSubscription(subscription);
  },
  getExercisesSubscription: ({ filter, showDeleted, onChange }) => {
    let ref;
    if (!filter) {
      ref = firebase
        .firestore()
        .collection("global/data/exercises")
        .orderBy("name");
    } else {
      ref = firebase
        .firestore()
        .collection("global/data/exercises")
        .where("mainMuscleGroups", "array-contains", filter)
        .orderBy("name");
    }
    return ref.onSnapshot(async (snapshot) => {
      consola.info("++++++ SNAPSHOT -> GlobalData: getExercisesSubscription");
      if (onChange) {
        // Try this again
        const exercises = snapshot.docs.map((d) => {
          return d.data();
        });
        const customExercises = await getCustomExercises();

        customExercises.forEach((custom) => {
          if (!custom.isDeleted || showDeleted) {
            const index = _.findIndex(exercises, { id: custom.id });
            if (index !== -1) {
              exercises[index] = {
                ...custom,
              };
            } else {
              exercises.push({
                ...custom,
              });
            }
          }
        });

        const sorted = _.sortBy(exercises, "name");
        onChange(sorted);
      }
    });
  },
  getExerciseKeys: async () => {
    const customExercises = await getCustomExercises();
    const ref = firebase.firestore().collection("global/data/exercises");
    consola.info("++++++ READ -> GlobalData: getExerciseKeys");
    const data = await ref.get();
    const groups = {};
    data.docs.forEach((g) => {
      groups[g.data().id] = g.data().name;
    });
    customExercises.forEach((c) => {
      groups[c.id] = c.name;
    });

    return groups;
  },
  updateExercise: async (exercise) => {
    const exerciseRef = firebase
      .firestore()
      .doc(`global/data/exercises/${exercise.id}`);
    consola.info("+++++ WRITE => GlobalData: updateExercise");
    await exerciseRef.set(exercise, { merge: true });
  },
  createExerciseWithNameOnly: async ({ name }) => {
    const organizationId = getRecoil(organizationIdState);

    const id = nanoid();

    const customExerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("customExercise")
      .doc(id);

    const data = {
      id: id,
      name: name,
      description: "",
      instructions: "",
      difficulty: Difficulty.beginner,
      icon: "all",
      mainMuscleGroups: ["all"],
      secondaryMuscleGroups: ["all"],
      equipment: [],
      isCustom: true,
    };
    consola.info("+++++ WRITE => GlobalData: createExerciseWithNameOnly");
    await customExerciseRef.set(data, { merge: true });
    return data;
  },
  updateCustomExercise: async ({ exercise }) => {
    const organizationId = getRecoil(organizationIdState);

    const customExerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("customExercise")
      .doc(exercise.id);

    const data = {
      ...exercise,
      isCustom: true,
    };
    consola.info("+++++ WRITE => GlobalData: updateCustomExercise");
    await customExerciseRef.set(data, { merge: true });
  },
  deleteExercise: async (exercise) => {
    const exerciseRef = firebase
      .firestore()
      .doc(`global/data/exercises/${exercise.id}`);
    await exerciseRef.delete();
  },
  checkIfCustomHasGlobal: async (exerciseId) => {
    const ref = firebase
      .firestore()
      .collection("global/data/exercises")
      .doc(exerciseId);
    consola.info("++++++ READ -> GlobalData: checkIfCustomHasGlobal");
    const snapshot = await ref.get();
    if (snapshot.exists) {
      return true;
    }
    return false;
  },
  deleteCustomExercise: async (exercise) => {
    const organizationId = getRecoil(organizationIdState);

    const customExerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("customExercise")
      .doc(exercise.id);

    consola.info("+++++ WRITE => GlobalData: deleteCustomExercise");
    await customExerciseRef.set(
      {
        isDeleted: true,
      },
      { merge: true },
    );
  },
  restoreCustomExercise: async (exercise) => {
    const organizationId = getRecoil(organizationIdState);

    const customExerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("customExercise")
      .doc(exercise.id);

    consola.info("+++++ WRITE => GlobalData: restoreCustomExercise");
    await customExerciseRef.set(
      {
        isDeleted: false,
      },
      { merge: true },
    );
  },
  editImageURL: async (exercise, url, index) => {
    if (index > 1) {
      index = 1;
    }
    if (index < 0) {
      index = 0;
    }
    const exerciseRef = firebase
      .firestore()
      .doc(`global/data/exercises/${exercise.id}`);
    consola.info("++++++ READ -> GlobalData: editImageURL");
    const exerciseData = await exerciseRef.get();
    if (exerciseData.exists) {
      const images = exerciseData.data().images;
      let imagesCopy = [];
      if (images) {
        imagesCopy = _.clone(images);
      }

      while (imagesCopy.length < 2) {
        imagesCopy.push("");
      }

      imagesCopy[index] = url;
      consola.info("+++++ WRITE => GlobalData: editImageURL");
      exerciseRef.set(
        {
          images: imagesCopy,
        },
        { merge: true },
      );
    }
  },
  editCustomImageURL: async (exercise, url, index) => {
    const organizationId = getRecoil(organizationIdState);

    const exerciseRef = firebase
      .firestore()
      .doc(`global/data/exercises/${exercise.id}`);
    consola.info("++++++ READ -> GlobalData: editCustomImageURL");
    const exerciseData = await exerciseRef.get();

    const customExerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("customExercise")
      .doc(exercise.id);

    if (index > 1) {
      index = 1;
    }
    if (index < 0) {
      index = 0;
    }

    const customExerciseData = await customExerciseRef.get();
    if (customExerciseData.exists) {
      const images = customExerciseData.data().images;
      let imagesCopy = [];
      if (images) {
        imagesCopy = _.clone(images);
      }

      while (imagesCopy.length < 2) {
        imagesCopy.push("");
      }

      imagesCopy[index] = url;
      consola.info("+++++ WRITE => GlobalData: editCustomImageURL");
      customExerciseRef.set(
        {
          images: imagesCopy,
        },
        { merge: true },
      );
    } else {
      const exerciseValue = exerciseData.data();
      const images = exerciseValue.images;
      let imagesCopy = [];
      if (images) {
        imagesCopy = _.clone(images);
      }

      while (imagesCopy.length < 2) {
        imagesCopy.push("");
      }

      imagesCopy[index] = url;

      customExerciseRef.set(
        {
          ...exerciseValue,
          images: imagesCopy,
          isCustom: true,
        },
        { merge: true },
      );
    }
  },
  addVideoURL: async (exercise, videoURL) => {
    const exerciseRef = firebase
      .firestore()
      .doc(`global/data/exercises/${exercise.id}`);
    consola.info("++++++ READ -> GlobalData: addVideoURL");
    const exerciseData = await exerciseRef.get();
    if (exerciseData.exists) {
      const videos = exerciseData.data().videos;
      let videosCopy = [];
      if (videos) {
        videosCopy = _.clone(videos);
      }
      videosCopy.push(videoURL);
      consola.info("+++++ WRITE => GlobalData: addVideoURL");
      exerciseRef.set(
        {
          videos: videosCopy,
        },
        { merge: true },
      );
    }
  },
  addCustomVideoURL: async (exercise, videoURL) => {
    const organizationId = getRecoil(organizationIdState);

    const customExerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("customExercise")
      .doc(exercise.id);

    const exerciseRef = firebase
      .firestore()
      .doc(`global/data/exercises/${exercise.id}`);
    const exerciseData = await exerciseRef.get();
    consola.info("++++++ READ -> GlobalData: addCustomVideoURL");
    const customExerciseData = await customExerciseRef.get();
    if (customExerciseData.exists) {
      const videos = customExerciseData.data().videos;
      let videosCopy = [];
      if (videos) {
        videosCopy = _.clone(videos);
      }
      videosCopy.push(videoURL);
      consola.info("+++++ WRITE => GlobalData: addCustomVideoURL");
      customExerciseRef.set(
        {
          videos: videosCopy,
        },
        { merge: true },
      );
    } else {
      const exerciseValue = exerciseData.data();
      const videos = exerciseValue.videos;
      let videosCopy = [];
      if (videos) {
        videosCopy = _.clone(videos);
      }
      videosCopy.push(videoURL);
      consola.info("+++++ WRITE => GlobalData: addCustomVideoURL");
      customExerciseRef.set(
        {
          ...exerciseValue,
          videos: videosCopy,
          isCustom: true,
        },
        { merge: true },
      );
    }
  },
  deleteCustomVideoURL: async (exercise, videoURL) => {
    const organizationId = getRecoil(organizationIdState);

    const exerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("customExercise")
      .doc(exercise.id);
    consola.info("++++++ READ -> GlobalData: deleteCustomVideoURL");
    const exerciseData = await exerciseRef.get();
    if (exerciseData.exists) {
      const videos = exerciseData.data().videos;

      let videosCopy = [];
      if (videos) {
        videosCopy = _.clone(videos);
      }

      videosCopy = _.reject(videosCopy, (e) => {
        return e === videoURL;
      });
      consola.info("+++++ WRITE => GlobalData: deleteCustomVideoURL");
      exerciseRef.set(
        {
          videos: videosCopy,
        },
        { merge: true },
      );
    }
  },
  deleteVideoURL: async (exercise, videoURL) => {
    const exerciseRef = firebase
      .firestore()
      .doc(`global/data/exercises/${exercise.id}`);
    consola.info("++++++ READ -> GlobalData: deleteVideoURL");
    const exerciseData = await exerciseRef.get();
    if (exerciseData.exists) {
      const videos = exerciseData.data().videos;
      let videosCopy = [];
      if (videos) {
        videosCopy = _.clone(videos);
      }

      videosCopy = _.reject(videosCopy, (e) => {
        return e === videoURL;
      });
      consola.info("+++++ WRITE => GlobalData: deleteVideoURL");
      exerciseRef.set(
        {
          videos: videosCopy,
        },
        { merge: true },
      );
    }
  },
};

export default GlobalData;
