import { Typography, Box, Button, Stack, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { nanoid } from "nanoid";
import { confirmAlert } from "react-confirm-alert";
import { useTheme } from "@mui/material/styles";

import SupplementEditDialog from "./SupplementEditDialog";
import useSupplements from "../../../../hooks/useSupplements";

import { EditButton, DeleteButton } from "../../../../atoms/EditButton";
import useEvents from "../../../../hooks/useEvents";

const EditSupplements = () => {
  const theme = useTheme();

  const storedModel = localStorage.getItem("supplementsGridColumns");
  const [visibleModel, setVisibilityModel] = React.useState(
    storedModel ? JSON.parse(storedModel) : {},
  );

  const { supplements, editSupplementData, deleteSupplementData } =
    useSupplements();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const selectedSupplement = React.useRef();
  const events = useEvents();

  const columns = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
      },
      {
        field: "salesLink",
        headerName: "Sales URL",
        minWidth: 100,
        flex: 1,
        hideable: false,
      },
      {
        headerAlign: "center",
        align: "right",
        minWidth: 120,
        field: "action",
        headerName: "",
        sortable: false,
        hideable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Stack spacing={1} direction="row">
              <EditButton
                onClick={() => {
                  selectedSupplement.current = params.row;
                  setDialogOpen(true);
                  events.logButtonClick({
                    buttonName: "Edit Supplement",
                    pageName: "Nutrition",
                    tabName: "Supplements",
                  });
                }}
              />
              <DeleteButton
                onClick={() => {
                  confirmAlert({
                    title: `Delete this supplement?`,
                    message:
                      "This cannot be undone. The supplement will be removed everywhere it is being used.",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: async () => {
                          deleteSupplementData(params.row.id);
                          events.logButtonClick({
                            buttonName: "Delete Supplement",
                            pageName: "Nutrition",
                            tabName: "Supplements",
                          });
                        },
                      },
                      {
                        label: "No",
                        onClick: () => {},
                      },
                    ],
                  });
                }}
              />
            </Stack>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <Grid container style={{ marginTop: 0 }}>
        <Grid item xs={8}>
          <Typography variant="h6">Supplements</Typography>
          <Typography
            style={{ fontSize: 16, fontWeight: "normal", marginBottom: 12 }}
          >
            This your supplement database. Setup supplments you will prescribe
            to your clients in the Client Summary.
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Box display="flex" flexDirection="row" justifyContent={"flex-end"}>
            <Button
              style={{ marginTop: 12 }}
              variant="contained"
              color="primary"
              onClick={() => {
                selectedSupplement.current = null;
                setDialogOpen(true);
                events.logButtonClick({
                  buttonName: "Add Supplement",
                  pageName: "Nutrition",
                  tabName: "Supplements",
                });
              }}
            >
              Add Supplement
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1, height: 200 }}>
        <DataGrid
          sx={{
            ".MuiDataGrid-sortIcon": {
              color: theme.palette.primary.main,
            },
            ".MuiDataGrid-filterIcon": {
              color: theme.palette.primary.main,
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-menuIconButton": {
              color: theme.palette.primary.main,
            },
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
            height: "100%",
          }}
          columnVisibilityModel={visibleModel}
          disableSelectionOnClick
          rows={supplements}
          columns={columns}
          getRowHeight={() => {
            return "auto";
          }}
          onColumnVisibilityModelChange={(model) => {
            localStorage.setItem(
              "mealPlansDataGridColumns",
              JSON.stringify(model),
            );
            setVisibilityModel(model);
          }}
        />
      </Box>

      {dialogOpen && (
        <SupplementEditDialog
          open={dialogOpen}
          supplement={selectedSupplement.current}
          onDialogClosed={async (data, supplement) => {
            if (!data && !supplement) {
              setDialogOpen(false);
              return;
            }
            setDialogOpen(false);

            if (data && supplement) {
              supplement.name = data.name;
              supplement.description = data.description;
              supplement.salesLink = data.salesLink;
              supplement.updatedOn = new Date();

              editSupplementData(supplement);
            } else {
              const newSupplement = {
                id: nanoid(),
                name: data.name,
                description: data.description,
                salesLink: data.salesLink,
                createdOn: new Date(),
                updatedOn: new Date(),
              };

              editSupplementData(newSupplement);
            }
          }}
        />
      )}
    </>
  );
};

export default EditSupplements;
