import React from "react";
import { TextField } from "@mui/material";

export default React.memo((props) => {
  return (
    <TextField
      {...props}
      onChange={(event) => {
        if (props.onChange) {
          if (event.target.value.length === 0) {
            props.onChange("");
          } else {
            props.onChange(
              isNaN(event.target.value) ? 0 : parseInt(event.target.value, 10),
            );
          }
        }
      }}
    ></TextField>
  );
});
