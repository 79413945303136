import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/firestore";

import { getRecoil } from "recoil-nexus";
import Bugsnag from "@bugsnag/js";
import _ from "underscore";
import { consola } from "consola";
import organizationIdSelector from "../atoms/organizationIdSelector";
import { nanoid } from "nanoid";

const useAPIKeys = () => {
  const organizationId = getRecoil(organizationIdSelector);

  const [keys, setKeys] = React.useState([]);

  const addAPIKey = React.useCallback(
    async function () {
      // Add the log data here.
      const key = nanoid(32);

      const ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("apiKeys")
        .doc(key);

      try {
        consola.info("+++++ WRITE => useApiKeys: addAPIKey")
        await ref.set(
          {
            key,
          },
          { merge: true },
        );
      } catch (e) {
        Bugsnag.notify(new Error("Error saving new key"));
      }
    },
    [organizationId],
  );

  const deleteAPIKey = React.useCallback(
    async function (key) {
      const ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("apiKeys")
        .doc(key);

      try {
        await ref.delete();
      } catch (e) {
        Bugsnag.notify(e);
      }
    },
    [organizationId],
  );

  const getKey = React.useCallback(
    (key) => {
      if (!key) {
        return null;
      }
      const value = _.findWhere(keys, { key: key });
      return value;
    },
    [keys],
  );

  React.useEffect(() => {
    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("apiKeys");

    const subscription = ref.onSnapshot((snapshot) => {
      consola.info("++++++ SNAPSHOT -> useAPIKeys")
      const data = snapshot.docs.map((d) => {
        return d.data();
      });
      if (data.length === 0) {
        setKeys([]);
      } else {
        setKeys(data);
      }
    });

    return () => {
      subscription();
    };
  }, [organizationId]);

  return { keys, addAPIKey, deleteAPIKey, getKey };
};

export default useAPIKeys;
