import React from "react";
import { consola } from "consola";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const useHealthReport = ({ uid, filter, operation, value }) => {
  const [report, setReport] = React.useState(null);

  React.useEffect(() => {
    async function getData() {
      if (!uid) {
        return;
      }

      const userRef = firebase.firestore().collection("users").doc(uid);
      consola.info("++++++ READ -> useHealthReport userData")
      const userData = await userRef.get();
      if (userData.exists) {
        const userDataValues = userData.data();
        const organizationId = userDataValues.organizationId;

        const clientRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("clients")
          .doc(userDataValues.uid);
          consola.info("++++++ READ -> useHealthReport clientData")
        const clientData = await clientRef.get();

        if (!clientData.exists) {
          return;
        }

        let reportRef = firebase
          .firestore()
          .collection(
            `organizations/${organizationId}/clients/${userDataValues.uid}/reportHealth`,
          );

        if (filter && filter.length > 0) {
          const filterString = `reportData.${filter}`;

          reportRef = reportRef.where(filterString, operation, value);
          reportRef = reportRef
            .orderBy(`reportData.${filter}`, "asc")
            .orderBy("createdOn", "desc");
        }
        reportRef = reportRef.limit(10);
        consola.info("++++++ READ -> useHealthReport: reportData")
        const reportData = await reportRef.get();
        if (reportData.docs.length > 0) {
          setReport(reportData.docs[reportData.docs.length - 1].data());
        }
      }
    }
    getData();
  }, [filter, operation, value, uid]);

  return { report };
};

export default useHealthReport;
