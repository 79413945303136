import React from "react";
import { Box, Typography } from "@mui/material";

const CircleText = ({ circleText, title }) => {
  return (
    <Box
      data-testid="CircleText"
      display="flex"
      alignItems="center"
      style={{ width: "90%" }}
    >
      <Box
        border={1}
        borderRadius={50}
        style={{
          display: "flex",
          borderWidth: "2px",
          width: 32,
          minWidth: 32,
          minHeight: 32,
          height: 32,
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 8,
        }}
      >
        {circleText}
      </Box>
      <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
        {title}
      </Typography>
    </Box>
  );
};

export default CircleText;
