import { useState, useEffect } from "react";

import { getFirestore, onSnapshot, collection } from "firebase/firestore";

const useClients = ({ organizationId }) => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    const db = getFirestore();
    const clientsCollection = collection(
      db,
      "organizations",
      organizationId,
      "clients",
    );
    onSnapshot(clientsCollection, (snapshot) => {
      const clients = snapshot.docs.map((doc) => doc.data());
      setClients(clients);
      setLoading(false);
    });
  }, [organizationId]);

  return { clients, loading };
};

export default useClients;
