import React from "react";
import { Box, Typography, Grid, Divider, Menu, MenuItem } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { rgba } from "polished";

const activeStyle = {
  backgroundColor: rgba(0, 128, 0, 0.1),
  padding: 8,
  borderRadius: 4,
  color: "black",
};

const inactiveStyle = {
  padding: 8,
};

const width = 120;

const CardioGoalRow = ({ cardioGoal, onActivate, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      type: "menu",
      menuName: "Set Goal as Active",
      menuFunction: () => {
        if (onActivate) {
          onActivate(cardioGoal);
        }
      },
    },
    {
      type: "menu",
      menuName: "Edit Cardio Goal",
      menuFunction: () => {
        if (onEdit) {
          onEdit(cardioGoal);
        }
      },
    },
    {
      type: "menu",
      menuName: "Delete Cardio Goal",
      menuFunction: () => {
        if (onDelete) {
          onDelete(cardioGoal);
        }
      },
    },
  ];
  return (
    <Box>
      <Box style={cardioGoal?.active ? activeStyle : inactiveStyle}>
        <Grid container justifyContent="space-between" alignItems={"center"}>
          <Grid item style={{ width: 200 }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${cardioGoal.name}`}</Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: width }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${cardioGoal.sessions}`}</Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: width }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${cardioGoal.duration}`}</Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: width }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${cardioGoal.intensity}`}</Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: width }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${cardioGoal.hr}`}</Typography>
          </Grid>

          <Grid
            item
            style={{
              width: 20,
              marginLeft: 100,
            }}
          >
            <MoreVertIcon onClick={handleClick} />
          </Grid>
        </Grid>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuItems &&
            menuItems.map((menuItem, index) => {
              if (menuItem.type === "menu") {
                return (
                  <MenuItem
                    key={menuItem.menuName}
                    onClick={() => {
                      setAnchorEl(null);
                      if (menuItem.menuFunction) {
                        menuItem.menuFunction(cardioGoal);
                      }
                    }}
                  >
                    {`${menuItem.menuName}`}
                  </MenuItem>
                );
              } else {
                return <Divider key={index} />;
              }
            })}
        </Menu>
      </Box>
      <Divider style={{ marginTop: 8 }} />
    </Box>
  );
};

export default CardioGoalRow;
