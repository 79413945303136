export const WorkoutTypes = {
  normalSet: "normal_set",
  superSet: "super_set",
};

export const ProtocolTypeLabel = {
  general: "General Fitness",
  body_building: "Body Building",
  strength: "Strength",
  cross_fit: "Cross Fit",
};

export const ActivityLevel = {
  sedentary: "sedentary",
  moderate1: "moderate1",
  moderate2: "moderate2",
  intense1: "intense1",
  intense2: "intense2",
  intense3: "intense3",
};

export const ActivityLevelLabel = {
  sedentary: "Sedentary",
  moderate1: "Exercise 1-3 times/week",
  moderate2: "Exercise 3-5 times/week",
  intense1: "Daily exercise",
  intense2: "Intense exercise",
  intense3: "Very intense exercise",
};

export const ActivityLevelValues = {
  sedentary: 1.2,
  moderate1: 1.3,
  moderate2: 1.4,
  intense1: 1.5,
  intense2: 1.7,
  intense3: 1.9,
};

export const WeightGoal = {
  lose: "lose",
  lose_light: "lose_light",
  maintain: "maintain",
  gain_light: "gain_light",
  gain: "gain",
};

export const WeightGoalLabel = {
  lose: "Lose (-20%)",
  lose_light: "Light Loss (-10%)",
  maintain: "Maintain",
  gain_light: "Light Gain (+10%)",
  gain: "Gain (+20%)",
};

export const WeightGoalFactor = {
  lose: -0.2,
  lose_light: -0.1,
  maintain: 0,
  gain_light: 0.1,
  gain: 0.2,
};

export const LanguageCodes = [
  { code: "en", label: "English" },
  { code: "nl_BE", label: "Dutch (Belgium)" },
];

export const AccountType = {
  trial: "trial",
  free: "free",
  stripe: "stripe",
  special_pricing: "special_pricing",
};
