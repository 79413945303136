import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { nanoid } from "nanoid";
import MDEditor from "@uiw/react-md-editor";

import LabelSelect from "../../../../components/molecules/LabelSelect";
import BlueButton from "../../../../components/BlueButton";
import ClientData from "../../../../data/ClientData";

const ProtocolDialog = ({
  id,
  selectedProtocol,
  onDialogClosed,
  allowClientSelection = false,
}) => {
  const [protocolId, setProtocolId] = React.useState(id);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [type, setType] = React.useState("general");
  const [privacy, setPrivacy] = React.useState("private");
  const [difficulty, setDifficulty] = React.useState("beginner");
  const [protocolLabels, setProtocolLabels] = React.useState([]);

  const [clients, setClients] = React.useState([]);
  const [clientUID, setClientUID] = React.useState("none");

  function validate() {
    if (!name || name.trim().length === 0) {
      return false;
    }
    return true;
  }

  React.useEffect(() => {
    async function getDataAsync() {
      const uid = localStorage.getItem("uid");
      const clients = await ClientData.getClients(uid);
      setClients(clients);
    }

    getDataAsync();
  }, []);

  React.useEffect(() => {
    if (!id) {
      setProtocolId(nanoid());
    } else {
      setProtocolId(id);
    }

    if (selectedProtocol) {
      setName(selectedProtocol.name);
      setDescription(selectedProtocol.description);
      setType(selectedProtocol.type);
      setPrivacy(selectedProtocol.privacy);
      setDifficulty(selectedProtocol.difficulty);
      setClientUID(
        selectedProtocol.clientUID ? selectedProtocol.clientUID : "none",
      );
      setProtocolLabels(
        selectedProtocol.protocolLabels ? selectedProtocol.protocolLabels : [],
      );
    } else {
      setName("");
      setDescription("");
      setType("general");
      setPrivacy("private");
      setDifficulty("beginner");
      setClientUID("none");
      setProtocolLabels([]);
    }
  }, [selectedProtocol, id]);

  return (
    <Dialog
      maxWidth="lg"
      open
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>
        {selectedProtocol ? `Edit Training Plan` : `Create Training Plan`}
      </DialogTitle>

      <DialogContent>
        <Stack
          spacing={1}
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <Typography>Training Plan Name</Typography>
          <TextField
            variant="outlined"
            style={{ width: "100%" }}
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </Stack>
        <Stack
          spacing={1}
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <Typography>Add Description</Typography>
          <div data-color-mode="light">
            <MDEditor
              preview="edit"
              style={{ width: 800 }}
              placeholder="Enter healthy recipe description or video link here"
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>
        </Stack>
        <Stack
          spacing={1}
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <Typography>Labels</Typography>
          <LabelSelect
            id="protocol-dialog"
            width="100%"
            currentLabels={protocolLabels}
            onLabelsChanged={(changes) => {
              setProtocolLabels(changes.protocolLabels);
            }}
          />
        </Stack>
        <Stack
          spacing={1}
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <Typography>Type:</Typography>
          <Select
            style={{ width: "100%" }}
            variant="outlined"
            value={type}
            onChange={(event) => {
              setType(event.target.value);
            }}
          >
            <MenuItem value="general">General Fitness</MenuItem>
            <MenuItem value="body_building">Body Building</MenuItem>
            <MenuItem value="strength">Strength</MenuItem>
            <MenuItem value="cross_fit">Cross Fit</MenuItem>
          </Select>
        </Stack>
        <Stack
          spacing={1}
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <Typography>Privacy:</Typography>
          <Select
            value={privacy}
            style={{ width: "100%" }}
            variant="outlined"
            onChange={(event) => {
              setPrivacy(event.target.value);
            }}
          >
            <MenuItem value="private">Private</MenuItem>
            <MenuItem value="organization">Organization</MenuItem>
            <MenuItem value="public">Public</MenuItem>
          </Select>
        </Stack>
        <Stack
          spacing={1}
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <Typography>Difficulty</Typography>
          <Select
            value={difficulty}
            style={{ width: "100%" }}
            variant="outlined"
            onChange={(event) => {
              setDifficulty(event.target.value);
            }}
          >
            <MenuItem value="beginner">Beginner</MenuItem>
            <MenuItem value="intermediate">Intermediate</MenuItem>
            <MenuItem value="advanced">Advanced</MenuItem>
            <MenuItem value="expert">Expert</MenuItem>
          </Select>
        </Stack>

        {allowClientSelection && (
          <Stack
            spacing={1}
            style={{
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            <Typography>Made for Client?</Typography>
            <Select
              value={clientUID ? clientUID : "none"}
              style={{ width: "100%" }}
              variant="outlined"
              onChange={(event) => {
                setClientUID(event.target.value);
              }}
            >
              <MenuItem key="none" value="none">
                No
              </MenuItem>

              {clients.map((client) => {
                return (
                  <MenuItem
                    key={client.uid}
                    value={client.uid}
                  >{`${client.firstName} ${client.lastName}`}</MenuItem>
                );
              })}
            </Select>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (onDialogClosed) {
              onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (!validate()) {
              return;
            }

            if (onDialogClosed) {
              onDialogClosed({
                id: protocolId,
                name: name,
                description: description,
                privacy: privacy,
                type: type,
                difficulty: difficulty,
                clientUID: clientUID && clientUID !== "none" ? clientUID : null,
                protocolLabels: protocolLabels,
              });
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProtocolDialog;
