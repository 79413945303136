import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useRecoilValue } from "recoil";

import BlueButton from "../../../../components/BlueButton";
import ProtocolData from "../../../../data/ProtocolData";

import exerciseDBKeysSelector from "../../../../atoms/exerciseDBkeysSelector";

import dragImage from "../../../../assets/dragimage.png";

// const dragImage = require("../../../../assets/dragimage.png");

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ExerciseReorderDialog = (props) => {
  const exerciseKeys = useRecoilValue(exerciseDBKeysSelector);

  const [open, setOpen] = React.useState(false);
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    setOpen(props.open);
    async function getData() {
      const exercises = await ProtocolData.getSplitExercises(props.split.id);

      if (exercises) {
        const orderedList = exercises.map((e) => {
          return e.data();
        });
        setList(orderedList);
      }
    }
    if (props.split && props.open) {
      getData();
    }
    //
  }, [props.open]); // eslint-disable-line

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(list, result.source.index, result.destination.index);

    setList(items);
  }

  if (!open) {
    return null;
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>{props.split ? props.split.name : ""}</DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {list.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Box style={{ marginBottom: 12 }}>
                            <Box display="flex" alignItems="center">
                              <img src={dragImage} alt="drag icon" />
                              <Typography
                                style={{
                                  marginLeft: 8,
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >
                                {item.type === "normal_set"
                                  ? item.exercise in exerciseKeys
                                    ? exerciseKeys[item.exercise].name
                                    : "-"
                                  : "Super Set"}
                              </Typography>
                            </Box>
                          </Box>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions style={{ marginTop: 20 }}>
        <Button
          onClick={() => {
            if (props.onDialogClosed) {
              props.onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (props.onDialogClosed) {
              //
              props.onDialogClosed(
                props.split.protocolId,
                props.split.id,
                list,
              );
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExerciseReorderDialog;
