import React from "react";

import { Box, Typography, Grid } from "@mui/material";
import useSupplements from "../../../../hooks/useSupplements";

const ActiveSupplements = ({ report }) => {
  const { getSupplementById } = useSupplements();

  const { clientData } = report;
  const { supplementPrescriptions } = clientData;

  return (
    <Box>
      <Typography
        style={{ paddingInline: 40, fontSize: 24, fontWeight: "bold" }}
      >
        Current Supplement Prescriptions
      </Typography>

      <Box
        sx={{
          backgroundColor: "#F9F9F9",
          width: "100%",
          padding: 1,
        }}
      >
        {supplementPrescriptions && supplementPrescriptions.length > 0 ? (
          supplementPrescriptions.map((supplement) => {
            const supplementData = getSupplementById(supplement.supplementId);

            return (
              <Box
                key={supplement.id}
                sx={{
                  backgroundColor: "#F9F9F9",
                  paddingInline: 10,
                }}
              >
                <Grid container justifyContent="space-between">
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                      {supplementData ? `${supplementData.name}` : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                      {supplement && supplement.dosage
                        ? `${supplement.dosage}`
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                      {supplement && supplement.frequency
                        ? `${supplement.frequency}`
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            );
          })
        ) : (
          <Typography style={{ fontSize: 16, fontWeight: "500" }}>
            No active supplements
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ActiveSupplements;
