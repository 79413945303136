import React, { Suspense } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics";

import {
  Container,
  Box,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isTablet } from "react-device-detect";

import logoImage from "../../assets/Logo.png";
import logoIcon from "../../assets/Round-Logo-Design.png";
import AppStore from "../../assets/ios/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";

const RedButton = React.lazy(() => import("../../components/RedButton"));
const BlueButton = React.lazy(() => import("../../components/BlueButton"));

const LandingPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    const subscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        navigate("/home");
        return;
      }
      navigate("/");
      setLoading(false);
    });

    return () => subscribe();
  }, []);

  React.useEffect(() => {
    firebase.analytics().logEvent("page_view", {
      page_title: "Landing Page",
      page_location: "/",
    });
  }, []);

  if (loading) {
    return (
      <Suspense fallback={<Box />}>
        <BrowserView>
          <AppBar
            elevation={0}
            position="sticky"
            style={{ backgroundColor: "white" }}
          >
            <Box boxShadow={1}>
              <Toolbar>
                <Box
                  style={{
                    height: 120,
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    style={{ textAlign: "left", justifyContent: "flex-start" }}
                  >
                    <a href="https://mighty45.com">
                      <img
                        alt="Mighty45 Logo"
                        style={{}}
                        width="146px"
                        height="78px"
                        src={logoImage}
                        onClick={() => {}}
                      />
                    </a>
                  </Box>
                </Box>
              </Toolbar>
            </Box>
          </AppBar>
        </BrowserView>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Box />}>
      <BrowserView>
        <AppBar
          elevation={0}
          position="sticky"
          style={{ backgroundColor: "white" }}
        >
          <Box boxShadow={1}>
            <Toolbar>
              <Box
                style={{
                  height: 120,
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  style={{ textAlign: "left", justifyContent: "flex-start" }}
                >
                  <a href="https://mighty45.com">
                    <img
                      alt="Mighty45 Logo"
                      style={{}}
                      width="146px"
                      height="78px"
                      src={logoImage}
                      onClick={() => {}}
                    />
                  </a>
                </Box>
                <Box
                  style={{
                    textAlign: "right",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Box style={{ justifyContent: "flex-end", marginRight: 20 }}>
                    <Box style={{ marginBottom: 12 }}>
                      <BlueButton
                        style={{ width: 285, marginRight: 12, marginTop: 12 }}
                        onClick={() => {
                          navigate("/signin");
                        }}
                      >
                        <Typography style={{ fontSize: 24 }}>
                          Sign In
                        </Typography>
                      </BlueButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Toolbar>
          </Box>
        </AppBar>
      </BrowserView>
      <Container maxWidth="lg">
        <Box style={{ textAlign: "center", marginTop: 34 }}>
          <Box>
            <MobileView>
              <img width="85%" src={logoIcon} alt="Mighty45 Logo" />
            </MobileView>
            {!isTablet && (
              <BrowserView>
                <img width="25%" src={logoIcon} alt="Mighty45 Logo" />
              </BrowserView>
            )}
          </Box>
          <Box>
            <Typography variant="h3">
              Welcome to <span style={{ color: "black" }}>MIGHTY</span>45
            </Typography>
          </Box>

          <BrowserView>
            <Box style={{ marginTop: 45 }}>
              <Typography variant="h6">
                Click the Create a new Organization button to get started with
                M45. Let&lsquo;s setup your organization and start adding
                clients.
              </Typography>
            </Box>

            <Box style={{ marginTop: 12 }}>
              <RedButton
                style={{ width: 315, marginRight: 12, marginTop: 12 }}
                onClick={() => {
                  navigate("/onboarding");
                }}
              >
                <Typography style={{ fontSize: 24 }}>
                  Create a new Organization
                </Typography>
              </RedButton>
            </Box>

            <Box style={{ marginTop: 45, marginBottom: 25 }}>
              <Typography variant="h6">
                Are you joining an existing organization and have a connect
                code?
              </Typography>
              <RedButton
                style={{ width: 315, marginRight: 12, marginTop: 12 }}
                onClick={() => {
                  navigate("/connect");
                }}
              >
                <Typography style={{ fontSize: 24 }}>
                  Join the Organization
                </Typography>
              </RedButton>
            </Box>
          </BrowserView>
          <MobileView>
            <Box>
              <Typography variant="h6">
                The M45 mobile app is used for mobile management of clients and
                client check-in.
              </Typography>
            </Box>
            <Box style={{ marginTop: 20 }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/mighty45/id1594939720"
              >
                <img width={200} src={AppStore} alt="App Store Button" />
              </a>
            </Box>
          </MobileView>
        </Box>
        <MobileView>
          <Box style={{ padding: 24, width: "100%" }}>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography style={{ fontSize: 12 }}>
                  © 2020 Mighty45 LLC | All rights reserved
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </MobileView>
      </Container>
      <BrowserView>
        <Box
          style={{ padding: 24, position: "fixed", bottom: 0, width: "100%" }}
        >
          <Grid container justifyContent="center" spacing={8}>
            <Grid item>
              <Typography>© 2020 Mighty45 LLC | All rights reserved</Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={8}>
                <Grid item>
                  <Link href="https://mighty45.tawk.help/" target="_blank">
                    <Typography>Help</Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://mighty45.com/about" target="_blank">
                    <Typography>About</Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </BrowserView>
    </Suspense>
  );
};

export default LandingPage;
