import React from "react";
import MenuButton from "../components/MenuButton";
import MenuButtonDeSelected from "../components/MenuButtonDeselected";

import AssessmentIcon from "@mui/icons-material/Assessment";
import PeopleIcon from "@mui/icons-material/People";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ForumIcon from "@mui/icons-material/Forum";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SettingsIcon from "@mui/icons-material/Settings";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { pages } from "../data/GlobalData";
import { Tooltip } from "@mui/material";

// So much reusable code here. Easy to refactor with a switch statement to get the correct icon

const MenuItem = ({ enabled, menuState, id, menuClicked, title }) => {
  function getIcon(id, enabled) {
    if (id === pages.reports) {
      if (enabled && menuState === "expanded") {
        return (
          <MenuButton
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<AssessmentIcon />}
          >
            {title}
          </MenuButton>
        );
      } else if (!enabled && menuState === "expanded") {
        return (
          <MenuButtonDeSelected
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<AssessmentIcon />}
          >
            {title}
          </MenuButtonDeSelected>
        );
      } else {
        return (
          <Tooltip title={title}>
            <AssessmentIcon
              color={enabled ? "primary" : "black"}
              onClick={() => {
                menuClicked(id, enabled);
              }}
            />
          </Tooltip>
        );
      }
    }

    if (id === pages.clients) {
      if (enabled && menuState === "expanded") {
        return (
          <MenuButton
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<PeopleIcon />}
          >
            {title}
          </MenuButton>
        );
      } else if (!enabled && menuState === "expanded") {
        return (
          <MenuButtonDeSelected
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<PeopleIcon />}
          >
            {title}
          </MenuButtonDeSelected>
        );
      } else {
        return (
          <Tooltip title={title}>
            <PeopleIcon
              color={enabled ? "primary" : "black"}
              onClick={() => {
                menuClicked(id, enabled);
              }}
            />
          </Tooltip>
        );
      }
    }

    if (id === pages.coaches) {
      if (enabled && menuState === "expanded") {
        return (
          <MenuButton
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<RecordVoiceOverIcon />}
          >
            {title}
          </MenuButton>
        );
      } else if (!enabled && menuState === "expanded") {
        return (
          <MenuButtonDeSelected
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<RecordVoiceOverIcon />}
          >
            {title}
          </MenuButtonDeSelected>
        );
      } else {
        return (
          <Tooltip title={title}>
            <RecordVoiceOverIcon
              color={enabled ? "primary" : "black"}
              onClick={() => {
                menuClicked(id, enabled);
              }}
            />
          </Tooltip>
        );
      }
    }

    if (id === pages.messages) {
      if (enabled && menuState === "expanded") {
        return (
          <MenuButton
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<ForumIcon />}
          >
            {title}
          </MenuButton>
        );
      } else if (!enabled && menuState === "expanded") {
        return (
          <MenuButtonDeSelected
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<ForumIcon />}
          >
            {title}
          </MenuButtonDeSelected>
        );
      } else {
        return (
          <Tooltip title={title}>
            <ForumIcon
              color={enabled ? "primary" : "black"}
              onClick={() => {
                menuClicked(id, enabled);
              }}
            />
          </Tooltip>
        );
      }
    }
    if (id === pages.shared_videos) {
      if (enabled && menuState === "expanded") {
        return (
          <MenuButton
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<YouTubeIcon />}
          >
            {title}
          </MenuButton>
        );
      } else if (!enabled && menuState === "expanded") {
        return (
          <MenuButtonDeSelected
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<YouTubeIcon />}
          >
            {title}
          </MenuButtonDeSelected>
        );
      } else {
        return (
          <Tooltip title={title}>
            <YouTubeIcon
              color={enabled ? "primary" : "black"}
              onClick={() => {
                menuClicked(id, enabled);
              }}
            />
          </Tooltip>
        );
      }
    }
    if (id === pages.protocols) {
      if (enabled && menuState === "expanded") {
        return (
          <MenuButton
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<ListAltIcon />}
          >
            {title}
          </MenuButton>
        );
      } else if (!enabled && menuState === "expanded") {
        return (
          <MenuButtonDeSelected
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<ListAltIcon />}
          >
            {title}
          </MenuButtonDeSelected>
        );
      } else {
        return (
          <Tooltip title={title}>
            <ListAltIcon
              color={enabled ? "primary" : "black"}
              onClick={() => {
                menuClicked(id, enabled);
              }}
            />
          </Tooltip>
        );
      }
    }

    if (id === pages.exercises) {
      if (enabled && menuState === "expanded") {
        return (
          <MenuButton
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<FitnessCenterIcon />}
          >
            {title}
          </MenuButton>
        );
      } else if (!enabled && menuState === "expanded") {
        return (
          <MenuButtonDeSelected
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<FitnessCenterIcon />}
          >
            {title}
          </MenuButtonDeSelected>
        );
      } else {
        return (
          <Tooltip title={title}>
            <FitnessCenterIcon
              color={enabled ? "primary" : "black"}
              onClick={() => {
                menuClicked(id, enabled);
              }}
            />
          </Tooltip>
        );
      }
    }

    if (id === pages.recipes) {
      if (enabled && menuState === "expanded") {
        return (
          <MenuButton
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<RestaurantIcon />}
          >
            {title}
          </MenuButton>
        );
      } else if (!enabled && menuState === "expanded") {
        return (
          <MenuButtonDeSelected
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<RestaurantIcon />}
          >
            {title}
          </MenuButtonDeSelected>
        );
      } else {
        return (
          <Tooltip title={title}>
            <RestaurantIcon
              color={enabled ? "primary" : "black"}
              onClick={() => {
                menuClicked(id, enabled);
              }}
            />
          </Tooltip>
        );
      }
    }

    if (id === pages.library) {
      if (enabled && menuState === "expanded") {
        return (
          <MenuButton
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<LibraryBooksIcon />}
          >
            {title}
          </MenuButton>
        );
      } else if (!enabled && menuState === "expanded") {
        return (
          <MenuButtonDeSelected
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<LibraryBooksIcon />}
          >
            {title}
          </MenuButtonDeSelected>
        );
      } else {
        return (
          <Tooltip title={title}>
            <LibraryBooksIcon
              color={enabled ? "primary" : "black"}
              onClick={() => {
                menuClicked(id, enabled);
              }}
            />
          </Tooltip>
        );
      }
    }
    if (id === pages.admin) {
      if (enabled && menuState === "expanded") {
        return (
          <MenuButton
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<SettingsIcon />}
          >
            {title}
          </MenuButton>
        );
      } else if (!enabled && menuState === "expanded") {
        return (
          <MenuButtonDeSelected
            style={{ marginTop: 4, minWidth: 145 }}
            onClick={() => {
              menuClicked(id, enabled);
            }}
            startIcon={<SettingsIcon />}
          >
            {title}
          </MenuButtonDeSelected>
        );
      } else {
        return (
          <Tooltip title={title}>
            <SettingsIcon
              color={enabled ? "primary" : "black"}
              onClick={() => {
                menuClicked(id, enabled);
              }}
            />
          </Tooltip>
        );
      }
    }

    if (enabled && menuState === "expanded") {
      return (
        <MenuButton
          style={{ marginTop: 4, minWidth: 145 }}
          onClick={() => {
            menuClicked(id, enabled);
          }}
          startIcon={<AssessmentIcon />}
        >
          {title}
        </MenuButton>
      );
    } else if (!enabled && menuState === "expanded") {
      return (
        <MenuButtonDeSelected
          style={{ marginTop: 4, minWidth: 145 }}
          onClick={() => {
            menuClicked(id, enabled);
          }}
          startIcon={<AssessmentIcon />}
        >
          {title}
        </MenuButtonDeSelected>
      );
    } else {
      return (
        <Tooltip title={title}>
          <AssessmentIcon
            color={enabled ? "primary" : "black"}
            onClick={() => {
              menuClicked(id, enabled);
            }}
          />
        </Tooltip>
      );
    }
  }
  return getIcon(id, enabled);
  // if (enabled) {
  //   if (menuState === "expanded") {
  //     return (
  //       <MenuButton
  //         style={{ marginTop: 4, minWidth: 145 }}
  //         onClick={() => {
  //           menuClicked(id, enabled);
  //         }}
  //         startIcon={<SettingsIcon />}
  //       >
  //         {title}
  //       </MenuButton>
  //     );
  //   } else {
  //     return getIcon(id, enabled);
  //   }
  // } else {
  //   if (menuState === "expanded") {
  //     return (
  //       <MenuButtonDeSelected
  //         style={{ marginTop: 4, minWidth: 145 }}
  //         onClick={() => {
  //           menuClicked(id, enabled);
  //         }}
  //       >
  //         {title}
  //       </MenuButtonDeSelected>
  //     );
  //   } else {
  //     return getIcon(id, enabled);
  //   }
  // }
};
export default MenuItem;
