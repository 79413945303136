import { styled } from "@mui/system";
import Button from "@mui/material/Button";

import colors from "../themes/Colors";

const MenuButtonDeSelected = styled(Button)(() => ({
  color: "black",
  backgroundColor: "white",
  fontWeight: "600",
  justifyContent: "left",
  borderRadius: 10,
  "&:hover": {
    backgroundColor: colors.secondaryBackground,
  },
}));

export default MenuButtonDeSelected;
