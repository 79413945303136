import { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { consola } from "consola";

import UserData from "../data/UserData";

const useSharedVideosByClient = ({ uid }) => {
  const [videos, setVideos] = useState([]);
  const [user, setUser] = useState();

  const subscription = useRef();

  useEffect(() => {
    UserData.getUserData(uid).then((user) => {
      setUser(user);
    });
  }, [uid]);

  useEffect(() => {
    if (subscription.current) {
      subscription.current();
    }
    const fetchVideos = async () => {
      if (!user || !uid) {
        return;
      }
      const sharedVideosRef = firebase
        .firestore()
        .collection(`organizations/${user.organizationId}/sharedVideos`)
        .where("clientId", "==", uid);

      subscription.current = sharedVideosRef
        .orderBy("createdOn", "desc")
        .onSnapshot((snapshot) => {
          consola.info(
            "++++++ SNAPSHOT -> useSharedVideosByClient: fetchVideos",
          );
          if (snapshot && snapshot.docs) {
            const data = snapshot.docs.map((doc) => {
              return doc.data();
            });
            setVideos(data);
          }
        });
    };
    fetchVideos();
  }, [user, uid]);

  const deleteSharedVideo = async (id) => {
    if (!user || !uid) {
      return;
    }
    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(user.organizationId)
      .collection("sharedVideos")
      .doc(id);

    // Get the file location and delete from shared storage.
    const sharedFileRef = firebase
      .storage()
      .ref(`/${user.organizationId}/shared_videos/${uid}/${id}.mp4`);
    // delete the file
    await sharedFileRef.delete();
    await ref.delete();
  };

  const setVideoProcessedState = async (id, coachUID, processed = true) => {
    if (!user || !uid || !id || !coachUID) {
      return;
    }

    const coachUser = await UserData.getUserData(coachUID);
    if (!coachUser) {
      return;
    }

    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(user.organizationId)
      .collection("sharedVideos")
      .doc(id);

    consola.info("+++++ WRITE => useSharedVideosByClient");
    await ref.set(
      {
        processed,
        processedOn: processed ? new Date() : null,
        processedBy: processed
          ? {
              uid: coachUser.uid,
              firstName: coachUser.firstName,
              lastName: coachUser.lastName,
            }
          : null,
      },
      { merge: true },
    );
  };

  return { videos, deleteSharedVideo, setVideoProcessedState };
};

export default useSharedVideosByClient;
