import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import _ from "lodash";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DateRangePicker from "../../../components/molecules/DateRangePicker";
import ReportData from "../../../data/ReportData";
import ReportSummaryDrawer from "./reports/ReportSummaryDrawer";
import ReportTableContainer from "../../../components/organisms/ReportTableContainer";
import useEvents from "../../../hooks/useEvents";

const lookBackCount = 200;

const ReportPage = () => {
  const navigate = useNavigate();
  const events = useEvents();

  const [activeReportsTier1, setActiveReportsTier1] = React.useState([]);
  const [activeReportsTier2, setActiveReportsTier2] = React.useState([]);
  const [activeReportsTier3, setActiveReportsTier3] = React.useState([]);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [report, setReport] = React.useState(undefined);
  const [update, setUpdate] = React.useState(null);
  const [type, setType] = React.useState("active");
  const [loading, setLoading] = React.useState(true);

  const [startDate, setStartDate] = React.useState(
    moment().subtract(30, "days").startOf("day"),
  );
  const [endDate, setEndDate] = React.useState(moment().endOf("day"));

  const subscriptionActive = React.useRef(undefined);

  React.useEffect(() => {
    return () => {
      if (navigate.action === "POP") {
        navigate("/home");
      }
    };
  }, [navigate]);

  React.useEffect(() => {
    firebase.analytics().logEvent("page_view", {
      page_title: "Report Page",
      page_location: "/reports",
    });
  }, []);

  function getReportsByTier(tier, reports) {
    const filtered = _.filter(reports, function (o) {
      const values = o;
      return (
        (!values.clientData.tier && tier === "tier1") ||
        values.clientData.tier === tier
      );
    });
    return filtered;
  }

  React.useEffect(() => {
    async function getReports() {
      // get the reports
      const uid = localStorage.getItem("uid");

      if (subscriptionActive.current) {
        subscriptionActive.current();
      }

      if (type === "active") {
        subscriptionActive.current =
          await ReportData.getReportsSubscriptionByStatus(
            uid,
            "unprocessed",
            undefined,
            undefined,
            undefined,
            (reports) => {
              setActiveReportsTier1(getReportsByTier("tier1", reports));
              setActiveReportsTier2(getReportsByTier("tier2", reports));
              setActiveReportsTier3(getReportsByTier("tier3", reports));

              setUpdate(new Date());
              setLoading(false);
            },
          );
      } else {
        subscriptionActive.current =
          await ReportData.getReportsSubscriptionByStatus(
            uid,
            "processed",
            lookBackCount,
            startDate.toDate(),
            endDate.toDate(),
            (reports) => {
              setActiveReportsTier1(getReportsByTier("tier1", reports));
              setActiveReportsTier2(getReportsByTier("tier2", reports));
              setActiveReportsTier3(getReportsByTier("tier3", reports));

              setUpdate(new Date());
              setLoading(false);
            },
          );
      }
    }
    getReports();

    return () => {
      //
      if (subscriptionActive.current) {
        subscriptionActive.current();
      }
    };
  }, [type, startDate, endDate]);

  return (
    <Box style={{ margin: 12 }}>
      {!drawerOpen && (
        <Box display="flex" justifyContent="flex-end">
          {type === "inactive" && (
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChangeDates={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // Switch type
              setLoading(true);
              if (type === "active") {
                setActiveReportsTier1([]);
                setActiveReportsTier2([]);
                setActiveReportsTier3([]);
                setType("inactive");
                events.logButtonClick({
                  buttonName: "See Recently Processed",
                  pageName: "Reports",
                });
              } else {
                setActiveReportsTier1([]);
                setActiveReportsTier2([]);
                setActiveReportsTier3([]);
                setType("active");
                events.logButtonClick({
                  buttonName: "See Active Reports",
                  pageName: "Reports",
                });
              }
            }}
          >
            {type === "active"
              ? "See Recently Processed"
              : "See Active Reports"}
          </Button>
        </Box>
      )}

      {!drawerOpen && (
        <ReportTableContainer
          loading={loading}
          type={type}
          title={type === "active" ? "Active Reports" : `Processed Reports`}
          reportsTier1={activeReportsTier1}
          reportsTier2={activeReportsTier2}
          reportsTier3={activeReportsTier3}
          showAlertCount={type === "active" ? true : false}
          onSeeProcessed={(type) => {
            setLoading(true);
            if (type === "active") {
              setActiveReportsTier1([]);
              setActiveReportsTier2([]);
              setActiveReportsTier3([]);
              setType("inactive");
            } else {
              setActiveReportsTier1([]);
              setActiveReportsTier2([]);
              setActiveReportsTier3([]);
              setType("active");
            }
          }}
          reportClicked={(report, closeOnly) => {
            setReport(report);
            if (closeOnly) {
              if (drawerOpen) {
                setDrawerOpen(false);
              }
            } else {
              setDrawerOpen(!drawerOpen);
            }
          }}
          deleteReportClicked={(report) => {
            confirmAlert({
              title: `Delete this report?`,
              message: "This cannot be undone. Continue with delete?",
              buttons: [
                {
                  label: "Yes",
                  onClick: async () => {
                    await ReportData.deleteReport(
                      localStorage.getItem("uid"),
                      report.id,
                    );
                    events.logButtonClick({
                      buttonName: "Delete Report",
                      pageName: "Reports",
                    });
                  },
                },
                {
                  label: "No",
                  onClick: () => {},
                },
              ],
            });
          }}
        />
      )}

      {drawerOpen && (
        <ReportSummaryDrawer
          open={drawerOpen}
          report={report}
          update={update}
          onClose={() => {
            setDrawerOpen(!drawerOpen);
          }}
          handleMarkProcessed={(report) => {
            if (report.status === "unprocessed") {
              ReportData.processReport({ reportId: report.id });
              events.logButtonClick({
                buttonName: "Process Report",
                pageName: "Reports",
                tabName: report,
              });
            } else {
              ReportData.processReport({
                reportId: report.id,
                status: "unprocessed",
              });
              events.logButtonClick({
                buttonName: "Unprocess Protocol",
                pageName: "Protocols",
                tabName: report,
              });
            }
            setDrawerOpen(!drawerOpen);
          }}
        />
      )}
    </Box>
  );
};

export default ReportPage;
