import React from "react";
import { Box, Select, MenuItem, Typography } from "@mui/material";
import _ from "underscore";
import moment from "moment";
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import GlobalData from "../../../../data/GlobalData";
import useEvents from "../../../../hooks/useEvents";

function getChartData(docs, chartType) {
  const filtered = _.filter(docs, (d) => {
    return d.exercise === chartType;
  });
  const data = filtered.map((exercise) => {
    const values = exercise;
    const exerciseReps = values.sets;
    // let totalWeight = 0;
    // let totalReps = 0;
    let maxWeight = 0;
    let maxReps = 0;
    if (exerciseReps) {
      exerciseReps.forEach((current) => {
        if (current.weight > maxWeight) {
          maxWeight = current.weight;
        }
        if (current.completedReps > maxReps) {
          maxReps = current.completedReps;
        }
      });

      return {
        id: values.exercise,
        date: moment(exercise.createdOn).format("MMM DD"),
        weight: maxWeight,
        reps: maxReps,
      };
    }
    return null;
  });
  return _.filter(data, (d) => {
    return d !== null;
  });
}

const ExerciseCharts = (props) => {
  const [chartType, setChartType] = React.useState("");
  const events = useEvents();
  const [dataType, setDataType] = React.useState("weight");
  const [exercises, setExercises] = React.useState([]);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    async function getData() {
      // Get the chart data.
      const exercisesDocs = await GlobalData.getExercises();
      setExercises(exercisesDocs);
    }
    getData();
  }, []);

  React.useEffect(() => {
    if (props.chartType) {
      const chartData = getChartData(props.exerciseData, props.chartType);

      const found = _.findWhere(exercises, { id: props.chartType });
      if (found) {
        setChartType(props.chartType);
      } else {
        setChartType("");
      }
      setData(chartData);
    }
  }, [props.exerciseData, props.chartType, exercises]); // eslint-disable-line

  React.useEffect(() => {
    const chartData = getChartData(props.exerciseData, chartType);
    setData(chartData);
  }, [chartType, props.exerciseData]);

  function getVisualChart() {
    if (data && data.length > 0) {
      return (
        <ResponsiveContainer minHeight="200" aspect={4.0 / 3.0} width="95%">
          <LineChart
            data={data}
            margin={{ top: 6, right: 0, left: -30, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis domain={[0, "dataMax + 20"]} />
            <Tooltip />

            <Line
              dot={false}
              type="monotone"
              dataKey={dataType}
              stroke="#8884d8"
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      );
    }
    return (
      <ResponsiveContainer minHeight="200" aspect={4.0 / 3.0} width="95%">
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            There is no history for this exercise.
          </Typography>
        </Box>
      </ResponsiveContainer>
    );
  }

  return (
    <Box style={{ padding: 12, backgroundColor: "white" }}>
      <Box>
        <Select
          fullWidth={true}
          style={{ width: "60%", marginBottom: 20, marginRight: 4 }}
          variant="outlined"
          value={chartType}
          onChange={(event) => {
            setChartType(event.target.value);
            events.logButtonClick({
              buttonName: "Exercise Chart Selected: " + event.target.value,
              pageName: "Clients",
              tabName: props.client.uid,
            });
          }}
        >
          {exercises.map((e) => {
            return (
              <MenuItem key={e.id} value={e.id}>
                {e.name}
              </MenuItem>
            );
          })}
        </Select>
        <Select
          fullWidth={true}
          style={{ width: "35%", marginBottom: 20 }}
          variant="outlined"
          value={dataType}
          onChange={(event) => {
            setDataType(event.target.value);
          }}
        >
          <MenuItem key="weight" value="weight">
            Weight
          </MenuItem>
          <MenuItem key="reps" value="reps">
            Reps
          </MenuItem>
        </Select>
      </Box>

      {getVisualChart()}
    </Box>
  );
};

export default ExerciseCharts;
