import React from "react";
import { Box, Typography, Stack, Divider } from "@mui/material";

const NutritionSummary = ({ report }) => {
  if (!report) {
    return null;
  }

  const meals = report.clientData?.statistics?.untrackedMeals ?? 0;

  console.log(meals);

  return (
    <Box sx={{ mt: 1 }}>
      <Stack>
        <Typography style={{ color: "black", fontSize: 24, fontWeight: "600" }}>
          Nutrition Summary
        </Typography>
        <Typography>
          {meals > 0
            ? `${meals} untracked meals were allowed this week`
            : "No untracked meals were allowed this week"}
        </Typography>
      </Stack>
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
};

export default NutritionSummary;
