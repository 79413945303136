import React from "react";
import { Select, Box, Chip, MenuItem } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import _ from "underscore";

import useLabels from "../../hooks/useLabels";

// const useStyles = makeStyles({
//   root: {
//     padding: 8,
//   },
//   selectMenu: {
//     minHeight: 35,
//   },
// });

const LabelSelect = ({
  id,
  useTimer = false,
  currentLabels,
  onLabelsChanged,
  width,
}) => {
  const [selectedLabels, setSelectedLabels] = React.useState(
    currentLabels ? currentLabels : [],
  );
  const timer = React.useRef();
  // const classes = useStyles();
  const { labels } = useLabels();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const setSelectedLabelsCallback = React.useCallback((event) => {
    const {
      target: { value },
    } = event;
    setSelectedLabels(value);
  }, []);

  const setSelectedLabelsClosedCallback = React.useCallback(() => {
    if (useTimer) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        const changes = {
          protocolLabels: selectedLabels,
        };
        if (id) {
          localStorage.setItem(`LabelSelect_${id}`, JSON.stringify(changes));
        }
        if (onLabelsChanged) {
          onLabelsChanged(changes);
        }
      }, [500]);
    } else {
      const changes = {
        protocolLabels: selectedLabels,
      };
      if (id) {
        localStorage.setItem(`LabelSelect_${id}`, JSON.stringify(changes));
      }
      if (onLabelsChanged) {
        onLabelsChanged(changes);
      }
    }
  }, [id, selectedLabels, onLabelsChanged, useTimer]);

  function getLabel(id) {
    const value = _.findWhere(labels, { id: id });
    return value;
  }

  function getStyle(id) {
    if (!selectedLabels) {
      return {
        fontWeight: "normal",
      };
    }
    const index = selectedLabels.indexOf(id);
    if (index !== -1) {
      return {
        fontWeight: "bold",
      };
    } else {
      return {
        fontWeight: "normal",
      };
    }
  }

  return (
    <Select
      // classes={{ root: classes.root, selectMenu: classes.selectMenu }}
      style={{ width: width ? width : 100 }}
      variant="outlined"
      multiple
      value={selectedLabels}
      onChange={setSelectedLabelsCallback}
      onClose={setSelectedLabelsClosedCallback}
      renderValue={(selected) => {
        if (!selected) {
          return null;
        }
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => {
              if (!value) {
                return null;
              }
              const label = getLabel(value);
              if (!label) {
                return null;
              }
              return (
                <Chip
                  variant="outlined"
                  style={{
                    color: label.color,
                    borderWidth: 2,
                    borderColor: label.backgroundColor,
                    marginRight: 6,
                  }}
                  key={value}
                  label={label.name}
                />
              );
            })}
          </Box>
        );
      }}
      MenuProps={MenuProps}
    >
      {labels &&
        labels.map((label) => (
          <MenuItem
            key={label.name}
            value={label.id}
            style={getStyle(label.id)}
          >
            {label.name}
          </MenuItem>
        ))}
    </Select>
  );
};

export default LabelSelect;
