import React from "react";

import { Box, Typography, Stack, Button } from "@mui/material";

import useAPIKeys from "../../hooks/useAPIKeys";
import APIKeyList from "./APIKeyList";
import useEvents from "../../hooks/useEvents";

function IntegrationPage({ organizationData }) {
  const { keys, addAPIKey, deleteAPIKey } = useAPIKeys();
  const events = useEvents();

  if (!organizationData) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box>
      <Stack>
        <Typography variant="h4">Integration</Typography>

        <Typography style={{ fontSize: 20 }}>
          Below you can generate an API key for your organization. This key is
          used to authenticate your organization when making API calls. Delete a
          key if you suspect it has been compromised.
        </Typography>

        <Box style={{ marginTop: 12 }}>
          <Stack>
            <APIKeyList
              snapshot={keys}
              onDelete={(key) => {
                deleteAPIKey(key);
                const deleteKeyEvent = {
                  buttonName: "Delete API Key",
                  pageName: "Admin",
                  tabName: "Integration ",
                };
                events.logButtonClick(deleteKeyEvent);
              }}
            />
            <Button
              variant="contained"
              onClick={() => {
                addAPIKey();
                const addKeyEvent = {
                  buttonName: "Add API Key",
                  pageName: "Admin",
                  tabName: "Integration ",
                };
                events.logButtonClick(addKeyEvent);
              }}
              color="primary"
            >
              Create new API Key
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default IntegrationPage;
