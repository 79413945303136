import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import CoachData from "../../../data/CoachData";
import CoachTable from "./coaches/CoachTable";
import CoachSummary from "./coaches/CoachSummary";
import AddCoachDialog from "./coaches/AddCoachDialog";

import userDataState from "../../../atoms/userDataAtom";
import useEvents from "../../../hooks/useEvents";

const CoachesPage = () => {
  const navigate = useNavigate();

  const [coaches, setCoaches] = React.useState([]);
  const [mode, setMode] = React.useState("list");
  const [showDisabled] = React.useState(true);

  const [editCoachOpen, setCoachEditOpen] = React.useState(false);

  const subscription = React.useRef(undefined);
  const selectedCoach = React.useRef(null);

  const userData = useRecoilValue(userDataState);
  const events = useEvents();

  React.useEffect(() => {
    return () => {
      if (navigate.action === "POP") {
        navigate("/home");
      }
    };
  }, [navigate]);

  React.useEffect(() => {
    firebase.analytics().logEvent("page_view", {
      page_title: "Coaches Page",
      page_location: "/coaches",
    });
  }, []);

  React.useEffect(() => {
    async function getCoaches() {
      if (subscription.current) {
        subscription.current();
      }

      subscription.current = await CoachData.getCoachesSubscription(
        (snapshot) => {
          if (showDisabled) {
            setCoaches(snapshot);
          } else {
            const filtered = _.filter(snapshot, { status: "active" });
            setCoaches(filtered);
          }
        },
      );
    }
    getCoaches();

    return () => {
      if (subscription.current) {
        subscription.current();
      }
    };
  }, [showDisabled]);

  if (mode === "list") {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CoachTable
          coaches={coaches}
          onAddCoachClicked={() => {
            setCoachEditOpen(true);
          }}
          onCoachClicked={(coach) => {
            selectedCoach.current = coach;
            setMode("detail");
          }}
        />
        {editCoachOpen && (
          <AddCoachDialog
            open={editCoachOpen}
            userData={userData}
            onDialogClosed={(coach) => {
              if (coach) {
                CoachData.addCoach(coach);
              }
              setCoachEditOpen(false);
            }}
          />
        )}
      </Box>
    );
  } else {
    return (
      <CoachSummary
        userData={userData}
        coach={selectedCoach.current}
        onBackClick={() => {
          events.logButtonClick({
            buttonName: "Back Button",
            pageName: "Coaches",
            tabName: selectedCoach.current,
          });
          setMode("list");
        }}
      />
    );
  }
};

export default CoachesPage;
