import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { getRecoil } from "recoil-nexus";
import _ from "underscore";
import ld from "lodash";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import { nanoid } from "nanoid";
import { customAlphabet } from "nanoid";

import MessageData, { GroupType, sendMessageToUsersGroup } from "./MessageData";
import { DataType, WorkoutStatus } from "./GlobalData";
import OrganizationData from "./OrganizationData";
import organizationIdState from "../atoms/organizationIdSelector";
import organizationDataState from "../atoms/organizationDataAtom";
import userDataState from "../atoms/userDataAtom";
import usersState from "../atoms/usersAtom";
import { getkCal } from "../components/LibraryFunctions";
import { consola } from "consola";
const nanoidCustom = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 8);

export const TierTitles = {
  tier1: "Tier 1",
  tier2: "Tier 2",
  tier3: "Tier 3",
};

export const ClientSubscriptionFilters = {
  all: "all",
  active: "active",
  disabled: "disabled",
};

const AveragesCache = () => {
  let averagesCache = {};
  const averagesCacheString = localStorage.getItem("averagesCache");
  if (averagesCacheString) {
    averagesCache = JSON.parse(averagesCacheString);
  }

  return {
    getCachedAverages: ({ clientId }) => {
      return averagesCache[clientId];
    },
    setCachedAverages: ({ clientId, hash, averages }) => {
      averagesCache[clientId] = {
        averages: averages,
        hash: hash,
      };

      localStorage.setItem("averagesCache", JSON.stringify(averagesCache));
    },
    clearCachedAverages: ({ clientId }) => {
      delete averagesCache[clientId];
      localStorage.setItem("averagesCache", JSON.stringify(averagesCache));
    },
  };
};

export const averagesCache = AveragesCache();

const getPhotoGroups = (docs, limit = 3) => {
  const groups = {};
  let groupCount = 0;
  // Build the groups
  docs.forEach((doc) => {
    const data = doc.data();
    if (data.groupId) {
      if (groupCount <= limit) {
        if (data.groupId in groups) {
          const group = groups[data.groupId];
          group.photos[data.type] = data.url;
          group.metadata.ids.push(data.id);
        } else {
          groupCount += 1;
          if (groupCount <= limit) {
            const groupData = {};
            groupData.photos = {};
            groupData.photos[data.type] = data.url;
            groupData.metadata = {
              createdOn: data.createdOn.toDate(),
              configuration: data.configuration,
              ids: [data.id],
            };
            groups[data.groupId] = groupData;
          }
        }
      }
    }
  });
  return groups;
};

function findUser(uid) {
  const usersData = getRecoil(usersState);
  return _.findWhere(usersData, { uid: uid });
}

const AggregateClientsData = async (clientData) => {
  const aggregatedPromises = clientData.map((doc) => {
    return new Promise((resolve) => {
      const clientValues = doc.data();
      const cachedUser = findUser(clientValues.uid);

      if (cachedUser) {
        resolve({
          ...cachedUser,
          ...clientValues,
        });
        return;
      }

      const ref = firebase
        .firestore()
        .collection("users")
        .doc(clientValues.uid);
      consola.info("++++++ READ -> ClientData: AggregateClientData");

      ref
        .get()
        .then((userData) => {
          if (userData.exists) {
            const userDataValues = userData.data();

            resolve({
              ...userDataValues,
              ...clientValues,
            });
          } else {
            Bugsnag.notify(
              new Error("User data not found getting client data."),
            );
            resolve(null);
          }
        })
        .catch((e) => {
          Bugsnag.notify(e);
          resolve(null);
        });
    });
  });
  let aggregated = await Promise.all(aggregatedPromises);
  aggregated = _.filter(aggregated, (a) => {
    return a !== null && a.isDeleted !== true;
  });
  const sorted = _.sortBy(aggregated, "firstName");
  return sorted;
};

const AggregateClientData = async (clientValues) => {
  let userDataValues = findUser(clientValues.uid);
  if (!userDataValues) {
    const ref = firebase.firestore().collection("users").doc(clientValues.uid);
    consola.info("++++++ READ -> UserData: AggregateClientData");
    const userData = await ref.get();
    userDataValues = userData.data();
  } else {
    consola.info("++++++ CACHE -> UserData: AggregateClientData");
  }

  if (!clientValues.nutritionGoals) {
    clientValues.nutritionGoals = [
      {
        id: nanoid(),
        name: "Default Goals",
        carbs: 100,
        fats: 50,
        proteins: 100,
        water: 1000,
        active: true,
      },
    ];
  }

  return {
    ...userDataValues,
    ...clientValues,
  };
};

function getActiveNutritionGoals(nutritionGoals) {
  if (!nutritionGoals) {
    consola.error(new Error("No nutrition goals defined."));
  }
  let active;
  nutritionGoals.forEach((nutritionGoal) => {
    if (nutritionGoal.active) {
      active = nutritionGoal;
    }
  });
  if (!active) {
    active = nutritionGoals[0];
  }
  return active;
}

export function getOptionalDefault(optional) {
  let optionalsLocal = {
    value: 0,
    notes: "",
  };

  if (!optional) {
    return optionalsLocal;
  }

  if (optional.type === DataType.multiline) {
    optionalsLocal = {
      value: "",
      notes: "",
    };
  } else if (optional.type === DataType.rating) {
    optionalsLocal = {
      value: 0,
      notes: "",
    };
  } else if (optional.type === DataType.yesno) {
    optionalsLocal = {
      value: false,
      notes: "",
    };
  } else {
    optionalsLocal = {
      value: optional.minValue ? optional.minValue : 0,
      notes: "",
    };
  }

  return optionalsLocal;
}

function getBaseReportObject() {
  return {
    kCal: 0,
    proteins: 0,
    carbs: 0,
    fats: 0,
    weight: 0,
    water: 0,
    notes: "",
    steps: 0,
    averageHeartRate: 0,
    kCalNotes: "",
    proteinsNotes: "",
    carbsNotes: "",
    fatsNotes: "",
    weightNotes: "",
    waterNotes: "",
  };
}

function getInitializedOptionals(orgData) {
  const optionalsLocal = {};
  if (orgData && orgData.optional) {
    orgData.optional.forEach((optional) => {
      optionalsLocal[optional.id] = getOptionalDefault(optional);
    });
  }
  return optionalsLocal;
}

export async function createHealthReport({ uid, date, report }) {
  if (!uid || !date) {
    consola.error(new Error("createHealthReport - uid and date are required."));
  }

  const userRef = firebase.firestore().collection("users").doc(uid);
  consola.info("++++++ READ -> ClientData: createHealthReport");
  const userData = await userRef.get();
  if (userData.exists) {
    const userDataValues = userData.data();
    const organizationId = userDataValues.organizationId;

    const clientRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(userDataValues.uid);
    const clientData = await clientRef.get();

    if (!clientData.exists) {
      consola.error(
        new Error("createHealthReport - Client data does not exist."),
      );
    }

    const clientDataValue = clientData.data();

    const dateIndex = moment(date).format("YYYY-MM-DD");

    const reportRef = firebase
      .firestore()
      .doc(
        `organizations/${organizationId}/clients/${userDataValues.uid}/reportHealth/${dateIndex}`,
      );

    report.id = nanoid();

    const activeGoals = getActiveNutritionGoals(clientDataValue.nutritionGoals);
    const macros = {
      proteins: activeGoals.proteins,
      carbs: activeGoals.carbs,
      fats: activeGoals.fats,
      water: activeGoals.water,
      activeGoalName: activeGoals.name,
      activeGoalId: activeGoals.id,
    };

    const saveData = {
      id: report.id,
      clientId: userDataValues.uid,
      organizationId: organizationId,
      reportData: report,
      clientData: {
        ...macros,
        ...clientDataValue.statistics,
      },
    };

    saveData.updatedOn = date;
    saveData.createdOn = date;
    saveData.dateIndex = dateIndex;
    consola.info("+++++ WRITE => ClientData.js createHealthReport");
    await reportRef.set(saveData, { merge: true });

    if (
      report.weight &&
      report.weight > 0 &&
      dateIndex === moment().format("YYYY-MM-DD")
    ) {
      await clientRef.update({
        statistics: {
          ...clientDataValue.statistics,
          currentWeight: report.weight,
        },
      });
    }

    return saveData;
  }
  consola.error(new Error("createHealthReport - User data does not exist."));
}

export async function saveHealthReport({ uid, date, data }) {
  if (!uid || !date) {
    consola.error(
      new Error(
        `saveHealthReport - uid and date are required. (${uid}, ${date})`,
      ),
    );
  }

  const userRef = firebase.firestore().collection("users").doc(uid);
  consola.info("++++++ READ -> ClientData: saveHealthReport");
  const userData = await userRef.get();
  if (userData.exists) {
    const userDataValues = userData.data();
    const organizationId = userDataValues.organizationId;

    const clientRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(userDataValues.uid);

    const clientData = await clientRef.get();

    if (!clientData.exists) {
      consola.error(
        new Error("saveHealthReport - Client data does not exist."),
      );
    }
    const clientDataValue = clientData.data();

    const dateIndex = moment(date).format("YYYY-MM-DD");

    const reportRef = firebase
      .firestore()
      .doc(
        `organizations/${organizationId}/clients/${userDataValues.uid}/reportHealth/${dateIndex}`,
      );

    const saveData = {
      reportData: {
        ...data,
      },
    };

    saveData.updatedOn = new Date();
    consola.info("+++++ WRITE => ClientData: saveHealthReport");
    await reportRef.set(saveData, { merge: true });

    if (
      data.weight &&
      data.weight > 0 &&
      dateIndex === moment().format("YYYY-MM-DD")
    ) {
      await clientRef.update({
        statistics: {
          ...clientDataValue.statistics,
          currentWeight: data.weight,
        },
      });
    }
  }
}

export function getExerciseHistoryWithId(exerciseHistory, exerciseId) {
  const found = _.find(exerciseHistory, (e) => {
    return e.id === exerciseId;
  });
  return found;
}

export async function getExerciseHistories(uid, organizationId) {
  const exerciseHistoryRef = firebase
    .firestore()
    .collection(
      `organizations/${organizationId}/clients/${uid}/exerciseHistory`,
    );
  consola.info("++++++ READ -> ClientData: getExerciseHistories");
  const exerciseHistory = await exerciseHistoryRef.get();
  const exerciseHistoryValues = exerciseHistory.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
  return exerciseHistoryValues;
}

async function getExerciseDataFromTemplate(exercises, uid, organizationId) {
  if (!exercises || !uid) {
    return {
      exercises: [],
      completed: false,
    };
  }

  const exerciseHistories = await getExerciseHistories(uid, organizationId);

  const result = exercises.map(async (exercise) => {
    const sets = [];

    for (var i = 0; i < exercise.sets; i++) {
      if (exercise.type === "super_set") {
        const exercises = exercise.exercises.map((e) => {
          const historyFound = getExerciseHistoryWithId(
            exerciseHistories,
            e.exercise,
          );

          const splitString = e.reps
            ? String(e.reps).match(/(?:\d+\.)?\d+/g)
            : 8;

          let minReps =
            e.reps && splitString && splitString.length > 0
              ? parseInt(splitString[0], 10)
              : 8;
          let weight = 0.0;

          if (historyFound && historyFound.completedSets) {
            const index = ld.findIndex(historyFound.completedSets, {
              index: 0,
            });
            if (index > -1) {
              minReps = historyFound.completedSets[index].completedReps
                ? historyFound.completedSets[index].completedReps
                : minReps;

              if (historyFound.completedSets[index].weight) {
                weight = historyFound.completedSets[index].weight;
              }
            }
          }

          return {
            exercise: e.exercise,
            exerciseName: e.exerciseName,
            notes: e.exercise.notes,
            reps: minReps,
            repsPattern: String(e.reps),
            repsMax: e.repsMax ? e.repsMax : minReps,
            tempo: e.tempo,
            setsToFailure: e.setsToFailure ? e.setsToFailure : 0,
            rest: e.rest,
            completed: false,
            weight: weight,
            completedReps: 0,
            id: nanoid(),
          };
        });
        sets.push({
          exercises: exercises,
          completed: false,
          id: nanoid(),
        });
      } else {
        const historyFound = getExerciseHistoryWithId(
          exerciseHistories,
          exercise.exercise,
        );

        const splitString = exercise.reps
          ? String(exercise.reps).match(/(?:\d+\.)?\d+/g)
          : 8;

        let minReps =
          exercise.reps && splitString && splitString.length > 0
            ? parseInt(splitString[0], 10)
            : 8;

        // let minReps = exercise.reps ? exercise.reps : 8;
        let weight = 0.0;

        if (historyFound && historyFound.completedSets) {
          const index = ld.findIndex(historyFound.completedSets, { index: i });
          if (index > -1) {
            minReps = historyFound.completedSets[index].completedReps
              ? historyFound.completedSets[index].completedReps
              : minReps;

            weight = historyFound.completedSets[index].weight
              ? historyFound.completedSets[index].weight
              : weight;
          }
        }

        sets.push({
          reps: minReps,
          repsMax: exercise.repsMax ? exercise.repsMax : minReps,
          repsPattern: String(exercise.reps),
          tempo: exercise.tempo,
          rest: exercise.rest,
          completed: false,
          weight: weight,
          completedReps: 0,
        });
      }
    }

    /**
     * When adding a new field or property to a workout, you need to
     * add that property here when the report is created. The report
     * clones that information to each new report.
     */
    return {
      id: nanoid(),
      sets: sets,
      exercise: exercise.exercise,
      exerciseName: exercise.exerciseName,
      labels: exercise.labels,
      superSetName: exercise.superSetName,
      setsToFailure: exercise.setsToFailure ? exercise.setsToFailure : 0,
      repsPattern: String(exercise.reps),
      type: exercise.type,
      notes: exercise.notes,
      completed: false,
    };
  });

  const values = await Promise.all(result);
  return {
    exercises: values,
    completed: false,
  };
}

async function getCustomExercises(organizationId) {
  const customExerciseRef = firebase
    .firestore()
    .collection("organizations")
    .doc(organizationId)
    .collection("customExercise");

  consola.info("++++++ READ -> ClientData: getCustomExercises");
  const data = await customExerciseRef.get();
  return data.docs.map((d) => {
    return d.data();
  });
}

export async function getExercises(organizationId) {
  const exercisesRef = firebase
    .firestore()
    .collection("global")
    .doc("data")
    .collection("exercises");

  consola.info("++++++ READ -> ClientData: getExercises");
  const exercisesDocs = await exercisesRef.get();
  const customExercises = await getCustomExercises(organizationId);

  const exercises = exercisesDocs.docs.map((d) => {
    const value = d.data();
    const found = _.findWhere(customExercises, { id: value.id });
    if (found) {
      return found;
    }
    return value;
  });

  customExercises.forEach((c) => {
    const found = _.findWhere(exercises, { id: c.id });
    if (!found) {
      exercises.push(c);
    }
  });
  const sorted = _.sortBy(exercises, "name");

  return sorted;
}

async function getProtocolData({ organizationId, protocolId, splitId }) {
  const protocolDataValues = {};
  if (protocolId) {
    // Get the protocol
    const protocolRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocols")
      .doc(protocolId);

    const splitsRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolSplits");

    consola.info("++++++ READ -> ClientData: getProtocolData");

    const protocolData = await protocolRef.get();
    const splitsData = await splitsRef.get();

    const splitDataValues = splitsData.docs.map((d) => {
      return d.data();
    });
    const sortedSplits = _.sortBy(splitDataValues, "index");

    if (protocolData.exists) {
      protocolDataValues.protocolData = protocolData.data();
      protocolDataValues.protocolSplits = sortedSplits;
    }

    if (splitId) {
      const splitRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocolSplits")
        .doc(splitId);

      const splitData = await splitRef.get();
      if (splitData.exists) {
        protocolDataValues.splitData = splitData.data();
      }

      const exercisesDB = await getExercises(organizationId);

      const groups = {
        super_set: "Super Set",
      };
      exercisesDB.forEach((g) => {
        groups[g.id] = {
          exerciseName: g.name,
          exerciseDescription: g.description,
          instructions: g.instructions,
          difficulty: g.difficulty,
          equipment: g.equipment,
          mainMuscleGroups: g.mainMuscleGroups,
          secondaryMuscleGroups: g.secondaryMuscleGroups,
          videos: g.videos,
          images: g.images,
        };
      });

      const exercisesRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocolExercises")
        .where("splitId", "==", splitId);

      consola.info("++++++ READ -> ClientData exerciseData");
      const exerciseData = await exercisesRef.get();

      const exerciseDataValues = exerciseData.docs
        .map((d) => {
          if (d.data().exercise in groups) {
            return {
              exerciseName: groups[d.data().exercise].exerciseName,
              exerciseDescription:
                groups[d.data().exercise].exerciseDescription,
              instructions: groups[d.data().exercise].instructions,
              difficulty: groups[d.data().exercise].difficulty,
              equipment: groups[d.data().exercise].equipment,
              mainMuscleGroups: groups[d.data().exercise].mainMuscleGroups,
              secondaryMuscleGroups:
                groups[d.data().exercise].secondaryMuscleGroups,
              videos: groups[d.data().exercise].videos,
              images: groups[d.data().exercise].images,
              ...d.data(),
              exercises:
                d.data().type === "normal_set"
                  ? d.data().exercises
                  : d
                      .data()
                      .exercises.map((e) => {
                        if (e.exercise in groups) {
                          return {
                            exerciseName: groups[e.exercise].exerciseName,
                            exerciseDescription:
                              groups[e.exercise].exerciseDescription,
                            instructions: groups[e.exercise].instructions,
                            difficulty: groups[e.exercise].difficulty,
                            equipment: groups[e.exercise].equipment,
                            mainMuscleGroups:
                              groups[e.exercise].mainMuscleGroups,
                            secondaryMuscleGroups:
                              groups[e.exercise].secondaryMuscleGroups,
                            videos: groups[e.exercise].videos,
                            images: groups[e.exercise].images,
                            ...e,
                          };
                        } else {
                          Bugsnag.notify(
                            new Error(
                              `Super Set - Missing exercise in groups: ${e.exercise}`,
                            ),
                          );
                          return null;
                        }
                      })
                      .filter((f) => {
                        return f !== null;
                      }),
            };
          } else {
            Bugsnag.notify(
              new Error(
                `Straight Set - Missing exercise in groups: ${
                  d.data().exercise
                }`,
              ),
            );
            return null;
          }
        })
        .filter((f) => {
          return f !== null;
        });
      const sortedExercises = _.sortBy(exerciseDataValues, "index");

      protocolDataValues.exercises = sortedExercises;
    }
  }
  return protocolDataValues;
}

export async function newExerciseReport({
  uid,
  organizationId,
  protocolId,
  splitId,
} = {}) {
  if (!uid) {
    consola.error(
      new Error("saveExerciseReport(): uid missing in parameters."),
    );
  }

  // Get protocol information.
  const protocolData = await getProtocolData({
    organizationId: organizationId,
    protocolId: protocolId,
    splitId: splitId,
  });
  const exercisesData = await getExerciseDataFromTemplate(
    protocolData.exercises,
    uid,
    organizationId,
  );

  const createdOn = new Date();
  const dateIndex = moment(createdOn).format("YYYY-MM-DD");

  const newReportId = nanoid();

  const path = `organizations/${organizationId}/clients/${uid}/reportExercise/${newReportId}`;
  const reportRef = firebase.firestore().doc(path);

  const newExercises = _.map(exercisesData.exercises, function (o) {
    return _.omit(o, ["instructions"]);
  });
  exercisesData.exercises = newExercises;

  const saveData = {
    id: newReportId,
    status: WorkoutStatus.incomplete,
    clientId: uid,
    organizationId: organizationId,
    createdOn: createdOn,
    updatedOn: createdOn,
    dateIndex: dateIndex,
    clientData: {
      exercises: protocolData.exercises,
    },
    reportData: {
      protocol: {
        ...protocolData.protocolData,
      },
      split: {
        ...protocolData.splitData,
      },
      ...exercisesData,
    },
  };

  const jsonHold = JSON.stringify(saveData);
  const removedValues = JSON.parse(jsonHold);
  removedValues.createdOn = createdOn;
  removedValues.updatedOn = createdOn;

  try {
    await firebase.firestore().runTransaction(async () => {
      consola.info("+++++ WRITE => ClientData.js newExerciseReport");
      await reportRef.set(removedValues, { merge: true });
    });
  } catch (e) {
    Bugsnag.notify(e);
    throw e;
  }

  return saveData;
}

async function getDefaultReport(
  clientUID,
  organizationId,
  protocolId,
  splitId,
) {
  return await newExerciseReport({
    uid: clientUID,
    organizationId: organizationId,
    protocolId: protocolId,
    splitId: splitId,
  });
}

export async function saveHealthReportClientData({
  uid,
  date,
  data,
  callback,
}) {
  if (!uid || !date) {
    consola.error(
      new Error(
        `saveHealthReport - uid and date are required. (${uid}, ${date})`,
      ),
    );
  }
  consola.info("++++++ READ -> ClientData: saveHealthReportClientData");
  const userRef = firebase.firestore().collection("users").doc(uid);
  const userData = await userRef.get();
  if (userData.exists) {
    const userDataValues = userData.data();
    const organizationId = userDataValues.organizationId;

    const clientRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(userDataValues.uid);
    const clientData = await clientRef.get();

    if (!clientData.exists) {
      consola.error(
        new Error("saveHealthReport - Client data does not exist."),
      );
    }

    const dateIndex = moment(date).format("YYYY-MM-DD");

    const reportRef = firebase
      .firestore()
      .doc(
        `organizations/${organizationId}/clients/${userDataValues.uid}/reportHealth/${dateIndex}`,
      );

    const saveData = {
      clientData: {
        ...data,
      },
    };

    saveData.updatedOn = new Date();
    consola.info("+++++ WRITE => ClientData: saveHealthReportClientData");
    await reportRef.set(saveData, { merge: true });

    if (callback) {
      callback(saveData);
    }
    return saveData;
  }
  consola.error(new Error("saveHealthReport - User data does not exist."));
}

export const activateNutritionGoal = async (clientData, nutritionGoal) => {
  const index = _.findIndex(clientData.nutritionGoals, {
    id: nutritionGoal.id,
  });
  if (index !== -1) {
    const newGoals = [].concat(clientData.nutritionGoals);
    newGoals.forEach((g) => {
      g.active = false;
    });
    newGoals[index].active = true;

    await ClientData.updateClient(clientData.uid, {
      nutritionGoals: newGoals,
    });

    const macros = {
      proteins: newGoals[index].proteins,
      carbs: newGoals[index].carbs,
      fats: newGoals[index].fats,
      water: newGoals[index].water,
      activeGoalName: newGoals[index].name,
      activeGoalId: newGoals[index].id,
    };

    await saveHealthReportClientData({
      uid: clientData.uid,
      date: moment().toDate(),
      data: macros,
    });
  }
};

const ClientData = {
  getClients: async (uid) => {
    const organizationId = getRecoil(organizationIdState);

    const reportRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .where("coaches", "array-contains", uid);
    consola.info("++++++ READ -> ClientData: getClients");
    const clients = await reportRef.get();
    const docs = clients.docs;

    const aggregatedData = await AggregateClientsData(docs);

    return aggregatedData;
  },
  getClientsSubscription: async ({
    uid,
    onChange,
    filter = ClientSubscriptionFilters.active,
  } = {}) => {
    try {
      const organizationId = getRecoil(organizationIdState);
      const userDataValues = getRecoil(userDataState);

      let clientRef;

      if (
        filter === ClientSubscriptionFilters.all &&
        userDataValues.role === "admin"
      ) {
        clientRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("clients");
      } else {
        if (filter === ClientSubscriptionFilters.all) {
          clientRef = firebase
            .firestore()
            .collection("organizations")
            .doc(organizationId)
            .collection("clients")
            .where("coaches", "array-contains", uid ? uid : userDataValues.uid);
        } else if (filter === ClientSubscriptionFilters.active) {
          clientRef = firebase
            .firestore()
            .collection("organizations")
            .doc(organizationId)
            .collection("clients")
            .where("coaches", "array-contains", uid ? uid : userDataValues.uid)
            .where("status", "==", ClientSubscriptionFilters.active);
        } else if (filter === ClientSubscriptionFilters.disabled) {
          clientRef = firebase
            .firestore()
            .collection("organizations")
            .doc(organizationId)
            .collection("clients")
            .where("coaches", "array-contains", uid ? uid : userDataValues.uid)
            .where("status", "==", ClientSubscriptionFilters.disabled);
        } else {
          clientRef = firebase
            .firestore()
            .collection("organizations")
            .doc(organizationId)
            .collection("clients")
            .where("coaches", "array-contains", uid ? uid : userDataValues.uid)
            .where("status", "==", ClientSubscriptionFilters.active);
        }
      }

      return clientRef.onSnapshot(
        async (snapshot) => {
          consola.info("++++++ SNAPSHOT -> ClientData: getClientsSubscription");
          if (onChange) {
            const aggregated = await AggregateClientsData(snapshot.docs);
            onChange(aggregated, snapshot.metadata);
          }
        },
        (error) => {
          consola.log("error", error);
          Bugsnag.notify(error);
        },
      );
    } catch (e) {
      Bugsnag.notify(e);
      throw e;
    }
  },
  getClientSubscription: async (uid, onChange, onSubscription) => {
    const organizationId = getRecoil(organizationIdState);

    const clientRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(uid);

    const subscription = clientRef.onSnapshot(async (snapshot) => {
      consola.info("++++++ SNAPSHOT -> ClientData: getClientSubscription");
      if (onChange) {
        const aggregated = await AggregateClientData(snapshot.data());
        onChange(aggregated);
      }
    });
    onSubscription(subscription);
  },
  deleteSharedVideo: async (uid, videoId) => {
    const organizationId = getRecoil(organizationIdState);

    const sharedVideoRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("sharedVideos")
      .doc(videoId);
    consola.info("++++++ READ -> ClientData: getClientSubscription");
    const doc = await sharedVideoRef.get();
    if (doc.exists) {
      // Delete the actual video file.
      const value = doc.data();

      if ("metadata" in value) {
        if (value.metadata && "exercise" in value.metadata) {
          const {
            metadata: { exercise },
          } = value;

          if (exercise) {
            // Remove the video URL from the exercise.
            const reportRef = firebase
              .firestore()
              .collection("organizations")
              .doc(organizationId)
              .collection("clients")
              .doc(uid)
              .collection("reportExercise")
              .doc(exercise.reportId);

            consola.info("++++++ READ -> ClientData: deleteSharedVideo");
            const reportDoc = await reportRef.get();
            if (reportDoc.exists) {
              const reportValue = await reportDoc.data();
              reportValue.reportData.exercises.forEach((e) => {
                if (e.id === exercise.id) {
                  e.userVideo = null;
                }
              });
              consola.info("+++++ WRITE => ClientData: deleteSharedVideo");
              await reportRef.set(reportValue, { merge: true });
            }
          }
        }
      }

      const fileRef = firebase
        .storage()
        .ref(`/${organizationId}/shared_videos/${uid}/${value.id}.mp4`);

      await fileRef.delete();

      await sharedVideoRef.delete();
      return true;
    }
  },
  getSharedVideos: async (uid) => {
    const organizationId = getRecoil(organizationIdState);

    const reportRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("sharedVideos")
      .where("clientId", "==", uid)
      .limit(10)
      .orderBy("createdOn", "desc");
    consola.info("++++++ READ -> ClientData: getSharedVideos");
    const data = (await reportRef.get()).docs;

    const finalData = data.map((d) => {
      return d.data();
    });
    return finalData;
  },
  getSharedFiles: async ({ uid, limit = 100 } = {}) => {
    const organizationId = getRecoil(organizationIdState);

    const sharedFilesRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("sharedFiles")
      .where("clientId", "==", uid)
      .limit(limit)
      .orderBy("createdOn");

    consola.info("++++++ READ -> ClientData: getSharedFiles");
    const data = (await sharedFilesRef.get()).docs;

    const finalData = data.map((d) => {
      return d.data();
    });
    return finalData;
  },
  getSharedFilesSubscription: ({ uid, limit = 100, onSnapshot } = {}) => {
    const organizationId = getRecoil(organizationIdState);

    const sharedFilesRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("sharedFiles")
      .where("clientId", "==", uid)
      .limit(limit)
      .orderBy("createdOn", "desc");

    return sharedFilesRef.onSnapshot((snapshot) => {
      consola.info("++++++ SNAPSHOT -> CkientData: getSharedFilesSubscription");
      const data = snapshot.docs;

      const finalData = data.map((d) => {
        return d.data();
      });
      if (onSnapshot) {
        onSnapshot(finalData);
      }
    });
  },
  getClientNutritionChartData: async ({ uid, duration, count } = {}) => {
    const organizationId = getRecoil(organizationIdState);

    const start = moment().subtract(duration, "days").startOf("day").toDate();
    const end = moment().endOf("day").toDate();

    let reportRef;

    if (duration) {
      reportRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients")
        .doc(uid)
        .collection("reportHealth")
        .where("createdOn", ">=", start)
        .where("createdOn", "<=", end)
        .orderBy("createdOn");
    } else {
      reportRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients")
        .doc(uid)
        .collection("reportHealth")
        .limit(count)
        .orderBy("createdOn", "desc");
    }
    consola.info("++++++ READ -> ClientData: getClientNutritionData");
    const data = (await reportRef.get()).docs;

    const finalData = data.map((d) => {
      const value = d.data();
      // calculate kCal using getKCal and report data macros
      let kCal = 0;
      if (
        value.reportData.carbs > 0 ||
        value.reportData.fats > 0 ||
        value.reportData.proteins > 0
      ) {
        kCal = getkCal({
          carbs: value.reportData.carbs,
          fats: value.reportData.fats,
          proteins: value.reportData.proteins,
        });
      }

      return {
        ...d.data().reportData,
        kCal: parseFloat(kCal.toFixed(2)),
        carbs: parseFloat(d.data().reportData.carbs.toFixed(2)),
        fats: parseFloat(d.data().reportData.fats.toFixed(2)),
        proteins: parseFloat(d.data().reportData.proteins.toFixed(2)),
        weight: parseFloat(d.data().reportData.weight.toFixed(2)),
        createdOn: d.data().createdOn.toDate(),
        createdOnString: moment(d.data().createdOn.toDate()).format("D MMM"),
      };
    });

    const sorted = finalData.sort((a, b) => {
      return a.createdOn - b.createdOn;
    });
    return sorted;
  },
  getClientExerciseReportByExercise: async (uid, duration, exercise) => {
    const organizationId = getRecoil(organizationIdState);

    const start = moment().subtract(duration, "days").startOf("day").toDate();
    const end = moment().endOf("day").toDate();

    const reportRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(uid)
      .collection("reportExercise")
      .where("createdOn", ">=", start)
      .where("createdOn", "<=", end)
      .where("status", "==", "complete")
      .orderBy("createdOn");

    consola.info(
      "++++++ READ -> ClientData: getClientExerciseReportsByExercise",
    );
    const reports = await reportRef.get();

    const exercises = reports.docs.map((rd) => {
      const data = rd.data();
      let completedOn = null;
      // if data.completedOn is string convert to date
      if (data && data.completedOn) {
        if (typeof data.completedOn === "string") {
          completedOn = new Date(data.completedOn);
        } else {
          completedOn = data.completedOn.toDate();
        }
      }
      const exercises = data.reportData.exercises.map((e) => {
        return {
          ...e,
          createdOn: data.createdOn.toDate(),
          completedOn: completedOn ? completedOn : null,
        };
      });
      return exercises;
    });

    const flattened = _.flatten(exercises);

    // roll up any supersets.
    let rolledUp = [];
    flattened.forEach((fe) => {
      if (fe.type === "super_set") {
        if (fe.sets && fe.sets.length > 0) {
          rolledUp = rolledUp.concat(
            fe.sets[0].exercises.map((e2) => {
              return {
                ...e2,
                completedSets: fe.completedSets,
                completedReps: fe.completedReps,
                sets: fe.sets,
                reps: fe.reps,
              };
            }),
          );
        }
      } else {
        rolledUp.push(fe);
      }
    });

    let filtered = rolledUp;
    if (exercise) {
      filtered = _.filter(rolledUp, (e) => {
        return e.exercise === exercise;
      });
    }
    return filtered;
  },
  getClientHealthAverages: async ({ clientId, duration } = {}) => {
    const organizationId = getRecoil(organizationIdState);

    const start = moment().subtract(duration, "days").startOf("day").toDate();
    const end = moment().endOf("day").toDate();
    const hash = moment().format("l");

    const cachedAverages = averagesCache.getCachedAverages({
      clientId: clientId,
    });
    if (cachedAverages && cachedAverages.hash === hash) {
      return cachedAverages.averages;
    }

    const reportRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(clientId)
      .collection("reportHealth")
      .where("createdOn", ">=", start)
      .where("createdOn", "<=", end)
      .orderBy("createdOn");

    consola.info("++++++ READ -> ClientData: getClientHealthAverages");

    const data = (await reportRef.get()).docs;

    const averages = {
      averagekCal: 0.0,
      averageWeight: 0.0,
      averageProteins: 0.0,
      averageCarbs: 0.0,
      averageFats: 0.0,
      averageWater: 0.0,
      totalkCal: 0.0,
      totalWeight: 0.0,
      totalProteins: 0.0,
      totalCarbs: 0.0,
      totalFats: 0.0,
      totalWater: 0.0,
    };

    if (data.length === 0) {
      averagesCache.setCachedAverages({
        clientId: clientId,
        hash: hash,
        averages: averages,
      });
      return averages;
    }

    let kCalCount = 0;
    let weightCount = 0;
    let proteinCount = 0;
    let carbCount = 0;
    let fatCount = 0;
    let waterCount = 0;

    data.forEach((d) => {
      if (d.data().reportData.kCal > 0) {
        averages.totalkCal = averages.totalkCal + d.data().reportData.kCal;
        kCalCount += 1;
      }

      if (d.data().reportData.weight > 0) {
        averages.totalWeight =
          averages.totalWeight + d.data().reportData.weight;
        weightCount += 1;
      }

      if (d.data().reportData.proteins > 0) {
        averages.totalProteins =
          averages.totalProteins + d.data().reportData.proteins;
        proteinCount += 1;
      }

      if (d.data().reportData.carbs) {
        averages.totalCarbs = averages.totalCarbs + d.data().reportData.carbs;
        carbCount += 1;
      }

      if (d.data().reportData.fats > 0) {
        averages.totalFats = averages.totalFats + d.data().reportData.fats;
        fatCount += 1;
      }

      if (d.data().reportData.water > 0) {
        averages.totalWater = averages.totalWater + d.data().reportData.water;
        waterCount += 1;
      }
    });

    averages.averagekCal = Math.round(averages.totalkCal / kCalCount);
    averages.averageWeight = Math.round(averages.totalWeight / weightCount);
    averages.averageProteins = Math.round(
      averages.totalProteins / proteinCount,
    );
    averages.averageCarbs = Math.round(averages.totalCarbs / carbCount);
    averages.averageFats = Math.round(averages.totalFats / fatCount);
    averages.averageWater = Math.round(averages.totalWater / waterCount);

    averagesCache.setCachedAverages({
      clientId: clientId,
      hash: hash,
      averages: averages,
    });

    return averages;
  },
  getHealthReportSubscription: ({ uid, date, onSnapshot }) => {
    const organizationId = getRecoil(organizationIdState);

    const dateIndex = moment(date).format("YYYY-MM-DD");

    const reportRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(uid)
      .collection("reportHealth")
      .doc(dateIndex);

    return reportRef.onSnapshot((snapshot) => {
      consola.info(
        "++++++ SNAPSHOT -> Client Data: getHealthReportSubscription",
      );
      if (onSnapshot) {
        onSnapshot(snapshot);
      }
    });
  },
  getClientReport: async (uid, date, status = null) => {
    const organizationId = getRecoil(organizationIdState);
    const organizationData = getRecoil(organizationDataState);

    const dateIndex = moment(date).format("YYYY-MM-DD");

    const reportRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(uid)
      .collection("reportHealth")
      .doc(dateIndex);

    let exerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(uid)
      .collection("reportExercise")
      .where("completedOn", ">=", moment(date).startOf("day").toDate())
      .where("completedOn", "<=", moment(date).endOf("day").toDate());

    if (status) {
      exerciseRef = exerciseRef.where("status", "==", status);
    }
    consola.info("++++++ READ -> ClientData: getClientReport");

    const data = await reportRef.get();
    const exerciseData = await exerciseRef.orderBy("completedOn", "desc").get();

    const result = {
      nutrition: null,
      exercises: null,
    };

    if (data.exists) {
      result.nutrition = data.data();
    } else {
      const finalReport = getBaseReportObject();
      finalReport.optional = getInitializedOptionals(organizationData);

      // create empty report
      const reportData = await createHealthReport({
        uid,
        date,
        report: finalReport,
      });

      result.nutrition = reportData;
    }

    if (exerciseData.docs.length > 0) {
      result.exercises = exerciseData.docs.map((d) => {
        return d.data();
      });
    }

    return result;
  },

  getStartingProgressPhotos: async (uid) => {
    const organizationId = getRecoil(organizationIdState);

    const clientRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(uid);

    consola.info("++++++ READ -> ClientData: getStartingProgressPhotos");
    const clientData = await clientRef.get();
    if (clientData.exists) {
      //
      const clientDataValues = clientData.data();
      if ("startingPhotoGroupId" in clientDataValues) {
        const startingPhotoGroupId = clientDataValues.startingPhotoGroupId;

        const reportRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("progressPhotos")
          .where("clientId", "==", uid)
          .where("groupId", "==", startingPhotoGroupId)
          .orderBy("createdOn", "desc");
        consola.info("++++++ READ -> ClientData: getStartingProgressPhotos");

        const data = await reportRef.get();

        const docs = data.docs;
        const groups = getPhotoGroups(docs, 1);
        return groups;
      }
    }
  },

  getProgressPhotos: async (uid, dateRange, limit = 10, daysBack = 30) => {
    const organizationId = getRecoil(organizationIdState);

    let start = moment().subtract(daysBack, "days").toDate();
    let end = moment().endOf("day").toDate();
    if (dateRange) {
      start = dateRange.start;
      end = dateRange.end;
    }

    const reportRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("progressPhotos")
      .where("clientId", "==", uid)
      .where("createdOn", ">=", start)
      .where("createdOn", "<=", end)
      .orderBy("createdOn", "desc");

    const data = await reportRef.get();
    consola.info("++++++ READ -> ClientData: getProgressPhotos");

    const docs = data.docs;

    const groups = getPhotoGroups(docs, limit);

    return groups;
  },
  deleteProgressPhotos: async (uid, groupId) => {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> ClientData: deleteProgressPhotos");
    const userData = await ref.get();
    if (userData.exists) {
      const userDataValues = userData.data();

      const organizationId = userDataValues.organizationId;

      const reportRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("progressPhotos")
        .where("groupId", "==", groupId);

      const data = await reportRef.get();

      const docs = data.docs;

      // Delete the images first.
      const deletePromises = docs.map((doc) => {
        const data = doc.data();
        const storageRef = firebase
          .storage()
          .ref(`/${organizationId}/progress_photos/${uid}/${data.id}.jpg`);

        return new Promise((resolve, reject) => {
          storageRef
            .delete()
            .then(() => {
              resolve();
            })
            .catch((error) => {
              if (error.code === "storage/object-not-found") {
                consola.warn("Image not found in storage. OK.");
                resolve();
              } else {
                consola.error("Error deleting image from storage.", error);
                reject(error);
              }
            });
        });
      });
      await Promise.all(deletePromises);

      // Delete the data.
      const deleteDataPromises = docs.map((doc) => {
        return doc.ref.delete();
      });
      await Promise.all(deleteDataPromises);

      const clientRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients")
        .doc(uid);

      const clientData = await clientRef.get();
      if (clientData.exists) {
        const clientDataValues = clientData.data();
        if (
          clientDataValues.startingPhotoGroupId &&
          clientDataValues.startingPhotoGroupId === groupId
        ) {
          consola.info("+++++ WRITE => ClientData: deleteProgressPhotos");
          clientRef.set(
            {
              startingPhotoGroupId: firebase.firestore.FieldValue.delete(),
            },
            { merge: true },
          );
        }
      }

      return true;
    }
    return null;
  },
  updateClientMessageGroup: async (uid, coaches) => {
    const group = await MessageData.getMessageGroup(uid);

    if (group.length === 1) {
      const messageGroupData = group[0];
      const messageGroupRef = messageGroupData.ref;
      const newUsers = [];
      newUsers.push(uid);
      coaches.forEach((c) => {
        newUsers.push(c);
      });
      consola.info("+++++ WRITE => ClientData: updateClientMessageGroup");
      messageGroupRef.set(
        {
          users: newUsers,
        },
        { merge: true },
      );
    }
  },
  updateClient: async (uid, data) => {
    const authId = firebase.auth().currentUser.uid;

    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> ClientData: updateClient");
    const userData = await ref.get();
    if (userData.exists) {
      const userDataValues = userData.data();

      const organizationId = userDataValues.organizationId;

      const clientRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients")
        .doc(uid);
      consola.info("++++++ READ -> ClientData: updateClient clientData");
      const clientData = await clientRef.get();
      if (clientData.exists) {
        // Check if protocol changed.
        const clientDataValues = clientData.data();

        return await firebase.firestore().runTransaction(async () => {
          if (
            "protocolId" in data &&
            clientDataValues.protocolId !== data.protocolId &&
            data.protocolId !== null
          ) {
            const splitsRef = firebase
              .firestore()
              .collection("organizations")
              .doc(organizationId)
              .collection("protocolSplits")
              .orderBy("index")
              .where("protocolId", "==", data.protocolId);

            const splitsData = await splitsRef.get();
            let splitId = null;
            if (splitsData && splitsData.docs.length > 0) {
              const split = splitsData.docs[0].data();
              splitId = split.id;
            }

            if (clientDataValues.currentExerciseReportId) {
              // It has changed, so remove current report history.
              const reportRef = firebase
                .firestore()
                .collection("organizations")
                .doc(organizationId)
                .collection("clients")
                .doc(uid)
                .collection("reportExercise")
                .doc(clientDataValues.currentExerciseReportId);

              const reportData = await reportRef.get();

              if (reportData.exists) {
                await reportRef.delete();
              }
            }

            const newReport = await getDefaultReport(
              uid,
              organizationId,
              data.protocolId,
              splitId,
            );
            consola.info("+++++ WRITE => ClientData updateClient");
            await clientRef.set(
              {
                ...data,
                splitId: splitId,
                updatedOn: new Date(),
                lastUpdatedBy: authId,
                currentExerciseReportId: newReport.id,
              },
              { merge: true },
            );
          } else {
            if ("status" in data) {
              const groupRef = firebase
                .firestore()
                .collection("organizations")
                .doc(organizationId)
                .collection("messageGroups")
                .where("userId", "==", uid);
              const groupData = await groupRef.get();
              if (groupData.docs.length > 0) {
                groupData.docs.forEach(async (doc) => {
                  consola.info("+++++ WRITE => updateClient isArchived");
                  if (data.status === "disabled") {
                    await doc.ref.set(
                      {
                        isArchived: true,
                      },
                      { merge: true },
                    );
                  } else {
                    await doc.ref.set(
                      {
                        isArchived: false,
                      },
                      { merge: true },
                    );
                  }
                });
              }
            }

            if ("supplementPrescriptionChanged" in data) {
              const dateIndex = moment().format("YYYY-MM-DD");
              const supplementHistoryReportRef = firebase
                .firestore()
                .collection("organizations")
                .doc(organizationId)
                .collection("clients")
                .doc(uid)
                .collection("supplementHistory")
                .doc(dateIndex);

              await supplementHistoryReportRef.delete();
            }
            consola.info("+++++ WRITE => ClientData updateClient final write");
            await clientRef.set(
              {
                ...data,
                updatedOn: new Date(),
                lastUpdatedBy: authId,
              },
              { merge: true },
            );
          }
          consola.info("++++++ READ -> ClientData: updateClient - return true");
          return true;
        });
      }
    }
    consola.info("++++++ READ -> ClientData: updateClient - return false");
    return false;
  },
  setStartingPhotoGroup: async (uid, groupId) => {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> ClientData: setStartingPhotoGroup");
    const userData = await ref.get();
    if (userData.exists) {
      const userDataValues = userData.data();
      const organizationId = userDataValues.organizationId;

      const clientRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients")
        .doc(uid);
      const client = await clientRef.get();
      if (client.exists) {
        consola.info("+++++ WRITE => ClientData setStartingPhotoGroup");
        clientRef.set(
          {
            startingPhotoGroupId: groupId,
          },
          { merge: true },
        );
      }
    }
  },
  resetConnectCode: async (uid) => {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> ClientData: resetConnectCode");
    const userData = await ref.get();
    if (userData.exists) {
      const userDataValues = userData.data();
      const organizationId = userDataValues.organizationId;

      const clientRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients")
        .doc(uid);

      const connectCode = nanoidCustom();
      const client = await clientRef.get();
      if (client.exists) {
        consola.info("+++++ WRITE => ClientData resetConnectCode");
        clientRef.set(
          {
            connectCode: connectCode,
          },
          { merge: true },
        );

        ref.set(
          {
            connectCode: connectCode,
          },
          { merge: true },
        );
      }
    }
  },
  addClient: async (data) => {
    const uid = nanoid();
    const connectCode = nanoidCustom();

    const userRef = firebase.firestore().collection("users").doc(uid);
    const coachRef = firebase.firestore().collection("users").doc(data.coachId);

    consola.log("data", data);

    try {
      await firebase.firestore().runTransaction(async () => {
        consola.info("++++++ READ -> ClientData: addClient");
        const coach = await coachRef.get();
        if (coach.exists) {
          const coachValue = coach.data();
          consola.info("+++++ WRITE => ClientData addClient (user)");
          await userRef.set(
            {
              uid: uid,
              firstName: data.firstName,
              lastName: data.lastName,
              organizationId: coachValue.organizationId,
              type: "client",
            },
            { merge: true },
          );

          const clientRef = firebase
            .firestore()
            .collection("organizations")
            .doc(coachValue.organizationId)
            .collection("clients")
            .doc(uid);

          consola.info("+++++ WRITE => Client Data addClient(client)");
          await clientRef.set(
            {
              uid: uid,
              status: "active",
              language: data.language,
              createdOn: new Date(),
              updatedOn: new Date(),
              connectCode: connectCode,
              coaches: [data.coachId],
              protocolId: null,
              splitId: null,
              protocolChanged: null,
              renewDate: data.renewDate,
              checkInDay: data.checkInDay,
              statistics: {
                experience: data.experience,
                goal: data.goal,
                height: data.height,
                sex: data.sex,
                weight: data.weight,
                startingWeight: data.startingWeight,
                birthDate: data.birthDate,
                activityLevel: data.activityLevel,
              },
              nutritionGoals: [
                {
                  id: nanoid(),
                  name: "Default Goals",
                  carbs: 300,
                  fats: 50,
                  proteins: 150,
                  water: 1000,
                  active: true,
                },
              ],
            },
            { merge: true },
          );
          consola.info("+++++ WRITE => ClientData addClient(user)");
          await userRef.set(
            {
              connectCode: connectCode,
              email: data.email,
            },
            { merge: true },
          );

          console.log("Adding message group");
          await MessageData.addMessageGroup({
            users: [uid, data.coachId],
            type: GroupType.single,
            name: `Coach Chat - ${data.firstName} ${data.lastName}`,
            userId: uid,
            lastMessage: "No messages yet",
            lastDate: new Date(),
            createdOn: new Date(),
          });
          console.log("Added message group");

          const organization = await OrganizationData.getOrganizationById(
            coachValue.organizationId,
          );

          if (
            organization &&
            organization.welcomeMessage &&
            organization.welcomeMessage.length > 0
          ) {
            sendMessageToUsersGroup({
              uid: uid,
              message: organization.welcomeMessage,
              mustBeRegistered: false,
            });
          }
        }
      });
      return uid;
    } catch (e) {
      Bugsnag.notify(e);
      return null;
    }
  },
  getDynamicLink: async ({
    connectCode,
    organizationName,
    organizationId,
    type = "client",
  } = {}) => {
    if (!connectCode) {
      return null;
      // consola.error(new Error("No connectCode provided."));
    }

    const socialTitle = organizationName
      ? `Join ${organizationName} on Mighty45`
      : type === "client"
        ? `Join your coach on Mighty45`
        : "Join as a coach on Mighty45";
    const socialDescription =
      type === "client"
        ? "Your coach has invited you to join them on Mighty45."
        : "You have been invited to join this organization on Mighty45.";

    const searchParams = new URLSearchParams({
      connectCode: connectCode,
      orgName: organizationName ? organizationName : "",
      orgId: organizationId ? organizationId : "",
      type: type,
    }).toString();

    const sendData = {
      dynamicLinkInfo: {
        domainUriPrefix: "https://mighty45.page.link",
        link: `https://mighty45.com/connect?${searchParams}`,
        iosInfo: {
          iosBundleId: "com.mighty45.client.app",
          iosAppStoreId: "1594939720",
          iosFallbackLink:
            "https://apps.apple.com/us/app/mighty45/id1594939720",
        },
        androidInfo: {
          androidPackageName: "com.mighty45client",
          androidFallbackLink:
            "https://play.google.com/store/apps/details?id=com.mighty45client",
        },
        navigationInfo: {
          enableForcedRedirect: false,
        },
        socialMetaTagInfo: {
          socialTitle: socialTitle,
          socialDescription: socialDescription,
          socialImageLink: "https://app.mighty45.com/PlateBlue10241024.png",
        },
      },
      suffix: {
        option: "SHORT",
      },
    };

    try {
      const response = await fetch(
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAzNT8--umi_mweya4f8wvjWz20vIHnzlQ",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(sendData),
        },
      );
      if (response.status === 200) {
        const jsonData = await response.json();
        return jsonData;
      } else {
        return null;
      }
    } catch (e) {
      Bugsnag.notify(e);
      return null;
    }
  },
};

export async function deleteGoal({ nutritionGoal, client }) {
  if (client.nutritionGoals) {
    const newGoals = [].concat(client.nutritionGoals);
    if (newGoals.length === 1) {
      return;
    }
    const index = _.findIndex(newGoals, {
      id: nutritionGoal.id,
    });
    if (index !== -1) {
      newGoals.splice(index, 1);
      newGoals[0].active = true;

      ClientData.updateClient(client.uid, {
        nutritionGoals: newGoals,
        nutritionGoalChanged: new Date(),
      });
    }
  }
}

export async function deleteCardioGoal({ cardioGoal, client }) {
  if (client.cardioGoals) {
    const newGoals = [].concat(client.cardioGoals);
    if (newGoals.length === 1) {
      return;
    }
    const index = _.findIndex(newGoals, {
      id: cardioGoal.id,
    });
    if (index !== -1) {
      newGoals.splice(index, 1);
      newGoals[0].active = true;

      ClientData.updateClient(client.uid, {
        cardioGoals: newGoals,
        cardioGoalChanged: new Date(),
      });
    }
  }
}

export async function deleteCollection(db, collectionPath, batchSize) {
  const collectionRef = db.collection(collectionPath);
  const query = collectionRef.orderBy("__name__").limit(batchSize);

  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, query, resolve).catch(reject);
  });
}

export async function deleteQueryBatch(db, query, resolve) {
  consola.info("++++++ READ -> ClientData: deleteQueryBatch");
  const snapshot = await query.get();

  const batchSize = snapshot.size;
  if (batchSize === 0) {
    // When there are no documents left, we are done
    resolve();
    return;
  }

  // Delete documents in a batch
  const batch = db.batch();
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });
  consola.info("+++++ WRITE => clientData: deleteQueryBatch");
  await batch.commit();

  deleteQueryBatch(db, query, resolve);
}

export async function deleteClient({ organizationId, uid }) {
  if (uid && organizationId) {
    const clientRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(uid);

    await clientRef.delete();
  }
  return true;
}

export default ClientData;
