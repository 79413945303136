import React from "react";
import { Box, Button } from "@mui/material";
import ClientData from "../../../../data/ClientData";
import ClientDataGrid from "./ClientDataGrid";

const ClientTable = ({
  coachId = null,
  filter,
  onAddClientClicked,
  onClientClicked,
  deleteClientClicked,
}) => {
  const subscription = React.useRef(null);
  const [, setIsLoading] = React.useState(true);
  const [, setIsEmpty] = React.useState(false);
  const [clients, setClients] = React.useState([]);

  React.useEffect(() => {
    async function getClients() {
      if (subscription.current) {
        subscription.current();
      }

      subscription.current = await ClientData.getClientsSubscription({
        filter: filter,
        uid: coachId,
        onChange: (snapshot) => {
          setIsLoading(false);
          if (snapshot.length === 0) {
            setIsEmpty(true);
          } else {
            setIsEmpty(false);
          }
          setClients(snapshot);
        },
      });
    }
    getClients();

    return () => {
      //
      if (subscription.current) {
        subscription.current();
      }
    };
  }, [filter, coachId]);

  return (
    <>
      {onAddClientClicked && (
        <Box
          style={{ marginRight: 24, marginBottom: 12 }}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (clients.length === 0) {
                if (onAddClientClicked) {
                  onAddClientClicked(true);
                }
                return;
              }

              if (onAddClientClicked) {
                onAddClientClicked(true);
              }
            }}
          >
            Add New Client
          </Button>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <ClientDataGrid
          clients={clients}
          onClick={(client) => {
            if (onClientClicked) {
              onClientClicked(client);
            }
          }}
          onDelete={(client) => {
            if (deleteClientClicked) {
              deleteClientClicked(client);
            }
          }}
        />
      </Box>
    </>
  );
};

export default ClientTable;
