import React from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import moment from "moment";
import LabelChips from "../../../components/molecules/LabelChips";
import GlobalData from "../../../data/GlobalData";
import ProtocolData from "../../../data/ProtocolData";
import useSplits from "../../../hooks/useSplits";
import useLabels from "../../../hooks/useLabels";
import SplitExerciseTablePrintable from "./SplitExerciseTablePrintable";
import exerciseDBState from "../../../atoms/exerciseDBAtom";
import OrganizationData from "../../../data/OrganizationData";
import logoImage from "../../../assets/Logo.png";

const ProtocolHeader = ({ protocol, organization }) => {
  if (!protocol) {
    return null;
  }

  return (
    <Box style={{ alignItems: "center" }}>
      <Typography
        variant="h3"
        style={{ textAlign: "center", marginBottom: 12 }}
      >
        {`Training Plan${organization ? ` by ${organization.name}` : ""}`}
      </Typography>

      <Typography variant="h4" style={{ textAlign: "center" }}>
        {protocol.name}
      </Typography>

      <Typography style={{ textAlign: "center", fontSize: isMobile ? 14 : 16 }}>
        {protocol.description}
      </Typography>

      <Typography style={{ textAlign: "center", marginTop: 12, fontSize: 12 }}>
        {`Last updated: ${moment(protocol.updatedOn.toDate()).format("lll")}`}
      </Typography>
      <Divider style={{ margin: 12 }} />
    </Box>
  );
};

const TrainingPlan = () => {
  const { id, organizationId } = useParams();
  localStorage.setItem("organizationId", organizationId);
  const setExerciseDBState = useSetRecoilState(exerciseDBState);

  const { splits } = useSplits({ protocolId: id });
  const { labels } = useLabels();

  const [protocol, setProtocol] = React.useState();
  const [organization, setOrganization] = React.useState();
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    OrganizationData.getOrganizationById(organizationId).then((data) => {
      setOrganization(data);
    });
  }, [organizationId]);

  React.useEffect(() => {
    let subscriptionValue;

    ProtocolData.getProtocolSubscription({
      protocolId: id,
      onChange: (snapshot) => {
        setProtocol(snapshot);
      },
      onSubscription: (subscription) => {
        subscriptionValue = subscription;
      },
      onError: () => {
        setError(true);
      },
    });

    return () => {
      if (subscriptionValue) {
        subscriptionValue();
      }
    };
  }, [id]);

  React.useEffect(() => {
    async function getData() {
      const exercises = await GlobalData.getExercises({
        organizationId: organizationId,
      });
      setExerciseDBState(exercises);
    }
    getData();
  }, [organizationId, setExerciseDBState]);

  if (error) {
    return (
      <Box
        style={{ justifyContent: "center", alignItems: "center", margin: 24 }}
      >
        <Typography
          style={{ textAlign: "center", fontSize: 24, fontWeight: "bold" }}
        >
          This training plan is not available.
        </Typography>
      </Box>
    );
  }

  if (splits && splits.length === 0) {
    return (
      <Box
        style={{ justifyContent: "center", alignItems: "center", margin: 24 }}
      >
        <Typography
          style={{ textAlign: "center", fontSize: 24, fontWeight: "bold" }}
        >
          This training plan is not available.
        </Typography>
      </Box>
    );
  }
  return (
    <Box className="training-plan-print" style={{ margin: isMobile ? 8 : 24 }}>
      <Box>
        <ProtocolHeader protocol={protocol} organization={organization} />
      </Box>
      <Box>
        {splits &&
          splits.map((split) => {
            return (
              <Box
                key={split.data().id}
                style={{
                  borderRadius: 30,
                  padding: isMobile ? 8 : 20,
                  paddingBottom: isMobile ? 0 : 20,
                  marginBottom: isMobile ? 0 : 20,
                  backgroundColor: split.data().isRestSplit ? "#D9D9D9" : null,
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginBottom: isMobile ? 0 : 20 }}
                >
                  <Grid item zeroMinWidth>
                    <Box>
                      <Typography
                        style={{
                          fontSize: isMobile ? 18 : 26,
                          fontWeight: "bold",
                        }}
                      >
                        {split.data().name}
                      </Typography>

                      <Typography
                        style={{ fontSize: 16, whiteSpace: "pre-line" }}
                      >
                        {split.data().description}
                      </Typography>
                      {split.data().labels &&
                        split.data().labels.length > 0 && (
                          <Box style={{ marginTop: 12 }}>
                            <LabelChips
                              labels={split.data().labels}
                              allLabels={labels}
                            />
                          </Box>
                        )}
                      {split.data().isRestSplit && (
                        <Typography style={{ fontSize: 18 }}>{`Rest for ${
                          split.data().restDays
                        } day(s)`}</Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <SplitExerciseTablePrintable split={split} labels={labels} />
                </Box>
              </Box>
            );
          })}
      </Box>
      <Typography style={{ textAlign: "center", marginBottom: 4 }}>
        Created with
      </Typography>
      <img
        alt="Mighty45 Logo"
        style={{
          alignItems: "center",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        width="146px"
        height="78px"
        src={logoImage}
        onClick={() => {
          window.open("https://mighty45.com", "_blank");
        }}
      />
    </Box>
  );
};

export default TrainingPlan;
