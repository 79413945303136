import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import _ from "underscore";

export default React.memo(({ exercise }) => {
  function getSetCompletion() {
    const sets = exercise.sets;
    const numberSets = sets.length;

    let completeCount = 0;
    sets.forEach((set) => {
      if (set.completed) {
        completeCount += 1;
      }
    });

    if (completeCount === 0 && exercise.completed === true) {
      return 100.0;
    }

    return (completeCount / numberSets) * 100.0;
  }

  function getReps(set) {
    if (set.completedReps && set.completedReps > 0) {
      return set.completedReps;
    }
    if (set.completed === true) {
      return set.reps;
    }
    return 0;
  }

  function getSetInformation() {
    let setNumber = 0;

    if (exercise && exercise.sets) {
      const filteredSets = _.filter(exercise.sets, (set) => {
        return set.completed === true;
      });

      if (filteredSets.length === 0 && exercise.completed) {
        return (
          <Box>
            <Typography>{`Exercise completed without set information recorded.`}</Typography>
          </Box>
        );
      }

      return (
        <Box>
          {filteredSets.map((set, index) => {
            setNumber += 1;
            return (
              <Box key={index}>
                <Typography>{`Set: ${setNumber} - Reps: ${getReps(
                  set,
                )} - Weight: ${set.weight}`}</Typography>
              </Box>
            );
          })}
        </Box>
      );
    }

    return null;
  }

  const setCompletion = getSetCompletion();

  return (
    <Box>
      <Box>
        <Typography
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: exercise.completed ? "green" : "red",
          }}
        >
          {exercise.completed ? `completed` : `not completed`}
        </Typography>
        <Box key={exercise.id} style={{ marginBottom: 4 }}>
          <LinearProgress variant="determinate" value={setCompletion} />
          {getSetInformation()}
        </Box>
      </Box>
    </Box>
  );
});
