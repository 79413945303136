const units = {
  g: "g",
  mg: "mg",
  kg: "kg",
  ml: "ml",
  l: "l",
  tsp: "tsp",
  tbsp: "tbsp",
  lb: "lb",
  oz: "oz",
  floz: "floz",
  pinch: "pinch",
  dash: "dash",
  drop: "drop",
  can: "can",
  piece: "piece",
  scoop: "scoop",
  fillet: "fillet",
  item: "item",
  cup: "cup",
  pt: "pt",
  gal: "gal",
  qt: "qt",
  clove: "clove",
  none: "none",
};

export const unitAlternatives = {
  g: ["g", "grams", "gram"],
  mg: ["mg", "milligrams", "milligram"],
  kg: ["kg", "kilograms", "kilogram"],
  ml: ["ml", "milliliters", "milliliter"],
  l: ["l", "liters", "liter"],
  tsp: ["tsp", "teaspoons", "teaspoon"],
  tbsp: ["tbsp", "tablespoons", "tablespoon"],
  lb: ["lb", "pounds", "pound"],
  oz: ["oz", "ounces", "ounce"],
  floz: ["floz", "fluid ounces", "fluid ounce"],
  pinch: ["pinch", "pinches"],
  dash: ["dash", "dashes"],
  drop: ["drop", "drops"],
  can: ["can", "cans"],
  piece: ["piece", "pieces"],
  scoop: ["scoop", "scoops"],
  fillet: ["fillet", "fillets"],
  item: [
    "item",
    "items",
    "large",
    "small",
    "medium",
    "extra large",
    "extra small",
    "x-large",
    "x-small",
    "xl",
    "xs",
    "lg",
    "md",
    "xl",
    "xs",
  ],
  cup: ["cup", "cups"],
  pt: ["pt", "pints", "pint"],
  gal: ["gal", "gallons", "gallon"],
  qt: ["qt", "quarts", "quart"],
  clove: ["clove", "cloves"],
};

// Get the unit from an alternative unit
export const getUnitFromAlternative = (alternative) => {
  for (const unit in unitAlternatives) {
    if (unitAlternatives[unit].includes(alternative)) {
      return unit;
    }
  }
  return null;
};

export default units;
