import React from "react";
import { Box, Avatar, Typography, Tooltip } from "@mui/material";

import CircleImage from "../atoms/CircleImage";

const AvatarList = ({
  users,
  size,
  overlapSize = 8,
  borderWidth = 1,
  borderColor = "black",
}) => {
  const displayedUsers = React.useMemo(() => {
    let result;
    if (!users) {
      return null;
    }
    if (users.length > 4) {
      result = users.slice(0, 4);
      const remainder = users.length - 4;
      return { result, remainder };
    }
    return { result: users, remainder: null };
  }, [users]);

  if (!users) {
    return null;
  }
  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        {displayedUsers &&
          displayedUsers.result.map((user, index) => {
            if (user.url) {
              return (
                <Tooltip
                  key={user.key}
                  title={`${user.firstName} ${user.lastName}`}
                >
                  <Box style={{ marginLeft: index === 0 ? 0 : -overlapSize }}>
                    <CircleImage
                      key={user.key}
                      url={user.url}
                      size={size}
                      borderWidth={borderWidth}
                      borderColor={borderColor}
                    />
                  </Box>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip
                  key={user.key}
                  title={`${user.firstName} ${user.lastName}`}
                >
                  <Avatar
                    style={{
                      height: size,
                      width: size,
                      marginLeft: index === 0 ? 0 : -overlapSize,
                      border: borderWidth > 0 ? "solid" : "none",
                      borderWidth: borderWidth,
                      borderColor: borderColor,
                    }}
                  >
                    <Typography
                      style={{ fontSize: 14 }}
                    >{`${user.firstName[0]}${user.lastName[0]}`}</Typography>
                  </Avatar>
                </Tooltip>
              );
            }
          })}
        {displayedUsers.remainder && (
          <Box style={{ marginLeft: 4 }}>
            <Typography>{`+ ${displayedUsers.remainder} more.`}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AvatarList;
