import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import MicRecorder from "mic-recorder-to-mp3";
import BlueButton from "./BlueButton";
import { consola } from "consola";
export default React.memo((props) => {
  const [open, setOpen] = React.useState(false);
  const recorder = React.useRef();

  React.useEffect(() => {
    setOpen(props.open);
    const mic = new MicRecorder({ bitRate: 128 });
    recorder.current = mic;

    mic
      .start()
      .then(() => {
        consola.log("Recording started");
      })
      .catch((e) => {
        consola.log("Recording failed - possibly permissions");
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
        }
        if (props.onError) {
          props.onError(e);
        }
        return;
      });

    return () => {
      recorder.current.stop();
    };
  }, [props.open]);

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Audio Message</DialogTitle>

      <DialogContent>
        <Typography>Recording. Tap Send when Done.</Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            recorder.current.stop();
            setOpen(false);
            if (props.onDialogClosed) {
              props.onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            recorder.current
              .stop()
              .getMp3()
              .then(([buffer, blob]) => {
                if (props.onDialogClosed) {
                  props.onDialogClosed(blob, buffer);
                }
              })
              .catch(() => {
                alert("We could not retrieve your message");
              });
          }}
          color="primary"
        >
          Send
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
});
