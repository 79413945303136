import React from "react";
import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useRecoilValue } from "recoil";

import { TierTitles } from "../../../../data/ClientData";
import AvatarName from "../../../../components/molecules/AvatarName";
import useProtocols from "data/hooks/useProtocols";
import organizationIdSelector from "../../../../atoms/organizationIdSelector";
import useRecipes from "../../../../hooks/useRecipes";
import useClientsLastSeen from "../../../../hooks/useClientsLastSeen";
import { DeleteButton } from "../../../../atoms/EditButton";

import { DaysOfWeekString } from "../../../../data/GlobalData";

const TierStyles = {
  tier1: {
    color: "black",
  },
  tier2: {
    color: "#E05118",
  },
  tier3: {
    color: "#7B2680",
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ClientDataGrid = ({ clients, onClick, onDelete }) => {
  const theme = useTheme();
  const organizationId = useRecoilValue(organizationIdSelector);

  const { getProtocols, findProtocol } = useProtocols();
  const { getMealPlanById } = useRecipes({ organizationId });
  const { lastSeenUsers } = useClientsLastSeen({ organizationId });
  const [protocols, setProtocols] = React.useState([]);

  const storedModel = localStorage.getItem("clientDataGridColumns");
  const [visibleModel, setVisibilityModel] = React.useState(
    storedModel ? JSON.parse(storedModel) : {},
  );

  React.useEffect(() => {
    getProtocols({ organizationId }).then((protocols) => {
      setProtocols(protocols);
    });
  }, [organizationId]);

  const columns = React.useMemo(() => {
    return [
      // { field: "id", headerName: "ID", width: 150 },
      {
        field: "avatar",
        headerName: "",
        headerAlign: "center",
        align: "center",
        width: 60,
        hideable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <AvatarName
              showName={false}
              firstName={params.row.firstName}
              lastName={params.row.lastName}
              url={params.row.avatarUrl}
            />
          );
        },
      },
      {
        field: "firstName",
        headerName: "First Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "lastName",
        headerName: "Last Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "protocolName",
        headerName: "Training Plan",
        flex: 1,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          if (params.value === "Not assigned") {
            return (
              <Box style={{ color: theme.palette.primary.main }}>
                {params.value}
              </Box>
            );
          }
        },
      },
      {
        field: "trainingPlanChanged",
        headerName: "Plan Age (days)",
        width: 120,
        headerAlign: "center",
        align: "center",
        type: "number",
        valueGetter: (params) => {
          const lastSeen = params.row.protocolChanged;
          if (!lastSeen) {
            return -1;
          }
          const diff = moment().diff(moment(lastSeen.toDate()), "days");
          return diff;
        },
        renderCell: (params) => {
          if (params.value < 0) {
            return <Box style={{ color: theme.palette.primary.main }}>N/A</Box>;
          }

          if (params.value > 30) {
            return (
              <Box style={{ color: theme.palette.primary.main }}>
                {params.value}
              </Box>
            );
          }
        },
      },
      {
        field: "mealPlanId",
        headerName: "Meal Plan",
        flex: 1,
        headerAlign: "center",
        align: "center",
        minWidth: 150,
        valueGetter: (params) => {
          const mealPlanId = params.row.mealPlanId;
          if (!mealPlanId) {
            return "";
          }
          const mealPlan = getMealPlanById(mealPlanId);
          return mealPlan ? mealPlan.name : "";
        },
      },
      {
        field: "lastSeen",
        headerAlign: "center",
        headerName: "Last Seen (days)",
        width: 135,
        type: "number",
        align: "center",
        valueGetter: (params) => {
          const lastSeenRecord = lastSeenUsers[params.row.uid];
          if (!lastSeenRecord) {
            return -1;
          }

          const diff = moment().diff(
            moment(lastSeenRecord.lastSeen.toDate()),
            "days",
          );
          return diff;
        },
        renderCell: (params) => {
          if (params.value < 0) {
            return <Box style={{ color: theme.palette.primary.main }}>-</Box>;
          }

          if (params.value > 7) {
            return (
              <Box style={{ color: theme.palette.primary.main }}>
                {params.value}
              </Box>
            );
          }
        },
      },
      {
        field: "renewDate",
        headerAlign: "center",
        headerName: "Renew Date",
        width: 135,
        type: "date",
        align: "center",
        valueGetter: (params) => {
          if (!params.row.renewDate) {
            return null;
          }
          return moment(params.row.renewDate.toDate()).toDate();
        },
        renderCell: (params) => {
          if (!params.value) {
            return <Box style={{ color: theme.palette.primary.main }}>-</Box>;
          }
          return (
            <Box style={TierStyles[params.value]}>
              {moment(params.value).format("MM/DD/YYYY")}
            </Box>
          );
        },
      },
      {
        field: "checkInDay",
        headerAlign: "center",
        headerName: "Check In Day",
        width: 135,
        align: "center",
        valueGetter: (params) => {
          return params.row.checkInDay;
        },
        renderCell: (params) => {
          if (!params.value) {
            return "-";
          }
          return DaysOfWeekString[params.value];
        },
      },
      {
        field: "tier",
        headerName: "Tier",
        headerAlign: "center",
        align: "center",
        width: 75,
        type: "singleSelect",
        valueOptions: ["tier1", "tier2", "tier3"],
        valueGetter: (params) => {
          if (!params.row.tier) {
            return "tier1";
          }
          return params.row.tier;
        },
        renderCell: (params) => {
          if (!params.value) {
            return <Box style={{ color: theme.palette.primary.main }}>-</Box>;
          }
          return (
            <Box style={TierStyles[params.value]}>
              {TierTitles[params.value]}
            </Box>
          );
        },
      },
      {
        field: "startingPhotoGroupId",
        headerAlign: "center",
        headerName: "Starting Photos",
        width: 120,
        type: "boolean",
        valueGetter: (params) => {
          if (params.row.startingPhotoGroupId) {
            return true;
          }
          return false;
        },
        renderCell: (params) => {
          if (!params.value) {
            return <Box style={{ color: theme.palette.primary.main }}>No</Box>;
          }

          return <Box>Yes</Box>;
        },
      },
      {
        field: "authId",
        headerName: "Registered",
        headerAlign: "center",
        minWidth: 100,
        type: "boolean",
        valueGetter: (params) => {
          const authId = params.row.authId;
          if (authId) {
            return true;
          }
          return false;
        },
        renderCell: (params) => {
          if (!params.value) {
            return <Box style={{ color: theme.palette.primary.main }}>No</Box>;
          }

          return <Box>Yes</Box>;
        },
      },
      {
        field: "status",
        headerName: "Active",
        headerAlign: "center",
        align: "center",
        minWidth: 100,
        type: "singleSelect",
        valueOptions: ["active", "disabled"],
        renderCell: (params) => {
          if (params.value === "disabled") {
            return (
              <Box style={{ color: theme.palette.primary.main }}>
                {params.value}
              </Box>
            );
          }

          return <Box>{params.value}</Box>;
        },
      },
      {
        field: "appVersion",
        headerName: "App version",
        minWidth: 120,
        valueGetter: (params) => {
          const lastSeenRecord = lastSeenUsers[params.row.uid];
          if (!lastSeenRecord) {
            if (params.row.appVersion) {
              return params.row.appVersion;
            }
            return -1;
          }
          return lastSeenRecord.appVersion;
        },
        renderCell: (params) => {
          if (params.value < 0) {
            return <Box style={{ color: theme.palette.primary.main }}>-</Box>;
          }

          if (params.value > 7) {
            return (
              <Box style={{ color: theme.palette.primary.main }}>
                {params.value}
              </Box>
            );
          }
        },
      },
      {
        minWidth: 25,
        headerAlign: "center",
        align: "left",
        field: "action",
        headerName: "",
        sortable: false,
        hideable: false,
        filterable: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderCell: (params) => {
          return (
            <Stack
              spacing={1}
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              {params.row.status === "disabled" && (
                <DeleteButton
                  onClick={(event) => {
                    event.ignore = true;
                    event.stopPropagation();
                    onDelete(params.row);
                  }}
                />
              )}
            </Stack>
          );
        },
      },
    ];
  }, [getMealPlanById, lastSeenUsers]);

  const rows = React.useMemo(() => {
    return clients.map((client) => {
      return {
        id: client.uid,
        ...client,
        protocolName:
          findProtocol({ protocolId: client.protocolId, protocols })?.name ||
          "Not assigned",
      };
    });
  }, [clients, protocols]);

  return (
    <DataGrid
      sx={{
        ".MuiDataGrid-sortIcon": {
          color: theme.palette.primary.main,
        },
        ".MuiDataGrid-filterIcon": {
          color: theme.palette.primary.main,
        },
        ".MuiDataGrid-menuIconButton": {
          color: theme.palette.primary.main,
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
      }}
      columnVisibilityModel={visibleModel}
      disableSelectionOnClick
      rows={rows}
      columns={columns}
      getRowHeight={() => {
        return "auto";
      }}
      onRowClick={(params) => {
        onClick(params.row);
      }}
      onColumnVisibilityModelChange={(model) => {
        localStorage.setItem("clientDataGridColumns", JSON.stringify(model));
        setVisibilityModel(model);
      }}
    />
  );
};

export default ClientDataGrid;
