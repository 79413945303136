import React from "react";
import { Box, Typography } from "@mui/material";

import RedButton from "./RedButton";
import BlueButton from "./BlueButton";

import ExerciseGroupImage from "../components/ExerciseGroupImage";

const MuscleGroupButton = ({ id, title, filter, onClick }) => {
  if (id === filter) {
    return (
      <BlueButton
        style={{ height: 80, width: 134, marginRight: 12, marginTop: 12 }}
        onClick={() => {
          if (onClick) {
            onClick(id);
          }
        }}
      >
        <Box>
          <ExerciseGroupImage id={id} selected={true} width={45} />
          <Typography>{title}</Typography>
        </Box>
      </BlueButton>
    );
  }
  return (
    <RedButton
      style={{ height: 80, width: 134, marginRight: 12, marginTop: 12 }}
      onClick={() => {
        if (onClick) {
          onClick(id);
        }
      }}
    >
      <Box>
        <ExerciseGroupImage id={id} selected={true} width={45} />
        <Typography>{title}</Typography>
      </Box>
    </RedButton>
  );
};

export default MuscleGroupButton;
