import React from "react";
import { Box, Grid, Typography, Stack } from "@mui/material";
import BlueBorderButton from "../../../../components/BlueBorderButton";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import SupplementPrescriptionRow from "./SupplementPrescriptionRow";
import SupplementPrescriptionDialog from "../../../../components/organisms/SupplementPrescriptionDialog";
import useSupplements from "../../../../hooks/useSupplements";
import { ago } from "../../../../data/GlobalData";
import RecentHistory from "../../../../components/molecules/RecentHistory";
import _ from "underscore";
import useEvents from "../../../../hooks/useEvents";
import { DropDownButton } from "../../../../atoms/EditButton";
import SupplementTitleRow from "./SupplementTitleRow";
// import consola from "consola";

const SupplementPrescriptionRows = ({
  client,
  getSupplementById,
  onEdit,
  onDelete,
  onUpdate,
}) => {
  if (!client) return null;

  if (client && !client.supplementPrescriptions) {
    return (
      <Box style={{ marginTop: 0 }}>
        <Typography style={{ fontSize: 12, fontWeight: "500" }}>
          No supplement prescriptions have been added
        </Typography>
      </Box>
    );
  }
  client.supplementPrescriptions = _.sortBy(
    client.supplementPrescriptions,
    "name",
  ).reverse();
  client.supplementPrescriptions = _.sortBy(
    client.supplementPrescriptions,
    "isActive",
  ).reverse();
  return (
    <Box style={{ marginTop: 0 }}>
      <SupplementTitleRow></SupplementTitleRow>
      {client.supplementPrescriptions.map((supplementPrescription) => {
        return (
          <SupplementPrescriptionRow
            key={supplementPrescription.id}
            prescription={supplementPrescription}
            supplement={getSupplementById(supplementPrescription.supplementId)}
            onEdit={() => {
              onEdit(supplementPrescription);
            }}
            onDelete={() => {
              onDelete(supplementPrescription);
            }}
            onUpdate={() => {
              onUpdate(supplementPrescription);
            }}
          />
        );
      })}
    </Box>
  );
};

const SupplementPrescriptions = ({ client, style }) => {
  const confirm = useConfirm();
  const [historyOpen, setHistoryOpen] = React.useState(false);
  const [showSupplements, setShowSupplements] = React.useState(true);

  const events = useEvents();
  const {
    getSupplementById,
    editClientSupplementData,
    deleteClientSupplementData,
  } = useSupplements();

  const [
    supplementPrescriptionDialogOpen,
    setSupplementPrescriptionDialogOpen,
  ] = React.useState(false);
  const selectedSupplementPrescription = React.useRef(null);

  const changedString = React.useMemo(() => {
    const { supplementPrescriptionChanged } = client;

    if (supplementPrescriptionChanged) {
      const date = supplementPrescriptionChanged.toDate();
      const agoTime = ago(date);
      return `${moment(date).format("MM/DD/YYYY")} - ${agoTime}`;
    }
    return "";
  }, [client]);

  function getHistoryString(historyOpen) {
    if (historyOpen) {
      return "Hide History";
    } else {
      return "Show History";
    }
  }

  return (
    <>
      <Box
        boxShadow={3}
        display="flex"
        borderRadius={2}
        borderBottom={showSupplements ? 1 : 0}
        style={{
          width: "100%",
          backgroundColor: "#F6F7FB",
          marginTop: 20,
          ...style,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          paddingLeft={3}
        >
          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <path
                    d="M2.62665 12.0533L12.0533 2.62662C15.1867 -0.493379 20.24 -0.493379 23.3733 2.62662C26.4933 5.74662 26.4933 10.8133 23.3733 13.9466L13.9467 23.3733C10.8133 26.4933 5.74665 26.4933 2.62665 23.3733C-0.493348 20.24 -0.493348 15.1866 2.62665 12.0533ZM4.51998 13.9466C3.11998 15.3333 2.65332 17.32 3.13332 19.0933L11.12 11.12L16.7733 16.7733L21.48 12.0533C23.5733 9.97329 23.5733 6.59995 21.48 4.51995C19.4 2.42662 16.0267 2.42662 13.9467 4.51995L4.51998 13.9466Z"
                    fill="black"
                  />
                </svg>
              </Box>
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "normal",
                  fontFamily: "Lato",
                  verticalAlign: "Center",
                  paddingLeft: 20,
                }}
              >
                Supplements
              </Typography>
              <Typography
                flexShrink={0}
                style={{
                  fontFamily: "Lato",
                  fontSize: 12,
                  fontWeight: "500",
                  verticalAlign: "center",
                  paddingLeft: 60,
                  paddingTop: 10,
                }}
              >
                {`Last Changed: ${changedString}`}
              </Typography>
            </Box>
          </Grid>

          <Grid item textAlign="center" style={{}}>
            <DropDownButton
              onClick={() => {
                setShowSupplements(!showSupplements);
              }}
              show={showSupplements}
            />
          </Grid>
        </Grid>
      </Box>
      {showSupplements && (
        <Box display={"flex"}>
          <Box
            boxShadow={3}
            borderRadius={2}
            style={{
              width: "100%",
              backgroundColor: "#F6F7FB",
              padding: 20,
              ...style,
            }}
          >
            <Box style={{ paddingInline: 75 }}>
              <SupplementPrescriptionRows
                client={client}
                getSupplementById={getSupplementById}
                onEdit={(prescription) => {
                  selectedSupplementPrescription.current = prescription;
                  setSupplementPrescriptionDialogOpen(true);
                  const logEvent = {
                    buttonName: "Edit Supplement",
                    pageName: "Clients",
                    tabName: client.uid,
                  };
                  events.logButtonClick(logEvent);
                }}
                onDelete={(prescription) => {
                  confirm({
                    title: "Delete this supplement?",
                    description:
                      "This will remove the supplement prescription from the clients plan. Continue?",
                  }).then(async () => {
                    const logEvent = {
                      buttonName: "Delete Supplement",
                      pageName: "Clients",
                      tabName: client.uid,
                    };
                    events.logButtonClick(logEvent);
                    deleteClientSupplementData(client, prescription);
                  });
                }}
                onUpdate={(prescription) => {
                  prescription.isActive = !prescription.isActive;
                  editClientSupplementData(client, prescription);
                }}
              />
            </Box>
            {historyOpen && (
              <RecentHistory
                client={client}
                historyCollection="supplementPrescriptionHistory"
              />
            )}

            <Stack
              style={{ marginTop: 12 }}
              direction={"row"}
              spacing={3}
              justifyContent={"center"}
            >
              <BlueBorderButton
                style={{ paddingInline: 150, marginTop: 12, marginBottom: 12 }}
                onClick={() => {
                  selectedSupplementPrescription.current = null;
                  setSupplementPrescriptionDialogOpen(true);
                }}
              >
                Add Supplement Prescription
              </BlueBorderButton>
              <BlueBorderButton
                style={{ paddingInline: 150, marginTop: 12, marginBottom: 12 }}
                onClick={() => {
                  if (!historyOpen) {
                    const logEvent = {
                      buttonName: "Display Supplement History",
                      pageName: "Clients",
                      tabName: client.uid,
                    };
                    events.logButtonClick(logEvent);
                  }
                  setHistoryOpen(!historyOpen);
                }}
              >
                {getHistoryString(historyOpen)}
              </BlueBorderButton>
            </Stack>
          </Box>
        </Box>
      )}
      {supplementPrescriptionDialogOpen && (
        <SupplementPrescriptionDialog
          client={client}
          open={supplementPrescriptionDialogOpen}
          supplementPrescription={selectedSupplementPrescription.current}
          onDialogClosed={(supplementPrescription) => {
            setSupplementPrescriptionDialogOpen(false);

            if (supplementPrescription) {
              editClientSupplementData(client, supplementPrescription);
            }
          }}
        />
      )}
    </>
  );
};

export default SupplementPrescriptions;
