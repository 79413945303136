import { selector } from "recoil";
import organizationIdState from "./organizationIdAtom";

const organizationIdSelector = selector({
  key: "organizationIdSelector",
  default: null,
  get: ({ get }) => {
    const organizationId = get(organizationIdState);
    const storedId = localStorage.getItem("organizationId");
    if (!organizationId) {
      if (storedId) {
        return storedId;
      }
    }
    return organizationId;
  },
});

export default organizationIdSelector;
