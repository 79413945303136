import React from "react";
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import MacroSummary from "./MacroSummary";

function Macros({ report }) {
  const isHealthData = React.useMemo(() => {
    let result = false;
    if (report.healthData) {
      report.healthData.forEach((hd) => {
        const { reportData } = hd;
        if (
          reportData.proteins > 0 ||
          reportData.carbs > 0 ||
          reportData.fats > 0
        ) {
          result = true;
        }
      });
    }
    return result;
  }, [report]);
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Box
      // display="flex"
      // boxShadow={3}
      // borderRadius={2}
      // style={{ backgroundColor: "#F6F7FB", padding: 20 }}
      style={{ marginTop: 20 }}
    >
      <Accordion
        display="flex"
        boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems={"center"}
            paddingLeft={0}
          >
            <Grid item textAlign="center" style={{}}>
              <Box display={"inline-flex"} alignItems={"center"}>
                <Box>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5UlEQVR4nN2UWQrCMBBAn0J7PHfReiIVoS530HoO8RgVlyvoZxsJpBC6prVF6IOBkkzmQTMTaDs2MAc8YKOty+8zsFA5lZgCd0CoCLW9UFt/AZMyhTvAQSsgtIhI29sDXRPBLqOAKBAIdTaXfs5hYSCQMc4qbgHPGgQPVSuBU3BQGAqE6rwExxoFXprALyG45OQEwDpN8CkhqMS7aYFfQnDNyZFTvvrLJTs1CmZpAksNSWODJunVIBhSgPuDwMXwud5WELimz3XECLjFJjQiiP3zARWxVUfIFl5q67LPT2ov80LbwRe15PvEjRL2QQAAAABJRU5ErkJggg=="
                    width="27"
                    height="28"
                  ></img>
                </Box>
                <Typography
                  style={{
                    color: "black",
                    fontSize: 24,
                    fontWeight: "600",
                    marginLeft: 10,
                  }}
                >
                  Macros
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <MacroSummary report={report} isHealthData={isHealthData} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default Macros;
