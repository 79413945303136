import React from "react";
import { Box, Typography, Stack, Grid } from "@mui/material";
import moment from "moment";
import _ from "underscore";
import {
  LineChart,
  Line,
  CartesianGrid,
  Tooltip,
  XAxis,
  ResponsiveContainer,
} from "recharts";

import { DataType } from "../../../../data/GlobalData";

const OptionalDataSummary = (props) => {
  const [optionalData, setOptionalData] = React.useState([]);
  const [report, setReport] = React.useState(props.report);
  const optionalDictionary = React.useRef(null);

  React.useEffect(() => {
    function getOptionalData(report) {
      const data = report.healthData.map((hd) => {
        return {
          date: moment(hd.createdOn.toDate()).format("MM/DD"),
          ...hd.reportData.optional,
        };
      });
      setOptionalData(data);
    }
    setReport(props.report);
    getOptionalData(props.report);
    getOptionalDictionary(props.report);
  }, [props.report, props.update]);

  function getOptionalDictionary(report) {
    const data = {};

    report.optionals.forEach((d) => {
      data[d.id] = d;
    });
    optionalDictionary.current = data;
  }

  function getOptionalDataByKey(key) {
    const data = optionalData.map((data) => {
      return {
        name: optionalDictionary.current[key].name,
        date: data.date,
        value: data[key] && data[key].value ? data[key].value : 0,
      };
    });

    const filtered = _.filter(data, (i) => {
      return i.value > 0;
    });
    return filtered;
  }

  return (
    <Box style={{ padding: 20, marginTop: 20, marginBottom: 20 }} boxShadow={1}>
      <Grid container spacing={2}>
        {report.optionals.map((option, index) => {
          if (
            option.type === DataType.multiline ||
            option.type === DataType.yesno
          ) {
            return null;
          }
          return (
            <Grid key={index} item xs={4}>
              <Stack style={{ alignItems: "center" }} spacing={2}>
                <Typography> {option.name}</Typography>
                <ResponsiveContainer aspect={4.0 / 3.0} width={"100%"}>
                  <LineChart
                    width={180}
                    height={140}
                    data={getOptionalDataByKey(option.id)}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" style={{ fontSize: 12 }} />
                    {/* <YAxis
                      domain={[0, "dataMax + 20"]}
                      style={{ fontSize: 12 }}
                    /> */}
                    <Tooltip />

                    <Line
                      dot={false}
                      type="monotone"
                      dataKey="value"
                      stroke="#8884d8"
                      strokeWidth={3}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default OptionalDataSummary;
