import React from "react";
import { MessageList } from "react-chat-elements";
import { Stack, Box, TextField, Button } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
// import Linkify from "react-linkify";
import "react-chat-elements/dist/main.css";
import "../../chat.css";
import SendMessage from "../SendMessage";

import useMessagesByGroup from "../../hooks/useMessagesByGroup";
import useEvents from "../../hooks/useEvents";

const ChatComponent = ({ uid, groupId, minHeight, maxHeight }) => {
  const messageListReference = React.createRef();
  const textInputRef = React.useRef(null);
  const searchTimeout = React.useRef();
  const events = useEvents();

  const [search, setSearch] = React.useState("");

  const { messages } = useMessagesByGroup({
    groupId,
    filter: search.length > 0 ? search : null,
  });

  // let id;

  // if (uid) {
  //   id = uid;
  // } else if (groupId) {
  //   id = groupId;
  // }

  // const [message, setMessage] = React.useState("");
  const [transformedMessages, setTransformedMessages] = React.useState([]);

  React.useEffect(() => {
    if (messages) {
      const transformed = messages.map((message) => {
        if (message.messageType === "image") {
          const width = 255;
          const height = 255 * (16 / 9);

          return {
            position: message.fromUID === uid ? "right" : "left",
            type: "photo",
            title: `${message.fromUser.firstName} ${message.fromUser.lastName}`,
            avatar: message.fromUser.avatarUrl || undefined,
            notch: false,
            data: {
              uri: message.imageUrl,
              width: width,
              height: height,
            },
            date: message.createdOn.toDate(),
          };
        }

        if (message.messageType === "video") {
          return {
            position: message.fromUID === uid ? "right" : "left",
            type: "video",
            title: `${message.fromUser.firstName} ${message.fromUser.lastName}`,
            avatar: message.fromUser.avatarUrl || undefined,
            notch: false,
            data: {
              uri: message.videoUrl,
              videoURL: message.videoUrl,
              status: {
                autoDownload: true,
                click: false,
                loading: false,
                download: true,
              },
            },
            date: message.createdOn.toDate(),
          };
        }

        if (message.messageType === "audio") {
          return {
            position: message.fromUID === uid ? "right" : "left",
            type: "audio",
            title: `${message.fromUser.firstName} ${message.fromUser.lastName}`,
            avatar: message.fromUser.avatarUrl || undefined,
            text: message.message,
            notch: false,
            data: {
              audioURL: message.audioUrl,
            },
            date: message.createdOn.toDate(),
          };
        }

        return {
          position: message.fromUID === uid ? "right" : "left",
          type: "text",
          title: `${message.fromUser.firstName} ${message.fromUser.lastName}`,
          avatar: message.fromUser.avatarUrl || undefined,
          notch: false,
          text: (
            <ReactMarkdown remarkPlugins={[remarkGfm, remarkBreaks]}>
              {message.message}
            </ReactMarkdown>
          ),
          date: message.createdOn.toDate(),
        };
      });
      setTransformedMessages(transformed);
    }
  }, [messages]);

  return (
    <Box
      className="chatParentBox"
      style={{
        minHeight: minHeight || 500,
        maxHeight: maxHeight || 500,
      }}
    >
      <Stack
        spacing={2}
        style={{ minHeight: minHeight || 500, maxHeight: maxHeight || 500 }}
      >
        <style>
          {`
          .message-list {
            height: ${minHeight || 500}px;
          }
        `}
        </style>

        <Stack
          direction={"row"}
          spacing={2}
          style={{ marginInline: 40, marginTop: 12, marginBottom: 12 }}
        >
          <TextField
            inputRef={textInputRef}
            placeholder="Search messages"
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{ style: { fontSize: 18 } }}
            onChange={(event) => {
              const value = event.target.value;
              if (searchTimeout.current) {
                clearTimeout(searchTimeout.current);
              }
              searchTimeout.current = setTimeout(() => {
                events.logTextInput({
                  pageName: "Messages",
                  textboxName: "Search Messages",
                  text: search,
                });
                setSearch(value);
              }, 500);
            }}
          />
          <Button
            style={{ color: "#0277C7", fontSize: 20 }}
            onClick={() => {
              // let page;
              // if (id === uid) {
              //   page = "Clients";
              // } else if (id === groupId) {
              //   page = "Messages";
              // }
              events.logButtonClick({
                buttonName: "Clear Search",
                pageName: "Messages",
                tabName: groupId,
              });
              setSearch("");
              textInputRef.current.value = "";
            }}
          >
            Clear
          </Button>
        </Stack>

        <MessageList
          style={{ minHeight: minHeight || 500, maxHeight: maxHeight || 500 }}
          toBottomHeight={"100%"}
          referance={messageListReference}
          className="message-list"
          lockable={false}
          dataSource={transformedMessages}
          messageBoxStyles={{
            marginInline: 50,
          }}
        />
        <SendMessage client={{ uid: uid }} groupId={groupId} />
      </Stack>
    </Box>
  );
};

export default ChatComponent;
