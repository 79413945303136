const firebaseConfig = {
  apiKey: "AIzaSyCwRHw4uSPpByLIcuQf_VucsnN57e-9BwY",
  authDomain: "mighty45-test.firebaseapp.com",
  projectId: "mighty45-test",
  storageBucket: "mighty45-test.appspot.com",
  messagingSenderId: "634095088422",
  appId: "1:634095088422:web:15ab4817893710e8ff87e7",
  measurementId: "G-4NZPKGPWZV",
};

export default firebaseConfig;
