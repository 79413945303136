import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";

import WorkoutSelectionExercises from "./WorkoutSelectionExercises";
import LabelChips from "../molecules/LabelChips";

const WorkoutSelectionList = ({ workouts, loading, labels, onAddToPlan }) => {
  const splits = React.useMemo(() => {
    const allSplits = [];
    if (workouts) {
      workouts.forEach((protocol) => {
        const { splits } = protocol;
        splits.forEach((split) => {
          allSplits.push(split);
        });
      });
    }
    return allSplits;
  }, [workouts]);

  if (loading) {
    return (
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: 500,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box style={{ width: "100%" }}>
      {splits.map((split) => {
        return (
          <Box key={split.id} style={{ marginBottom: 12, width: "100%" }}>
            <Grid
              style={{ width: "100%" }}
              container
              spacing={3}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item sm={11}>
                <Box>
                  <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                    {split.name}
                  </Typography>
                  <Typography style={{ fontSize: 16, fontWeight: "500" }}>
                    {`From protocol: ${split.protocolName}`}
                  </Typography>
                  <Typography style={{ fontSize: 12, fontWeight: "normal" }}>
                    {split.description}
                  </Typography>
                  <Typography style={{ fontSize: 12, fontWeight: "normal" }}>
                    {`${split.exercises.length} exercises`}
                  </Typography>
                  <LabelChips labels={split.labels} allLabels={labels} />
                </Box>
                <Box>
                  <WorkoutSelectionExercises exercises={split.exercises} />
                </Box>
              </Grid>
              <Grid item sm={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (onAddToPlan) {
                      // const cloneSplit = replaceIds(protocol, split);
                      onAddToPlan(split);
                    }
                  }}
                >
                  Add to Plan
                </Button>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
          </Box>
        );
      })}
    </Box>
  );
};

export default WorkoutSelectionList;
