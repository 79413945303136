import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  Stack,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getOpenAIResponse } from "../../../../data/GlobalData";
import BlueButton from "../../../../components/BlueButton";
import units from "../../../../data/Units";

const IngredientStack = ({ unit, setUnit }) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Typography>Per:</Typography>
      <Select
        sx={{ width: 100 }}
        size="small"
        value={unit}
        onChange={(event) => {
          setUnit(event.target.value);
        }}
      >
        {Object.keys(units).map((unit) => {
          return (
            <MenuItem key={unit} value={unit}>
              {t(`units.${unit}`)}
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};

const IngredientEditDialog = ({
  ingredient,
  open = false,
  onDialogClosed = null,
}) => {
  const [dialogOpen, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [name, setName] = React.useState("");
  const [proteins, setProteins] = React.useState(0);
  const [carbs, setCarbs] = React.useState(0);
  const [fats, setFats] = React.useState(0);
  const [unit, setUnit] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setOpen(open);
  }, [open]); // eslint-disable-line

  React.useEffect(() => {
    if (ingredient) {
      setName(ingredient.name);
      setProteins(ingredient?.proteins ? parseFloat(ingredient.proteins) : 0);
      setCarbs(ingredient?.carbs ? parseFloat(ingredient.proteins) : 0);
      setFats(ingredient?.fats ? parseFloat(ingredient.proteins) : 0);
      setUnit(ingredient?.unit || null);
    } else {
      setName("");
      setProteins(0);
      setCarbs(0);
      setFats(0);
      setUnit("na");
    }
  }, [ingredient]);

  function validate() {
    if (!name) {
      return ["A name is required."];
    }
    return null;
  }

  if (!dialogOpen) {
    return null;
  }

  return (
    <Dialog
      key="IngredientEditDialog"
      maxWidth="lg"
      open={dialogOpen}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>
        {!ingredient ? "Add Ingredient" : "Edit Ingredient"}
      </DialogTitle>

      <DialogContent>
        <Stack
          style={{
            marginTop: 24,
            marginBottom: 12,
          }}
        >
          <Typography>Ingredient:</Typography>
          <TextField
            size="small"
            value={name}
            variant="outlined"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </Stack>

        <Stack
          direction={"row"}
          spacing={2}
          style={{
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <IngredientStack unit={unit} setUnit={setUnit} />
          <Stack>
            <Typography>Proteins (g):</Typography>
            <TextField
              size="small"
              type="number"
              value={proteins}
              variant="outlined"
              onChange={(event) => {
                setProteins(event.target.value);
              }}
            />
          </Stack>

          <Stack>
            <Typography>Carbs (g):</Typography>
            <TextField
              size="small"
              type="number"
              value={carbs}
              variant="outlined"
              onChange={(event) => {
                setCarbs(event.target.value);
              }}
            />
          </Stack>

          <Stack>
            <Typography>Fats (g):</Typography>
            <TextField
              size="small"
              type="number"
              value={fats}
              variant="outlined"
              onChange={(event) => {
                setFats(event.target.value);
              }}
            />
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"flex-end"}
          style={{
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Button
              variant="contained"
              style={{ height: 35 }}
              onClick={() => {
                setLoading(true);
                getOpenAIResponse(
                  `Give macros in grams for 1 ${unit} of ${name}. return the response in JSON format.
                If you can't find the macros, return 0 for each.
                use the keys proteins, carbs, and fats. value should only be numbers. json should be on a single line and unformatted.
                encode the json so it can be converted to json using JSON.parse. example: {"proteins": 1, "carbs": 2, "fats": 3}`,
                ).then((response) => {
                  setLoading(false);
                  if (response && response.response) {
                    const json = JSON.parse(response.response);
                    setProteins(json.proteins);
                    setCarbs(json.carbs);
                    setFats(json.fats);
                  }
                });
              }}
              color="primary"
            >
              Auto-fill Macros
            </Button>
            {loading && <CircularProgress size={32} variant="indeterminate" />}
            <Typography style={{ marginLeft: 8 }}>
              Use this to use AI assistance auto-fill the macros for the
              ingredient.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      {error && (
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ width: 200, color: "red" }}>
              {error}
            </Typography>
          </Box>
        </DialogContent>
      )}

      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            if (onDialogClosed) {
              onDialogClosed(null, null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            const validateResult = validate();
            if (!validateResult) {
              setOpen(false);
              if (onDialogClosed) {
                onDialogClosed(
                  {
                    id: ingredient ? ingredient.id : null,
                    name,
                    proteins,
                    carbs,
                    fats,
                    unit,
                  },
                  ingredient,
                );
              }
            } else {
              setError(validateResult[0]);
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default IngredientEditDialog;
