import React from "react";
import {
  Box,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import { updateSubscription } from "../../data/StripeData";
import organizationIdSelector from "../../atoms/organizationIdSelector";
import { useRecoilValue } from "recoil";
import StripeFormWrapper from "./StripeForm";

const SubscribeNowDialog = () => {
  const organizationId = useRecoilValue(organizationIdSelector);

  const [stripeData, setStripeData] = React.useState(null);

  React.useEffect(() => {
    async function getStripeData() {
      if (!organizationId) {
        return;
      }
      const stripeData = await updateSubscription({
        organizationId: organizationId,
      });
      setStripeData(stripeData);
    }
    getStripeData();
  }, [organizationId]);

  const options = React.useMemo(() => {
    if (!stripeData || !stripeData.clientSecret) {
      return null;
    }
    return {
      clientSecret: stripeData.clientSecret,
    };
  }, [stripeData]);

  return (
    <Dialog
      maxWidth="lg"
      open={true}
      onClose={(reason) => {
        if (reason === "backdropClick") {
          return;
        }
      }}
    >
      <DialogTitle>Subscribe Now</DialogTitle>

      <DialogContent>
        <Stack>
          <Typography variant="h6">Your trial is expired.</Typography>
          <Typography variant="h6" style={{ marginTop: 20 }}>
            For an unbeatable value, our base monthly fee is just $10.00! But
            that&apos;s not all – we believe in supporting your growing success,
            which is why we only charge an additional $3.00 for each active
            coach and a mere $1.50 for each active client. We&apos;ve got you
            covered!{" "}
          </Typography>
          <Typography variant="h6" style={{ marginTop: 20 }}>
            As a special bonus, disabled coaches and clients won&apos;t incur
            any charges, so you can focus on optimizing your business without
            any financial burden. Join the Mighty45 Premium family today and
            revolutionize your coaching practice.
          </Typography>
        </Stack>
      </DialogContent>
      {options && stripeData && stripeData.stripeStatus !== "active" && (
        <Box style={{ padding: 20 }}>
          <StripeFormWrapper />
        </Box>
      )}
    </Dialog>
  );
};

export default SubscribeNowDialog;
