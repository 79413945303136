import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import Bugsnag from "@bugsnag/js";
import { nanoid } from "nanoid";
import { consola } from "consola";
export const UserType = {
  client: "client",
  coach: "coach",
  admin: "admin",
};

export async function getConnectCodeUserCount({ connectCode }) {
  if (!connectCode) {
    consola.error(new Error("Incorrect parameters"));
  }

  if (window.location.hostname === "127.0.0.1") {
    return {
      status: "OK",
      count: 0,
    };
  }

  const response = await fetch(
    `https://app.mighty45.com/api/v1/connect_code_count/${connectCode}`,
    {
      method: "GET",
    },
  );
  if (response.status === 200) {
    const jsonData = await response.json();
    return jsonData;
  }
  consola.error(new Error("Error getting connect code user count"));
}

export async function setUserClaim({ userId, organizationId, authId }) {
  if (!userId || !organizationId || !authId) {
    consola.error(new Error("Incorrect parameters"));
  }

  const sendData = {
    userId: userId,
    organizationId: organizationId,
    authId: authId,
  };

  const json = JSON.stringify(sendData);

  const response = await fetch("https://app.mighty45.com/api/v1/claims", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: json,
  });
  if (response.status === 200) {
    const jsonData = await response.json();
    return jsonData;
  }
  consola.error(new Error("Error setting claim"));
}

/**
 * Check if the connect code is valid.
 * @param {*} param0
 * @returns
 */
export async function checkConnectCode({ connectCode }) {
  if (!connectCode) {
    consola.error(new Error("Incorrect parameters"));
  }

  const userRef = firebase
    .firestore()
    .collection("users")
    .where("connectCode", "==", connectCode);
  consola.info("++++++ READ -> OrganizationData: checkConnectCode")
  const userData = await userRef.get();
  if (userData.docs.length > 0) {
    const doc = userData.docs[0];
    const userDataValues = doc.data();

    if (userDataValues.connectCode === connectCode) {
      if (userDataValues.type === UserType.coach) {
        return true;
      }
    }
  }

  return false;
}

const OrganizationData = {
  getOrganization: async (uid) => {
    try {
      const userRef = firebase.firestore().collection("users").doc(uid);
      consola.info("++++++ READ -> OrganizationData: getOrganization")
      const userData = await userRef.get();
      if (userData.exists) {
        const userDataValues = userData.data();

        const ref = firebase
          .firestore()
          .collection("organizations")
          .doc(userDataValues.organizationId);
        const data = await ref.get();

        return {
          ...data.data(),
          id: data.id,
        };
      }
    } catch (error) {
      Bugsnag.notify(error);
      throw error;
    }
  },
  getOrganizationById: async (id) => {
    try {
      const ref = firebase.firestore().collection("organizations").doc(id);
      consola.info("++++++ READ -> OrganizationData: getOrganizationById")
      const data = await ref.get();

      return {
        ...data.data(),
        id: data.id,
      };
    } catch (error) {
      Bugsnag.notify(error);
      throw error;
    }
  },
  saveOrganizationData: async (uid, data) => {
    const userRef = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> OrganizationData: saveOrganizationData")
    const userData = await userRef.get();
    if (userData.exists) {
      const userDataValues = userData.data();

      const ref = firebase
        .firestore()
        .collection("organizations")
        .doc(userDataValues.organizationId);

      if (
        userDataValues.role &&
        userDataValues.role === "admin" &&
        userDataValues.type &&
        userDataValues.type === UserType.coach
      ) {
        consola.info("+++++ WRITE => OrganizationData: saveOrganizationData")
        await ref.set(data, { merge: true });
        return true;
      }
    }
    return false;
  },
  getCoaches: async (organizationId) => {
    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("coaches");
      
    consola.info("++++++ READ -> OrganizationData getCoaches")
    const coaches = await ref.get();
    const promises = coaches.docs.map((d) => {
      return new Promise((resolve, reject) => {
        const data = d.data();

        const userRef = firebase.firestore().collection("users").doc(data.uid);
        userRef
          .get()
          .then((user) => {
            if (user.exists) {
              resolve({
                ...user.data(),
              });
            } else {
              reject(new Error(`User does not exist (${data.uid})`));
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    });
    const final = await Promise.all(promises);

    return final;
  },
  usersWithConnectCode: async (connectCode) => {
    const userRef = firebase
      .firestore()
      .collection("users")
      .where("connectCode", "==", connectCode);
    consola.info("++++++ READ -> OrganizationData: usersWithConnectCode")
    const userData = await userRef.get();
    return userData.docs;
  },
  addToOrganization: async (authId, connectCode) => {
    const userRef = firebase
      .firestore()
      .collection("users")
      .where("connectCode", "==", connectCode);
    consola.info("++++++ READ -> OrganizationData: addToOrganization")
    const userData = await userRef.get();
    if (userData.docs.length > 0) {
      const doc = userData.docs[0];
      const userDataValues = userData.docs[0].data();

      if (
        userDataValues.connectCode === connectCode &&
        userDataValues.type === UserType.coach
      ) {
        if (!userDataValues.authId) {
          // Retrieve new token.
          await firebase.auth().currentUser.getIdTokenResult(true);
          consola.info("+++++ WRITE => OrganizationData: addToOrganization")
          await doc.ref.set(
            {
              authId: authId,
              type: UserType.coach,
              role: UserType.coach,
              onboardedOn: new Date(),
            },
            { merge: true },
          );
          return userDataValues;
        }
      }
    }
    return null;
  },
  addOrganization: async (userId, input) => {
    const userRef = firebase.firestore().collection("users").doc(userId);
    consola.info("++++++ READ -> OrganizationData: addOrganization")
    const userData = await userRef.get();
    if (userData.exists) {
      const organizationId = nanoid();

      // Retrieve new token.
      await firebase.auth().currentUser.getIdTokenResult(true);

      const organizationRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId);

      const coachRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("coaches")
        .doc(userId);

      try {
        await firebase.firestore().runTransaction(async () => {
          await organizationRef.set(input, { merge: true });
          await userRef.set(
            {
              organizationId: organizationId,
              updatedOn: new Date(),
            },
            { merge: true },
          );
          await coachRef.set(
            {
              uid: userId,
              status: "active",
              connectCode: "",
              updatedAt: new Date(),
            },
            { merge: true },
          );
        });
        return organizationId;
      } catch (e) {
        consola.log(e);
        Bugsnag.notify(e);
        return null;
      }
    }
    return null;
  },
};

export default OrganizationData;
