import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import {
  Box,
  Typography,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Breadcrumbs,
  Link,
  Button,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import Bugsnag from "@bugsnag/js";
import ReactPlayer from "react-player";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import useProtocols from "data/hooks/useProtocols";
import AddVideoDialog from "./AddVideoDialog";
import GlobalData from "../../../../data/GlobalData";
import ProtocolData from "../../../../data/ProtocolData";
import NewLineTypography from "../../../../atoms/NewLineTypography";
import { consola } from "consola";
import useEvents from "../../../../hooks/useEvents";

const ExerciseDetails = (props) => {
  const confirm = useConfirm();
  const { findExercise } = useProtocols();

  const [addVideoURLOpen, setAddVideoURLOpen] = React.useState(false);
  const [exercise, setExercise] = React.useState(null);
  const [update, setUpdate] = React.useState(new Date());
  const [foundProtocols, setFoundProtocols] = React.useState([]);

  const subscription = React.useRef(null);
  const uidRef = React.useRef(localStorage.getItem("uid"));
  const events = useEvents();

  React.useEffect(() => {
    // Get list of all protocols
    async function getData() {
      const exercises = findExercise({
        exerciseId: props.exercise.id,
        protocols: props.allProtocols,
      });

      // get all protcols
      const protocols = await Promise.all(
        exercises.map(async (exercise) => {
          // Get Protocol
          const protocol = await ProtocolData.getProtocol(exercise.protocolId);
          return protocol.data();
        }),
      );

      setFoundProtocols(protocols);
    }
    getData();
  }, [props.allProtocols]);

  const planString = React.useMemo(() => {
    // Get a string of all the protocols using the name
    const planString = foundProtocols
      ? foundProtocols.length
      : 0 + " Plans Found";
    return planString;
  }, [foundProtocols]);

  React.useEffect(() => {
    if (subscription.current) {
      subscription.current();
    }

    if (props.exercise.isCustom) {
      GlobalData.getCustomExerciseSubscription(
        props.exercise.id,
        (snapshot) => {
          setExercise(snapshot);
        },
        (subscription) => {
          subscription.current = subscription;
        },
      );
    } else {
      subscription.current = GlobalData.getExerciseSubscription(
        props.exercise.id,
        (snapshot) => {
          setExercise(snapshot);
        },
      );
    }

    return () => {
      if (subscription.current) {
        subscription.current();
      }
    };
  }, [props.exercise, update]);

  if (!exercise) {
    return null;
  }

  // REFACTOR: Remove the call to user data values.
  const handleCustomImageUpload = async (uid, file, imageIndex, exercise) => {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> ExerciseDetails: handleCustomImageUpload");
    const userData = await ref.get();
    if (userData.exists) {
      const userDataValues = userData.data();

      const organizationId = userDataValues.organizationId;
      const path = `/${organizationId}/exercise_images/${exercise.id}_${imageIndex}`;
      const uploadTask = firebase.storage().ref(path).put(file);

      uploadTask.on(
        "state_changed",
        () => {
          // progress function ...
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          consola.error(new Error(error));
        },
        () => {
          firebase
            .storage()
            .ref(path)
            .getDownloadURL()
            .then(async (url) => {
              await GlobalData.editCustomImageURL(
                props.exercise,
                url,
                imageIndex,
              );
              setUpdate(new Date());
            })
            .catch((e) => {
              Bugsnag.notify(e);
            });
        },
      );
    }
  };

  const handleCustomImageDelete = async (uid, imageIndex, exercise) => {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> ExerciseDetails: handleCustomImageDelete");
    const userData = await ref.get();
    if (userData.exists) {
      const userDataValues = userData.data();
      events.logButtonClick({
        buttonName: "Custom Image Delete",
        pageName: "Exercises",
        tabName: props.exercise.id,
      });

      const organizationId = userDataValues.organizationId;
      const path = `/${organizationId}/exercise_images/${exercise.id}_${imageIndex}`;
      firebase
        .storage()
        .ref(path)
        .delete()
        .then(() => {
          GlobalData.editCustomImageURL(props.exercise, "", imageIndex);
          setUpdate(new Date());
        });
    }
  };

  const handleImage1Upload = async (e) => {
    if (e.target.files[0]) {
      events.logButtonClick({
        buttonName: "Image 1 Upload",
        pageName: "Exercises",
        tabName: props.exercise.id,
      });
      const image = e.target.files[0];
      await handleCustomImageUpload(uidRef.current, image, 0, props.exercise);
    }
  };

  const handleImage2Upload = async (e) => {
    if (e.target.files[0]) {
      events.logButtonClick({
        buttonName: "Image 2 Upload",
        pageName: "Exercises",
        tabName: props.exercise.id,
      });
      const image = e.target.files[0];
      handleCustomImageUpload(uidRef.current, image, 1, props.exercise);
    }
  };

  function getEquipmentString(equipment) {
    const strings = equipment.map((e) => {
      return props.equipmentKey[e];
    });
    return strings.join(", ");
  }

  return (
    <Box
      style={{
        marginLeft: 20,
        marginTop: 20,
      }}
    >
      <Breadcrumbs separator=">">
        <Link
          onClick={() => {
            if (props.onBackClick) {
              props.onBackClick();
            }
          }}
        >
          Exercise
        </Link>
        <Typography>{`${exercise.name}`}</Typography>
      </Breadcrumbs>

      <Box style={{ marginTop: 20 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              style={{ marginBottom: 20, fontSize: 24, fontWeight: "bold" }}
              variant="h5"
            >
              Overview
            </Typography>

            <Typography
              style={{ fontSize: 20, fontWeight: "500", marginBottom: 20 }}
            >{`Main Muscle Group : ${
              props.muscleGroupsKey[exercise.mainMuscleGroups[0]]
            }`}</Typography>
            <Typography
              style={{ fontSize: 20, fontWeight: "500", marginBottom: 20 }}
            >{`Secondary Muscle : ${
              props.muscleGroupsKey[exercise.secondaryMuscleGroups[0]]
            }`}</Typography>
            <Typography
              style={{
                fontSize: 20,
                fontWeight: "500",
                marginBottom: 20,
                width: 400,
              }}
            >{`Equipment : ${
              exercise.equipment && exercise.equipment.length > 0
                ? getEquipmentString(exercise.equipment)
                : "None"
            }`}</Typography>
            <Typography
              style={{ fontSize: 20, fontWeight: "500", marginBottom: 20 }}
            >{`Difficulty : ${
              props.difficultyKey[exercise.difficulty]
            }`}</Typography>

            <Typography
              style={{ fontSize: 20, fontWeight: "500", marginBottom: 20 }}
            >{`# Plans : ${
              foundProtocols.length > 0 ? planString : "None"
            }`}</Typography>
          </Grid>
          <Grid item>
            <ImageList>
              <ImageListItem
                style={{
                  width: 420,
                  height: 350,
                  backgroundColor: "white",
                  margin: 24,
                  borderRadius: 8,
                }}
              >
                {exercise.images &&
                  exercise.images.length >= 1 &&
                  exercise.images[0].length > 0 && (
                    <img src={exercise.images[0]} alt={exercise.name} />
                  )}

                <ImageListItemBar
                  actionIcon={
                    <Box style={{ height: 25, marginRight: 20 }}>
                      <label>
                        <AddAPhotoIcon
                          color="primary"
                          style={{ color: "white" }}
                        />
                        <input
                          hidden={true}
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={handleImage1Upload}
                        />
                      </label>

                      <DeleteIcon
                        color="primary"
                        style={{ color: "white", marginLeft: 20 }}
                        onClick={() => {
                          confirm({
                            title: "Remove the image?",
                            description:
                              "This will remove the image from the exercise. Continue?",
                          }).then(async () => {
                            handleCustomImageDelete(
                              uidRef.current,
                              0,
                              props.exercise,
                            );
                          });
                        }}
                      />
                    </Box>
                  }
                ></ImageListItemBar>
              </ImageListItem>
              <ImageListItem
                style={{
                  width: 420,
                  height: 350,
                  backgroundColor: "white",
                  margin: 24,
                  borderRadius: 8,
                }}
              >
                {exercise.images &&
                  exercise.images.length >= 2 &&
                  exercise.images[1].length > 0 && (
                    <img src={exercise.images[1]} alt={exercise.name} />
                  )}
                <ImageListItemBar
                  actionIcon={
                    <Box style={{ height: 25, marginRight: 20 }}>
                      <label>
                        <AddAPhotoIcon
                          color="primary"
                          style={{ color: "white" }}
                        />
                        <input
                          hidden={true}
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={handleImage2Upload}
                        />
                      </label>

                      <DeleteIcon
                        color="primary"
                        style={{ color: "white", marginLeft: 20 }}
                        onClick={() => {
                          confirm({
                            title: "Remove the image?",
                            description:
                              "This will remove the image from the exercise. Continue?",
                          }).then(async () => {
                            handleCustomImageDelete(
                              uidRef.current,
                              1,
                              props.exercise,
                            );
                          });
                        }}
                      />
                    </Box>
                  }
                ></ImageListItemBar>
              </ImageListItem>
            </ImageList>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography
          style={{
            marginTop: 40,
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          Description
        </Typography>
        <NewLineTypography text={props.exercise.description} />
      </Box>
      <Box>
        <Typography
          style={{
            marginTop: 40,
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          Instructions to perform exercise
        </Typography>
        <NewLineTypography text={props.exercise.instructions} />
      </Box>
      <Box>
        <Typography
          style={{
            marginTop: 40,
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          Video instructions
        </Typography>
        <Box
          justifyContent="flex-end"
          style={{ marginTop: 12, marginRight: 12 }}
        >
          <Grid container justifyContent="flex-start">
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setAddVideoURLOpen(true);
                  events.logButtonClick({
                    buttonName: "Add Video",
                    pageName: "Exercises",
                    tabName: props.exercise.id,
                  });
                }}
              >
                Add Video
              </Button>
            </Grid>
          </Grid>
        </Box>
        {exercise.videos && exercise.videos.length > 0 && (
          <Grid container>
            {exercise.videos &&
              exercise.videos.length > 0 &&
              exercise.videos.map((e) => {
                let finalURL = e;
                if (e?.includes?.("youtube.com/shorts")) {
                  finalURL = e.split("?")[0].replace("shorts/", "watch?v=");
                }

                return (
                  <Grid
                    item
                    xs={12}
                    key={e}
                    style={{
                      width: "100%",

                      marginTop: 24,
                      borderRadius: 8,
                    }}
                  >
                    <ReactPlayer width={600} url={finalURL} controls={true} />
                    <Button
                      style={{ marginTop: 12 }}
                      variant="outlined"
                      onClick={async () => {
                        await GlobalData.deleteCustomVideoURL(exercise, e);
                        setUpdate(new Date());
                      }}
                    >
                      Delete Video
                    </Button>
                  </Grid>
                );
              })}
          </Grid>
        )}

        <Box>
          <Typography
            style={{
              marginTop: 40,
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            Training Plans using this Exercise
          </Typography>

          {foundProtocols.map((protocol) => {
            return (
              <Box key={protocol.id} style={{ marginTop: 12 }}>
                <Typography style={{ fontSize: 16 }}>
                  {protocol.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>

      <AddVideoDialog
        open={addVideoURLOpen}
        exercise={exercise}
        onDialogClosed={async (videoURL) => {
          if (videoURL && videoURL.length > 0) {
            await GlobalData.addCustomVideoURL(exercise, videoURL);
            setUpdate(new Date());
          }
          setAddVideoURLOpen(false);
        }}
      />
    </Box>
  );
};

export default ExerciseDetails;
