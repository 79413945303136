import { nanoid } from "nanoid";
import { getRecoil } from "recoil-nexus";
import Bugsnag from "@bugsnag/js";
import { consola } from "consola";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/firestore";

import organizationIdState from "../atoms/organizationIdSelector";
import userDataState from "../atoms/userDataAtom";

async function setProtocolUpdatedOn({ organizationId, protocolId }) {
  try {
    const protocolDocRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocols")
      .doc(protocolId);
    consola.info("+++++ WRITE => ProtocolData: setProtocolUpdatedOn");
    await protocolDocRef.set(
      {
        updatedOn: new Date(),
      },
      { merge: true },
    );
  } catch (e) {
    Bugsnag.notify(e);
    throw e;
  }
}

async function setSplitDuplicateOffFlat({ organizationId, splitId }) {
  try {
    const splitDocRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolSplits")
      .doc(splitId);
    consola.info("+++++ WRITE => ProtocolData: setSplitDuplicateOffFlat");
    await splitDocRef.set(
      {
        isDuplicate: false,
      },
      { merge: true },
    );
  } catch (e) {
    Bugsnag.notify(e);
    throw e;
  }
}

async function reorderExercises(protocolId, splitId) {
  const organizationId = getRecoil(organizationIdState);
  var batch = firebase.firestore().batch();

  const splitRef = firebase
    .firestore()
    .collection("organizations")
    .doc(organizationId)
    .collection("protocolExercises")
    .orderBy("index")
    .where("splitId", "==", splitId);
  consola.info("++++++ READ -> ProtocolData: reorderExercises");
  const data = await splitRef.get();
  const docs = data.docs;

  let index = 0;
  docs.forEach((e) => {
    consola.info("+++++ WRITE => ProtocolData: reorderExercises");
    batch.set(
      e.ref,
      {
        index: index,
      },
      { merge: true },
    );
    index += 1;
  });
  consola.info("+++++ WRITE => ProtocolData: reorderExercises");
  await batch.commit();
  await setProtocolUpdatedOn({ organizationId, protocolId: protocolId });
}

async function setOrderOfExercises(protocolId, list) {
  const organizationId = getRecoil(organizationIdState);

  var batch = firebase.firestore().batch();

  let index = 0;

  list.forEach((e) => {
    const exerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolExercises")
      .doc(e.id);
    consola.info("+++++ WRITE => ProtocolData: setOrderOfExercises");
    batch.set(
      exerciseRef,
      {
        index: index,
      },
      { merge: true },
    );
    index += 1;
  });
  consola.info("+++++ WRITE => ProtocolData: setOrderOfExercises");
  await batch.commit();
  await setProtocolUpdatedOn({ organizationId, protocolId });
}

async function setOrderOfSplits(protocolId, list) {
  const organizationId = getRecoil(organizationIdState);

  var batch = firebase.firestore().batch();

  let index = 0;
  list.forEach((split) => {
    const splitRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolSplits")
      .doc(split.id);
    consola.info("+++++ WRITE => ProtocolData: setOrderOfSplits");
    batch.set(
      splitRef,
      {
        index: index,
      },
      { merge: true },
    );
    index += 1;
  });
  consola.info("+++++ WRITE => ProtocolData setOrderOfSplits");
  await batch.commit();
  await setProtocolUpdatedOn({ organizationId, protocolId });
}

const ProtocolData = {
  getProtocol: async (protocolId) => {
    consola.log(`data(): getProtocol`);

    const organizationId = getRecoil(organizationIdState);
    if (!protocolId) {
      return null;
    }
    const protocolRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocols")
      .doc(protocolId);

    consola.info("++++++ READ -> ProtocolData: getProtocol");
    const protocol = await protocolRef.get();
    if (protocol.exists) {
      return protocol;
    }
  },
  getProtocols: async ({ filters = null, clientUID = null } = {}) => {
    consola.log(`data(): getProtocols`);
    const organizationId = getRecoil(organizationIdState);

    if (!organizationId) {
      consola.error(new Error("No organization ID"));
    }

    let protocolRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocols");

    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        protocolRef = protocolRef.where(
          filter.field,
          filter.operation,
          filter.value,
        );
      });
    }

    if (clientUID) {
      protocolRef = protocolRef.where("clientUID", "==", clientUID);
    } else {
      protocolRef = protocolRef.where("clientUID", "==", null);
    }

    protocolRef = protocolRef.orderBy("name", "asc");

    const reports = await protocolRef.get();
    const docs = reports.docs;

    consola.info("++++++ READ -> ProtocolData: getProtcols", docs.length);

    return docs;
  },
  getProtocolsSubscription: async (uid, onChange) => {
    const organizationId = getRecoil(organizationIdState);
    const protocolRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocols")
      .orderBy("name", "asc");

    return protocolRef.onSnapshot((snapshot) => {
      consola.info("++++++ SNAPSHOT -> ProtocolData: getProtocolsSubscription");
      if (onChange) {
        onChange(snapshot);
      }
    });
  },
  getProtocolSubscription: async ({
    protocolId,
    onChange,
    onSubscription,
    onError,
  }) => {
    consola.log(`data(): getProtocolSubscription`);
    const organizationId = getRecoil(organizationIdState);
    const protocolRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocols")
      .doc(protocolId);

    consola.info("++++++ SNAPSHOT -> Protocol Data: getProtocolSubscription");
    const subscription = protocolRef.onSnapshot(
      (snapshot) => {
        if (onChange) {
          onChange(snapshot.data());
        }
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      },
    );
    if (onSubscription) {
      onSubscription(subscription);
    }
  },
  getSplitsSubscriptionFlat: async (protocolId, onChange) => {
    consola.log(`data(): getSplitsSubscriptionFlat`);
    const organizationId = getRecoil(organizationIdState);
    const splitRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolSplits")
      .orderBy("index")
      .where("protocolId", "==", protocolId);

    return splitRef.onSnapshot((snapshot) => {
      consola.info(
        "++++++ SNAPSHOT -> ProtocolData: getSplitsSubscriptionFlat",
      );
      if (onChange) {
        onChange(snapshot);
      }
    });
  },
  getProtocolSplits: async (protocolId) => {
    consola.log(`data(): getProtocolSplits`);
    const organizationId = getRecoil(organizationIdState);
    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolSplits")
      .orderBy("index")
      .where("protocolId", "==", protocolId);

    consola.info("++++++ READ -> ProtocolData: getProtocolSplits");
    return (await ref.get()).docs;
  },
  getSplitExercises: async (splitId) => {
    consola.log(`data(): getSplitExercises`);
    const organizationId = getRecoil(organizationIdState);
    const splitRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolExercises")
      .orderBy("index")
      .where("splitId", "==", splitId);
    consola.info("++++++ READ -> ProtocolData: getSplitExercises");
    return (await splitRef.get()).docs;
  },
  getSplitExerciseSubscriptionFlat: async (splitId, onChange) => {
    consola.log(`data(): getSplitExerciseSubscriptionFlat`);
    const organizationId = getRecoil(organizationIdState);
    const splitRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolExercises")
      .orderBy("index")
      .where("splitId", "==", splitId);

    return splitRef.onSnapshot((snapshot) => {
      consola.info(
        "++++++ SNAPSHOT -> Protocol Data: getSplitExerciseSubscriptionFlat",
      );
      if (onChange) {
        onChange(snapshot);
      }
    });
  },
  addProtocol: async (values) => {
    const organizationId = getRecoil(organizationIdState);
    const protocolRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocols")
      .doc(values.id);

    const splitRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolSplits")
      .where("protocolId", "==", values.id);

    consola.info("++++++ READ -> ProtocolData: addProtocol");
    const data = await splitRef.get();
    const docs = data.docs;
    consola.info("+++++ WRITE => ProtocolData: addProtocol");
    await protocolRef.set(
      { splitCount: docs.length, ...values },
      { merge: true },
    );
    const newProtocol = await protocolRef.get();
    if (newProtocol.exists) {
      return newProtocol.data();
    }
    return null;
  },
  duplicateProtocol: async ({ protocolId, name = null, clientUID = null }) => {
    consola.log(`data(): ProtocolData`);
    // REFACTOR: Refactor to use batches.
    const organizationId = getRecoil(organizationIdState);
    const userState = getRecoil(userDataState);

    const result = await firebase.firestore().runTransaction(async () => {
      const currentProtocolRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocols")
        .doc(protocolId);
      consola.info("++++++ READ -> ProtocolData: duplicateProtocol");
      const protocolDoc = await currentProtocolRef.get();
      if (protocolDoc.exists) {
        const protocolValue = protocolDoc.data();

        // First create the new protocol.
        const newProtocolId = nanoid();

        protocolValue.id = newProtocolId;
        protocolValue.name = name ? name : `${protocolValue.name} (copy)`;
        protocolValue.privacy = "private";
        protocolValue.createdByUID = userState.uid;
        protocolValue.clientUID = clientUID;

        const newProtocolRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("protocols")
          .doc(newProtocolId);
        consola.info("+++++ WRITE => ProtocolData: duplicateProtocol");
        await newProtocolRef.set(protocolValue, { merge: true });

        // Next, create the splits.
        const currentSplitsRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("protocolSplits")
          .where("protocolId", "==", protocolId);

        const splitsDocs = await currentSplitsRef.get();

        splitsDocs.forEach(async (splitDoc) => {
          const newSplitId = nanoid();
          const currentSplitValue = splitDoc.data();

          const currentSplitExercisesRef = firebase
            .firestore()
            .collection("organizations")
            .doc(organizationId)
            .collection("protocolExercises")
            .where("splitId", "==", currentSplitValue.id);

          const exercisesDocs = await currentSplitExercisesRef.get();

          // Add new split
          const newSplitsRef = firebase
            .firestore()
            .collection("organizations")
            .doc(organizationId)
            .collection("protocolSplits")
            .doc(newSplitId);

          // Set protocol and split id
          currentSplitValue.id = newSplitId;
          currentSplitValue.protocolId = newProtocolId;

          await newSplitsRef.set(currentSplitValue, { merge: true });

          // Add exercises
          const promises = exercisesDocs.docs.map((exerciseDoc) => {
            const exerciseValue = exerciseDoc.data();
            exerciseValue.splitId = newSplitId;
            exerciseValue.protocolId = newProtocolId;
            exerciseValue.id = nanoid();

            const newExerciseRef = firebase
              .firestore()
              .collection("organizations")
              .doc(organizationId)
              .collection("protocolExercises")
              .doc(exerciseValue.id);

            return newExerciseRef.set(exerciseValue, { merge: true });
          });
          await Promise.all(promises);
        });

        return protocolValue;
      }
      return null;
    });

    return result;
  },
  duplicateSplit: async ({
    protocolId,
    split,
    count,
    showCopyAppend = true,
  }) => {
    consola.log(`data(): ProtocolData`);
    // REFACTOR: use batch() for the sets.
    const organizationId = getRecoil(organizationIdState);

    const newSplitId = nanoid();

    const result = await firebase.firestore().runTransaction(async () => {
      const currentSplitExercisesRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocolExercises")
        .where("splitId", "==", split.id);

      const exercisesDocs = await currentSplitExercisesRef.get();
      consola.info("++++++ READ -> ProtocolData: suplicateSplit");

      const newSplitRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocolSplits")
        .doc(newSplitId);

      const newSplitData = {
        ...split,
        id: newSplitId,
        protocolId: protocolId,
        name: showCopyAppend ? `${split.name} (copy)` : `${split.name}`,
        isDuplicate: true,
        duplicatedOn: new Date(),
        index: count,
      };
      consola.info("+++++ WRITE => ProtocolData: duplicateSplit");
      await newSplitRef.set(newSplitData, { merge: true });

      // Add exercises to the new split.
      const promises = exercisesDocs.docs.map((exerciseDoc) => {
        const exerciseValue = exerciseDoc.data();
        exerciseValue.splitId = newSplitId;
        exerciseValue.protocolId = protocolId;
        exerciseValue.id = nanoid();

        if (exerciseValue.type === "super_set") {
          exerciseValue.exercises.forEach((e) => {
            e.splitId = newSplitId;
            e.protocolId = protocolId;
          });
        }

        const newExerciseRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("protocolExercises")
          .doc(exerciseValue.id);

        return newExerciseRef.set(exerciseValue, { merge: true });
      });
      await Promise.all(promises);

      const protocolDocRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocols")
        .doc(protocolId);

      protocolDocRef.set(
        {
          splitCount: count + 1,
          updatedOn: new Date(),
        },
        { merge: true },
      );
    });

    await setProtocolUpdatedOn({ organizationId, protocolId: protocolId });

    return result;
  },
  addSplitFlat: async (values, isAdd = false) => {
    consola.log(`data(): ProtocolData`);
    const organizationId = getRecoil(organizationIdState);

    const batch = firebase.firestore().batch();

    const splitsRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolSplits")
      .where("protocolId", "==", values.protocolId);

    consola.info("++++++ READ -> ProtocolData: addSplitFlat");
    const data = await splitsRef.get();
    const docs = data.docs;

    const splitDocRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolSplits")
      .doc(values.id);

    consola.info("+++++ WRITE => ProtocolData: addSplitFlat");
    batch.set(
      splitDocRef,
      {
        index: values.index ? values.index : docs.length,
        ...values,
        isDuplicate: false,
      },
      { merge: true },
    );

    if (isAdd) {
      // Update protocol split count
      const protocolDocRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocols")
        .doc(values.protocolId);

      batch.set(
        protocolDocRef,
        {
          splitCount: docs.length + 1,
          updatedOn: new Date(),
        },
        { merge: true },
      );
    }
    consola.info("+++++ WRITE => ProtocolData: addSplitFlat");
    await batch.commit();
    await setProtocolUpdatedOn({
      organizationId,
      protocolId: values.protocolId,
    });
  },
  addSplitExerciseFlat: async (values) => {
    // REFACTOR: Add batch and transaction code here.
    consola.log(`data(): ProtocolData`);
    const organizationId = getRecoil(organizationIdState);
    const exercisesCollectionRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolExercises")
      .where("splitId", "==", values.splitId);

    consola.info("++++++ READ -> ProtocolData: addSplitExerciseFlat");
    const data = await exercisesCollectionRef.get();
    const docs = data.docs;

    const exerciseDocRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolExercises")
      .doc(values.id);
    consola.info("+++++ WRITE => ProtocolData: addSplitExerciseFlat");
    await exerciseDocRef.set(
      { index: values.index ? values.index : docs.length, ...values },
      { merge: true },
    );
    await setProtocolUpdatedOn({
      organizationId,
      protocolId: values.protocolId,
    });
    await setSplitDuplicateOffFlat({
      organizationId,
      protocolId: values.protocolId,
      splitId: values.splitId,
    });
  },
  deleteSplit: async (protocolId, splitId) => {
    consola.log(`data(): ProtocolData`);
    // REFACTOR: use batches.
    const organizationId = getRecoil(organizationIdState);
    const splitRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolSplits")
      .doc(splitId);

    const splitExerciseRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolExercises")
      .where("splitId", "==", splitId);

    // Delete the exercises
    // REFACTOR: use batches here.

    await firebase.firestore().runTransaction(async () => {
      consola.info("++++++ READ -> ProtocolData: deleteSplit");
      const exercises = await splitExerciseRef.get();
      const promises = exercises.docs.map((e) => {
        return e.ref.delete();
      });
      await Promise.all(promises);

      // Delete the split.
      await splitRef.delete();

      // Re-order the splits.
      const splitsRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocolSplits")
        .orderBy("index", "asc")
        .where("protocolId", "==", protocolId);

      const protocolDocRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocols")
        .doc(protocolId);

      const splitsDocs = await splitsRef.get();

      // update split count in protocol.
      // REFACTOR: use batches here also.
      consola.info("+++++ WRITE => ProtocolData: deleteSplit");
      protocolDocRef.set(
        {
          splitCount: splitsDocs.docs.length,
          updatedOn: new Date(),
        },
        { merge: true },
      );

      let index = 0;
      splitsDocs.docs.forEach((doc) => {
        doc.ref.set(
          {
            index: index,
          },
          { merge: true },
        );
        index += 1;
      });
    });
  },

  deleteSplitExercise: async (values) => {
    const organizationId = getRecoil(organizationIdState);
    const exerciseDocRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolExercises")
      .doc(values.id);

    await firebase.firestore().runTransaction(async () => {
      await exerciseDocRef.delete();
      await reorderExercises(values.protocolId, values.splitId);
      await setProtocolUpdatedOn({
        organizationId,
        protocolId: values.protocolId,
      });
    });
  },
  deleteProtocol: async (protocolId) => {
    const organizationId = getRecoil(organizationIdState);

    const result = await firebase.firestore().runTransaction(async () => {
      consola.info(
        "++++++ READ -> ProtocolData: deleteProtocol",
        protocolId,
        organizationId,
      );
      const protocolRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocols")
        .doc(protocolId);

      const protocol = await protocolRef.get();
      consola.info(
        "++++++ READ -> ProtocolData: deleteProtocol",
        protocol.data(),
      );

      const splitsCollectionRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocolSplits")
        .where("protocolId", "==", protocolId);

      const protocolExercisesRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("protocolExercises")
        .where("protocolId", "==", protocolId);

      const splits = await splitsCollectionRef.get();
      const exercises = await protocolExercisesRef.get();

      const promises = splits.docs.map((split) => {
        return split.ref.delete();
      });
      await Promise.all(promises);

      const promises2 = exercises.docs.map((exercise) => {
        return exercise.ref.delete();
      });
      await Promise.all(promises2);

      await protocolRef.delete();
    });
    return result;
  },
  setExerciseOrder: async (protocolId, list) => {
    await setOrderOfExercises(protocolId, list);
  },
  setSplitOrder: async (protocolId, list) => {
    await setOrderOfSplits(protocolId, list);
  },
};

export default ProtocolData;
