import { useRef, useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import consola from "consola";
import firebase from "firebase/compat/app";
import _ from "underscore";
import clientExerciseReportsState from "../atoms/clientExerciseReportsAtom";

function useClientExerciseReports({
  uid,
  organizationId,
  start,
  end,
  exercise = undefined,
}) {
  const subscription = useRef(null);

  const [data, setData] = useState(null);
  const setClientExerciseReports = useSetRecoilState(
    clientExerciseReportsState,
  );

  useEffect(() => {
    consola.info(
      "++++++ EFFECT -> useClientExerciseReports",
      uid,
      organizationId,
      start,
      end,
      exercise,
    );

    if (subscription.current) {
      subscription.current();
    }

    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("clients")
      .doc(uid)
      .collection("reportExercise")
      .where("createdOn", ">=", start)
      .where("createdOn", "<=", end)
      .where("status", "==", "complete")
      .orderBy("createdOn");

    subscription.current = ref.onSnapshot((snapshot) => {
      consola.info("++++++ SNAPSHOT -> useClientExerciseReports");
      const exercises = snapshot.docs.map((rd) => {
        const data = rd.data();
        let completedOn = null;
        // if data.completedOn is string convert to date
        if (data && data.completedOn) {
          if (typeof data.completedOn === "string") {
            completedOn = new Date(data.completedOn);
          } else {
            completedOn = data.completedOn.toDate();
          }
        }
        const exercises = data.reportData.exercises.map((e) => {
          return {
            ...e,
            createdOn: data.createdOn.toDate(),
            completedOn: completedOn ? completedOn : null,
          };
        });
        return exercises;
      });

      const flattened = _.flatten(exercises);

      // roll up any supersets.
      let rolledUp = [];
      flattened.forEach((fe) => {
        if (fe.type === "super_set") {
          if (fe.sets && fe.sets.length > 0) {
            rolledUp = rolledUp.concat(
              fe.sets[0].exercises.map((e2) => {
                return {
                  ...e2,
                  completedSets: fe.completedSets,
                  completedReps: fe.completedReps,
                  sets: fe.sets,
                  reps: fe.reps,
                };
              }),
            );
          }
        } else {
          rolledUp.push(fe);
        }
      });

      let filtered = rolledUp;
      if (exercise) {
        filtered = _.filter(rolledUp, (e) => {
          return e.exercise === exercise;
        });
      }

      // set filtered to state
      setData(filtered);
      setClientExerciseReports(filtered);
    });
  }, [uid, organizationId, exercise]);

  return { exerciseData: data };
}

export default useClientExerciseReports;
