import React from "react";
import { DialogTitle } from "@mui/material";

export default React.memo(({ isEdit, isRestSplit }) => {
  const typeString = isRestSplit ? "Rest Day" : "Workout";

  return (
    <DialogTitle>
      {isEdit ? `Edit ${typeString}` : `Add ${typeString}`}
    </DialogTitle>
  );
});
