import React from "react";
import { Box, Typography, Button, Stack } from "@mui/material";
import Image from "react-cool-img";
import colors from "../../themes/Colors";

import completion from "../../assets/noun-completed-2350074-FFFFFF.svg";

function NoReportsView({ type = "active", onSeeProcessed }) {
  const style = {
    activeStyle: {
      backgroundColor: colors.primaryBlue,
      padding: 50,
      borderRadius: 24,
    },
    inactiveStyle: {
      backgroundColor: colors.primaryRed,
      padding: 50,
      borderRadius: 24,
    },
  };

  return (
    <Box data-testid="NoReportsView" style={{ margin: 24 }}>
      <Box style={type === "active" ? style.activeStyle : style.inactiveStyle}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Image src={completion} alt="completion" height={200} width={200} />
          <Typography
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: 24,
              maxWidth: 450,
            }}
          >
            {type === "active"
              ? "Yay! All reports are processed. Take a break, you've earned it."
              : "There's work to do. Check your active reports."}
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: 24,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack style={{ maxWidth: 500 }}>
          <Typography style={{ textAlign: "center" }}>
            You can see the history of your processed reports by clicking the
            &quot;See recently processed reports?&quot; button below.
          </Typography>
          <Button
            data-testid="NoReportsView-Button"
            style={{ marginTop: 8, fontSize: 18, borderRadius: 12 }}
            variant="contained"
            onClick={() => {
              if (onSeeProcessed) {
                onSeeProcessed(type);
              }
            }}
          >
            {type === "active"
              ? "See recently processed reports?"
              : "See active reports?"}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default NoReportsView;
