import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";

export default React.memo(({ exercise, chartClicked } = {}) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: exercise.completed ? "green" : "red",
          }}
        >
          {exercise.completed ? `completed` : `not completed`}
        </Typography>
      </Box>
      {exercise.sets.map((set, index) => {
        const exercises = set.exercises.map((exercise) => {
          return (
            <Box key={exercise.id} style={{ marginBottom: 4 }}>
              <Grid container>
                <Grid item>
                  <Typography style={{ fontSize: 18 }}>
                    {exercise.exerciseName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    marginLeft: 12,
                    width: 100,
                  }}
                >
                  <TimelineIcon
                    onClick={() => {
                      if (chartClicked) {
                        chartClicked(exercise);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          );
        });
        return (
          <Box key={index} style={{ marginBottom: 12 }}>
            <Typography style={{ fontSize: 20, fontWeight: "500" }}>{`Set ${
              index + 1
            }`}</Typography>
            {exercises}
          </Box>
        );
      })}
    </Box>
  );
});
