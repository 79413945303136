import React from "react";
import { Box, Typography, Grid, Divider, Card } from "@mui/material";
import _ from "underscore";
import moment from "moment";
import ClientData from "../../../../data/ClientData";
import PhotoGroup from "../../../../components/organisms/PhotoGroup";

function StartingPhotos({
  startingGroup,
  makeStartingGroup,
  deletePhotoGroup,
}) {
  if (startingGroup && startingGroup.length > 0) {
    return (
      <Grid container spacking={1}>
        {startingGroup.map((group, index) => {
          return (
            <Grid key={index} item>
              <PhotoGroup
                group={group}
                onMakeStartingGroup={makeStartingGroup}
                onDeletePhotoGroup={deletePhotoGroup}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    return (
      <Box
        style={{
          height: 200,
          backgroundColor: "#F6F7FB",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          style={{ fontSize: 16, textAlign: "center", fontWeight: "bold" }}
        >
          No Photos
        </Typography>
      </Box>
    );
  }
}

function LatestPhotos({
  groups,
  showPrevious,
  makeStartingGroup,
  deletePhotoGroup,
  noPhotosMessage,
}) {
  if (groups && groups.length > 0) {
    return (
      <Box style={{ maxHeight: 800, overflow: "auto" }}>
        <Grid container spacing={1}>
          {groups.map((group, index) => {
            return (
              <Grid key={index} item>
                <PhotoGroup
                  showButton
                  key={index}
                  group={group}
                  onMakeStartingGroup={makeStartingGroup}
                  onDeletePhotoGroup={deletePhotoGroup}
                />
              </Grid>
            );
          })}

          {!showPrevious && (
            <Box
              style={{
                justifyContent: "center",
                alignItems: "center",
                height: 155 * (16 / 9),
              }}
            />
          )}
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box
        style={{
          height: 200,
          backgroundColor: "#F6F7FB",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 8,
        }}
      >
        <Typography
          style={{ fontSize: 16, textAlign: "center", fontWeight: "bold" }}
        >
          {noPhotosMessage ? noPhotosMessage : "No Photos"}
        </Typography>
      </Box>
    );
  }
}

function PreviousPhotos({
  showPrevious,
  previousGroups,
  makeStartingGroup,
  deletePhotoGroup,
  noPhotosMessage,
}) {
  if (!showPrevious) {
    return null;
  }
  if (previousGroups && previousGroups.length > 0) {
    return (
      <Grid container spacing={1}>
        {previousGroups.map((group, index) => {
          return (
            <Grid key={index} item>
              <Card variant="outlined" style={{ padding: 8 }}>
                <PhotoGroup
                  showButton
                  group={group}
                  onMakeStartingGroup={makeStartingGroup}
                  onDeletePhotoGroup={deletePhotoGroup}
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    return (
      <Box
        style={{
          height: 200,
          width: "100%",
          backgroundColor: "#F6F7FB",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 8,
        }}
      >
        <Typography
          style={{ fontSize: 16, textAlign: "center", fontWeight: "bold" }}
        >
          {noPhotosMessage ? noPhotosMessage : "No Photos"}
        </Typography>
      </Box>
    );
  }
}

const PhotoSummary = ({
  client,
  update,
  dateRange,
  showPrevious,
  noPhotosMessage,
  photoGroupTitle,
  makeStartingGroup,
  deletePhotoGroup,
}) => {
  const [groups, setGroups] = React.useState();
  const [previousGroups, setPreviousGroups] = React.useState();
  const [startingGroup, setStartingGroup] = React.useState();

  function makeGroupsFromPhotos(photos, dateRange = undefined) {
    let keys = _.keys(photos);
    keys = _.filter(keys, (k) => {
      return k !== "undefined";
    });

    const groups = [];
    keys.forEach((key) => {
      const photoArray = [];
      if (dateRange) {
        const groupDate = moment(photos[key].metadata.createdOn)
          .startOf("day")
          .toDate();

        if (groupDate >= dateRange.start && groupDate <= dateRange.end) {
          _.each(photos[key].metadata.configuration, (c) => {
            photoArray.push(photos[key].photos[c.id]);
          });
          groups.push({
            groupId: key,
            createdOn: moment(photos[key].metadata.createdOn),
            photos: photoArray,
          });
        }
      } else {
        _.each(photos[key].metadata.configuration, (c) => {
          photoArray.push(photos[key].photos[c.id]);
        });

        groups.push({
          groupId: key,
          createdOn: moment(photos[key].metadata.createdOn),
          photos: photoArray,
        });
      }
    });
    return groups;
  }

  React.useEffect(() => {
    async function getData(uid) {
      // add 7 days to dateRange.end to get the next week
      let newDateRange = undefined;
      if (dateRange) {
        const end = moment(dateRange.end).add(7, "days");
        newDateRange = {
          start: dateRange.start,
          end: end.toDate(),
        };
      }
      const photos = await ClientData.getProgressPhotos(
        uid,
        newDateRange,
        30,
        365,
      );
      const localGroups = makeGroupsFromPhotos(photos, newDateRange);
      setGroups(localGroups);
      const startingPhotos = await ClientData.getStartingProgressPhotos(uid);
      const localStartingGroup = makeGroupsFromPhotos(startingPhotos);

      setStartingGroup(localStartingGroup);

      if (dateRange && showPrevious) {
        const start = dateRange.start;

        const prevStart = moment(start).subtract(7, "days");
        const prevEnd = moment(start).endOf("day");
        const prevDateRange = {
          start: prevStart.toDate(),
          end: prevEnd.toDate(),
        };

        const previous = await ClientData.getProgressPhotos(
          uid,
          prevDateRange,
          30,
        );
        const localPrevGroups = makeGroupsFromPhotos(previous, prevDateRange);
        setPreviousGroups(localPrevGroups);
      }
    }
    getData(client.uid);
  }, [update, client, dateRange]); // eslint-disable-line

  if (showPrevious) {
    return (
      <Box>
        <Box>
          <Typography style={{ fontSize: 20, fontWeight: "500" }}>
            Starting Progress Photos
          </Typography>
          <StartingPhotos
            startingGroup={startingGroup}
            makeStartingGroup={makeStartingGroup}
            deletePhotoGroup={deletePhotoGroup}
          />
          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        </Box>
        <Box>
          <Typography style={{ fontSize: 20, fontWeight: "500" }}>
            {photoGroupTitle ? photoGroupTitle : "Latest Progress Photos"}
          </Typography>
          <LatestPhotos
            groups={groups}
            showPrevious={showPrevious}
            makeStartingGroup={makeStartingGroup}
            deletePhotoGroup={deletePhotoGroup}
            noPhotosMessage={noPhotosMessage}
          />
          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        </Box>

        <Box>
          <Typography style={{ fontSize: 20, fontWeight: "500" }}>
            Previous Weeks
          </Typography>
          <PreviousPhotos
            showPrevious={showPrevious}
            previousGroups={previousGroups}
            makeStartingGroup={makeStartingGroup}
            deletePhotoGroup={deletePhotoGroup}
            noPhotosMessage={noPhotosMessage}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            style={{ fontSize: 20, fontWeight: "500", textAlign: "center" }}
          >
            Starting Progress Photos
          </Typography>
          <StartingPhotos
            startingGroup={startingGroup}
            makeStartingGroup={makeStartingGroup}
            deletePhotoGroup={deletePhotoGroup}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            style={{ fontSize: 20, fontWeight: "500", textAlign: "center" }}
          >
            {photoGroupTitle ? photoGroupTitle : "Latest Progress Photos"}
          </Typography>
          <LatestPhotos
            groups={groups}
            showPrevious={showPrevious}
            makeStartingGroup={makeStartingGroup}
            deletePhotoGroup={deletePhotoGroup}
            noPhotosMessage={noPhotosMessage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PhotoSummary;
