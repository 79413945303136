import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
} from "@mui/material";
import MDEditor from "@uiw/react-md-editor";

import BlueButton from "../../../../components/BlueButton";

const FileEditDialog = ({ file, open = false, onDialogClosed = null }) => {
  const [dialogOpen, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");

  React.useEffect(() => {
    setOpen(open);
  }, [open]); // eslint-disable-line

  React.useEffect(() => {
    if (file) {
      setName(file.name);
      setDescription(file.description || "");
    }
  }, [file]);

  if (!dialogOpen) {
    return null;
  }
  if (!file) {
    return null;
  }

  return (
    <Dialog
      key="FileEditDialog"
      maxWidth="lg"
      open={dialogOpen}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>{"Edit File"}</DialogTitle>

      <DialogContent>
        <Stack
          spacing={1}
          style={{
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Typography>Friendly Name:</Typography>
          <TextField
            value={name}
            style={{ width: "100%" }}
            variant="outlined"
            onChange={(event) => {
              setName(event.target.value);
            }}
          ></TextField>
        </Stack>

        <Stack
          spacing={1}
          style={{
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Typography>Description:</Typography>
          <div data-color-mode="light">
            <MDEditor
              preview="edit"
              style={{ width: 800 }}
              placeholder="Enter description for this file"
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            if (onDialogClosed) {
              onDialogClosed(null, null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            setOpen(false);
            if (onDialogClosed) {
              onDialogClosed(
                {
                  name,
                  description,
                },
                file,
              );
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default FileEditDialog;
