import React from "react";
import { Box, ImageList } from "@mui/material";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import ProtocolData from "../../../../data/ProtocolData";

import SingleExerciseTile from "./SingleExerciseTile";
import SuperSetExerciseTile from "./SuperSetExerciseTile";
import SplitExerciseDialog from "./SplitExerciseDialog";
import SplitExerciseTable from "./SplitExerciseTable";
import { ViewMode } from "../../../../data/GlobalData";

import useSplitExercises from "../../../../hooks/useSplitExercises";

const ExerciseTile = ({ exercise, onExerciseEdit }) => {
  if (exercise.data().type === "normal_set") {
    return (
      <SingleExerciseTile
        key={exercise.data().id}
        exercise={exercise.data()}
        onExerciseEdit={(exercise) => {
          if (onExerciseEdit) {
            onExerciseEdit(exercise);
          }
        }}
        onExerciseDelete={(exercise) => {
          confirmAlert({
            title: `Delete the exercise?`,
            message: "This cannot be undone. Continue with the delete?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  ProtocolData.deleteSplitExercise({
                    id: exercise.id,
                    protocolId: exercise.protocolId,
                    splitId: exercise.splitId,
                  });
                },
              },
              {
                label: "No",
                onClick: () => {},
              },
            ],
          });
        }}
      />
    );
  } else {
    return (
      <SuperSetExerciseTile
        key={exercise.data().id}
        exercise={exercise.data()}
        onExerciseEdit={(exercise) => {
          //
          if (onExerciseEdit) {
            onExerciseEdit(exercise);
          }
        }}
        onExerciseDelete={(exercise) => {
          // Delete the exercise.
          confirmAlert({
            title: `Delete the exercise?`,
            message: "This cannot be undone. Continue with the delete?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  ProtocolData.deleteSplitExercise({
                    id: exercise.id,
                    protocolId: exercise.protocolId,
                    splitId: exercise.splitId,
                  });
                },
              },
              {
                label: "No",
                onClick: () => {},
              },
            ],
          });
        }}
      />
    );
  }
};

const SplitExerciseList = ({ split, mode, labels }) => {
  const { exercises } = useSplitExercises({
    protocolId: split.protocolId,
    splitId: split.id,
  });

  const [selectedExercise, setSelectedExercise] = React.useState(null);
  const [splitExerciseDialogOpen, setSplitExerciseDialogOpen] =
    React.useState(false);

  if (mode === ViewMode.list) {
    return (
      <>
        <SplitExerciseTable
          exercises={exercises}
          labels={labels}
          onEdit={(exercise) => {
            setSelectedExercise(exercise);
            setSplitExerciseDialogOpen(true);
          }}
          onDelete={(exercise) => {
            confirmAlert({
              title: `Delete the exercise?`,
              message: "This cannot be undone. Continue with the delete?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    ProtocolData.deleteSplitExercise({
                      id: exercise.id,
                      protocolId: exercise.protocolId,
                      splitId: exercise.splitId,
                    });
                  },
                },
                {
                  label: "No",
                  onClick: () => {},
                },
              ],
            });
          }}
          onFieldClick={(data) => {
            if (data) {
              const newValue = _.cloneDeep(data.value);
              newValue[data.field] = data.fieldValue;
              ProtocolData.addSplitExerciseFlat(newValue);
              setSelectedExercise(null);
              setSplitExerciseDialogOpen(false);
            }
          }}
          onSuperSetFieldClick={(data) => {
            if (data) {
              const newValue = _.cloneDeep(data.value);
              newValue.exercises[data.index][data.field] = data.fieldValue;

              ProtocolData.addSplitExerciseFlat(newValue);
              setSelectedExercise(null);
              setSplitExerciseDialogOpen(false);
            }
          }}
        />

        {splitExerciseDialogOpen && (
          <SplitExerciseDialog
            id={selectedExercise ? selectedExercise.id : null}
            selectedExercise={selectedExercise}
            splitId={selectedExercise ? selectedExercise.splitId : null}
            protocolId={selectedExercise ? selectedExercise.protocolId : null}
            open={splitExerciseDialogOpen}
            onDialogClosed={(values) => {
              if (values) {
                ProtocolData.addSplitExerciseFlat(values);
              }
              setSelectedExercise(null);
              setSplitExerciseDialogOpen(false);
            }}
          />
        )}
      </>
    );
  }

  return (
    <Box>
      <ImageList cols={4} gap={1}>
        {exercises &&
          exercises.map((e) => {
            return (
              <ExerciseTile
                key={e.id}
                exercise={e}
                onExerciseEdit={(exercise) => {
                  setSelectedExercise(exercise);
                  setSplitExerciseDialogOpen(true);
                }}
              />
            );
          })}
      </ImageList>

      <SplitExerciseDialog
        id={selectedExercise ? selectedExercise.id : null}
        selectedExercise={selectedExercise}
        splitId={selectedExercise ? selectedExercise.splitId : null}
        protocolId={selectedExercise ? selectedExercise.protocolId : null}
        open={splitExerciseDialogOpen}
        onDialogClosed={(values) => {
          if (values) {
            ProtocolData.addSplitExerciseFlat(values);
          }
          setSelectedExercise(null);
          setSplitExerciseDialogOpen(false);
        }}
      />
    </Box>
  );
};

export default SplitExerciseList;
