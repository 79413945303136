import { styled } from "@mui/system";
import Button from "@mui/material/Button";

import colors from "../themes/Colors";

const MenuButton = styled(Button)(() => ({
  color: "black",
  fontFamily: "Lato",
  backgroundColor: colors.backgroundRed,
  background: colors.backgroundRed,
  fontWeight: "600",
  fontSize: "16",
  justifyContent: "left",
  borderRadius: 10,
  "&:hover": {
    backgroundColor: colors.backgroundBlue,
  },
}));

export default MenuButton;
