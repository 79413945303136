import { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { consola } from "consola";

import UserData from "../data/UserData";

const useSharedVideos = ({ showAll = false, coachUID, organizationId }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingClients, setLoadingClients] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [clients, setClients] = useState([]);
  const [count, setCount] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const clientSubscription = useRef();

  useEffect(() => {
    if (!organizationId) {
      return;
    }
    const getClients = () => {
      setLoadingClients(true);
      const reportRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients")
        .where("coaches", "array-contains", coachUID);

      if (clientSubscription.current) {
        clientSubscription.current();
      }
      clientSubscription.current = reportRef.onSnapshot((querySnapshot) => {
        consola.info("++++++ SNAPSHOT -> useSharedVideos: clientSubscription");
        setClients(querySnapshot.docs.map((doc) => doc.data()));
        setLoadingClients(false);
      });
    };
    getClients();

    return () => {
      clientSubscription.current();
    };
  }, [coachUID, organizationId]);

  useEffect(() => {
    const fetchVideos = async () => {
      if (!loading) {
        setIsRefreshing(true);
      }
      if (clients.length === 0) {
        setLoading(false);
        setIsRefreshing(false);
        return;
      }

      const path = `organizations/${organizationId}/sharedVideos`;
      const sharedVideosRef = firebase.firestore().collection(path);
      if (showAll) {
        consola.info("++++++ READ -> useSharedVideos showAll");
        return sharedVideosRef.orderBy("createdOn", "desc").get();
      }
      consola.info("++++++ READ -> useSharedVideos fetchVideos");

      const sharedVideoDocs = await sharedVideosRef
        .where("processed", "!=", true)
        .orderBy("processed", "desc")
        .orderBy("createdOn", "desc")
        .get();

      let videoCount = 0;
      const videosData = sharedVideoDocs.docs.map((doc) => {
        videoCount++;
        return doc.data();
      });

      // group videos by date within 1 hour of each other and by client
      const groupedVideos = videosData.reduce((acc, video) => {
        const videoDate = video.createdOn.toDate();

        const date = videoDate;
        const dateKey = date.toDateString();
        const hourKey = date.getHours();
        const clientKey = video.clientId;

        if (!acc[dateKey]) {
          acc[dateKey] = {};
        }
        if (!acc[dateKey][hourKey]) {
          acc[dateKey][hourKey] = {};
        }
        if (!acc[dateKey][hourKey][clientKey]) {
          acc[dateKey][hourKey][clientKey] = [];
        }
        acc[dateKey][hourKey][clientKey].push(video);

        return acc;
      }, {});

      // flatten grouped videos
      const flattenedVideos = Object.keys(groupedVideos).map((dateKey) => {
        const hours = Object.keys(groupedVideos[dateKey]).map((hourKey) => {
          const clients = Object.keys(groupedVideos[dateKey][hourKey]).map(
            (clientKey) => {
              return {
                clientUID: clientKey,
                videos: groupedVideos[dateKey][hourKey][clientKey],
              };
            },
          );
          return {
            hour: hourKey,
            clients,
          };
        });
        return {
          date: dateKey,
          hours,
        };
      });

      // order flattened videos by date ascending
      const orderedVideos = flattenedVideos.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return aDate - bDate;
      });

      setCount(videoCount);
      setLoading(false);
      setIsRefreshing(false);
      setVideos(orderedVideos);
    };
    fetchVideos();
  }, [clients, lastUpdated, organizationId, showAll]);

  const deleteSharedVideo = async ({ id, uid }) => {
    if (!id || !uid || !organizationId) {
      return;
    }
    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("sharedVideos")
      .doc(id);

    // Get the file location and delete from shared storage.
    const sharedFileRef = firebase
      .storage()
      .ref(`/${organizationId}/shared_videos/${uid}/${id}.mp4`);
    // delete the file
    await sharedFileRef.delete();
    await ref.delete();
  };

  const setVideoProcessedState = async ({
    id,
    feedback,
    uid,
    coachUID,
    processed = true,
  }) => {
    if (!uid || !id || !coachUID) {
      return;
    }

    const coachUser = await UserData.getUserData(coachUID);
    if (!coachUser) {
      return;
    }

    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("sharedVideos")
      .doc(id);

    consola.info("+++++ WRITE => useSharedVideos: setVideoProcessedState");
    await ref.set(
      {
        processed,
        processedOn: processed ? new Date() : null,
        feedback: feedback || null,
        processedBy: processed
          ? {
              uid: coachUser.uid,
              firstName: coachUser.firstName,
              lastName: coachUser.lastName,
            }
          : null,
      },
      { merge: true },
    );
  };

  return {
    videos,
    loading,
    loadingClients,
    isRefreshing,
    count,
    deleteSharedVideo,
    setVideoProcessedState,
    setLastUpdated,
  };
};

export default useSharedVideos;
