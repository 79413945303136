import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import { useRecoilValue } from "recoil";
import organizationIdState from "../atoms/organizationIdAtom";
import { consola } from "consola";
// import { filter } from "underscore";
// import { indexOf } from "underscore";

const useHistory = ({
  uid,
  historyCollection,
  limit = 5,
  name = undefined,
}) => {
  const [history, setHistory] = React.useState([]);
  const subscription = React.useRef(null);
  const organizationId = useRecoilValue(organizationIdState);

  useEffect(() => {
    if (subscription.current) {
      subscription.current();
    }

    if (!historyCollection) {
      return;
    }

    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection(historyCollection)
      .where("uid", "==", uid)
      .limit(limit)
      .orderBy("changedOn", "desc");
    subscription.current = ref.onSnapshot((snapshot) => {
      consola.info("++++++ SNAPSHOT -> useHistory");
      const dataSorted = snapshot.docs.map((doc) => {
        const data = doc.data();
        let index = 0;

        if (name) {
          const filtered = data;
          filtered.changes.forEach((c) => {
            if (c.name !== name || c.differences.active !== undefined) {
              index = filtered.changes.indexOf(c);
              filtered.changes.splice(index);
            }
            const differences = {};
            Object.keys(c.differences)
              .sort()
              .forEach((key) => {
                differences[key] = c.differences[key];
              });
            c.differences = differences;
          });
          // filtered.changes.forEach((c) => {
          //   const differences = {};
          //   Object.keys(c.differences)
          //     .sort()
          //     .forEach((key) => {
          //       differences[key] = c.differences[key];
          //     });
          //   c.differences = differences;
          // });
          return filtered;
        }

        // sort data.changes.differences by key alphabetically
        else {
          data.changes.forEach((c) => {
            if (c.differences.active !== undefined) {
              index = data.changes.indexOf(c);
              data.changes.splice(index);
            }
            // if (data.changes.length > 1) {
            //   index = data.changes.indexOf(c);
            //   data.changes.splice(index);
            // }
            const differences = {};
            Object.keys(c.differences)
              .sort()
              .forEach((key) => {
                differences[key] = c.differences[key];
              });
            c.differences = differences;
          });
          return data;
        }
      });

      setHistory(dataSorted);
    });
  }, [organizationId, uid, historyCollection]);
  return { history };
};

export default useHistory;
