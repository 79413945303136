import React from "react";
import { Button, Typography, Grid, Stack } from "@mui/material";
import { useRecoilValue } from "recoil";
import Bugsnag from "@bugsnag/js";
import { remoteConfig } from "../../../../data/FirebaseApp";
import { getBoolean } from "firebase/remote-config";

import useRecipes from "../../../../hooks/useRecipes";
import organizationIdSelector from "../../../../atoms/organizationIdSelector";
import fileUploadLimitSelector from "../../../../atoms/fileUploadLimitSelector";
import RecipesDataGrid from "./RecipesDataGrid";
import RecipeDialog from "./RecipeDialog";
import RecipeGeneratorDialog from "../clients/RecipeGeneratorDialog";
import useEvents from "../../../../hooks/useEvents";

const EditRecipes = ({ onShowDetails }) => {
  const organizationId = useRecoilValue(organizationIdSelector);
  const fileUploadLimit = useRecoilValue(fileUploadLimitSelector);
  const events = useEvents();
  const {
    recipes,
    ingredients,
    editRecipe,
    archiveRecipe,
    editIngredient,
    uploadImage,
    addRecipeFromObject,
  } = useRecipes({
    organizationId,
  });

  const isRecipeGeneratorEnabled = getBoolean(
    remoteConfig,
    "showRecipeGenerator",
  );

  const [showDialog, setShowDialog] = React.useState(false);
  const [showMacroSelectDialog, setShowMacroSelectDialog] =
    React.useState(false);

  const [selectedRecipe, setSelectedRecipe] = React.useState(null);

  return (
    <>
      <Grid container style={{ marginTop: 0 }} justifyContent={"space-between"}>
        <Grid item xs={8}>
          <Typography variant="h6">Recipes</Typography>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: "normal",
              marginBottom: 12,
            }}
          >
            Enter recipe title, ingredients, and instructions here. These
            recipes will be included in meal plans for your clients.
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Stack direction={"row"} spacing={3} justifyContent={"flex-end"}>
            {isRecipeGeneratorEnabled && (
              <Button
                style={{ marginTop: 12 }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowMacroSelectDialog(true);
                  events.logButtonClick({
                    buttonName: "Generate Recipe",
                    pageName: "Nutrition",
                    tabName: "Recipes",
                  });
                }}
              >
                Generate Recipe
              </Button>
            )}
            <Button
              style={{ marginTop: 12 }}
              variant="contained"
              color="primary"
              onClick={() => {
                setSelectedRecipe(null);
                setShowDialog(true);
                events.logButtonClick({
                  buttonName: "Add New Recipe",
                  pageName: "Nutrition",
                  tabName: "Recipes",
                });
              }}
            >
              Add Recipe
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <RecipesDataGrid
        recipes={recipes}
        onClick={(recipe) => {
          if (onShowDetails) {
            onShowDetails(recipe);
          }
          setSelectedRecipe(recipe);
        }}
        onEdit={(recipe) => {
          setSelectedRecipe(recipe);
          setShowDialog(true);
          events.logButtonClick({
            buttonName: "Edit Recipe",
            pageName: "Nutrition",
            tabName: "Recipes",
          });
        }}
        onDelete={(recipe) => {
          confirm({
            title: "Archive this recipe?",
            description: "This will archive the recipe. Continue?",
          }).then(async () => {
            await archiveRecipe(recipe.id);
            events.logButtonClick({
              buttonName: "Delete Recipe",
              pageName: "Nutrition",
              tabName: "Recipes",
            });
          });
        }}
      />

      {showMacroSelectDialog === true && (
        <RecipeGeneratorDialog
          recipes={recipes}
          defaultMacros={{
            proteins: 0,
            carbs: 0,
            fats: 0,
          }}
          onDialogClosed={(recipe) => {
            if (recipe) {
              addRecipeFromObject(organizationId, recipe);
            }
            setShowMacroSelectDialog(false);
          }}
        />
      )}

      {showDialog && (
        <RecipeDialog
          open={showDialog}
          recipe={selectedRecipe}
          ingredientsDB={ingredients}
          editIngredient={editIngredient}
          onClose={(recipe, imageURL) => {
            if (recipe) {
              editRecipe(recipe).then(() => {
                if (imageURL && imageURL.size < fileUploadLimit) {
                  uploadImage({ image: imageURL, recipe });
                } else {
                  Bugsnag.notify("image too large");
                }
              });
            }
            setShowDialog(false);
          }}
        />
      )}
    </>
  );
};

export default EditRecipes;
