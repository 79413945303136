import React from "react";
import { useRecoilValue } from "recoil";
import {
  Box,
  Typography,
  Stack,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider,
} from "@mui/material";
import moment from "moment";
import { consola } from "consola";
import organizationIdSelector from "../../atoms/organizationIdSelector";
import trialDaysSelector from "../../atoms/trialDaysSelector";
import colors from "../../themes/Colors";
import { AccountType } from "../../data/Types";
import { getStripeProducts } from "../../data/StripeData";

import StripeFormWrapper from "./StripeForm";

function Prices({ products, trial = false }) {
  if (!products) {
    return null;
  }
  const totalPrice = products.prices.reduce((acc, price) => {
    return acc + (price.unit_amount * price.quantity) / 100;
  }, 0);

  // let planMessage = "You are subscribed to the ";
  let totalMessage = "Your monthly charge is ";
  if (trial) {
    // planMessage = "You will be subscribed to the ";
    totalMessage = "Your monthly charge will be ";
  }

  return (
    <Stack>
      <Table style={{ width: 400 }}>
        <TableBody>
          <TableRow>
            <TableCell>Base Plan</TableCell>
            <TableCell>
              ${(products.prices[0].unit_amount / 100).toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Additional Coaches</TableCell>
            <TableCell>
              ${(products.prices[1].unit_amount / 100).toFixed(2)} x{" "}
              {products.prices[1].quantity}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Clients</TableCell>
            <TableCell>
              ${(products.prices[2].unit_amount / 100).toFixed(2)} x{" "}
              {products.prices[2].quantity}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Typography style={{ fontSize: 20, marginTop: 20 }}>
        {totalMessage} ${totalPrice.toFixed(2)} per month.
      </Typography>
    </Stack>
  );
}

function PlanName({ account, trial = false }) {
  const organizationId = useRecoilValue(organizationIdSelector);

  const [products, setProducts] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    getStripeProducts({ organizationId })
      .then((data) => {
        setProducts(data);
        setLoading(false);
      })
      .catch((error) => {
        consola.log("error", error);
        setLoading(false);
      });
  }, [account]);

  if (account.stripeSubscriptionId) {
    return (
      <Stack style={{ marginTop: 12, marginBottom: 12 }}>
        <Typography
          style={{ fontSize: 24, fontWeight: "bold", color: colors.primaryRed }}
        >
          {trial ? "  Subscription" : "Subscribed"}
        </Typography>

        {loading && (
          <Typography style={{ fontSize: 18, marginTop: 12 }}>
            Loading plan...
          </Typography>
        )}

        <Prices products={products} trial={trial} />
      </Stack>
    );
  }
  return (
    <Stack style={{ marginTop: 12, marginBottom: 12 }}>
      <Typography style={{ fontSize: 20 }}>No Plan Found</Typography>
    </Stack>
  );
}

function SpecialPricingPlan() {
  const organizationId = useRecoilValue(organizationIdSelector);
  const mailLink = `mailto:support@mighty45.com?subject=Support: subscription help. (${organizationId})`;

  return (
    <Stack style={{ marginTop: 12, marginBottom: 12 }}>
      <Typography
        style={{ fontSize: 24, fontWeight: "bold", color: colors.primaryRed }}
      >
        Special Pricing Plan
      </Typography>
      <Typography style={{ fontSize: 20 }}>
        You have been provided special discount pricing.{" "}
        <a target="_blank" href={mailLink} rel="noreferrer">
          Contact us
        </a>{" "}
        for pricing details and to make adjustments.
      </Typography>
    </Stack>
  );
}

function TrialDaysLeft({ account }) {
  const [expireDate, setExpireDate] = React.useState(null);
  const accountData = useRecoilValue(trialDaysSelector);

  React.useEffect(() => {
    const dateStart = new Date(account.dateCreated.toDate());
    setExpireDate(
      moment(dateStart).add(accountData.trialDays, "days").toDate(),
    );
  }, [account, accountData]);

  return (
    <Stack style={{ marginTop: 12, marginBottom: 12 }}>
      <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
        You have {accountData.daysLeft} days left in your trial. It will expire
        on {expireDate?.toLocaleDateString()}.
      </Typography>
    </Stack>
  );
}

function PricingPage({ organizationData }) {
  const accountData = useRecoilValue(trialDaysSelector);
  const organizationId = useRecoilValue(organizationIdSelector);
  const mailLink = `mailto:support@mighty45.com?subject=Support: subscription help. (${organizationId})`;

  if (!organizationData) {
    return null;
  }

  const { account } = organizationData;

  if (
    account.accountType &&
    account.accountType === AccountType.special_pricing
  ) {
    return (
      <Box>
        <Stack>
          <Typography variant="h4">Subscription Plan</Typography>
          <SpecialPricingPlan />
        </Stack>
      </Box>
    );
  }

  if (
    account.accountType &&
    account.stripeCustomerId &&
    account.accountType === AccountType.stripe
  ) {
    return (
      <Box>
        <Stack>
          <Typography variant="h4">Subscription Plan</Typography>
          <PlanName account={account} />
          <Typography style={{ fontSize: 20 }}>
            If you have any questions, or to cancel or pause your account,
            please{" "}
            <a target="_blank" href={mailLink} rel="noreferrer">
              contact us
            </a>
            .
          </Typography>
        </Stack>
      </Box>
    );
  }

  if (
    !account ||
    !account.accountType ||
    (account.accountType && account.accountType === AccountType.trial)
  ) {
    if (accountData.expired) {
      return (
        <Stack style={{ marginTop: 12, marginBottom: 12 }}>
          <Typography variant="h4">Mighty45 Trial Over</Typography>
          <Typography style={{ fontSize: 20 }}>
            Your trial has expired. Please{" "}
            <a target="_blank" href={mailLink} rel="noreferrer">
              contact us
            </a>{" "}
            to continue using Mighty45.
          </Typography>
        </Stack>
      );
    }

    return (
      <Box>
        <Stack>
          <Typography variant="h4">Mighty45 Trial</Typography>
          <Typography variant="h6" style={{ marginTop: 20 }}>
            For an unbeatable value, our base monthly fee is just $10.00! But
            that&apos;s not all – we believe in supporting your growing success,
            which is why we only charge an additional $3.00 for each active
            coach and a mere $1.50 for each active client. We&apos;ve got you
            covered!{" "}
            <strong>
              Disabled coaches and clients do not count towards your monthly
              cost.
            </strong>
          </Typography>

          <TrialDaysLeft account={account} />
          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
          <PlanName account={account} trial={true} />
          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
          <Box
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: colors.lightGray,
              padding: 12,
              borderRadius: 4,
              marginBottom: 24,
            }}
          >
            <Typography style={{ fontSize: 20, marginBottom: 24 }}>
              Start your subscription early to avoid interruption of service
              when your trial expires. Provide your payment information below to
              get started.
            </Typography>
            <StripeFormWrapper />
          </Box>
        </Stack>
      </Box>
    );
  }

  return null;
}

export default PricingPage;
