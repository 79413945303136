import React from "react";
import { TextField } from "@mui/material";

const FieldValue = ({
  type,
  value,
  field,
  isSuperSet = false,
  width = 65,
  delay = 800,
  id,
  validator,
  onChange,
}) => {
  const timerHandle = React.useRef();
  const [savedValue, setSavedValue] = React.useState(value);

  React.useEffect(() => {
    setSavedValue(value);
  }, [value]);

  if (type === "super_set" && field !== "sets" && field !== "setsToFailure") {
    return "-";
  }

  return (
    <TextField
      size="small"
      style={{ width: width }}
      value={savedValue}
      onChange={(event) => {
        let value = event.target.value;
        if (validator) {
          value = validator(value);
        }
        setSavedValue(value);
        if (timerHandle.current) {
          clearTimeout(timerHandle.current);
        }

        timerHandle.current = setTimeout(() => {
          if (onChange) {
            onChange(value, field, isSuperSet, id);
          }
        }, delay);
      }}
    />
  );
};

export default FieldValue;
