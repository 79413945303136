import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import BlueButton from "../../../../components/BlueButton";
import ProtocolData from "../../../../data/ProtocolData";
import _ from "underscore";

const ProtocolSelectDialog = (props) => {
  const protocolClientOnlySelection = localStorage.getItem(
    "protocolClientOnlySelection",
  );

  const [open, setOpen] = React.useState(false);
  const [protocols, setProtocols] = React.useState([]);
  const [selected, setSelected] = React.useState(props.client.protocolId);
  const [clientOnly, setClientOnly] = React.useState(
    protocolClientOnlySelection ? protocolClientOnlySelection : true,
  );

  React.useEffect(() => {
    async function getData() {
      const protocols = await ProtocolData.getProtocols({
        clientUID: clientOnly ? props.client.uid : null,
      });

      setProtocols(protocols);
    }
    setOpen(props.open);
    getData();
  }, [props.open, clientOnly]); // eslint-disable-line

  function getProtocol(id) {
    return _.find(protocols, (p) => {
      return p.data().id === id;
    });
  }

  function DisplayEmpty() {
    if (protocols.length === 0) {
      return (
        <MenuItem key={"none"} value="">
          There are no protocols defined. Go to the protocols tab to define
          some.
        </MenuItem>
      );
    }
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Select Training Plan</DialogTitle>

      <DialogContent>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={clientOnly}
                onChange={() => {
                  setClientOnly(!clientOnly);
                  localStorage.setItem(
                    "protocolClientOnlySelection",
                    !clientOnly,
                  );
                }}
              />
            }
            label="Only show training plans made for this client."
          />
        </Box>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Training Plan:</Typography>
          <Select
            style={{ width: 512 }}
            variant="outlined"
            value={selected}
            onChange={(event) => {
              setSelected(event.target.value);
            }}
          >
            {<DisplayEmpty />}
            {protocols.map((p) => {
              return (
                <MenuItem key={p.data().id} value={p.data().id}>
                  {p.data().name}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            if (props.onDialogClosed) {
              props.onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            setOpen(false);
            if (props.onDialogClosed) {
              const protocol = getProtocol(selected);
              props.onDialogClosed({
                protocolId: selected,
                protocol: protocol,
              });
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProtocolSelectDialog;
