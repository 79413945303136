import React from "react";
import { Box, Typography, Button, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";

import AvatarList from "../../../../components/molecules/AvatarList";
import { EditButton } from "../../../../atoms/EditButton";

const GroupTable = ({
  id,
  groups,
  users,
  title,
  onAddGroupClicked,
  onGroupClicked,
  editClicked,
}) => {
  const theme = useTheme();

  const storedModel = localStorage.getItem("groupDataGridColumns" + id);
  const storedPageSize = localStorage.getItem("groupDataGridPageSize" + id);

  const [visibleModel, setVisibilityModel] = React.useState(
    storedModel ? JSON.parse(storedModel) : {},
  );

  const [pageSize, setPageSize] = React.useState(
    parseInt(storedPageSize) || 25,
  );

  const columns = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "users",
        headerName: "Invited",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          const usersData = users
            .filter((u) => {
              return params.row.users.includes(u.uid);
            })
            .map((user) => {
              return {
                key: user.uid,
                url: user.avatarUrl,
                firstName: user.firstName,
                lastName: user.lastName,
              };
            });

          return <AvatarList users={usersData} size={32} />;
        },
      },
      {
        field: "lastMessage",
        headerName: "Last Message",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          // return only 200 characters of last message
          return (
            <Typography style={{ fontSize: "0.875rem", overflow: "clip" }}>
              {params.row.lastMessage
                ? params.row.lastMessage.substring(0, 80) + "..."
                : ""}
            </Typography>
          );
        },
      },
      {
        field: "lastDate",
        headerName: "Last Date",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
        // type: "dateTime",
        valueGetter: (params) => {
          if (!params.row.lastDate) {
            return null;
          }
          return params.row.lastDate.toDate();
        },
        renderCell: (params) => {
          if (!params.row.lastUser) {
            return "";
          }

          return params.row.lastDate
            ? new Date(params.row.lastDate.toDate()).toLocaleString()
            : "";
        },
      },
      {
        field: "lastUser",
        headerName: "Last User",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
        type: "string",
        valueGetter: (params) => {
          if (!params.row.lastUser) {
            return null;
          }
          return `${params.row.lastUser.firstName} ${params.row.lastUser.lastName}`;
        },
        renderCell: (params) => {
          if (!params.row.lastUser) {
            return "";
          }
          return `${params.row.lastUser.firstName} ${params.row.lastUser.lastName}`;
        },
      },
      {
        minWidth: 25,
        headerAlign: "center",
        align: "left",
        field: "action",
        headerName: "",
        sortable: false,
        hideable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Stack
              spacing={1}
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <EditButton
                onClick={(event) => {
                  event.ignore = true;
                  event.stopPropagation();
                  editClicked(params.row);
                }}
              />
            </Stack>
          );
        },
      },
    ];
  }, [users]);

  const rows = React.useMemo(() => {
    return groups.map((group) => {
      return {
        id: group.uid,
        ...group,
      };
    });
  }, [groups]);

  return (
    <Box style={{ margin: 12, height: "100%" }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">{title || "Message Groups"}</Typography>
        {onAddGroupClicked && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (onAddGroupClicked) {
                onAddGroupClicked();
              }
            }}
          >
            Add Message Group
          </Button>
        )}
      </Box>

      <Box sx={{ flexGrow: 1, marginTop: 1, height: window.innerHeight - 400 }}>
        <DataGrid
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => {
            localStorage.setItem("groupDataGridPageSize" + id, newPageSize);
            setPageSize(newPageSize);
          }}
          rowsPerPageOptions={[10, 25, 50]}
          sx={{
            ".MuiDataGrid-sortIcon": {
              color: theme.palette.primary.main,
            },
            ".MuiDataGrid-filterIcon": {
              color: theme.palette.primary.main,
            },
            ".MuiDataGrid-menuIconButton": {
              color: theme.palette.primary.main,
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
          columnVisibilityModel={visibleModel}
          disableSelectionOnClick
          rows={rows}
          columns={columns}
          getRowHeight={() => {
            return "auto";
          }}
          onColumnVisibilityModelChange={(model) => {
            localStorage.setItem(
              "coachDataGridColumns" + id,
              JSON.stringify(model),
            );
            setVisibilityModel(model);
          }}
          onRowClick={(params) => {
            if (onGroupClicked) {
              onGroupClicked(params.row);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default GroupTable;
