import React, { useEffect } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import Bugsnag from "@bugsnag/js";
import { getRecoil } from "recoil-nexus";
import { consola } from "consola";

import organizationIdSelector from "../atoms/organizationIdSelector";

const useIngredients = () => {
  const organizationId = getRecoil(organizationIdSelector);
  const [ingredients, setIngredients] = React.useState([]);
  const subscriptionIngredients = React.useRef();

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    if (subscriptionIngredients.current) {
      subscriptionIngredients.current();
    }

    const subscriptionIngredientsRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("ingredients")
      .orderBy("name", "asc");

    subscriptionIngredients.current = subscriptionIngredientsRef.onSnapshot(
      (snapshot) => {
        consola.info("++++++ SNAPSHOT -> useIngredients: subscriptionIngredients")
        const data = snapshot.docs;

        const finalData = data.map((d) => {
          return d.data();
        });
        setIngredients(finalData);
      },
    );
  }, [organizationId]);

  const ingredientDictionary = React.useMemo(() => {
    const dictionary = {};
    ingredients.forEach((ingredient) => {
      dictionary[ingredient.id] = ingredient;
    });
    return dictionary;
  }, [ingredients]);

  const editIngredient = React.useCallback(
    async function (ingredientData) {
      // Add the log data here.
      const ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("ingredients")
        .doc(ingredientData.id);

      try {
        consola.info("+++++ WRITE => useIngredients: editIngredient")
        await ref.set(ingredientData, { merge: true });
      } catch (e) {
        Bugsnag.notify(new Error("Error saving ingredient data"));
      }
    },
    [organizationId],
  );

  return { ingredients, ingredientDictionary, editIngredient };
};

export default useIngredients;
