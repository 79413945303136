import React from "react";
import ProtocolData from "../data/ProtocolData";

const useSplits = ({ protocolId }) => {
  const subscription = React.useRef();
  const [splits, setSplits] = React.useState();

  React.useEffect(() => {
    async function getDataFlat(protocolId) {
      if (subscription.current) {
        subscription.current();
      }
      subscription.current = await ProtocolData.getSplitsSubscriptionFlat(
        protocolId,
        (snapshot) => {
          setSplits(snapshot.docs);
        },
      );
    }

    getDataFlat(protocolId);

    return () => {
      if (subscription.current) {
        subscription.current();
      }
    };
  }, [protocolId]);

  return { splits };
};

export default useSplits;
