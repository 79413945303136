import React from "react";
import { Box, Typography, Grid, Divider, Menu, MenuItem } from "@mui/material";

import { getkCal } from "../../../../components/LibraryFunctions";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { rgba } from "polished";

const macroWidth = 100;

const activeStyle = {
  backgroundColor: rgba(0, 128, 0, 0.1),
  padding: 8,
  borderRadius: 8,
  color: "black",
  fontWeight: "600",
};

const inactiveStyle = {
  padding: 8,
  fontWeight: "600",
};

export default React.memo(({ nutritionGoal, onActivate, onEdit, onDelete }) => {
  const kCals = React.useMemo(() => {
    return getkCal(nutritionGoal);
  }, [nutritionGoal]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      type: "menu",
      menuName: "Set Goal as Active",
      menuFunction: () => {
        if (onActivate) {
          onActivate(nutritionGoal);
        }
      },
    },
    {
      type: "menu",
      menuName: "Edit Nutrition Goal",
      menuFunction: () => {
        if (onEdit) {
          onEdit(nutritionGoal);
        }
      },
    },
    {
      type: "menu",
      menuName: "Delete Nutrition Goal",
      menuFunction: () => {
        if (onDelete) {
          onDelete(nutritionGoal);
        }
      },
    },
  ];

  return (
    <Box>
      <Box style={nutritionGoal.active ? activeStyle : inactiveStyle}>
        <Grid container justifyContent="space-between" alignItems={"center"}>
          <Grid item style={{ width: 200 }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${nutritionGoal.name}`}</Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: macroWidth }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${
              nutritionGoal.proteins ? nutritionGoal.proteins.toFixed(0) : "0"
            } g`}</Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: macroWidth }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${
              nutritionGoal.carbs ? nutritionGoal.carbs.toFixed(0) : "0"
            } g`}</Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: macroWidth }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${
              nutritionGoal.fats ? nutritionGoal.fats.toFixed(0) : "0"
            } g`}</Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: macroWidth }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${kCals}`}</Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: macroWidth }}>
            <Typography
              style={{ fontSize: 16, fontWeight: "bold", marginTop: 0 }}
            >{`${
              nutritionGoal.water ? nutritionGoal.water.toFixed(0) : "0"
            } ml`}</Typography>
          </Grid>

          <Grid
            item
            style={{
              width: 20,
              marginLeft: 100,
            }}
          >
            <MoreVertIcon onClick={handleClick} />
          </Grid>
        </Grid>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems &&
          menuItems.map((menuItem, index) => {
            if (menuItem.type === "menu") {
              return (
                <MenuItem
                  key={menuItem.menuName}
                  onClick={() => {
                    setAnchorEl(null);
                    if (menuItem.menuFunction) {
                      menuItem.menuFunction(nutritionGoal);
                    }
                  }}
                >
                  {`${menuItem.menuName}`}
                </MenuItem>
              );
            } else {
              return <Divider key={index} />;
            }
          })}
      </Menu>
    </Box>
  );
});
