import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import BlueButton from "../../../../components/BlueButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
const saveIndexes = (list) =>{
    let index = 0;
    list.forEach(item => {
        item.index = index;
        index +=1})
    return list
}
const ReorderDialog = ({
  reorderTitle,
  list,
  open = false,
  onDialogClosed = null,
}) => {
  const [dialogueOpen, setOpen] = React.useState(false);
  let newList = saveIndexes(list);


  
  React.useEffect(() => {
    setOpen(open);
  }, [open]); // eslint-disable-line

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    newList = reorder(newList, result.source.index, result.destination.index);
    saveIndexes(newList)
    // setList(items);
  }
  
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', height: 400 } }}      
      fullWidth={true}
      maxWidth="sm"
      open={dialogueOpen}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>{reorderTitle}</DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {newList.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Box style={{ marginBottom: 12 }}>
                            <Box display="flex" alignItems="center">
                              {/* <img src={dragImage} alt="drag icon" /> */}
                              <Typography
                              style={{
                                marginLeft: 2,
                                fontSize: 16,
                                // color: theme.palette.primary.main,
                                fontWeight: "bold",
                                
                              }}
                              >
                              {item.index + 1}.
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: 8,
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >
                                {item.name}
                              </Typography>
                            </Box>
                          </Box>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions style={{ marginTop: 20 }}>
        <Button
          onClick={() => {
            if (onDialogClosed) {
              saveIndexes(list)
              onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (onDialogClosed) {
              saveIndexes(newList)
              onDialogClosed(newList);
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReorderDialog;
