import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import {
  convertHeightToCm,
  convertWeightToKg,
  getAgeFromBirthday,
} from "../../../../data/GlobalData";

import BlueButton from "../../../../components/BlueButton";
import BlueBorderButton from "../../../../components/BlueBorderButton";
import {
  ActivityLevel,
  ActivityLevelValues,
  ActivityLevelLabel,
  WeightGoal,
  WeightGoalLabel,
  WeightGoalFactor,
} from "../../../../data/Types";
import useHealthReport from "../../../../hooks/useHealthReport";
import useOrganizationData from "../../../../hooks/useOrganizationData";
import useHistory from "../../../../hooks/useHistory";
import moment from "moment";
import colors from "../../../../themes/Colors";

const MacroSelectSimpleDialog = ({
  client,
  nutritionGoal,
  open,
  isEdit,
  onDialogClosed,
}) => {
  const { report } = useHealthReport({
    uid: client.uid,
    filter: "fatPercentage",
    operation: "!=",
    value: 0,
  });

  const organizationData = useOrganizationData({ uid: client.uid });

  const [openDialog, setOpenDialog] = React.useState(false);

  const [macros, setMacros] = React.useState(nutritionGoal);
  const [error, setError] = React.useState(null);

  const storedMethod = localStorage.getItem("storedMethod");
  const storedFatPercentage = localStorage.getItem("fatPercentage");
  const storedCarbSplit = localStorage.getItem("carbSplit");
  const storedFatSplit = localStorage.getItem("fatSplit");
  const storedProteinSplit = localStorage.getItem("proteinSplit");
  const storedWeightGoal = localStorage.getItem("weightGoal");

  const [method, setMethod] = React.useState(storedMethod || "body-fat");

  const [fatPercentage, setFatPercentage] = React.useState(
    storedFatPercentage || 25,
  );
  const [carbSplit, setCarbSplit] = React.useState(
    parseInt(storedCarbSplit) || 35,
  );
  const [fatSplit, setFatSplit] = React.useState(
    parseInt(storedFatSplit) || 30,
  );
  const [proteinSplit, setProteinSplit] = React.useState(
    parseInt(storedProteinSplit) || 35,
  );
  const [weightGoal, setWeightGoal] = React.useState(
    storedWeightGoal || WeightGoal.maintain,
  );
  const [historyOpen, setHistoryOpen] = React.useState(false);

  const totalkCal = React.useMemo(() => {
    if (!macros) {
      return 0;
    }
    const proteinskCal = macros.proteins * 4;
    const carbskCal = macros.carbs * 4;
    const fatskCal = macros.fats * 9;

    return proteinskCal + carbskCal + fatskCal;
  }, [macros]);

  function validate() {
    return true;
  }

  React.useEffect(() => {
    setOpenDialog(open);

    if (nutritionGoal) {
      setMacros(nutritionGoal);
    }
  }, [open, nutritionGoal]); // eslint-disable-line

  React.useEffect(() => {
    localStorage.setItem("fatPercentage", fatPercentage);
    localStorage.setItem("carbSplit", carbSplit);
    localStorage.setItem("fatSplit", fatSplit);
    localStorage.setItem("proteinSplit", proteinSplit);
    localStorage.setItem("weightGoal", weightGoal);
    localStorage.setItem("storedMethod", method);
  }, [fatPercentage, carbSplit, fatSplit, proteinSplit, weightGoal, method]);

  React.useEffect(() => {
    if (report) {
      const { reportData } = report;
      if (reportData.fatPercentage) {
        setFatPercentage(reportData.fatPercentage);
      }
    }
  }, [report]);
  function getHistoryString(historyOpen) {
    if (historyOpen) {
      return "Hide History";
    } else {
      return "Show History";
    }
  }

  const foundWeight = React.useMemo(() => {
    if (client.statistics && client.statistics.currentWeight) {
      if (client.statistics.currentWeight > 0) {
        return client.statistics.currentWeight;
      }
    }

    if (client.statistics && client.statistics.startingWeight) {
      if (client.statistics.startingWeight > 0) {
        return client.statistics.startingWeight;
      }
    }

    if (client.statistics && client.statistics.weight) {
      if (client.statistics.weight > 0) {
        return client.statistics.weight;
      }
    }
  }, [client.statistics]);

  const calculatedValues = React.useMemo(() => {
    const sex = client.statistics.sex;
    const height = convertHeightToCm(client.statistics.height);
    let system = "imperial";
    if (organizationData && organizationData.unitSystem) {
      system = organizationData.unitSystem;
    }
    let weightKg = 0;
    if (system === "imperial") {
      weightKg = convertWeightToKg(foundWeight || 0);
    } else {
      weightKg = foundWeight || 0;
    }

    let age = 0;
    if (client.statistics.birthDate) {
      age = getAgeFromBirthday(client.statistics.birthDate.toDate());
    }

    let bmr1 = 0;
    if (sex === "male") {
      bmr1 = 66.5 + 13.75 * weightKg + 5.003 * height - 6.775 * age;
    }
    if (sex === "female") {
      bmr1 = 655.1 + 9.563 * weightKg + 1.85 * height - 4.676 * age;
    }

    const lbm = (weightKg * (100 - fatPercentage || 0)) / 100;

    const bmr2 = 370 + 21.6 * lbm;

    const adjustedbmr1 = client.statistics.activityLevel
      ? ActivityLevelValues[client.statistics.activityLevel] * bmr1
      : bmr1;

    const adjustedbmr2 = client.statistics.activityLevel
      ? ActivityLevelValues[client.statistics.activityLevel] * bmr2
      : bmr2;

    return {
      sex,
      height,
      weightKg,
      age,
      bmr1,
      bmr2,
      lbm,
      adjustedbmr1: adjustedbmr1,
      adjustedbmr2: adjustedbmr2,
      bmr1WeightFactor:
        adjustedbmr1 + adjustedbmr1 * WeightGoalFactor[weightGoal],

      bmr2WeightFactor:
        adjustedbmr2 + adjustedbmr2 * WeightGoalFactor[weightGoal],
    };
  }, [
    client.statistics,
    fatPercentage,
    weightGoal,
    foundWeight,
    organizationData,
  ]);

  const macroSplitTotal = React.useMemo(() => {
    return carbSplit + fatSplit + proteinSplit;
  }, [carbSplit, fatSplit, proteinSplit]);

  /**
   * Calculate macros
   */
  const handleCalculateMacros = React.useCallback(() => {
    const { bmr2WeightFactor, bmr1WeightFactor } = calculatedValues;

    const factor = method === "body-fat" ? bmr2WeightFactor : bmr1WeightFactor;

    const proteins = Math.round((factor * (proteinSplit / 100)) / 4);
    const fats = Math.round((factor * (fatSplit / 100)) / 9);
    const carbs = Math.round((factor * (carbSplit / 100)) / 4);

    setMacros({
      ...macros,
      proteins: proteins,
      fats: fats,
      carbs: carbs,
    });
  }, [calculatedValues, fatSplit, proteinSplit, carbSplit, macros, method]);

  if (!macros) {
    return null;
  }
  function HighlightText(e) {
    e.target.style.fontWeight = "bold";
  }
  function RemoveHighlight(e) {
    e.target.style.fontWeight = "normal";
  }
  function GetRoundedString(title, value) {
    if (isNaN(value + 1)) {
      return title + ": " + String(value);
    } else {
      return title + ": " + String(Math.round(value));
    }
  }
  function ChangeDifference({ title, value }) {
    if (title === "changedOn" || title === "updatedOn") {
      return null;
    }
    const finalValue = `${value}`;
    return (
      <Grid key={title} item sm={2} width={125}>
        {/* <Typography key={title} style={{ fontSize: 12 }}>
          {title}: {finalValue}
        </Typography> */}
        <Button
          key={title}
          style={{
            margin: 0,
            padding: 0,
            alignSelf: "left",
            justifyContent: "left",
            color: "black",
            fontSize: 12,
          }}
          onClick={() => {
            if (finalValue.length === 0) {
              setMacros({
                ...macros,
                title: "",
              });
              return;
            } else if (title === "name") {
              setMacros({
                ...macros,
                name: finalValue,
              });
            } else if (title === "carbs") {
              setMacros({
                ...macros,
                carbs: isNaN(finalValue) ? 0 : parseInt(finalValue, 10),
              });
            } else if (title === "proteins") {
              setMacros({
                ...macros,
                proteins: isNaN(finalValue) ? 0 : parseInt(finalValue, 10),
              });
            } else if (title === "fats") {
              setMacros({
                ...macros,
                fats: isNaN(finalValue) ? 0 : parseInt(finalValue, 10),
              });
            } else if (title === "water") {
              setMacros({
                ...macros,
                water: isNaN(finalValue) ? 0 : parseInt(finalValue, 10),
              });
            }
          }}
          onMouseOver={HighlightText}
          onMouseLeave={RemoveHighlight}
        >
          {GetRoundedString(title, finalValue)}
        </Button>
      </Grid>
    );
  }

  function HistoryChange({ change }) {
    const { differences } = change;
    return (
      <Grid container spacing={1}>
        <Grid item md={2}>
          <Button
            style={{
              color: "black",
              margin: 0,
              padding: 0,
              justifyContent: "left",
              textAlign: "left",
              fontSize: 12,
              fontWeight: "500",
              cursor: "default",
            }}
            disabled
          >
            {change.name}
          </Button>
        </Grid>

        {Object.keys(differences).map((key) => {
          return (
            <ChangeDifference key={key} title={key} value={differences[key]} />
          );
        })}
      </Grid>
    );
  }

  function HistoryItem({ history }) {
    if (!history || !history.changes || history.changes.length === 0) {
      return null;
    }
    return (
      <Box key={history.id} sx={{ mb: 1 }}>
        <Typography
          style={{
            color: colors.primaryBlue,
            textAlign: "left",
            fontSize: 12,
            fontWeight: "bold",
          }}
        >
          {moment(history.changedOn.toDate()).format("LLL")}
        </Typography>
        <Box>
          {history.changes.map((change) => {
            return <HistoryChange key={change.id} change={change} />;
          })}
        </Box>
      </Box>
    );
  }

  function InteractiveHistory({ client, historyCollection, limit = 10 }) {
    const { history } = useHistory({
      uid: client.uid,
      historyCollection: historyCollection,
      limit: limit,
      name: nutritionGoal.name,
    });
    const revertString =
      "(Select a value to revert back to before that change)";

    return (
      <Box
        sx={{
          minWidth: 450,
          maxWidth: 450,
          maxHeight: 200,
          overflowY: "scroll",
        }}
      >
        <Typography sx={{ fs: 14, fontWeight: "bold" }}>
          Recent History
        </Typography>
        <Typography
          sx={{
            fontSize: 13,
            fs: 14,
            fontStyle: "italic",
            mb: 1,
          }}
        >
          {revertString}
        </Typography>
        <Box>
          {history?.map((h) => {
            return <HistoryItem key={h.id} history={h} />;
          })}
        </Box>
      </Box>
    );
  }

  return (
    <Dialog
      maxWidth="lg"
      open={openDialog}
      PaperProps={{ sx: { height: 800 } }}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Select Macros</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Box>
              <Typography>Name:</Typography>
              <TextField
                value={macros.name}
                style={{ width: 300 }}
                variant="outlined"
                onChange={(event) => {
                  if (event.target.value.length === 0) {
                    setMacros({
                      ...macros,
                      name: "",
                    });
                    return;
                  }

                  setMacros({
                    ...macros,
                    name: event.target.value,
                  });
                }}
              ></TextField>
            </Box>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                width: "200",
              }}
            >
              <Typography style={{ width: 200 }}>Carbs (g):</Typography>
              <TextField
                type="number"
                value={macros.carbs ? macros.carbs.toFixed(0) : ""}
                style={{ width: 100 }}
                variant="outlined"
                onChange={(event) => {
                  if (event.target.value.length === 0) {
                    setMacros({
                      ...macros,
                      carbs: 0,
                    });
                    return;
                  }
                  setMacros({
                    ...macros,
                    carbs: isNaN(event.target.value)
                      ? 0
                      : parseInt(event.target.value, 10),
                  });
                }}
              ></TextField>
            </Box>

            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
            >
              <Typography style={{ width: 200 }}>Proteins (g):</Typography>
              <TextField
                type="number"
                value={macros.proteins ? macros.proteins.toFixed(0) : ""}
                style={{ width: 100 }}
                variant="outlined"
                onChange={(event) => {
                  if (event.target.value.length === 0) {
                    setMacros({
                      ...macros,
                      proteins: 0,
                    });
                    return;
                  }
                  setMacros({
                    ...macros,
                    proteins: isNaN(event.target.value)
                      ? 0
                      : parseInt(event.target.value, 10),
                  });
                }}
              ></TextField>
            </Box>

            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
            >
              <Typography style={{ width: 200 }}>Fats (g):</Typography>
              <TextField
                type="number"
                value={macros.fats ? macros.fats.toFixed(0) : ""}
                style={{ width: 100 }}
                variant="outlined"
                onChange={(event) => {
                  if (event.target.value.length === 0) {
                    setMacros({
                      ...macros,
                      fats: 0,
                    });
                    return;
                  }
                  setMacros({
                    ...macros,
                    fats: isNaN(event.target.value)
                      ? 0
                      : parseInt(event.target.value, 10),
                  });
                }}
              ></TextField>
            </Box>

            {error && (
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ width: 200, color: "red" }}>
                  {error}
                </Typography>
              </Box>
            )}

            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
            >
              <Typography style={{ width: 200 }}>Total kCal:</Typography>
              <Typography>{totalkCal.toFixed(0)}</Typography>
            </Box>

            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
            >
              <Typography style={{ width: 200 }}>Water (mL):</Typography>
              <TextField
                value={macros.water}
                style={{ width: 100 }}
                variant="outlined"
                onChange={(event) => {
                  if (event.target.value.length === 0) {
                    setMacros({
                      ...macros,
                      water: 0,
                    });
                    return;
                  }
                  setMacros({
                    ...macros,
                    water: isNaN(event.target.value)
                      ? 0
                      : parseInt(event.target.value, 10),
                  });
                }}
              ></TextField>
            </Box>
            {isEdit && (
              <BlueBorderButton
                style={{ marginTop: 12, marginBottom: 12 }}
                justifyContent={"flex-left"}
                onClick={() => {
                  //
                  setHistoryOpen(!historyOpen);
                }}
              >
                {getHistoryString(historyOpen)}
              </BlueBorderButton>
            )}
            {historyOpen && (
              <InteractiveHistory
                client={client}
                historyCollection={"nutritionGoalHistory"}
              />
            )}
          </Grid>
          <Grid item>
            <Box>
              <Typography>Macro Calculator</Typography>
              <Box
                style={{
                  width: 400,
                  borderStyle: "solid",
                  borderWidth: 1,
                  padding: 12,
                  borderRadius: 12,
                  borderColor: "#ccc",
                }}
              >
                <Box>
                  <Typography>Select method of calculation</Typography>
                  <Select
                    style={{ width: "95%" }}
                    variant="outlined"
                    value={method}
                    onChange={(event) => {
                      setMethod(event.target.value);
                    }}
                  >
                    <MenuItem value="body-fat">Use Body Fat%</MenuItem>
                    <MenuItem value="age-weight">
                      Use Gender/Weight/Height/Age
                    </MenuItem>
                  </Select>
                </Box>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography>Body Fat %:</Typography>
                    <TextField
                      value={fatPercentage}
                      type="number"
                      style={{ width: 150 }}
                      variant="outlined"
                      onChange={(event) => {
                        setFatPercentage(event.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Box>
                      <Typography style={{ width: 200 }}>
                        Weight Goal:
                      </Typography>
                      <Select
                        style={{ width: 185 }}
                        variant="outlined"
                        value={weightGoal}
                        onChange={(event) => {
                          setWeightGoal(event.target.value);
                        }}
                      >
                        <MenuItem value={WeightGoal.lose}>
                          {WeightGoalLabel.lose}
                        </MenuItem>
                        <MenuItem value={WeightGoal.lose_light}>
                          {WeightGoalLabel.lose_light}
                        </MenuItem>
                        <MenuItem value={WeightGoal.maintain}>
                          {WeightGoalLabel.maintain}
                        </MenuItem>
                        <MenuItem value={WeightGoal.gain_light}>
                          {WeightGoalLabel.gain_light}
                        </MenuItem>
                        <MenuItem value={WeightGoal.gain}>
                          {WeightGoalLabel.gain}
                        </MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item>
                    <Typography>Carb %:</Typography>
                    <TextField
                      value={carbSplit}
                      type="number"
                      style={{ width: 100 }}
                      variant="outlined"
                      onChange={(event) => {
                        setCarbSplit(parseInt(event.target.value));
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>Protein %:</Typography>
                    <TextField
                      value={proteinSplit}
                      type="number"
                      style={{ width: 100 }}
                      variant="outlined"
                      onChange={(event) => {
                        setProteinSplit(parseInt(event.target.value));
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>Fat %:</Typography>
                    <TextField
                      value={fatSplit}
                      type="number"
                      style={{ width: 100 }}
                      variant="outlined"
                      onChange={(event) => {
                        setFatSplit(parseInt(event.target.value));
                      }}
                    />
                  </Grid>
                </Grid>
                <Box>
                  <Typography
                    style={{
                      width: 200,
                      fontWeight: "bold",
                      color: macroSplitTotal !== 100 ? "red" : "black",
                    }}
                  >
                    Total: {macroSplitTotal}%
                  </Typography>
                </Box>

                <Box style={{ marginTop: 8 }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Activity Level
                  </Typography>
                  <Typography>
                    {client.statistics.activityLevel
                      ? ActivityLevelLabel[client.statistics.activityLevel]
                      : ActivityLevelLabel[ActivityLevel.sedentary]}
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  <Grid item>
                    <Box style={{ marginTop: 8 }}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Gender
                      </Typography>
                      <Typography>{calculatedValues.sex}</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box style={{ marginTop: 8 }}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Age
                      </Typography>
                      <Typography
                        style={{
                          color:
                            calculatedValues.age === 0
                              ? colors.primaryRed
                              : "black",
                        }}
                      >
                        {calculatedValues.age}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box style={{ marginTop: 8 }}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Weight (kg)
                      </Typography>
                      <Typography
                        style={{
                          color:
                            calculatedValues.weightKg === 0
                              ? colors.primaryRed
                              : "black",
                        }}
                      >
                        {calculatedValues.weightKg}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box style={{ marginTop: 8 }}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Height (cm)
                      </Typography>
                      <Typography
                        style={{
                          color:
                            calculatedValues.height === 0
                              ? colors.primaryRed
                              : "black",
                        }}
                      >
                        {calculatedValues.height.toFixed(2)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Box style={{ marginTop: 0, marginLeft: 8 }}>
                    <Typography style={{ fontSize: 12 }}>
                      * Age, weight, and height can be changed by editing the
                      client record.
                    </Typography>
                  </Box>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item>
                    <Box style={{ marginTop: 8 }}>
                      <Typography style={{ fontWeight: "bold" }}>
                        BMR
                      </Typography>
                      <Typography>
                        {method === "body-fat"
                          ? calculatedValues.bmr2.toFixed(0)
                          : calculatedValues.bmr1.toFixed(0)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box style={{ marginTop: 8 }}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Adjusted Calories
                      </Typography>
                      <Typography>
                        {method === "body-fat"
                          ? calculatedValues.adjustedbmr2.toFixed(0)
                          : calculatedValues.adjustedbmr1.toFixed(0)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box style={{ marginTop: 8 }}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Goal Calories
                      </Typography>
                      <Typography>
                        {method === "body-fat"
                          ? calculatedValues.bmr2WeightFactor.toFixed(0)
                          : calculatedValues.bmr1WeightFactor.toFixed(0)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Box style={{ marginTop: 12 }}>
                  <Button
                    disabled={macroSplitTotal !== 100}
                    variant="contained"
                    onClick={handleCalculateMacros}
                  >
                    Calculate Grams
                  </Button>
                </Box>
                <Box style={{ marginTop: 8 }}>
                  <Typography style={{ fontSize: 12 }}>
                    * BMR calculated using Katch-McArdle formula.
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>
                    * Adjusted calories calculated using (BMR + Activity Level).
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>
                    * Goal calories calculated using (Adjusted calories +/-
                    Weight Goal).
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setOpenDialog(false);
            if (onDialogClosed) {
              onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (validate()) {
              setOpenDialog(false);
              if (onDialogClosed) {
                onDialogClosed({
                  id: macros.id,
                  name: macros.name,
                  proteins: macros.proteins,
                  carbs: macros.carbs,
                  fats: macros.fats,
                  water: macros.water,
                  active: macros.active ? macros.active : false,
                });
              }
            } else {
              setError("Total of grams must be 100.");
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default MacroSelectSimpleDialog;
