export function parseContentType(contentType) {
  const parts = contentType.split("/");
  return parts[parts.length - 1];
}

export function getExtensionFromContentType(file) {
  const contentType = file.type;
  const extension = parseContentType(contentType);
  return extension;
}

export function myParseFloat(value) {
  return parseFloat(value) || 0;
}

export function getGridHeight(){
  return window.innerHeight - 250
}