import React from "react";
import { Box } from "@mui/material";
import MuscleGroupButton from "../../../../components/MuscleGroupButton";

const MuscleGroupButtonGroup = ({ muscleGroups, filter, onClick }) => {
  return (
    <Box>
      {muscleGroups &&
        muscleGroups.map((m) => {
          return (
            <MuscleGroupButton
              onClick={(id) => {
                if (onClick) {
                  onClick(id);
                }
              }}
              key={m.data().id}
              title={m.data().name}
              id={m.data().id}
              filter={filter}
            />
          );
        })}
    </Box>
  );
};

export default MuscleGroupButtonGroup;
