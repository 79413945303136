import React from "react";
import { TextField, Button, Stack } from "@mui/material";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import MessageData from "../data/MessageData";
import AudioCaptureModal from "./AudioCaptureModal";
import useEvents from "../hooks/useEvents";

const SendMessage = (props) => {
  const [message, setMessage] = React.useState(
    localStorage.getItem(`draftMessage-${props.groupId}`) || "",
  );
  const [audioOpen, setAudioOpen] = React.useState(false);
  const events = useEvents();
  React.useEffect(() => {
    setMessage(localStorage.getItem(`draftMessage-${props.groupId}`) || "");
  }, [props.client, props.groupId]);

  async function sendTheMessage() {
    if (message.length > 0 && props.groupId) {
      await MessageData.sendMessageToGroup(props.groupId, message);
      setMessage("");
    }
    localStorage.removeItem(`draftMessage-${props.groupId}`);
  }

  async function sendTheAudioMessage(blob, buffer) {
    if (blob) {
      await MessageData.sendAudioMessageToGroup(props.groupId, blob, buffer);
      setMessage("");
    }
  }

  return (
    <>
      <Stack
        direction={"row"}
        style={{ paddingInline: 40 }}
        alignItems="center"
        spacing={2}
        paddingBottom={2}
      >
        <TextField
          InputProps={{
            endAdornment: (
              <KeyboardVoiceIcon
                position="end"
                onClick={() => {
                  setAudioOpen(true);
                }}
              />
            ),
          }}
          fullWidth={true}
          multiline={true}
          variant="outlined"
          value={message}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && !e.shiftKey) {
              sendTheMessage();
            }
          }}
          onChange={(event) => {
            localStorage.setItem(
              `draftMessage-${props.groupId}`,
              event.target.value,
            );
            setMessage(event.target.value);
          }}
        ></TextField>

        <Button
          variant="contained"
          style={{
            width: 120,
            height: 55,
            backgroundColor: "#0077C8",
            fontSize: 18,
          }}
          onClick={async () => {
            events.logButtonClick({
              buttonName: "Send Message",
              pageName: "Messages",
              tabName: props.groupId,
            });
            sendTheMessage();
            const logEvent = {
              clientId: props.client,
              groupId: props.groupId,
            };
            events.logButtonClick(logEvent);
          }}
        >
          Send
        </Button>
      </Stack>

      {audioOpen && (
        <AudioCaptureModal
          open={audioOpen}
          onDialogClosed={(blob, buffer) => {
            if (blob) {
              sendTheAudioMessage(blob, buffer);
            }
            setAudioOpen(false);
          }}
          onError={() => {
            //
          }}
        />
      )}
    </>
  );
};

export default SendMessage;
