import React from "react";
import { Box } from "@mui/material";
import _ from "underscore";
import OrganizationData from "../../../../data/OrganizationData";
import { DataType } from "../../../../data/GlobalData";
import NutritionProgress from "../../../../components/organisms/NutritionProgress";
import NutritionValue from "../../../../components/organisms/NutritionValue";
import OptionalText from "../../../../components/organisms/OptionalText";

const OptionalData = (props) => {
  const [optionals, setOptionals] = React.useState(null);

  React.useEffect(() => {
    async function getData() {
      const organization = await OrganizationData.getOrganization(
        props.client.uid,
      );
      setOptionals(organization.optional);
    }
    getData(props.client.uid);
  }, [props.update]); // eslint-disable-line

  function getOptionalType(id, optionals) {
    const value = _.findWhere(optionals, { id: id });
    if (value) {
      return value.type;
    }
    return null;
  }

  if (
    !props.report ||
    !props.report.nutrition ||
    !props.report.nutrition.reportData ||
    !props.report.nutrition.reportData.optional
  ) {
    return null;
  }

  return (
    <Box
      style={{ height: "100%" }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {optionals
        ? optionals.map((o) => {
            let value = 0;
            if (props.report && props.report.nutrition) {
              if (props.report.nutrition.reportData.optional) {
                if (o.id in props.report.nutrition.reportData.optional) {
                  if (o.maxValue > 0) {
                    value =
                      (props.report.nutrition.reportData.optional[o.id].value /
                        o.maxValue) *
                      100.0;
                    if (isNaN(value)) {
                      value = 0;
                    }
                  } else {
                    value =
                      props.report.nutrition.reportData.optional[o.id].value;
                  }

                  const type = getOptionalType(o.id, optionals);

                  if (type === DataType.multiline) {
                    return (
                      <OptionalText
                        key={o.id}
                        title={o.name}
                        value={
                          props.report.nutrition.reportData.optional[o.id].value
                        }
                      />
                    );
                  } else if (type === DataType.yesno) {
                    const textValue = props.report.nutrition.reportData
                      .optional[o.id].value
                      ? "Yes"
                      : "No";
                    return (
                      <OptionalText
                        key={o.id}
                        title={o.name}
                        value={textValue}
                      />
                    );
                  } else {
                    if (o.maxValue) {
                      return (
                        <NutritionProgress
                          key={o.id}
                          title={o.name}
                          target=""
                          value={value}
                          showTarget={false}
                        />
                      );
                    } else {
                      return (
                        <NutritionValue
                          key={o.id}
                          title={o.name}
                          value={value}
                        />
                      );
                    }
                  }
                }
              }
            }
            return null;
          })
        : null}
    </Box>
  );
};

export default OptionalData;
