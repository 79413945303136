import React from "react";
import {
  Box,
  Typography,
  Stack,
  Grid,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { consola } from "consola";
import moment from "moment";
import { useConfirm } from "material-ui-confirm";
import { useRecoilValue } from "recoil";
import BlueBorderButton from "../BlueBorderButton";
import useSharedFiles from "../../hooks/useSharedFiles";
import organizationIdSelector from "../../atoms/organizationIdSelector";
import useEvents from "../../hooks/useEvents";
import { DropDownButton } from "../../atoms/EditButton";
import SharedFilesTitleRow from "./SharedFilesTitleRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function FileMenuItem({ key, menuItem, setAnchorEl }) {
  return (
    <MenuItem
      key={key}
      onClick={() => {
        setAnchorEl(null);
        if (menuItem.menuFunction) {
          menuItem.menuFunction(menuItem.file);
        }
      }}
    >
      {`${menuItem.menuName}`}
    </MenuItem>
  );
}

function FileMenu({ uid, anchorEl, setAnchorEl, handleClose, onDelete }) {
  const confirm = useConfirm();
  const events = useEvents();

  function confirmDelete() {
    confirm({
      description: `Are you sure you want to delete this file?`,
    })
      .then(() => {
        if (onDelete) {
          onDelete();
        }
        // deleteFile({ id: fileProperty.id });
        const logEvent = {
          buttonName: "Delete File",
          pageName: "Clients",
          tabName: uid,
        };
        events.logButtonClick(logEvent);
      })
      .catch(() => {
        consola.log("delete file cancelled");
      });
  }

  const menuItems = [
    {
      id: "delete",
      type: "menu",
      menuName: "Delete File",
      menuFunction: confirmDelete,
    },
  ];

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {menuItems &&
        menuItems.map((menuItem, index) => {
          if (menuItem.type === "menu") {
            return (
              <FileMenuItem
                key={menuItem.id}
                menuItem={menuItem}
                setAnchorEl={setAnchorEl}
              />
            );
          } else {
            return <Divider key={index} />;
          }
        })}
    </Menu>
  );
}

function MenuIcon({ file, onClick }) {
  return (
    <MoreVertIcon
      onClick={(event) => {
        onClick(file, event);
      }}
    />
  );
}

function SharedFiles({ uid, style }) {
  const organizationId = useRecoilValue(organizationIdSelector);
  const events = useEvents();
  const [showNotes, setShowNotes] = React.useState(true);
  const { sharedFiles, uploadFile, deleteFile } = useSharedFiles({
    uid: localStorage.getItem("uid"),
    clientUID: uid,
    organizationId,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentFile = React.useRef(null);

  const fileInputRef = React.useRef(null);

  const handleClick = (file, event) => {
    currentFile.current = file;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!sharedFiles) {
    return null;
  }

  return (
    <>
      <input
        ref={fileInputRef}
        hidden={true}
        type="file"
        accept=".pdf,.csv,.doc,.docx,.txt,.xls,.xlsx,.zip,.rtf,.rar,.ppt,.pptx,.mp4,.mp3,.mov"
        onChange={(e) => {
          const logEvent = {
            buttonName: "Upload File",
            pageName: "Clients",
            tabName: uid,
          };
          events.logButtonClick(logEvent);
          uploadFile({ file: e.target.files[0], isCoach: true });
          e.target.value = null;
        }}
      />
      <Box
        display="flex"
        boxShadow={3}
        borderRadius={2}
        borderBottom={showNotes ? 1 : 0}
        style={{ marginTop: 20, backgroundColor: "#F6F7FB" }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          paddingLeft={3}
        >
          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                >
                  <path
                    d="M17.0603 1H4.81085C3.68798 1 2.76927 1.9 2.76927 3V17H4.81085V3H17.0603V1ZM16.0395 5L22.1643 11V21C22.1643 22.1 21.2456 23 20.1227 23H8.8838C7.76093 23 6.85243 22.1 6.85243 21L6.86264 7C6.86264 5.9 7.77114 5 8.89401 5H16.0395ZM15.0187 12H20.6331L15.0187 6.5V12Z"
                    fill="black"
                  />
                </svg>
              </Box>
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "normal",
                  fontFamily: "Lato",
                  verticalAlign: "Center",
                  paddingLeft: 20,
                }}
              >
                Shared Files
              </Typography>
            </Box>
          </Grid>

          <Grid item textAlign="center" style={{}}>
            <DropDownButton
              onClick={() => {
                setShowNotes(!showNotes);
              }}
              show={showNotes}
            />
          </Grid>
        </Grid>
      </Box>
      {showNotes && sharedFiles.length === 0 && (
        <Box display={"flex"}>
          <Box
            boxShadow={3}
            borderRadius={2}
            style={{
              width: "100%",
              backgroundColor: "#F6F7FB",
              padding: 20,
              ...style,
            }}
          >
            <Box style={{ paddingInline: 75 }}>
              <Typography>
                There are no files shared by your client. Files will appear here
                when your client shares files with you. You can also share files
                with your clients. Click the &quot;Upload a File&quot; button to
                share a file.
              </Typography>
              <Stack
                style={{ marginTop: 12 }}
                direction={"row"}
                spacing={3}
                justifyContent={"center"}
              >
                <BlueBorderButton
                  style={{
                    paddingInline: 150,
                    marginTop: 12,
                    marginBottom: 12,
                  }}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  Upload a File
                </BlueBorderButton>
              </Stack>
            </Box>
          </Box>
        </Box>
      )}
      {showNotes && sharedFiles.length > 0 && (
        <Box display={"flex"}>
          <Box
            boxShadow={3}
            borderRadius={2}
            style={{
              width: "100%",
              backgroundColor: "#F6F7FB",
              padding: 20,
              ...style,
            }}
          >
            <Box style={{ paddingInline: 75 }}>
              <SharedFilesTitleRow></SharedFilesTitleRow>
              {sharedFiles.map((file) => {
                return (
                  <Box key={file.id} mt={2}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Grid item width={200}>
                        <a
                          style={{
                            color: "#0077C8",
                            fontSize: 16,
                            textDecorationLine: "underline",
                            marginBottom: 12,
                            marginTop: 4,
                          }}
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {file.name}
                        </a>
                      </Grid>
                      <Grid item width={200} textAlign="center">
                        <Typography
                          style={{
                            fontSize: 16,
                            marginTop: 4,
                          }}
                        >
                          {moment(file.createdOn.toDate()).format(
                            "MMMM D, YYYY",
                          )}
                        </Typography>
                      </Grid>
                      <Grid item width={150} textAlign="center">
                        {file.metadata && file.metadata.sharedBy && (
                          <Typography
                            style={{
                              fontSize: 16,
                              marginTop: 4,
                            }}
                          >
                            {file.metadata.sharedBy.firstName}{" "}
                            {file.metadata.sharedBy.lastName}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        style={{
                          width: 20,
                          marginLeft: 100,
                        }}
                      >
                        <MenuIcon
                          file={file}
                          onClick={(file, event) => {
                            handleClick(file, event);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
              <Stack
                style={{ marginTop: 12 }}
                direction={"row"}
                spacing={3}
                justifyContent={"center"}
              >
                <BlueBorderButton
                  style={{
                    paddingInline: 150,
                    marginTop: 12,
                    marginBottom: 12,
                  }}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  Upload a File
                </BlueBorderButton>
              </Stack>
            </Box>
          </Box>
          <FileMenu
            uid={uid}
            anchorEl={anchorEl}
            onDelete={() => {
              deleteFile({ id: currentFile.current.id });
            }}
            setAnchorEl={setAnchorEl}
            handleClose={handleClose}
          />
        </Box>
      )}
    </>
  );
}

export default SharedFiles;
