import React from "react";

import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { useRecoilValue } from "recoil";

import CloseIcon from "@mui/icons-material/Close";
import ChatComponent from "./ChatComponent";

import organizationIdSelector from "../../atoms/organizationIdSelector";
import useClientMessageGroupId from "../../hooks/useClientMessageGroupId";

const SendMessageDialog = ({ coachId, uid, open, onDialogClosed }) => {
  const organizationId = useRecoilValue(organizationIdSelector);
  const groupId = useClientMessageGroupId({ uid, organizationId });

  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Send a Message
        <IconButton
          onClick={() => {
            if (onDialogClosed) {
              onDialogClosed();
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ minWidth: 1000, minHeight: 500 }}>
        <ChatComponent uid={coachId} groupId={groupId} />
      </DialogContent>
    </Dialog>
  );
};

export default SendMessageDialog;
