import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";

import BlueButton from "../../../../components/BlueButton";
import { saveHealthReport } from "../../../../data/ClientData";
import moment from "moment";
import {
  convertHeightToCm,
  convertWeightToKg,
  getAgeFromBirthday,
} from "../../../../data/GlobalData";

const BodyMeasurementsEditDialog = ({ client, report, onDialogClosed }) => {
  const [error, setError] = React.useState(null);

  const {
    nutrition: { reportData: reportData },
  } = report;

  const [fatPercentage, setFatPercentage] = React.useState(
    reportData.fatPercentage || 0,
  );

  const [hydrationPercentage, setHydrationPercentage] = React.useState(
    reportData.hydrationPercentage || 0,
  );

  const [muscleMass, setMuscleMass] = React.useState(
    reportData.muscleMass || 0,
  );

  const [waist, setWaist] = React.useState(reportData.waist || 0);
  const [metabolicAge, setMetabolicAge] = React.useState(
    reportData.metabolicAge || 0,
  );

  const [visceralFat, setVisceralFat] = React.useState(
    reportData.visceralFat || 0,
  );

  const [weight, setWeight] = React.useState(reportData.weight || 0);

  const calculatedValues = React.useMemo(() => {
    const sex = client.statistics.sex;
    const height = convertHeightToCm(client.statistics.height);
    const weightKg = convertWeightToKg(weight || 0);
    const age = getAgeFromBirthday(client.statistics.birthDate.toDate());

    let bmr = 0;
    if (sex === "male") {
      bmr = 66.5 + 13.75 * weightKg + 5.003 * height - 6.775 * age;
    }
    if (sex === "female") {
      bmr = 655.1 + 9.563 * weightKg + 1.85 * height - 4.676 * age;
    }

    const lbm = (weightKg * (100 - fatPercentage || 0)) / 100;

    const bmr2 = 370 + 21.6 * lbm;

    return {
      bmr,
      bmr2,
      lbm,
    };
  }, [client.statistics, fatPercentage, weight]);

  function validate() {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      open={true}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Edit Body Measurements</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Box>
              <Typography>Weight:</Typography>
              <TextField
                value={weight}
                style={{ width: 100 }}
                type="number"
                variant="outlined"
                onChange={(event) => {
                  setWeight(parseFloat(event.target.value));
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item>
            <Box>
              <Typography>Body Fat %:</Typography>
              <TextField
                value={fatPercentage}
                style={{ width: 100 }}
                type="number"
                variant="outlined"
                onChange={(event) => {
                  setFatPercentage(parseFloat(event.target.value));
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item>
            <Box>
              <Typography>Visceral Fat:</Typography>
              <TextField
                value={visceralFat}
                style={{ width: 100 }}
                type="number"
                variant="outlined"
                onChange={(event) => {
                  setVisceralFat(parseFloat(event.target.value));
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Box>
              <Typography>Muscle Mass:</Typography>
              <TextField
                value={muscleMass}
                style={{ width: 100 }}
                type="number"
                variant="outlined"
                onChange={(event) => {
                  setMuscleMass(parseFloat(event.target.value));
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item>
            <Box>
              <Typography>Metabolic Age:</Typography>
              <TextField
                value={metabolicAge}
                style={{ width: 100 }}
                type="number"
                variant="outlined"
                onChange={(event) => {
                  setMetabolicAge(parseFloat(event.target.value));
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item>
            <Box>
              <Typography>Waist:</Typography>
              <TextField
                value={waist}
                style={{ width: 100 }}
                type="number"
                variant="outlined"
                onChange={(event) => {
                  setWaist(parseFloat(event.target.value));
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item>
            <Box>
              <Typography>Hydration %:</Typography>
              <TextField
                value={hydrationPercentage}
                style={{ width: 100 }}
                type="number"
                variant="outlined"
                onChange={(event) => {
                  setHydrationPercentage(parseFloat(event.target.value));
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Box>
              <Typography>{`BMR 1: ${calculatedValues.bmr.toFixed(
                2,
              )}`}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box>
              <Typography>{`BMR 2: ${calculatedValues.bmr2.toFixed(
                2,
              )}`}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box>
              <Typography>{`LBM: ${calculatedValues.lbm.toFixed(
                2,
              )}`}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginTop: 12 }}>
          <Typography style={{ fontSize: 12 }}>
            BMR 1: Based on Harris-Benedict Equation.
          </Typography>
          <Typography style={{ fontSize: 12 }}>BMR 2: Based on LBM.</Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        {error && (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ width: 200, color: "red" }}>
              {error}
            </Typography>
          </Box>
        )}

        <Button
          onClick={() => {
            if (onDialogClosed) {
              onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={async () => {
            const validation = validate();
            if (!validation) {
              // Save stuff
              const reportData = {
                fatPercentage,
                hydrationPercentage,
                muscleMass,
                waist,
                visceralFat,
                weight,
                metabolicAge,
              };

              await saveHealthReport({
                uid: client.uid,
                date: moment(report.nutrition.dateIndex).toDate(),
                data: {
                  ...reportData,
                },
              });

              if (onDialogClosed) {
                onDialogClosed();
              }
            } else {
              setError(validation);
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default BodyMeasurementsEditDialog;
