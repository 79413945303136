import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import { consola } from "consola";

import { nanoid } from "nanoid";

import { getExtensionFromContentType, parseContentType } from "../data/Library";

const useSharedFiles = ({
  uid,
  clientUID,
  organizationId,
  limit = 100,
  global = false,
}) => {
  const sharedFilesSubscription = React.useRef();

  const [sharedFiles, setSharedFiles] = React.useState([]);

  React.useEffect(() => {
    if (!organizationId) {
      return;
    }

    if (sharedFilesSubscription.current) {
      sharedFilesSubscription.current();
    }

    let sharedFilesRef;

    if (!global) {
      sharedFilesRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("sharedFiles")
        .where("clientId", "==", clientUID)
        .limit(limit)
        .orderBy("createdOn", "desc");
    } else {
      sharedFilesRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("sharedFiles")
        .where("isOrganization", "==", true)
        .limit(limit)
        .orderBy("createdOn", "desc");
    }

    sharedFilesSubscription.current = sharedFilesRef.onSnapshot((snapshot) => {
      consola.info(
        "++++++ SNAPSHOT -> useSharedFiles: sharedFilesSubscription",
      );
      const data = snapshot.docs;

      const finalData = data.map((d) => {
        return d.data();
      });
      setSharedFiles(finalData);
    });
  }, [clientUID, limit, organizationId, global]);

  async function deleteFile({ id }) {
    const sharedFileRef = firebase
      .firestore()
      .doc(`organizations/${organizationId}/sharedFiles/${id}`);

    consola.info("++++++ READ -> useSharedFiles: deleteFile");
    const sharedFileData = await sharedFileRef.get();
    if (!sharedFileData.exists) {
      return;
    }

    const extension = parseContentType(sharedFileData.data().type);
    let path;
    if (!global) {
      path = `/${organizationId}/shared_files/${clientUID}/${id}.${extension}`;
    } else {
      path = `/${organizationId}/shared_files/${id}.${extension}`;
    }

    const storageRef = firebase.storage().ref(path);
    storageRef.delete().then(() => {
      sharedFileRef.delete();
    });
  }

  async function updateFile({ id, name, description }) {
    const sharedFileRef = firebase
      .firestore()
      .doc(`organizations/${organizationId}/sharedFiles/${id}`);

    consola.info("++++++ READ -> useSharedFiles: updateFile");
    const sharedFileData = await sharedFileRef.get();
    if (!sharedFileData.exists) {
      return;
    }
    consola.info("+++++ WRITE => useSharedFiles: updateFile");
    await sharedFileRef.set(
      {
        name,
        description,
      },
      { merge: true },
    );
  }

  /**
   * Upload a shared file.
   * @param {*} param0
   */
  async function uploadOrganizationFile({
    file,
    onProgress = null,
    onError = null,
    onComplete = null,
  }) {
    const newId = nanoid();

    const extension = getExtensionFromContentType(file);

    const path = `/${organizationId}/shared_files/${newId}.${extension}`;

    const uploadTask = firebase.storage().ref(path).put(file, {
      cacheControl: "private, max-age=645000",
    });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progress function ...
        if (onProgress) {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          );
          onProgress(progress);
        }
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      },
      () => {
        firebase
          .storage()
          .ref(path)
          .getDownloadURL()
          .then(async (url) => {
            const sharedFileRef = firebase
              .firestore()
              .doc(`organizations/${organizationId}/sharedFiles/${newId}`);

            const metadata = {
              sharedBy: {
                uid,
              },
            };
            consola.info(
              "+++++ WRITE => useSharedFiles: uploadOrganizationFile",
            );
            await sharedFileRef.set(
              {
                url: url,
                id: newId,
                name: file.name,
                type: file.type,
                metadata: metadata,
                createdOn: new Date(),
                isOrganization: true,
              },
              { merge: true },
            );

            if (onComplete) {
              onComplete(true);
            }
          })
          .catch((e) => {
            if (onError) {
              onError(e);
            }
          });
      },
    );
  }

  /**
   * Upload a shared file.
   * @param {*} param0
   */
  async function uploadFile({
    file,
    isCoach = false,
    onProgress = null,
    onError = null,
    onComplete = null,
  }) {
    const newId = nanoid();

    const ref = firebase.firestore().collection("users").doc(clientUID);
    const coachRef = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> useSharedFiles: uploadFile");
    const userData = await ref.get();
    const coachData = await coachRef.get();
    if (userData.exists && coachData.exists) {
      const extension = getExtensionFromContentType(file);

      const path = `/${organizationId}/shared_files/${clientUID}/${newId}.${extension}`;

      const uploadTask = firebase.storage().ref(path).put(file, {
        cacheControl: "private, max-age=645000",
      });

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress function ...
          if (onProgress) {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            );
            onProgress(progress);
          }
        },
        (error) => {
          if (onError) {
            onError(error);
          }
        },
        () => {
          firebase
            .storage()
            .ref(path)
            .getDownloadURL()
            .then(async (url) => {
              const sharedFileRef = firebase
                .firestore()
                .doc(`organizations/${organizationId}/sharedFiles/${newId}`);

              const metadata = {
                sharedBy: {
                  uid,
                  firstName: coachData.data().firstName,
                  lastName: coachData.data().lastName,
                },
              };
              consola.info("+++++ WRITE => useSharedFiles: uploadFile");
              await sharedFileRef.set(
                {
                  url: url,
                  id: newId,
                  name: file.name,
                  type: file.type,
                  isCoach,
                  isOrganization: false,
                  clientId: clientUID,
                  metadata: metadata,
                  createdOn: new Date(),
                },
                { merge: true },
              );

              if (onComplete) {
                onComplete(true);
              }
            })
            .catch((e) => {
              if (onError) {
                onError(e);
              }
            });
        },
      );
    }
  }

  return {
    sharedFiles,
    uploadFile,
    uploadOrganizationFile,
    deleteFile,
    updateFile,
  };
};

export default useSharedFiles;
