import React from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
} from "@mui/material";

import moment from "moment";
import { ExpandMore } from "@mui/icons-material";

const CardioGoalsGridItem = ({ cardioGoal }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F9F9F9",
        display: "flex",
        width: "100%",
        padding: 3,
        margin: 1,
        borderRadius: 4,
      }}
    >
      <Grid container spacing={3} sx={{ alignItems: "center" }}>
        <Grid item>
          <Typography style={{ fontWeight: "500", fontSize: 20 }}>
            {moment(cardioGoal.date).format("ll")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: 16 }}>
            {`Duration: ${cardioGoal.cardioGoal.cardioReport.duration}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: 16 }}>
            {`Intensity: ${cardioGoal.cardioGoal.cardioReport.intensity}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: 16 }}>
            {`HR Target: ${cardioGoal.cardioGoal.cardioReport.hr}`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const CardioGoals = ({ report }) => {
  const [expanded, setExpanded] = React.useState(false);

  const activeCardioGoal = React.useMemo(() => {
    if (report.clientData.cardioGoals) {
      let active;
      report.clientData.cardioGoals.some((goal) => {
        if (goal.active) {
          active = goal;
          return true;
        }
        return false;
      });
      return active;
    }
    return undefined;
  }, [report]);

  const cardioGoals = React.useMemo(() => {
    if (report.healthData) {
      const goals = report.healthData
        .map((report) => {
          if (
            report.reportData &&
            report.reportData.cardioGoals &&
            report.reportData.cardioGoals.length > 0
          ) {
            return {
              date: report.createdOn.toDate(),
              cardioGoal: report.reportData.cardioGoals[0],
            };
          }
          return null;
        })
        .filter((r) => {
          return r !== null;
        });
      return goals;
    }
  }, [report]);

  return (
    <Box style={{ marginTop: 20 }}>
      <Accordion
        display="flex"
        boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M16 2.66663C8.62666 2.66663 2.66666 8.66663 2.66666 16C2.66666 19.5362 4.07141 22.9276 6.5719 25.428C7.81001 26.6662 9.27987 27.6483 10.8975 28.3184C12.5152 28.9884 14.249 29.3333 16 29.3333C19.5362 29.3333 22.9276 27.9285 25.4281 25.428C27.9286 22.9276 29.3333 19.5362 29.3333 16C29.3333 14.249 28.9884 12.5152 28.3184 10.8975C27.6483 9.27984 26.6662 7.80998 25.4281 6.57187C24.19 5.33375 22.7201 4.35163 21.1024 3.68157C19.4848 3.0115 17.7509 2.66663 16 2.66663ZM13 10.4266C14.16 10.4266 15.2667 10.9733 16 11.8266C16.7333 10.9733 17.84 10.4266 19 10.4266C21.0533 10.4266 22.6667 12.04 22.6667 14.0933C22.6667 16.6133 20.4 18.6666 16.96 21.7866L16 22.6666L15.04 21.7866C11.6 18.6666 9.33332 16.6133 9.33332 14.0933C9.33332 12.04 10.9467 10.4266 13 10.4266Z"
              fill="black"
            />
          </svg>
          <Typography
            style={{
              color: "black",
              fontSize: 24,
              fontWeight: "600",
              marginLeft: 10,
            }}
          >
            Cardio Goals
          </Typography>
        </AccordionSummary>

        <Box sx={{ padding: 2, marginLeft: 5 }}>
          <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
            Current Cardio Goal
          </Typography>

          <Box
            sx={{
              padding: 2,
              backgroundColor: "#F9F9F9",
              width: "100%",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F9F9F9",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                paddingInline: 5,
                borderRadius: 8,
              }}
            >
              <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                {activeCardioGoal
                  ? activeCardioGoal.name
                  : "No goals are defined"}
              </Typography>
              <Grid container spacing={3}>
                <Grid item>
                  <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                    {`Sessions: ${
                      activeCardioGoal ? activeCardioGoal.sessions : ""
                    }`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                    {`Duration: ${
                      activeCardioGoal ? activeCardioGoal.duration : ""
                    }`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                    {`Intensity: ${
                      activeCardioGoal ? activeCardioGoal.intensity : ""
                    }`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                    {`HR Target: ${
                      activeCardioGoal ? activeCardioGoal.hr : ""
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Stack
          sx={{
            marginBottom: 1,
            paddingLeft: 7,
            paddingRight: 3,
            width: "100%",
          }}
          spacing={1}
        >
          <Box
            style={{ justifyContent: "center", width: "100%", marginLeft: 5 }}
          >
            <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
              Completed Cardio Goals
            </Typography>
          </Box>

          {cardioGoals && cardioGoals.length === 0 && (
            <Box sx={{ marginTop: 0 }}>
              <Box sx={{ marginTop: 0, paddingInline: 7 }}>
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "500",
                  }}
                >
                  No Cardio Goal History for this report.
                </Typography>
              </Box>
            </Box>
          )}

          {cardioGoals &&
            cardioGoals.length > 0 &&
            cardioGoals.map((cardioGoal) => {
              return (
                <CardioGoalsGridItem
                  key={cardioGoal.date}
                  cardioGoal={cardioGoal}
                />
              );
            })}
        </Stack>
      </Accordion>
    </Box>
  );
};

export default CardioGoals;
