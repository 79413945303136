import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import _ from "underscore";
import BlueButton from "../../../../components/BlueButton";

const ReportNoteDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  function getOptionalNotesList(reportData) {
    if (!reportData) {
      return [];
    }
    const { optional } = reportData;

    const keys = _.keys(optional);

    const notes = keys.map((key) => {
      const value = optional[key];
      return {
        key: key,
        ...value,
      };
    });
    return notes;
  }

  const notesList = React.useMemo(() => {
    const notes = getOptionalNotesList(props.reportData);
    return notes;
  }, [props.reportData]);

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Report Notes</DialogTitle>

      <DialogContent style={{ width: 600 }}>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 0 }}>
          <List>
            {props.reportData &&
              props.reportData.notes &&
              props.reportData.notes.length > 0 && (
                <ListItem>
                  <ListItemText
                    primary="Daily notes/comments"
                    secondary={
                      props.reportData &&
                      props.reportData.notes &&
                      props.reportData.notes.length > 0
                        ? props.reportData.notes
                        : "None"
                    }
                  ></ListItemText>
                </ListItem>
              )}

            {props.reportData &&
              props.reportData.proteinsNotes &&
              props.reportData.proteinsNotes.length > 0 && (
                <ListItem>
                  <ListItemText
                    primary="Proteins"
                    secondary={
                      props.reportData &&
                      props.reportData.proteinsNotes &&
                      props.reportData.proteinsNotes.length > 0
                        ? props.reportData.proteinsNotes
                        : "None"
                    }
                  ></ListItemText>
                </ListItem>
              )}

            {props.reportData &&
              props.reportData.carbsNotes &&
              props.reportData.carbsNotes.length > 0 && (
                <ListItem>
                  <ListItemText
                    primary="Carbs"
                    secondary={
                      props.reportData &&
                      props.reportData.carbsNotes &&
                      props.reportData.carbsNotes.length > 0
                        ? props.reportData.carbsNotes
                        : "None"
                    }
                  ></ListItemText>
                </ListItem>
              )}

            {props.reportData &&
              props.reportData.fatsNotes &&
              props.reportData.fatsNotes.length > 0 && (
                <ListItem>
                  <ListItemText
                    primary="Fats"
                    secondary={
                      props.reportData &&
                      props.reportData.fatsNotes &&
                      props.reportData.fatsNotes.length > 0
                        ? props.reportData.fatsNotes
                        : "None"
                    }
                  ></ListItemText>
                </ListItem>
              )}

            {notesList.map((note) => {
              if (!note || !note.notes || note.notes.length <= 0) {
                return null;
              }
              return (
                <Box key={note.key}>
                  <ListItem dense>
                    <ListItemText
                      primary={
                        note && note.key && note.key.length > 0 ? note.key : ""
                      }
                      secondary={
                        note && note.notes && note.notes.length > 0
                          ? note.notes
                          : "None"
                      }
                    ></ListItemText>
                  </ListItem>
                </Box>
              );
            })}
          </List>
        </Box>
      </DialogContent>

      <DialogActions>
        <BlueButton
          onClick={() => {
            setOpen(false);
            if (props.onDialogClosed) {
              props.onDialogClosed();
            }
          }}
          color="primary"
        >
          Done
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReportNoteDialog;
