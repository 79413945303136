import React from "react";
import { Box, Typography, Stack, Link } from "@mui/material";
import PricingPage from "../../../components/organisms/PricingPage";
import useOrganizationSubscription from "../../../hooks/useOrganizationSubscription";
import colors from "../../../themes/Colors";
import logoImage from "../../../assets/Logo.png";

const SubscribePage = () => {
  const uid = localStorage.getItem("uid");

  const subscribeOrg = useOrganizationSubscription({ uid });

  return (
    <Box style={{ margin: 12 }}>
      <Stack>
        <Stack direction={"row"} justifyContent="flex-start">
          <Link href="/home">
            <Typography>Back to Mighty45 Console</Typography>
          </Link>
        </Stack>

        <Box style={{ margin: 20 }}>
          <img
            alt="Mighty45 Logo"
            style={{
              alignItems: "center",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            width="146px"
            height="78px"
            src={logoImage}
            onClick={() => {
              window.open("/home");
            }}
          />
        </Box>

        <Box
          style={{
            height: "50vh",
            backgroundColor: colors.secondaryBackground,
            padding: 24,
          }}
        >
          <PricingPage organizationData={subscribeOrg} />
        </Box>
      </Stack>
    </Box>
  );
};

export default SubscribePage;
