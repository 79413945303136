import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import ChartLegend from "./ChartLegend";
import { DropDownButton } from "../../../../atoms/EditButton";
import { ClientNutrition } from "./ClientSummaryComponentModules";
import OptionalData from "./OptionalData";

const DailyProgressReports = ({
  modifierStyles,
  selectedDay,
  calendarModifiers,
  client,
  onDayClick,
  dailyReport,
  setBodyMeasurementOpen,
}) => {
  const [showCalendar, setShowCalendar] = React.useState(true);

  return (
    <>
      <Box
        display="flex"
        boxShadow={3}
        borderRadius={2}
        borderBottom={showCalendar ? 1 : 0}
        style={{ marginTop: 20, marginBottom: 0, backgroundColor: "#F6F7FB" }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          paddingLeft={1}
        >
          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="57"
                  height="56"
                  viewBox="0 0 57 56"
                  fill="none"
                >
                  <rect x="0.067627" width="56" height="56" rx="8" />
                  <path
                    d="M46.7343 35.4668V39.2002H16.8676V9.3335H20.6009V30.9308L31.2596 14.1308L37.1583 18.0695L41.6756 13.5522L44.3263 16.2028L37.6436 22.9415L32.3423 19.4135L22.1316 35.4668M13.1343 42.9335V13.0668H9.40094V46.6668H46.7343V42.9335H13.1343Z"
                    fill="black"
                  />
                </svg>
              </Box>
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "normal",
                  fontFamily: "Lato",
                  verticalAlign: "Center",
                  paddingLeft: 8,
                }}
              >
                Daily Progress Reports
              </Typography>
            </Box>
          </Grid>
          <Grid item textAlign="center" style={{}}>
            <DropDownButton
              onClick={() => {
                setShowCalendar(!showCalendar);
              }}
              show={showCalendar}
            />
          </Grid>
        </Grid>
      </Box>

      {showCalendar && (
        <Box
          borderRadius={2}
          style={{
            backgroundColor: "#F6F7FB",
            paddingTop: 12,
          }}
          boxShadow={3}
        >
          <Box>
            {/* <style>{selectedStyle}</style> */}
            <DayPicker
              style={{ marginInline: 75, padding: 12 }}
              numberOfMonths={3}
              fixedWeeks={false}
              showOutsideDays={false}
              enableOutsideDaysClick={false}
              disabled={{ after: new Date() }}
              selected={selectedDay}
              modifiers={calendarModifiers}
              modifiersStyles={modifierStyles}
              defaultMonth={moment().subtract(2, "months").toDate()}
              onDayClick={onDayClick}
            />
            <ChartLegend style={{ paddingInline: 75 }} />
          </Box>
          <Grid
            container
            justifyContent="space-between"
            alignItems={"center"}
            paddingLeft={3}
            marginTop={4}
            style={{ backgroundColor: "#D2E6F44D" }}
          >
            <Grid item textAlign="center" style={{}}>
              <Box display={"inline-flex"} alignItems={"center"}>
                <Box>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                  >
                    <rect width="56" height="56" rx="8" />
                    <path
                      d="M19.1111 28H22.6667V31.2H19.1111V28ZM44 18.4V40.8C44 42.576 42.4178 44 40.4444 44H15.5556C13.5822 44 12 42.56 12 40.8V18.4C12 16.64 13.6 15.2 15.5556 15.2H17.3333V12H20.8889V15.2H35.1111V12H38.6667V15.2H40.4444C42.4178 15.2 44 16.64 44 18.4ZM15.5556 21.6H40.4444V18.4H15.5556V21.6ZM40.4444 40.8V24.8H15.5556V40.8H40.4444ZM33.3333 31.2V28H36.8889V31.2H33.3333ZM26.2222 31.2V28H29.7778V31.2H26.2222ZM19.1111 34.4H22.6667V37.6H19.1111V34.4ZM33.3333 37.6V34.4H36.8889V37.6H33.3333ZM26.2222 37.6V34.4H29.7778V37.6H26.2222Z"
                      fill="black"
                    />
                  </svg>
                </Box>
                <Typography
                  style={{
                    fontSize: 24,
                    fontWeight: 500,
                    lineHeight: "normal",
                    fontFamily: "Lato",
                    verticalAlign: "Center",
                    paddingLeft: 20,
                  }}
                >
                  Report Date:
                </Typography>
                <Typography
                  style={{
                    fontSize: 24,
                    fontWeight: 600,
                    lineHeight: "normal",
                    fontFamily: "Lato",
                    verticalAlign: "Center",
                    paddingLeft: 10,
                  }}
                >
                  {moment(selectedDay).format("MMMM Do")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box
            paddingLeft={3}
            marginTop={4}
            style={{ backgroundColor: "#F5EAEA" }}
          >
            <Typography
              style={{
                fontSize: 24,
                fontWeight: 600,
                lineHeight: "normal",
                fontFamily: "Lato",
                verticalAlign: "Center",
                marginLeft: 40,
                padding: 10,
              }}
            >
              Wellness
            </Typography>
          </Box>
          <Box display={"flex"} paddingInline={10} marginTop={5}>
            <OptionalData client={client} report={dailyReport} />
          </Box>

          <Box
            paddingLeft={3}
            marginTop={4}
            style={{ backgroundColor: "#F5EAEA" }}
          >
            <Typography
              style={{
                fontSize: 24,
                fontWeight: 600,
                lineHeight: "normal",
                fontFamily: "Lato",
                verticalAlign: "Center",
                marginLeft: 40,
                padding: 10,
              }}
            >
              Nutrition
            </Typography>
          </Box>
          <Box display={"flex"} paddingInline={10} marginTop={5}>
            <ClientNutrition
              client={client}
              report={dailyReport}
              setBodyMeasurementOpen={setBodyMeasurementOpen}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default DailyProgressReports;
