import React from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Container,
  Typography,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import Bugsnag from "@bugsnag/js";
import { useRecoilValue } from "recoil";
import RecipeDialog from "./recipes/RecipeDialog";
import organizationIdSelector from "../../../atoms/organizationIdSelector";
import fileUploadLimitSelector from "../../../atoms/fileUploadLimitSelector";
import useRecipes from "../../../hooks/useRecipes";
import RecipeDetails from "./recipes/RecipeDetails";
import MealPlanDetails from "./recipes/MealPlanDetails";
import EditSupplements from "./admin/EditSupplements";
import EditIngredients from "./admin/EditIngredients";
import EditMealPlans from "./recipes/EditMealPlans";
import EditRecipes from "./recipes/EditRecipes";
import useEvents from "../../../hooks/useEvents";

const RecipesPage = () => {
  const [mode, setMode] = React.useState("list");
  const [tab, setTab] = React.useState("recipes");
  const [showDialog, setShowDialog] = React.useState(false);
  const [selectedRecipe, setSelectedRecipe] = React.useState(null);
  const [selectedMealPlan, setSelectedMealPlan] = React.useState(null);
  const events = useEvents();
  const organizationId = useRecoilValue(organizationIdSelector);
  const fileUploadLimit = useRecoilValue(fileUploadLimitSelector);

  const {
    recipes,
    ingredients,
    editRecipe,
    editIngredient,
    uploadImage,
    getRecipesFromList,
  } = useRecipes({
    organizationId,
  });

  React.useEffect(() => {
    if (selectedRecipe) {
      const recipe = recipes.find((recipe) => recipe.id === selectedRecipe.id);
      setSelectedRecipe(recipe);
    }
  }, [recipes]);

  if (mode === "list") {
    return (
      <>
        <Container
          maxWidth={false}
          sx={{
            marginTop: 1,
            display: "flex",
            height: "95%",
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 1, mb: 1 }}>
            <Box>
              <Typography variant="h5">Nutrition</Typography>
              <Divider style={{ marginTop: 20 }}></Divider>
            </Box>
            <Tabs
              value={tab}
              onChange={(event, newValue) => {
                events.logTabSwitch({
                  pageName: "Nutrition",
                  tabName: newValue,
                  lastTab: tab,
                });
                setTab(newValue);
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Recipes" value="recipes" />
              <Tab label="Meal Plans" value="meal-plans" />
              <Tab label="Supplements" value="supplements" />
              <Tab label="Ingredients" value="ingredients" />
            </Tabs>
          </Box>

          {tab === "recipes" && (
            <EditRecipes
              onShowDetails={(recipe) => {
                events.logSubpageAccess({
                  pageType: "Recipes",
                  pageName: recipe.id,
                });
                setSelectedRecipe(recipe);
                setMode("details");
              }}
            />
          )}

          {tab === "meal-plans" && (
            <>
              <EditMealPlans
                onShowDetails={(mealPlan) => {
                  events.logSubpageAccess({
                    pageType: "Meal Plans",
                    pageName: mealPlan.id,
                  });
                  setSelectedMealPlan(mealPlan);
                  setMode("meal-plan-details");
                }}
              />
            </>
          )}

          {tab === "supplements" && (
            <>
              <EditSupplements />
            </>
          )}

          {tab === "ingredients" && (
            <>
              <EditIngredients />
            </>
          )}
        </Container>

        {showDialog && (
          <RecipeDialog
            open={showDialog}
            recipe={selectedRecipe}
            ingredientsDB={ingredients}
            editIngredient={editIngredient}
            onClose={(recipe, imageURL) => {
              if (recipe) {
                editRecipe(recipe).then(() => {
                  if (imageURL && imageURL.size < fileUploadLimit) {
                    uploadImage({ image: imageURL, recipe });
                  } else {
                    Bugsnag.notify("image too large");
                  }
                });
              }
              setShowDialog(false);
            }}
          />
        )}
      </>
    );
  }

  if (mode === "details") {
    return (
      <Container
        maxWidth={false}
        sx={{
          marginTop: 1,
          display: "flex",
          height: "95%",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            marginTop: 20,
          }}
        >
          <Breadcrumbs separator=">">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setMode("list");
                events.logButtonClick({
                  buttonName: "Back Button",
                  pageName: "Nutrition",
                  tabName: "Recipes",
                });
              }}
            >
              Recipe
            </Link>
            <Typography>{selectedRecipe.name}</Typography>
          </Breadcrumbs>

          <Box style={{ marginTop: 20 }}>
            <RecipeDetails recipe={selectedRecipe} uploadImage={uploadImage} />
          </Box>
        </Box>
      </Container>
    );
  } else {
    return (
      <Container
        maxWidth={false}
        sx={{
          margin: 1,
          display: "flex",
          height: "95%",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            marginLeft: 20,
            marginTop: 20,
          }}
        >
          <Breadcrumbs separator=">">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setMode("list");
                events.logButtonClick({
                  buttonName: "Back Button",
                  pageName: "Nutrition",
                  tabName: "Meal Plan",
                });
              }}
            >
              Meal Plan
            </Link>
            <Typography>{selectedMealPlan.name}</Typography>
          </Breadcrumbs>

          <Box style={{ marginTop: 20 }}>
            <MealPlanDetails
              mealPlan={selectedMealPlan}
              getRecipesFromList={getRecipesFromList}
            />
          </Box>
        </Box>
      </Container>
    );
  }
};

export default RecipesPage;
