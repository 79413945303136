const OnboardingEmails = {
  en: {
    templateId: "d-248af587c9ec4949adc6bdd0589ff992",
  },
  nl_BE: {
    templateId: "d-3606c09ff9f74181a52085c8feff1716",
  },
};

export default OnboardingEmails;
