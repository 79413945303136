/* eslint-disable react/no-children-prop */
import React from "react";
import { Box, Typography, Divider, IconButton, Grid } from "@mui/material";
import ReactPlayer from "react-player";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useConfirm } from "material-ui-confirm";
import TrashIcon from "@mui/icons-material/Delete";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import useSharedVideosByClient from "../../../../hooks/useSharedVideosByClient";
import colors from "../../../../themes/Colors";
import useEvents from "../../../../hooks/useEvents";

export default React.memo(({ client }) => {
  const confirm = useConfirm();
  const events = useEvents();
  const { videos, setVideoProcessedState, deleteSharedVideo } =
    useSharedVideosByClient({
      uid: client.uid,
    });

  const handleProcessVideoCallback = React.useCallback(
    (video) => {
      setVideoProcessedState(
        video.id,
        localStorage.getItem("uid"),
        !video.processed,
      );
    },
    [setVideoProcessedState],
  );

  const handleDeleteVideoCallback = React.useCallback(
    (video) => {
      confirm({
        description:
          "Deleting a shared video will also delete it from the client. Are you sure you want to delete this video? This action cannot be undone.",
      }).then(() => {
        deleteSharedVideo(video.id);
        events.logButtonClick({
          buttonName: "Delete Shared Video",
          pageName: "Clients",
          tabName: client.uid,
        });
      });
    },
    [deleteSharedVideo, confirm],
  );

  if (videos.length === 0) {
    return (
      <Box
        style={{
          marginBottom: 12,
          minHeight: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 12,
        }}
      >
        <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
          There are no videos shared by your client. Videos will appear here
          when your client attaches them to a workout or uploads them from their
          dashboard.
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      style={{
        marginBottom: 12,
        maxHeight: 1000,
        overflow: "auto",
      }}
    >
      <Grid container>
        {videos.map((video) => {
          return (
            <Grid key={video.id} item>
              <Box style={{ margin: 12, textAlign: "center" }}>
                <ReactPlayer controls={true} width="100%" url={video.url} />
                <Box style={{ marginBottom: 20 }}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {moment(video.createdOn.toDate()).format("LLL")}
                  </Typography>
                  {video.processed && (
                    <Typography
                      style={{ color: colors.primaryRed }}
                    >{`Video processed by ${video.processedBy.firstName} ${video.processedBy.lastName}`}</Typography>
                  )}

                  {video.metadata && video.metadata.notes && (
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: "500",
                      }}
                    >
                      {video.metadata.notes}
                    </Typography>
                  )}

                  {video.feedback && (
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      style={{
                        fontSize: 16,
                        fontWeight: "500",
                      }}
                      children={video.feedback}
                    />
                  )}
                  <Box>
                    <IconButton
                      style={{ marginRight: 4 }}
                      onClick={() => {
                        events.logButtonClick({
                          buttonName: "Toggle Video Processed Visibility",
                          pageName: "Clients",
                          tabName: client.uid,
                        });
                        handleProcessVideoCallback(video);
                      }}
                    >
                      {video.processed ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                    <IconButton
                      style={{ marginRight: 4 }}
                      onClick={() => {
                        handleDeleteVideoCallback(video);
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </Box>
                  <Divider style={{ margin: "12px 0" }} />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
});
