var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { consola } from "consola";
import { getRecoil } from "recoil-nexus";
import organizationIdSelector from "../atoms/organizationIdSelector"; // interface UseAllWorkoutsProps {
//   protocolId?: string;
// }
var useAllWorkouts = function () {
    var _this = this;
    var _a = (arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}).protocolId, protocolId = _a === void 0 ? undefined : _a;
    var organizationId = getRecoil(organizationIdSelector);
    var _b = React.useState(), workouts = _b[0], setWorkouts = _b[1];
    var _c = React.useState(false), loading = _c[0], setLoading = _c[1];
    var getAllWorkoutsCallback = React.useCallback(function (labels) { return __awaiter(_this, void 0, void 0, function () {
        var getExercises, getSplits, deNormalizedView, protocolsRef, protocols, promises;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    getExercises = function (splitId, split) { return __awaiter(_this, void 0, void 0, function () { var exercisesRef, exercises; return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                exercisesRef = firebase.firestore().collection("organizations").doc(organizationId).collection("protocolExercises").where("splitId", "==", splitId).orderBy("index", "asc");
                                consola.info("++++++ READ -> useAllWorkouts: getAllWorkoutsCallback");
                                return [4 /*yield*/, exercisesRef.get()];
                            case 1:
                                exercises = _a.sent();
                                exercises.forEach(function (e) { split.exercises.push(__assign({}, e.data())); });
                                return [2 /*return*/];
                        }
                    }); }); };
                    getSplits = function (protocolId, protocolName, protocol) { return __awaiter(_this, void 0, void 0, function () {
                        var splitsRef, splits, promises;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (labels && labels.length > 0) {
                                        splitsRef = firebase.firestore().collection("organizations").doc(organizationId).collection("protocolSplits").where("labels", "array-contains-any", labels).orderBy("index", "asc");
                                    }
                                    else {
                                        splitsRef = firebase.firestore().collection("organizations").doc(organizationId).collection("protocolSplits").where("protocolId", "==", protocolId).orderBy("index", "asc");
                                    }
                                    consola.info("++++++ READ -> useAllWorkouts: getSplits");
                                    return [4 /*yield*/, splitsRef.get()];
                                case 1:
                                    splits = _a.sent();
                                    promises = splits.docs.map(function (s) {
                                        var isDuplicate = s.data().isDuplicate;
                                        var isRestSplit = s.data().isRestSplit;
                                        if ((isDuplicate === undefined || isDuplicate === false) && !isRestSplit) {
                                            var deNormalizedSplit_1 = __assign(__assign({}, s.data()), { protocolName: protocolName, exercises: new Array(), originalSplit: s.data() });
                                            return new Promise(function (resolve) {
                                                getExercises(s.data().id, deNormalizedSplit_1).then(function () {
                                                    if (deNormalizedSplit_1.exercises.length > 0) {
                                                        protocol.splits.push(deNormalizedSplit_1);
                                                    }
                                                    resolve(true);
                                                });
                                            });
                                        }
                                        return null;
                                    });
                                    return [4 /*yield*/, Promise.all(promises)];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    deNormalizedView = new Array();
                    if (protocolId) {
                        protocolsRef = firebase.firestore().collection("organizations").doc(organizationId).collection("protocols").where("id", "==", protocolId).orderBy("name");
                    }
                    else {
                        protocolsRef = firebase.firestore().collection("organizations").doc(organizationId).collection("protocols").where("clientUID", "==", null).orderBy("name");
                    }
                    consola.info("++++++ READ -> useAllWorkouts: protocols");
                    return [4 /*yield*/, protocolsRef.get()];
                case 1:
                    protocols = _a.sent();
                    promises = protocols.docs.map(function (p) { var deNormalizedProtocol = { protocol: __assign({}, p.data()), splits: new Array() }; return new Promise(function (resolve) { getSplits(p.data().id, p.data().name, deNormalizedProtocol).then(function () { deNormalizedView.push(deNormalizedProtocol); resolve(deNormalizedView); }); }); });
                    return [4 /*yield*/, Promise.all(promises)];
                case 2:
                    _a.sent();
                    setWorkouts(deNormalizedView);
                    return [2 /*return*/];
            }
        });
    }); }, [organizationId, protocolId]);
    React.useEffect(function () { setLoading(true); getAllWorkoutsCallback([]).then(function () { setLoading(false); }); }, [organizationId, getAllWorkoutsCallback]);
    return { workouts: workouts, loading: loading, getAllWorkoutsCallback: getAllWorkoutsCallback };
};
export default useAllWorkouts;
