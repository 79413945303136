import React from "react";
import { Box, Typography, ImageList } from "@mui/material";

import { PieChart, Pie, Cell } from "recharts";
import moment from "moment";
import _ from "underscore";
import {
  getPercentFromGrams,
  getkCal,
  getActiveNutritionGoal,
} from "../../../../components/LibraryFunctions";
import ReportNoteDialog from "./ReportNoteDialog";

const HealthNotes = (props) => {
  const { reportData } = props.healthData;

  function getOptionalNotesList(reportData) {
    if (!reportData) {
      return [];
    }
    const { optional } = reportData;

    const keys = _.keys(optional);

    const notes = [];
    keys.forEach((key) => {
      const value = optional[key];
      if (value.notes && value.notes.length > 0) {
        notes.push(value.notes);
      }
    });
    return notes;
  }

  function hasNotes(reportData) {
    if (reportData) {
      if (reportData.notes && reportData.notes.length > 0) {
        return true;
      }

      if (reportData.proteinsNotes && reportData.proteinsNotes.length > 0) {
        return true;
      }

      if (reportData.carbsNotes && reportData.carbsNotes.length > 0) {
        return true;
      }

      if (reportData.fatsNotes && reportData.fatsNotes.length > 0) {
        return true;
      }

      const optionalsNotes = getOptionalNotesList(reportData);
      if (optionalsNotes.length > 0) {
        return true;
      }
    }
    return false;
  }

  if (hasNotes(reportData)) {
    return (
      <Box
        onClick={() => {
          if (props.seeNotesClicked) {
            props.seeNotesClicked(reportData);
          }
        }}
      >
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "500",
            textDecoration: "underline",
            marginBotton: 4,
          }}
        >
          See Notes
        </Typography>
      </Box>
    );
  }
  return null;
};

const MacroSummary = ({ report, isHealthData }) => {
  const { healthData } = report;

  const [reportNotesOpen, setReportNotesOpen] = React.useState(false);
  const currentReportData = React.useRef();

  function getDay(healthData) {
    const day = moment(healthData.createdOn.toDate()).format("ddd, DD MMM");
    return day;
  }

  function getTargetValue(clientData) {
    const activeGoal = getActiveNutritionGoal(clientData.nutritionGoals);

    if (clientData && clientData.activeGoalId) {
      return {
        id: clientData.activeGoalId,
        name: clientData.activeGoalName,
        proteins: clientData.proteins,
        carbs: clientData.carbs,
        fats: clientData.fats,
        water: clientData.water,
      };
    } else {
      return {
        id: null,
        name: "",
        proteins: clientData.proteins
          ? clientData.proteins
          : activeGoal.proteins,
        carbs: clientData.carbs ? clientData.carbs : activeGoal.carbs,
        fats: clientData.fats ? clientData.fats : activeGoal.fats,
        water: clientData.water ? clientData.water : activeGoal.water,
      };
    }
  }

  function getPieData(healthData) {
    const { reportData, clientData } = healthData;

    const targetValues = getTargetValue(clientData);

    if (
      reportData.proteins === 0 &&
      reportData.carbs === 0 &&
      reportData.fats === 0
    ) {
      return null;
    }

    const kCal = getkCal({
      proteins: reportData.proteins,
      carbs: reportData.carbs,
      fats: reportData.fats,
    });
    const targetkCal = getkCal({
      proteins: targetValues.proteins,
      carbs: targetValues.carbs,
      fats: targetValues.fats,
    });

    const pieData = [
      {
        name: "No Data",
        value: 0,
        baseValue: 0,
        targetValue: 0,
        color: "gray",
        kCal: kCal,
        targetkCal: targetkCal,
      },
      {
        name: "Proteins",
        value: getPercentFromGrams(targetkCal, reportData.proteins, "proteins"),
        baseValue: Math.round(reportData.proteins),
        targetValue: targetValues.proteins,
        color: "#4667CC",
      },
      {
        name: "Carbs",
        value: getPercentFromGrams(targetkCal, reportData.carbs, "carbs"),
        baseValue: Math.round(reportData.carbs),
        targetValue: targetValues.carbs,
        color: "#5D2471",
      },
      {
        name: "Fats",
        value: getPercentFromGrams(targetkCal, reportData.fats, "fats"),
        baseValue: Math.round(reportData.fats),
        targetValue: targetValues.fats,
        color: "#EB515A",
      },
    ];

    return pieData;
  }

  if (!isHealthData) {
    return (
      <Box
        display="flex"
        style={{
          margin: 8,
          padding: 8,
          width: "100%",
          height: 100,
          borderRadius: 8,
          justifyContent: "center",
          alignItems: "center",
        }}
        boxShadow={1}
      >
        <Typography style={{ fontSize: 30, fontWeight: "bold" }}>
          No macro data reported during the reporting period
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <ImageList cols={4} gap={40}>
        {healthData.map((hd, index) => {
          const data = getPieData(hd);

          if (!data) {
            return null;
          }

          return (
            <Box
              style={{
                paddingInline: 40,
                width: 210,
                height: 250,
                borderRadius: 8,
              }}
              boxShadow={0} //originall was 1
              key={`main-${index}`}
            >
              <Box>
                <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                  {getDay(hd)}
                </Typography>
              </Box>
              <Box>
                <Box display="flex">
                  <Box style={{ width: 110 }}>
                    <PieChart width={110} height={121}>
                      <Pie
                        data={data}
                        innerRadius={25}
                        fill="gray"
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {data.map((entry, index) => {
                          return (
                            <Cell
                              key={`cell-${index}`}
                              fill={data[index].color}
                            />
                          );
                        })}
                      </Pie>
                    </PieChart>
                  </Box>

                  <Box display="flex" flexDirection="column">
                    <Box>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontWeight: "500",
                          color: data[1].color,
                        }}
                      >
                        Proteins
                      </Typography>
                      <Typography style={{ fontSize: 14 }}>{`${Math.round(
                        data[1].baseValue,
                      )}g/${Math.round(data[1].targetValue)}g`}</Typography>
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontWeight: "500",
                          color: data[2].color,
                        }}
                      >
                        Carbs
                      </Typography>
                      <Typography style={{ fontSize: 14 }}>{`${Math.round(
                        data[2].baseValue,
                      )}g/${Math.round(data[2].targetValue)}g`}</Typography>
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontWeight: "500",
                          color: data[3].color,
                        }}
                      >
                        Fats
                      </Typography>
                      <Typography style={{ fontSize: 14 }}>{`${Math.round(
                        data[3].baseValue,
                      )}g/${Math.round(data[3].targetValue)}g`}</Typography>
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontWeight: "500",
                          color: data[3].color,
                        }}
                      >
                        kCal
                      </Typography>
                      <Typography style={{ fontSize: 14 }}>{`${Math.round(
                        data[0].kCal,
                      )}/${Math.round(data[0].targetkCal)}`}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <HealthNotes
                  healthData={hd}
                  seeNotesClicked={(reportData) => {
                    // Clicked to see notes.

                    currentReportData.current = reportData;
                    setReportNotesOpen(true);
                  }}
                />
              </Box>
            </Box>
          );
        })}
      </ImageList>
      <ReportNoteDialog
        open={reportNotesOpen}
        reportData={currentReportData.current}
        onDialogClosed={() => {
          setReportNotesOpen(false);
        }}
      />
    </>
  );
};

export default MacroSummary;
