import React from "react";
import { Box, Typography } from "@mui/material";

export default React.memo(({ title, value }) => {
  return (
    <Box style={{ marginBottom: 25 }}>
      <Box alignItems="center">
        <Typography
          display="inline"
          style={{ fontSize: 16, fontWeight: "400", fontFamily: "Lato", lineHeight: "normal" }}
        >
          {title}
        </Typography>
        <Typography display = "inline" paddingLeft={16} style={{ fontSize: 16, fontWeight: "400", fontFamily: "Lato", lineHeight: "normal" , marginLeft: 0 }}>{value}</Typography>
      </Box>
    </Box>
  );
});
