import { useRef, useEffect } from "react";
import consola from "consola";

const useRenderCount = ({
  name = "Unknown",
  maxWarnRenders = undefined,
} = {}) => {
  const renderCount = useRef(0);
  const nameRef = useRef(name);
  let renderCountLocal = renderCount.current;
  useEffect(() => {
    renderCount.current = renderCountLocal;
  });
  renderCountLocal++;

  if (maxWarnRenders && renderCountLocal > maxWarnRenders)
    consola.warn(
      `++++++ MAX RENDER REACHED -> ${
        nameRef?.current || "Unknown"
      }: ${renderCountLocal}`,
    );
  else {
    consola.info(
      `++++++ RENDER -> ${nameRef?.current || "Unknown"}: ${renderCountLocal}`,
    );
  }

  return renderCount.current;
};

export const useStopInfiniteRender = (maxRenders) => {
  const renderCount = useRenderCount();
  if (renderCount > maxRenders)
    throw new Error("Infinite Renders limit reached!!");
};

export default useRenderCount;
