import { selector } from "recoil";
import exerciseDBState from "./exerciseDBAtom";

const exerciseDBKeysSelector = selector({
  key: "exerciseDBKeysSelector",
  default: null,
  get: ({ get }) => {
    const exercisesState = get(exerciseDBState);
    if (!exercisesState) {
      return {};
    }
    const groups = {};
    exercisesState.forEach((g) => {
      groups[g.id] = g;
    });
    return groups;
  },
});

export default exerciseDBKeysSelector;
