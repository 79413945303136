import React from "react";
import { Box, Avatar } from "@mui/material";

function CircleImage({ size, url, firstName = "", lastName = "", onClick }) {
  const initials = React.useMemo(() => {
    if (firstName.length > 0 && lastName.length > 0) {
      return `${firstName[0]}${lastName[0]}`;
    } else if (firstName.length > 0) {
      return `${firstName[0]}`;
    } else if (lastName.length > 0) {
      return `${lastName[0]}`;
    }
    return "";
  }, [firstName, lastName]);

  return (
    <Box
      data-testid="CircleImage"
      style={{ height: size, width: "auto" }}
      onClick={onClick}
    >
      <Avatar
        src={url ? url : null}
        style={{ width: size, height: size }}
        alt={initials}
      >
        {initials}
      </Avatar>
    </Box>
  );
}

export default CircleImage;
