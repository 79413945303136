import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Typography,
  Divider,
} from "@mui/material";

import BlueButton from "../../../../components/BlueButton";
import useAllWorkouts from "../../../../hooks/useAllWorkouts";
import WorkoutSelectionList from "../../../../components/organisms/WorkoutSelectionList";
import LabelSelect from "../../../../components/molecules/LabelSelect";

const ChooseExistingWorkoutDialog = ({
  protocol,
  labels,
  open,
  onDialogClosed,
  onAddToPlan,
}) => {
  const [selectedLabels, setSelectedLabels] = React.useState([]);

  const { workouts, loading, getAllWorkoutsCallback } = useAllWorkouts();

  const setSelectedLabelsClosedCallback = React.useCallback(
    (labels) => {
      setSelectedLabels(labels.protocolLabels);
      getAllWorkoutsCallback(labels.protocolLabels);
    },
    [getAllWorkoutsCallback],
  );

  if (!open) {
    return null;
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={false}
      open={open}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Choose an Existing Workout</DialogTitle>

      <Box style={{ marginRight: 24, marginBottom: 24 }}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ marginRight: 12 }}>Labels</Typography>
              <LabelSelect
                id="ChooseExistingWorkoutDialog"
                useTimer
                currentLabels={selectedLabels}
                onLabelsChanged={setSelectedLabelsClosedCallback}
                width={400}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider style={{ marginBottom: 12 }} />

      <DialogContent style={{ width: "100%" }}>
        <WorkoutSelectionList
          protocol={protocol}
          loading={loading}
          labels={labels}
          workouts={workouts}
          onAddToPlan={onAddToPlan}
        />
      </DialogContent>
      <DialogActions>
        <BlueButton
          onClick={() => {
            if (onDialogClosed) {
              onDialogClosed();
            }
          }}
          color="primary"
        >
          Done
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChooseExistingWorkoutDialog;
