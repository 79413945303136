/* eslint-disable react/no-children-prop */
import React from "react";
import { Box, Divider, Typography, Stack, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

import remarkGfm from "remark-gfm";
import _ from "lodash";

const MealPlanDetails = ({ mealPlan, getRecipesFromList }) => {
  const { t } = useTranslation();
  const [recipes, setRecipes] = React.useState([]);
  const [ingredients, setIngredients] = React.useState([]);

  React.useEffect(() => {
    getRecipesFromList(mealPlan.selectedRecipes).then((recipes) => {
      setRecipes(recipes);
    });
  }, [mealPlan]);

  React.useEffect(() => {
    // Get all ingredients from recipes
    const ingredients = [];
    recipes.forEach((recipe) => {
      recipe.ingredients.forEach((ingredient) => {
        ingredients.push(ingredient);
      });
    });

    // get total amount of each ingredient
    const ingredientMap = {};
    ingredients.forEach((ingredient) => {
      const key = `${ingredient.name}${ingredient.unit}`;
      if (ingredientMap[key]) {
        ingredientMap[key].amount += ingredient.amount;
      } else {
        ingredientMap[key] = _.cloneDeep(ingredient);
      }
    });

    // convert to array
    const ingredientArray = [];
    Object.keys(ingredientMap).forEach((key) => {
      ingredientArray.push(ingredientMap[key]);
    });

    setIngredients(ingredientArray);
  }, [recipes]);

  return (
    <Box style={{ marginRight: 24, marginBottom: 12 }}>
      <Stack direction="row" spacing={2}>
        <Typography variant="h4">{mealPlan.name}</Typography>
      </Stack>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Stack>
              <Box sx={{ mt: 1 }}>
                <Typography variant="h5">Description</Typography>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  children={mealPlan.description}
                />
              </Box>
              <Divider sx={{ mt: 1, mb: 2 }} />

              <Typography variant="h5" sx={{ mb: 1 }}>
                Recipes Included
              </Typography>
              {recipes.map((recipe) => (
                <Stack
                  key={`${recipe.id}_${recipe.recipeId}`}
                  direction="row"
                  spacing={2}
                >
                  <Typography sx={{ width: "50%", fontWeight: "bold" }}>
                    {recipe.name}
                  </Typography>

                  <Box sx={{ width: "20%" }}>
                    <Stack direction={"row"} spacing={1}>
                      {recipe.days.map((day) => (
                        <Typography key={`${recipe.id}_${day}`}>
                          {day}
                        </Typography>
                      ))}
                    </Stack>
                  </Box>

                  <Typography sx={{ width: 100 }}>
                    {t(recipe.mealType)}
                  </Typography>
                  <Typography>Serves: {recipe.servings || 0}</Typography>
                  <Typography>kCal: {recipe.kCals}</Typography>
                </Stack>
              ))}
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Typography variant="h5" sx={{ mb: 1 }}>
                Shopping List
              </Typography>
              {ingredients.map((ingredient) => (
                <Stack
                  key={`${ingredient.id}_${ingredient.ingredientId}`}
                  direction="row"
                  spacing={2}
                >
                  <Typography sx={{ width: "25%" }}>
                    {ingredient.amount} {ingredient.unit}
                  </Typography>
                  <Typography>{ingredient.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MealPlanDetails;
