import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/firestore";

import { nanoid } from "nanoid";
import { getRecoil } from "recoil-nexus";
import Bugsnag from "@bugsnag/js";
import _ from "underscore";
import { customAlphabet } from "nanoid";

import organizationIdState from "../atoms/organizationIdSelector";
import { UserType } from "./OrganizationData";
import { consola } from "consola";

const nanoidCustom = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 8);

const AggregateCoachData = async (coachData) => {
  return await new Promise((resolve, reject) => {
    consola.info("++++++ READ -> CoachData: AggregateCoachData")
    const ref = firebase.firestore().collection("users").doc(coachData.uid);
    ref
      .get()
      .then((userData) => {
        const userDataValues = userData.data();

        if (!("status" in coachData)) {
          coachData.status = "active";
        }

        resolve({
          ...userDataValues,
          ...coachData,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const AggregateCoachesData = async (coaches) => {
  const aggregatedPromises = coaches.map((doc) => {
    return new Promise((resolve, reject) => {
      try {
        const coachValues = doc.data();

        const ref = firebase
          .firestore()
          .collection("users")
          .doc(coachValues.uid);
        ref
          .get()
          .then((userData) => {
            const userDataValues = userData.data();

            if (!("status" in coachValues)) {
              coachValues.status = "active";
            }

            resolve({
              ...userDataValues,
              ...coachValues,
            });
          })
          .catch((e) => {
            reject(e);
          });
      } catch (e) {
        reject(e);
      }
    });
  });
  const aggregated = await Promise.all(aggregatedPromises);
  return aggregated;
};

const CoachData = {
  getCoach: async (uid) => {
    if (!uid) {
      return null;
    }

    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> CoachData: getCoach")
    const userData = await ref.get();
    if (userData.exists) {
      const userDataValues = userData.data();

      if (userDataValues.coachId) {
        const organizationId = userDataValues.organizationId;

        const coachDocRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("coaches")
          .doc(userDataValues.uid);

        const coachDoc = await coachDocRef.get();

        if (coachDoc.exists) {
          return coachDoc.data();
        }
      }
    }
    return null;
  },
  getCoachSubscription: (uid, onSnapshot) => {
    if (!uid) {
      return null;
    }

    const organizationId = getRecoil(organizationIdState);

    const coachDocRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("coaches")
      .doc(uid);

    return coachDocRef.onSnapshot(async (snapshot) => {
      consola.info("++++++ SNAPSHOT -> Coach Data: getCoachSubscription")
      if (onSnapshot) {
        const aggregate = await AggregateCoachData(snapshot.data());
        onSnapshot(aggregate);
      }
    });
  },
  getCoachesSubscription: async (onChange) => {
    const organizationId = getRecoil(organizationIdState);

    const coachRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("coaches");

    return coachRef.onSnapshot(async (snapshot) => {
      consola.info("++++++ SNAPSHOT -> CoachData: getCoachesSubscription")
      if (onChange) {
        let aggregated = await AggregateCoachesData(snapshot.docs);
        aggregated = _.sortBy(aggregated, "firstName");

        onChange(aggregated);
      }
    });
  },
  addCoach: async (data) => {
    const organizationId = getRecoil(organizationIdState);
    consola.info("++++++ BATCH -> CoachData addCoach")
    const batch = firebase.firestore().batch();

    const uid = nanoid();
    const connectCode = nanoidCustom();

    const userRef = firebase.firestore().collection("users").doc(uid);
    consola.info("+++++ WRITE => CoachData: addCoach")
    batch.set(
      userRef,
      {
        uid: uid,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        organizationId: organizationId,
        type: UserType.coach,
        role: data.role,
      },
      { merge: true },
    );

    const coachRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("coaches")
      .doc(uid);
    batch.set(
      coachRef,
      {
        uid: uid,
        connectCode: connectCode,
        status: data.status,
      },
      { merge: true },
    );

    batch.set(
      userRef,
      {
        connectCode: connectCode,
      },
      { merge: true },
    );
    consola.info("+++++ WRITE => CoachData: addCoach")
    await batch.commit();
  },
  editCoach: async (coachId, input) => {
    const organizationId = getRecoil(organizationIdState);
    const batch = firebase.firestore().batch();

    const coachRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("coaches")
      .doc(coachId);
    consola.info("++++++ READ -> CoachData: editCoach")
    const coachData = await coachRef.get();
    if (coachData.exists) {
      const coachUserRef = firebase
        .firestore()
        .collection("users")
        .doc(coachId);

      const status = input.status;
      const coachInput = {
        updatedAt: new Date(),
      };
      if (status) {
        coachInput.status = status;
      }

      // Data is user data.
      consola.info("+++++ WRITE => CoachData: editCoach")
      batch.set(coachUserRef, _.omit(input, ["status"]), {
        merge: true,
      });

      batch.set(coachRef, coachInput, { merge: true });
      consola.info("+++++ WRITE => coachData: editCoach")
      await batch.commit();
    }
  },
  deleteCoach: async (coachId) => {
    const organizationId = getRecoil(organizationIdState);
    const coachRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("coaches")
      .doc(coachId);
    consola.info("++++++ READ -> CoachData: deleteCoach")
    const coach = await coachRef.get();
    if (coach.exists) {
      try {
        // Now do the deletiong from all over the place.
        await firebase.firestore().runTransaction(async () => {
          const messageGroupsRef = firebase
            .firestore()
            .collection("organizations")
            .doc(organizationId)
            .collection("messageGroups");

          const messageGroups = await messageGroupsRef.get();

          const messageRefs = [];
          // Find all messages with this coach.
          messageGroups.forEach(async (messageGroup) => {
            //
            const messageGroupData = messageGroup.data();

            const messagesRef = firebase
              .firestore()
              .collection("organizations")
              .doc(organizationId)
              .collection("messageGroups")
              .doc(messageGroupData.id)
              .collection("messages");

            const messagesData = await messagesRef.get();
            messagesData.forEach((message) => {
              const messageValue = message.data();
              if (messageValue.fromUID === coachId) {
                messageRefs.push(message.ref);
              }
            });
          });
        });
      } catch (e) {
        Bugsnag.notify(e);
      }
    }
  },
  resetConnectCode: async (uid) => {
    const organizationId = getRecoil(organizationIdState);
    const batch = firebase.firestore().batch();

    const coachRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("coaches")
      .doc(uid);

    const connectCode = nanoidCustom();
    consola.info("++++++ READ -> CoachData: resetConnectCode")
    const coach = await coachRef.get();
    if (coach.exists) {
      batch.set(
        coachRef,
        {
          connectCode: connectCode,
        },
        { merge: true },
      );

      const userRef = firebase.firestore().collection("users").doc(uid);
      consola.info("+++++ WRITE => CoachData: resetConnectCode")
      batch.set(
        userRef,
        {
          connectCode: connectCode,
        },
        { merge: true },
      );
      await batch.commit();
    }
  },
};

export default CoachData;
