import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";

import { CoachStatus } from "../../../../data/GlobalData";
import BlueButton from "../../../../components/BlueButton";
import { UserType } from "../../../../data/OrganizationData";

const AddCoachDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [lastName, setLastName] = React.useState(
    props.coach ? props.coach.lastName : "",
  );
  const [firstName, setFirstName] = React.useState(
    props.coach ? props.coach.firstName : "",
  );
  const [email, setEmail] = React.useState(
    props.coach ? props.coach.email : "",
  );
  const [role, setRole] = React.useState(
    props.coach ? props.coach.role : UserType.coach,
  );
  const [status, setStatus] = React.useState(
    props.coach && props.coach.status ? props.coach.status : "active",
  );

  const [error, setError] = React.useState(null);

  function validate() {
    if (lastName.length === 0 || firstName.length === 0) {
      return false;
    }

    if (email && email.length !== 0) {
      // validate the email
      const re = /\S+@\S+\.\S+/;
      if (!re.test(email)) {
        return false;
      }
    }

    return true;
  }

  React.useEffect(() => {
    if (!props.coach) {
      setLastName("");
      setFirstName("");
      setEmail("");
      setRole(UserType.coach);
    } else {
      setLastName(props.coach.lastName);
      setFirstName(props.coach.firstName);
      setEmail(props.coach.email ? props.coach.email : "");
      setRole(props.coach.role);
    }
    setOpen(props.open);
  }, [props.open]); // eslint-disable-line

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>
        {props.mode === "edit" ? "Edit Coach" : "Add Coach"}
      </DialogTitle>

      <DialogContent>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>First Name:</Typography>
          <TextField
            value={firstName}
            style={{ width: 350 }}
            variant="outlined"
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          ></TextField>
        </Box>

        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Last Name:</Typography>
          <TextField
            value={lastName}
            style={{ width: 350 }}
            variant="outlined"
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          ></TextField>
        </Box>

        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Email:</Typography>
          <TextField
            value={email}
            style={{ width: 350 }}
            variant="outlined"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          ></TextField>
        </Box>

        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Role:</Typography>
          <Select
            style={{ width: 350 }}
            disabled={!props.userData || props.userData.role === UserType.coach}
            variant="outlined"
            defaultValue={UserType.coach}
            value={role}
            onChange={(event) => {
              setRole(event.target.value);
            }}
          >
            <MenuItem value={UserType.coach}>Coach</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </Box>

        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Status:</Typography>
          <Select
            style={{ width: 350 }}
            disabled={!props.userData || props.userData.role === UserType.coach}
            variant="outlined"
            defaultValue={CoachStatus.active}
            value={status}
            onChange={(event) => {
              setStatus(event.target.value);
            }}
          >
            <MenuItem value={CoachStatus.active}>Active</MenuItem>
            <MenuItem value={CoachStatus.disabled}>Inactive</MenuItem>
          </Select>
        </Box>
      </DialogContent>

      {error && (
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ width: 200, color: "red" }}>
              {error}
            </Typography>
          </Box>
        </DialogContent>
      )}

      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            if (props.onDialogClosed) {
              props.onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (validate()) {
              setOpen(false);
              if (props.onDialogClosed) {
                props.onDialogClosed({
                  lastName: lastName,
                  firstName: firstName,
                  email: email,
                  role: role,
                  status: status,
                });
              }
            } else {
              setError("First name and last name must be entered.");
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddCoachDialog;
