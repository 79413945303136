import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import {
  getFirestore,
  doc,
  setDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";

import { getRecoil } from "recoil-nexus";
import Bugsnag from "@bugsnag/js";
import _ from "underscore";
import { consola } from "consola";

import ClientData from "../data/ClientData";
import { sendMessageToUsersGroup } from "../data/MessageData";

import organizationIdSelector from "../atoms/organizationIdSelector";
import { nanoid } from "nanoid";
import useEvents from "./useEvents";

const useSupplements = () => {
  const organizationId = getRecoil(organizationIdSelector);
  const events = useEvents();

  const [supplements, setSupplements] = React.useState([]);

  const editSupplementData = React.useCallback(
    async function (supplementData) {
      // Add the log data here.
      let id = nanoid();
      if (supplementData.id) {
        id = supplementData.id;
      }

      const db = getFirestore();
      const ref = doc(db, "organizations", organizationId, "supplements", id);

      try {
        await setDoc(
          ref,
          {
            id: id,
            name: supplementData.name,
            description: supplementData.description,
            salesLink: supplementData.salesLink,
            createdOn: supplementData.createdOn,
            updatedOn: supplementData.updatedOn,
          },
          { merge: true },
        );
        const logEvent = {
          buttonName: "Edit Supplement",
          pageName: "Clients",
        };
        events.logButtonClick(logEvent);
      } catch (e) {
        consola.log("Error saving supplement data", e);
        Bugsnag.notify(new Error("Error saving supplement data"));
      }
    },
    [organizationId],
  );

  // TODO: Removing a supplement should activate a cloud function to delete references.
  const deleteSupplementData = React.useCallback(
    async function (id) {
      const ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("supplements")
        .doc(id);

      try {
        await firebase.firestore().runTransaction(async () => {
          // Get all clients with this supplement
          const clientsRef = firebase
            .firestore()
            .collection("organizations")
            .doc(organizationId)
            .collection("clients");
          consola.info("++++++ READ -> useSupplements clients");
          const clients = await clientsRef
            .where("supplementPrescriptionsIndex", "array-contains-any", [id])
            .get();

          clients.docs.forEach(async (doc) => {
            const client = doc.data();

            if (client.supplementPrescriptions) {
              const supplementPrescriptions = client.supplementPrescriptions;
              const index = _.findIndex(supplementPrescriptions, {
                supplementId: id,
              });

              if (index !== -1) {
                const newSupplementPrescriptions = _.without(
                  supplementPrescriptions,
                  _.findWhere(supplementPrescriptions, {
                    supplementId: id,
                  }),
                );

                await ClientData.updateClient(client.uid, {
                  supplementPrescriptions: newSupplementPrescriptions,
                  supplementPrescriptionsIndex: newSupplementPrescriptions.map(
                    (s) => {
                      return s.supplementId;
                    },
                  ),
                  supplementPrescriptionChanged: new Date(),
                });
              }
            }
          });

          await ref.delete();
        });
      } catch (e) {
        Bugsnag.notify(e);
      }
    },
    [organizationId],
  );

  const getSupplementById = React.useCallback(
    (id) => {
      if (!supplements) {
        return null;
      }
      const value = _.findWhere(supplements, { id: id });
      return value;
    },
    [supplements],
  );

  async function deleteClientSupplementData(client, supplementData) {
    let supplementPrescriptions = [];
    if (client.supplementPrescriptions) {
      supplementPrescriptions = client.supplementPrescriptions;
    }

    if (supplementPrescriptions) {
      let newSupplementPrescriptions = [].concat(supplementPrescriptions);
      const index = _.findIndex(newSupplementPrescriptions, {
        id: supplementData.id,
      });

      let changeMessage = "";
      if (index !== -1) {
        const supplement = getSupplementById(supplementData.supplementId);
        changeMessage = `Your coach changed your supplement prescription. Removed ${supplement.name}.`;

        newSupplementPrescriptions = _.without(
          newSupplementPrescriptions,
          _.findWhere(newSupplementPrescriptions, {
            id: supplementData.id,
          }),
        );

        await ClientData.updateClient(client.uid, {
          supplementPrescriptions: newSupplementPrescriptions,
          supplementPrescriptionsIndex: newSupplementPrescriptions.map((s) => {
            return s.supplementId;
          }),
          supplementPrescriptionChanged: new Date(),
        });

        await sendMessageToUsersGroup({
          uid: client.uid,
          message: changeMessage,
        });
      }
    }
  }

  async function editClientSupplementData(client, supplementData) {
    let supplementPrescriptions = [];
    if (client.supplementPrescriptions) {
      supplementPrescriptions = client.supplementPrescriptions;
    }

    if (supplementPrescriptions) {
      const newSupplementPrescriptions = [].concat(supplementPrescriptions);
      const index = _.findIndex(newSupplementPrescriptions, {
        id: supplementData.id,
      });

      let changeMessage = "";
      if (index !== -1) {
        const supplement = getSupplementById(supplementData.supplementId);
        supplementData.name = supplement.name;
        newSupplementPrescriptions[index] = supplementData;
        changeMessage = `Your coach changed your supplement prescription. ${
          supplement ? supplement.name : ""
        } - ${supplementData.dosage}, ${supplementData.frequency}`;
      } else {
        // Add a new one.
        if (newSupplementPrescriptions.length === 0) {
          supplementData.isActive = true;
        }
        const supplement = getSupplementById(supplementData.supplementId);
        supplementData.name = supplement.name;

        newSupplementPrescriptions.push(supplementData);
        changeMessage = `Your coach added a supplement prescription. ${
          supplement ? supplement.name : ""
        } - ${supplementData.dosage}, ${supplementData.frequency}`;
      }

      await ClientData.updateClient(client.uid, {
        supplementPrescriptions: newSupplementPrescriptions,
        supplementPrescriptionsIndex: newSupplementPrescriptions.map((s) => {
          return s.supplementId;
        }),
        supplementPrescriptionChanged: new Date(),
      });

      await sendMessageToUsersGroup({
        uid: client.uid,
        message: changeMessage,
        mustBeRegistered: true,
      });
    }
  }

  React.useEffect(() => {
    const db = getFirestore();
    const ref = collection(db, "organizations", organizationId, "supplements");
    const theQuery = query(ref, orderBy("name", "asc"));

    const subscription = onSnapshot(theQuery, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return doc.data();
      });
      setSupplements(data);
    });

    return () => {
      subscription();
    };
  }, [organizationId]);

  return {
    supplements,
    editSupplementData,
    editClientSupplementData,
    deleteClientSupplementData,
    deleteSupplementData,
    getSupplementById,
  };
};

export default useSupplements;
