import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
} from "@mui/material";
import MDEditor from "@uiw/react-md-editor";

import BlueButton from "../../../../components/BlueButton";
import { isValidUrl } from "../../../../components/LibraryFunctions";

const SupplementEditDialog = ({
  supplement,
  open = false,
  onDialogClosed = null,
}) => {
  const [dialogOpen, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [salesLink, setSalesLink] = React.useState("");

  React.useEffect(() => {
    setOpen(open);
  }, [open]); // eslint-disable-line

  React.useEffect(() => {
    if (supplement) {
      setName(supplement.name);
      setDescription(supplement.description);
      setSalesLink(supplement.salesLink);
    } else {
      setName("");
      setDescription("");
      setSalesLink("");
    }
  }, [supplement]);

  function validate() {
    if (!name) {
      return ["A name is required."];
    }
    if (!isValidUrl(salesLink)) {
      return ["The sales link is not valid."];
    }
    return null;
  }
  if (!dialogOpen) {
    return null;
  }

  return (
    <Dialog
      key="SupplementEditDialog"
      maxWidth="lg"
      open={dialogOpen}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>
        {!supplement ? "Add Supplement" : "Edit Supplement"}
      </DialogTitle>

      <DialogContent>
        <Stack
          spacing={1}
          style={{
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Typography>Supplement:</Typography>
          <TextField
            value={name}
            style={{ width: "100%" }}
            variant="outlined"
            onChange={(event) => {
              setName(event.target.value);
            }}
          ></TextField>
        </Stack>

        <Stack
          spacing={1}
          style={{
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Typography>Sales Link:</Typography>
          <TextField
            value={salesLink}
            style={{ width: "100%" }}
            variant="outlined"
            onChange={(event) => {
              setSalesLink(event.target.value);
            }}
          ></TextField>
        </Stack>

        <Stack
          spacing={1}
          style={{
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Typography>Description:</Typography>
          <div data-color-mode="light">
            <MDEditor
              preview="edit"
              style={{ width: 800 }}
              placeholder="Enter healthy recipe description or video link here"
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>
        </Stack>
      </DialogContent>

      {error && (
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ width: 200, color: "red" }}>
              {error}
            </Typography>
          </Box>
        </DialogContent>
      )}

      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            if (onDialogClosed) {
              onDialogClosed(null, null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            const validateResult = validate();
            if (!validateResult) {
              setOpen(false);
              if (onDialogClosed) {
                onDialogClosed(
                  {
                    id: supplement ? supplement.id : null,
                    name,
                    description,
                    salesLink,
                  },
                  supplement,
                );
              }
            } else {
              setError(validateResult[0]);
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default SupplementEditDialog;
