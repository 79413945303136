import { Typography, Box, Button, Grid, Stack } from "@mui/material";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

import useProgressPhotos from "../../../../hooks/useProgressPhotos";

import { EditButton, DeleteButton } from "../../../../atoms/EditButton";
import QuestionEditDialog from "./QuestionEditDialog";
import { QuestionType } from "../../../../data/GlobalData";
import ReorderDialog from "./ReorderDialog";
import useEvents from "../../../../hooks/useEvents";

const EditProgressPhotos = () => {
  const {
    progressPhotos,
    saveProgressPhoto,
    deleteProgressPhoto,
    addProgressPhoto,
  } = useProgressPhotos();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [questionReorderDialogOpen, setQuestionReorderDialogOpen] =
    React.useState(false);
  const theme = useTheme();
  const events = useEvents();

  const selectedQuestion = React.useRef();

  const titleStrings = React.useMemo(() => {
    const tab = "Progress Photos";
    const button = "Add Progress Photo Position";
    const title = "Progress Photo Positions";
    const description =
      "Progress photo positions determine what angles you want the client to take pictures of when they report. Examples: Front, Side, Back, etc.";
    const editTitle = "Edit Progress Photo Position";
    const reorderTitle = "Reorder Progress Photo Positions";
    return { tab, button, title, description, editTitle, reorderTitle };
  });

  const columns = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        headerAlign: "left",
        align: "left",
        flex: 1,
        type: "string",
        hideable: false,
        sortable: false,
        filterable: false,
      },

      {
        field: "icons",
        headerName: "",
        align: "left",
        // fit width properly
        width: 120,
        hideable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {/* <ArrowUpButton
                onClick={() => {
                  moveProgressPhoto(params.row.id, "up");
                }}
              />
              <ArrowDownButton
                onClick={() => {
                  moveProgressPhoto(params.row.id, "down");
                }}
              /> */}
              <EditButton
                onClick={() => {
                  selectedQuestion.current = params.row;
                  setDialogOpen(true);
                  const editQuestionEvent = {
                    buttonName: "Edit Progress Photo",
                    pageName: "Admin",
                    tabName: "Progress Photos ",
                  };
                  events.logButtonClick(editQuestionEvent);
                }}
              />
              <DeleteButton
                onClick={() => {
                  confirmAlert({
                    title: `Delete this progress photo question?`,
                    message:
                      "This cannot be undone. The question will be removed everywhere it is being used.",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: async () => {
                          deleteProgressPhoto(params.row.id);
                          const deleteQuestionEvent = {
                            buttonName: "Delete Progress Photo",
                            pageName: "Admin",
                            tabName: "Progress Photos ",
                          };
                          events.logButtonClick(deleteQuestionEvent);
                        },
                      },
                      {
                        label: "No",
                        onClick: () => {
                          const cancelDeleteEvent = {
                            buttonName: "Cancel Progress Photo Delete",
                            pageName: "Admin",
                            tabName: "Progress Photos ",
                          };
                          events.logButtonClick(cancelDeleteEvent);
                        },
                      },
                    ],
                  });
                }}
              />
            </Box>
          );
        },
      },
    ];
  }, [saveProgressPhoto, deleteProgressPhoto]);

  if (progressPhotos) {
    return (
      <Box style={{ marginBottom: 54 }}>
        <Grid container style={{ marginTop: 4 }}>
          <Grid item xs={8}>
            <Typography
              style={{ fontSize: 20, fontWeight: "500", marginBottom: 12 }}
            >
              Progress Photo Positions
            </Typography>
            <Typography
              style={{ fontSize: 16, fontWeight: "normal", marginBottom: 12 }}
            >
              Progress photo positions determine what angles you want the client
              to take pictures of when they report. Examples: Front, Side, Back,
              etc.
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Stack direction={"row"} spacing={3} justifyContent={"flex-end"}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"flex-end"}
              >
                <Button
                  style={{ marginTop: 12 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    selectedQuestion.current = null;
                    setQuestionReorderDialogOpen(true);
                    const reorderQuestionEvent = {
                      buttonName: "Reorder Progress Photos",
                      pageName: "Admin",
                      tabName: "Progress Photos ",
                    };
                    events.logButtonClick(reorderQuestionEvent);
                  }}
                >
                  Reorder Progress Photos
                </Button>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"flex-end"}
              >
                <Button
                  style={{ marginTop: 12 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    selectedQuestion.current = null;
                    setDialogOpen(true);
                    const addQuestionEvent = {
                      buttonName: "Add Progress Photo",
                      pageName: "Admin",
                      tabName: "Progress Photos ",
                    };
                    events.logButtonClick(addQuestionEvent);
                  }}
                >
                  {titleStrings.button}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Box style={{ margin: 12 }}>
          <Box sx={{ marginTop: 1, height: window.innerHeight - 420 }}>
            <DataGrid
              sx={{
                ".MuiDataGrid-sortIcon": {
                  color: theme.palette.primary.main,
                },
                ".MuiDataGrid-filterIcon": {
                  color: theme.palette.primary.main,
                },
                ".MuiDataGrid-menuIconButton": {
                  color: theme.palette.primary.main,
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
              disableSelectionOnClick
              rows={progressPhotos}
              columns={columns}
              getRowHeight={() => {
                return "auto";
              }}
              // onRowClick={(params) => {
              //   if (onProtocolClick) {
              //     onProtocolClick(params.row);
              //   }
              // }}
            />
          </Box>
        </Box>

        {dialogOpen && (
          <QuestionEditDialog
            open={dialogOpen}
            questionType={QuestionType.progress}
            titleStrings={titleStrings}
            question={selectedQuestion.current}
            onDialogClosed={async (data, question) => {
              if (!data && !question) {
                setDialogOpen(false);
                return;
              }
              setDialogOpen(false);

              if (data && question) {
                question.name = data.name;

                saveProgressPhoto(progressPhotos);
              } else {
                addProgressPhoto(data.name);
              }
            }}
          />
        )}
        {questionReorderDialogOpen && (
          <ReorderDialog
            open={questionReorderDialogOpen}
            reorderTitle={titleStrings.reorderTitle}
            list={progressPhotos}
            onDialogClosed={async (data) => {
              if (!data) {
                setQuestionReorderDialogOpen(false);
                return;
              }
              setQuestionReorderDialogOpen(false);
              if (data) {
                saveProgressPhoto(data);
              }
            }}
            // onDialogClosed={(questions, list) => {
            //   if (list) {
            //     ProtocolData.setSplitOrder(protocolId, list);
            //   }
            //   setQuestionReorderDialogOpen(false);
            // }}
          />
        )}
      </Box>
    );
  }
};
export default EditProgressPhotos;
