import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import moment from "moment";
import _ from "underscore";
import OptionalDataSummary from "./OptionalDataSummary";
import { ExpandMore } from "@mui/icons-material";

export default React.memo((props) => {
  const [expanded, setExpanded] = React.useState(false);

  const data = props.report.healthData.map((hd) => {
    return {
      date: moment(hd.createdOn.toDate()).format("MM/DD"),
      ...hd.reportData.optional,
    };
  });

  const filtered = _.filter(data, (i) => {
    let value = false;
    _.chain(i)
      .omit(["date"])
      .values()
      .forEach((v) => {
        if (v.value > 0) {
          value = true;
        }
      });
    return value;
  });

  if (filtered.length === 0) {
    return null;
  }

  return (
    <Box style={{ marginTop: 20 }}>
      <Accordion
        display="flex"
        boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            viewBox="0 0 56 56"
            fill="none"
          >
            <rect x="0.067627" width="56" height="56" rx="8" />
            <path
              d="M46.7343 35.4668V39.2002H16.8676V9.3335H20.6009V30.9308L31.2596 14.1308L37.1583 18.0695L41.6756 13.5522L44.3263 16.2028L37.6436 22.9415L32.3423 19.4135L22.1316 35.4668M13.1343 42.9335V13.0668H9.40094V46.6668H46.7343V42.9335H13.1343Z"
              fill="black"
            />
          </svg>
          <Typography
            style={{
              color: "black",
              fontSize: 24,
              fontWeight: "600",
              marginLeft: 5,
            }}
          >
            Daily Data
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OptionalDataSummary report={props.report} update={props.update} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});
