import React from "react";
import { Box } from "@mui/material";
import colors from "../../../../themes/Colors";

export const parentBoxStyle = {
  width: "95%",
  backgroundColor: colors.parentBoxGray,
  padding: 15,
  marginTop: 0,
  marginBottom: 0,
  borderRadius: 12,
};

export const surroundingBoxStyle = {
  backgroundColor: "#F6F7FB",
  padding: 20,
  width: "98%",
};

export const SurroundingBox = ({ children }) => {
  return <Box style={surroundingBoxStyle}>{children}</Box>;
};

export const RaisedBox = ({ children }) => {
  return (
    <Box style={{ backgroundColor: "#F6F7FB", padding: 12 }} boxShadow={1}>
      {children}
    </Box>
  );
};
