import { Typography, Box, Button, Stack, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { nanoid } from "nanoid";
import { DataGrid } from "@mui/x-data-grid";

import IngredientEditDialog from "./IngredientEditDialog";
import useIngredients from "../../../../hooks/useIngredients";

import { EditButton } from "../../../../atoms/EditButton";
import useEvents from "../../../../hooks/useEvents";

const EditIngredients = () => {
  const theme = useTheme();
  const { ingredients, editIngredient } = useIngredients();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const storedModel = localStorage.getItem("ingredientsGridColumns");
  const events = useEvents();
  const [visibleModel, setVisibilityModel] = React.useState(
    storedModel ? JSON.parse(storedModel) : {},
  );
  const selected = React.useRef();

  const onEdit = (row) => {
    selected.current = row;
    setDialogOpen(true);
  };

  const columns = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Ingredient Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "proteins",
        headerName: "Proteins (g)",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
        valueGetter: (params) => {
          const value = params.row.proteins;
          if (!value) {
            return 0;
          }
          return value;
        },
      },
      {
        field: "carbs",
        headerName: "Carbs (g)",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
        valueGetter: (params) => {
          const value = params.row.carbs;
          if (!value) {
            return 0;
          }
          return value;
        },
      },
      {
        field: "fats",
        headerName: "Fats (g)",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
        valueGetter: (params) => {
          const value = params.row.fats;
          if (!value) {
            return 0;
          }
          return value;
        },
      },
      {
        field: "unit",
        headerName: "per unit",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
        valueGetter: (params) => {
          const value = params.row.unit;
          if (!value) {
            return "-";
          }
          return value;
        },
      },
      {
        minWidth: 35,
        headerAlign: "center",
        align: "right",
        field: "delete",
        headerName: "",
        sortable: false,
        hideable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Stack
              spacing={0}
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <EditButton
                onClick={() => {
                  events.logButtonClick({
                    buttonName: "Edit Ingredient",
                    pageName: "Nutrition",
                    tabName: "Recipes",
                  });
                  onEdit(params.row);
                }}
              />
            </Stack>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <Grid container style={{ marginTop: 0 }}>
        <Grid item xs={8}>
          <Typography variant="h6">Ingredients</Typography>
          <Typography
            style={{ fontSize: 16, fontWeight: "normal", marginBottom: 12 }}
          >
            This your ingredient database. Setup ingredients you will use in the
            recipes you create.
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Box display="flex" flexDirection="row" justifyContent={"flex-end"}>
            <Button
              style={{ marginTop: 12 }}
              variant="contained"
              color="primary"
              onClick={() => {
                selected.current = null;
                setDialogOpen(true);
                events.logButtonClick({
                  buttonName: "Add Ingredient",
                  pageName: "Nutrition",
                  tabName: "Ingredients",
                });
              }}
            >
              Add Ingredient
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1, height: 200 }}>
        <DataGrid
          sx={{
            ".MuiDataGrid-sortIcon": {
              color: theme.palette.primary.main,
            },
            ".MuiDataGrid-filterIcon": {
              color: theme.palette.primary.main,
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-menuIconButton": {
              color: theme.palette.primary.main,
            },
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
          columnVisibilityModel={visibleModel}
          disableSelectionOnClick
          rows={ingredients}
          columns={columns}
          getRowHeight={() => {
            return "auto";
          }}
          onColumnVisibilityModelChange={(model) => {
            localStorage.setItem(
              "clientDataGridColumns",
              JSON.stringify(model),
            );
            setVisibilityModel(model);
          }}
        />
      </Box>

      {dialogOpen && (
        <IngredientEditDialog
          open={dialogOpen}
          ingredient={selected.current}
          onDialogClosed={async (data, ingredient) => {
            if (!data && !ingredient) {
              setDialogOpen(false);
              return;
            }
            setDialogOpen(false);

            if (data && ingredient) {
              ingredient.name = data.name;
              ingredient.proteins = data.proteins;
              ingredient.carbs = data.carbs;
              ingredient.fats = data.fats;
              ingredient.unit = data.unit;

              editIngredient(ingredient);
            } else {
              const newIngredient = {
                id: nanoid(),
                name: data.name,
                proteins: data.proteins,
                carbs: data.carbs,
                fats: data.fats,
                unit: data.unit,
              };

              editIngredient(newIngredient);
            }
          }}
        />
      )}
    </>
  );
};

export default EditIngredients;
