import React from "react";
import { Typography } from "@mui/material";

function replaceWithBr(value) {
  return value.replace(/\n/g, "<br />");
}

const NewLineTypography = ({ text }) => {
  return (
    <Typography
      style={{
        fontSize: 16,
        whiteSpace: "pre-wrap",
      }}
      dangerouslySetInnerHTML={{
        __html: replaceWithBr(text),
      }}
    />
  );
};

export default NewLineTypography;
