import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import useHistory from "../../hooks/useHistory";

function GetRoundedString(title, value) {
  if (isNaN(value + 1)) {
    return title + ": " + String(value);
  } else {
    return title + ": " + String(Math.round(value));
  }
}

function ChangeDifference({ title, value }) {
  if (title === "changedOn" || title === "updatedOn") {
    return null;
  }
  const finalValue = `${value}`;
  return (
    <Grid key={title} item sm={2}>
      <Typography key={title} style={{ fontSize: 14 }}>
        {GetRoundedString(title, finalValue)}
      </Typography>
      {/* <Button key={title} style={{fontSize: 12 }}
        onClick={() => {
          consola.log("Clicked on history!")
        }}
      >
        {title}: {finalValue}

      </Button> */}
    </Grid>
  );
}

function HistoryChange({ change }) {
  const { differences } = change;
  return (
    <Grid container spacing={1}>
      {/* <Button
          style={{
            color: "black",
            margin: 0,
            padding: 0,
            justifyContent: "left",
            textAlign: "left",
            fontSize: 14,
            fontWeight: "500",
            cursor: "default",
          }}
          disabled
        >
          {change.name}
        </Button> */}

      {Object.keys(differences).map((key) => {
        return (
          <ChangeDifference key={key} title={key} value={differences[key]} />
        );
      })}
    </Grid>
  );
}

// function HistoryItem({ history }) {
//   if (!history || !history.changes || history.changes.length === 0) {
//     return null;
//   }
//   return (
//     <Box key={history.id} sx={{ mb: 1 }}>
//       <Typography
//         style={{
//           color: colors.primaryBlue,
//           textAlign: "left",
//           fontSize: 12,
//           fontWeight: "bold",
//         }}
//       >
//         {moment(history.changedOn.toDate()).format("LLL")}
//       </Typography>
//       <Box>
//         {history.changes.map((change) => {
//           return <HistoryChange key={change.id} change={change} />;
//         })}
//       </Box>
//     </Box>
//   );
// }

function RecentHistory({ client, historyCollection, limit = 10 }) {
  const { history } = useHistory({
    uid: client.uid,
    historyCollection: historyCollection,
    limit: limit,
  });

  return (
    <Box
      sx={{ marginInline: 9 }}
      backgroundColor={"#D2E6F447"}
      borderRadius={4}
      marginTop={2}
    >
      <TableContainer padding={5}>
        <Table aria-label="history table">
          <TableHead>
            <TableRow>
              <TableCell
                width={150}
                align="left"
                style={{ borderBottomColor: "black" }}
              >
                <Typography fontSize={16} fontWeight={600}>
                  Recent History
                </Typography>
              </TableCell>
              <TableCell
                width={200}
                align="left"
                style={{ borderBottomColor: "black" }}
              >
                <Typography fontSize={16} fontWeight={600}>
                  Date Changed
                </Typography>
              </TableCell>
              <TableCell
                width={1000}
                align="left"
                style={{ borderBottomColor: "black" }}
              >
                <Typography fontSize={16} fontWeight={600}>
                  Changes
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {history.length === 0 && (
              <TableRow>
                <TableCell
                  style={{ width: 200, borderBottom: "None", marginTop: 0 }}
                >
                  <Typography fontSize={14} textAlign={"left"}>
                    No historic data to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {history.map((h) => {
              if (!h || !h.changes || h.changes.length === 0) {
                return null;
              } else {
                return (
                  <TableRow key={h.id}>
                    <TableCell style={{ borderBottom: "None", marginTop: 0 }}>
                      <Typography fontSize={14} textAlign={"left"}>
                        {h.changes[0].name}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "None", marginTop: 0 }}>
                      <Typography fontSize={14} textAlign={"left"}>
                        {moment(h.changedOn.toDate()).format("MMMM D, YYYY")}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "None", marginTop: 0 }}>
                      <Typography fontSize={14} textAlign={"left"}>
                        {h.changes.map((change) => {
                          return (
                            <HistoryChange key={change.id} change={change} />
                          );
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Typography sx={{ fs: 14, fontWeight: "bold" }}>
        Recent History
      </Typography>
      <Typography
        sx={{
          fontSize: 13,
          fs: 14,
          fontStyle: "italic",
          mb: 1,
        }}
      >
        {revertString}
      </Typography>
      <Box>
        {history?.map((h) => {
          return <HistoryItem key={h.id} history={h} />;
        })}
      </Box> */}
    </Box>
  );
}

export default RecentHistory;
