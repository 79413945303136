import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Link,
} from "@mui/material";
import _ from "lodash";
import { nanoid } from "nanoid";

import useSupplements from "../../hooks/useSupplements";

import BlueButton from "../BlueButton";
import useEvents from "../../hooks/useEvents";

const SupplementPrescriptionDialog = ({
  supplementPrescription,
  open,
  onDialogClosed,
}) => {
  const { supplements } = useSupplements();
  const [openDialog, setOpenDialog] = React.useState(open);

  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const events = useEvents();

  function validate() {
    return true;
  }

  React.useEffect(() => {
    if (supplementPrescription) {
      setData(_.cloneDeep(supplementPrescription));
    } else {
      setData({
        id: nanoid(),
        supplementId: "",
        dosage: "",
        frequency: "",
        instructions: "",
        isActive: true,
        updatedOn: new Date(),
      });
    }
    setOpenDialog(open);
  }, [open, supplementPrescription]);

  const selectedSupplement = React.useMemo(() => {
    if (data && data.supplementId && data.supplementId.length > 0) {
      return supplements.find(
        (supplement) => supplement.id === data.supplementId,
      );
    }
    return null;
  }, [data, supplements]);

  if (!data || !open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      open={openDialog}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Supplement Prescription</DialogTitle>

      <DialogContent>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Supplement:</Typography>
          <Select
            style={{ width: 512 }}
            variant="outlined"
            value={data.supplementId}
            onChange={(event) => {
              setData({
                ...data,
                supplementId: event.target.value,
              });
            }}
          >
            {supplements.map((supplement) => (
              <MenuItem key={supplement.id} value={supplement.id}>
                {supplement.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {selectedSupplement && selectedSupplement.salesLink && (
          <>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 18,
                overflowWrap: "anywhere",
              }}
            >
              <Typography style={{ width: 200 }}>Supplement URL:</Typography>
              <Link
                href={selectedSupplement.salesLink}
                target="_blank"
                style={{ width: 512 }}
              >
                {selectedSupplement.salesLink}
              </Link>
            </Box>
          </>
        )}
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Dosage:</Typography>
          <TextField
            value={data.dosage}
            style={{ width: 512 }}
            variant="outlined"
            onChange={(event) => {
              setData({
                ...data,
                dosage: event.target.value,
              });
            }}
          />
        </Box>

        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Frequency:</Typography>
          <TextField
            value={data.frequency}
            style={{ width: 512 }}
            variant="outlined"
            onChange={(event) => {
              setData({
                ...data,
                frequency: event.target.value,
              });
            }}
          />
        </Box>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Instructions:</Typography>
          <TextField
            value={data.instructions}
            style={{ width: 512 }}
            multiline
            maxRows={6}
            minRows={6}
            variant="outlined"
            onChange={(event) => {
              setData({
                ...data,
                instructions: event.target.value,
              });
            }}
          ></TextField>
        </Box>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Active:</Typography>
          <Checkbox
            checked={data.isActive}
            onChange={() => {
              setData({
                ...data,
                isActive: !data.isActive,
              });
            }}
            name="isActive"
          />
        </Box>
        {error && (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ width: 200, color: "red" }}>
              {error}
            </Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setOpenDialog(false);
            if (onDialogClosed) {
              onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (validate()) {
              setOpenDialog(false);
              if (onDialogClosed) {
                onDialogClosed({
                  id: data.id,
                  supplementId: data.supplementId,
                  dosage: data.dosage,
                  frequency: data.frequency,
                  instructions: data.instructions,
                  isActive: data.isActive,
                  updatedOn: new Date(),
                });
                const logEvent = {
                  buttonName: "Add Supplement",
                  pageName: "Clients",
                };
                events.logButtonClick(logEvent);
              }
            } else {
              setError("Please fill out all fields");
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default SupplementPrescriptionDialog;
