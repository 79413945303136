import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import ExerciseNameTableCell from "../molecules/ExerciseNameTableCell";
import exerciseDBKeysSelector from "../../atoms/exerciseDBkeysSelector";
import colors from "../../themes/Colors";

const WorkoutSelectionExercises = ({ exercises }) => {
  const exerciseKeys = useRecoilValue(exerciseDBKeysSelector);
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table style={{ minWidth: "max-content" }}>
        <TableHead>
          <TableRow>
            <TableCell>Order</TableCell>
            <TableCell>Exercise/Super Set</TableCell>
            <TableCell>Set Type</TableCell>
            <TableCell>Reps Min</TableCell>
            <TableCell>Reps Max</TableCell>
            <TableCell>{t("exercise_columns.tempo")}</TableCell>
            <TableCell>Rest</TableCell>
            <TableCell>Sets</TableCell>
            <TableCell>Sets to Failure</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exercises.map((exercise, index) => {
            return (
              <TableRow key={exercise.id}>
                <TableCell
                  style={{ width: 45, backgroundColor: colors.lightGray }}
                >
                  <Box>{index + 1}</Box>
                </TableCell>
                <ExerciseNameTableCell
                  exercise={exercise}
                  exerciseKeys={exerciseKeys}
                />
                <TableCell style={{ width: 65 }}>
                  {exercise.type === "super_set"
                    ? t("super_set")
                    : t("normal_set")}
                </TableCell>
                <TableCell style={{ width: 65 }}>
                  <Typography>{exercise.reps}</Typography>
                </TableCell>
                <TableCell style={{ width: 65 }}>
                  <Typography>{exercise.repsMax}</Typography>
                </TableCell>
                <TableCell style={{ width: 65 }}>
                  <Typography>{exercise.tempo}</Typography>
                </TableCell>
                <TableCell style={{ width: 65 }}>
                  <Typography>{exercise.rest}</Typography>
                </TableCell>
                <TableCell style={{ width: 65 }}>
                  <Typography>{exercise.sets}</Typography>
                </TableCell>
                <TableCell style={{ width: 65 }}>
                  <Typography>{exercise.setsToFailure}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WorkoutSelectionExercises;
