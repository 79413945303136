import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import colors from "../../../../themes/Colors";
import CircleText from "../../../../components/CircleText";
import { ExpandMore } from "@mui/icons-material";

const SuperSet = ({ set }) => {
  return (
    <Box>
      {set.exercises.map((exercise) => {
        return (
          <Box key={exercise.id}>
            <Typography key={exercise.id} style={{ fontWeight: "500" }}>
              {`${exercise.exerciseName} - ${exercise.reps} x ${exercise.weight}`}
            </Typography>
            <UserNotes exercise={exercise} />
            <UserVideo exercise={exercise} />
          </Box>
        );
      })}
    </Box>
  );
};

const UserVideo = ({ exercise }) => {
  if (exercise.userVideo) {
    return (
      <Box style={{ marginTop: 6, marginBottom: 6 }}>
        <Typography style={{ fontWeight: "bold", color: colors.primaryRed }}>
          Attached Video
        </Typography>
        <video width="100%" controls>
          <source src={exercise.userVideo} type="video/mp4" />
        </video>
      </Box>
    );
  }
  return null;
};

const UserNotes = ({ exercise }) => {
  if (exercise.userNotes) {
    return (
      <Box style={{ marginTop: 6, marginBottom: 6 }}>
        <Typography style={{ fontWeight: "bold", color: colors.primaryRed }}>
          Attached Notes
        </Typography>
        <Typography>{exercise.userNotes}</Typography>
      </Box>
    );
  }
  return null;
};

const ExerciseDetails = ({ exercise }) => {
  if (exercise.type === "super_set") {
    return (
      <Box>
        {exercise.sets.map((set) => {
          return (
            <Box key={set.id}>
              <SuperSet set={set} />
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <Box>
      {exercise.sets
        .map((set) => {
          return `${set.reps} x ${set.weight}`;
        })
        .join(", ")}

      <UserNotes exercise={exercise} />
      <UserVideo exercise={exercise} />
    </Box>
  );
};

const ExerciseBox = ({ reportData }) => {
  return reportData.exercises.map((exercise, index) => {
    return (
      <Box key={exercise.id} style={{ marginBottom: 6, marginTop: 6 }}>
        <CircleText circleText={index + 1} title={exercise.exerciseName} />
        <ExerciseDetails exercise={exercise} />
      </Box>
    );
  });
};

const ExerciseData = ({ report }) => {
  const [expanded, setExpanded] = React.useState(false);

  const theme = useTheme();

  if (!report || !report.exerciseData || report.exerciseData.length === 0) {
    return (
      <Box style={{ marginTop: 20 }}>
        <Accordion
          display="flex"
          boxShadow={3}
          borderRadius={2}
          sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
          expanded={expanded}
          onChange={() => {
            setExpanded(!expanded);
          }}
        >
          <AccordionSummary
            expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M16 5.81818C14.224 5.81818 12.8 7.11273 12.8 8.72727C12.8 10.3418 14.224 11.6364 16 11.6364C17.776 11.6364 19.2 10.3418 19.2 8.72727C19.2 7.11273 17.776 5.81818 16 5.81818ZM32 0V7.27273H28.8V4.36364H3.2V7.27273H0V0H3.2V2.90909H28.8V0H32ZM20.8 14.9236V32H17.6V24.7273H14.4V32H11.2V14.9236C7.888 13.3382 5.6 10.1818 5.6 6.54545V5.81818H8.8V6.54545C8.8 10.1818 12 13.0909 16 13.0909C20 13.0909 23.2 10.1818 23.2 6.54545V5.81818H26.4V6.54545C26.4 10.1818 24.112 13.3382 20.8 14.9236Z"
                fill="black"
              />
            </svg>
            <Typography
              style={{
                color: "black",
                fontSize: 24,
                fontWeight: "600",
                marginLeft: 10,
              }}
            >
              Exercise Data
            </Typography>
          </AccordionSummary>
          <Box sx={{ padding: 2 }}>
            <Box sx={{ mt: 2, width: "100%", marginLeft: 2 }}>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 24,
                  fontWeight: "600",
                  marginLeft: 2,
                }}
              >
                No Exercise History
              </Typography>
            </Box>
          </Box>
        </Accordion>
      </Box>
    );
  }

  return (
    <Box style={{ marginTop: 20 }}>
      <Accordion
        display="flex"
        boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M16 5.81818C14.224 5.81818 12.8 7.11273 12.8 8.72727C12.8 10.3418 14.224 11.6364 16 11.6364C17.776 11.6364 19.2 10.3418 19.2 8.72727C19.2 7.11273 17.776 5.81818 16 5.81818ZM32 0V7.27273H28.8V4.36364H3.2V7.27273H0V0H3.2V2.90909H28.8V0H32ZM20.8 14.9236V32H17.6V24.7273H14.4V32H11.2V14.9236C7.888 13.3382 5.6 10.1818 5.6 6.54545V5.81818H8.8V6.54545C8.8 10.1818 12 13.0909 16 13.0909C20 13.0909 23.2 10.1818 23.2 6.54545V5.81818H26.4V6.54545C26.4 10.1818 24.112 13.3382 20.8 14.9236Z"
              fill="black"
            />
          </svg>
          <Typography
            style={{
              color: "black",
              fontSize: 24,
              fontWeight: "600",
              marginLeft: 10,
            }}
          >
            Exercise Data
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {report.exerciseData.map((exercise) => {
              const { reportData } = exercise;
              const { split, protocol } = reportData;

              if (!reportData.completed) {
                return null;
              }

              return (
                <Box key={exercise.id}>
                  <Box style={{ marginTop: 12, marginInline: 35 }}>
                    <Typography variant="h4">{`Training plan: ${protocol.name}`}</Typography>
                    <Typography variant="h6">{`Workout: ${split.name}`}</Typography>
                    <ExerciseBox key={exercise.id} reportData={reportData} />
                  </Box>
                  <Divider style={{ marginTop: 8 }} />
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ExerciseData;
