import { getAccountHolderUser } from "./UserData";
import { consola } from "consola";

export const getHost = () => {
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_ENV === "production") {
    return "https://app.mighty45.com";
  } else {
    return "https://app.test.mighty45.com";
  }
};

export const getAPIBasePath = () => {
  const host = getHost();
  return `${host}/api/v1`;
};

export const getStripeProducts = async ({ organizationId }) => {
  if (!organizationId) {
    consola.error(new Error("Incorrect parameters"));
  }

  const forceEmulator = false;
  let path;
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === "production" && !forceEmulator) {
    path = `${getAPIBasePath()}/stripe/subscription/`;
  } else {
    if (forceEmulator) {
      path = "http://127.0.0.1:5002/api/v1/stripe/subscription/";
    } else {
      path = `${getAPIBasePath()}/stripe/subscription/`;
    }
  }

  const response = await fetch(`${path}${organizationId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 200) {
    const jsonData = await response.json();
    return jsonData;
  }
  consola.error(
    new Error(`Error getting stripe response - ${response.status}`),
  );
};

export const updateSubscription = async ({ organizationId }) => {
  if (!organizationId) {
    consola.error(new Error("Incorrect parameters"));
  }

  const accountHolderUser = await getAccountHolderUser({ organizationId });

  consola.log("accountHolderUser", accountHolderUser);

  const message = {
    cart: {
      name: `${accountHolderUser.firstName} ${accountHolderUser.lastName}`,
      organization_id: organizationId,
    },
  };

  const forceEmulator = false;
  let path;
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === "production" && !forceEmulator) {
    path = `${getAPIBasePath()}/stripe_cart`;
  } else {
    if (forceEmulator) {
      path = "http://127.0.0.1:5002/api/v1/stripe_cart";
    } else {
      path = `${getAPIBasePath()}/stripe_cart`;
    }
  }

  const response = await fetch(`${path}`, {
    method: "POST",
    body: JSON.stringify(message),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 200) {
    const jsonData = await response.json();
    return jsonData;
  }
  consola.error(
    new Error(`Error getting stripe response - ${response.status}`),
  );
};
