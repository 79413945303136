import { nanoid } from "nanoid";
import { getUnitFromAlternative } from "./Units";

export const getFirebaseRecordFromRecipe = (recipe) => {
  const id = nanoid();
  return {
    id,
    name: recipe.n,
    description: recipe.d,
    instructions: recipe.i.map((r) => r.step).join("\n"),
    proteins: parseInt(recipe.mac.p),
    carbs: parseInt(recipe.mac.c),
    fats: parseInt(recipe.mac.f),
    kCals: parseInt(recipe.mac.kCals),
    servings: parseInt(recipe.ns),
    isArchived: false,
    isPublic: false,
    createdOn: new Date(),
    updatedOn: new Date(),
    mealType: "other",
  };
};

export const getIngredientsFromRecipe = (recipe) => {
  return recipe.ing.map((ingredient) => {
    const id = nanoid();
    return {
      id,
      name: ingredient.n,
      unit: getUnitFromAlternative(ingredient.u),
      amount: parseInt(ingredient.q),
      proteins: parseInt(ingredient.mac.p),
      carbs: parseInt(ingredient.mac.c),
      fats: parseInt(ingredient.mac.f),
      createdOn: new Date(),
      updatedOn: new Date(),
    };
  });
};
