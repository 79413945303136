import React from "react";
import { Box, Avatar, Typography } from "@mui/material";

import CircleImage from "../atoms/CircleImage";

export default React.memo(
  ({ url, size, firstName, lastName, showName = true }) => {
    const nameString = React.useMemo(() => {
      if (
        firstName &&
        lastName &&
        firstName.length > 0 &&
        lastName.length > 0
      ) {
        return `${firstName} ${lastName}`;
      }
      if (firstName && firstName.length > 0) {
        return `${firstName}`;
      }
      if (lastName && lastName.length > 0) {
        return `${lastName}`;
      }
      return "";
    }, [firstName, lastName]);

    const nameInitials = React.useMemo(() => {
      if (
        firstName &&
        lastName &&
        firstName.length > 0 &&
        lastName.length > 0
      ) {
        return `${firstName[0]}${lastName[0]}`;
      }
      if (firstName && firstName.length > 0) {
        return `${firstName[0]}`;
      }
      if (lastName && lastName.length > 0) {
        return `${lastName[0]}`;
      }
      return "";
    }, [firstName, lastName]);

    if (url) {
      return (
        <Box display="flex" flexDirection="row" alignItems="center">
          <CircleImage url={url} size={size} />
          {showName && (
            <Typography style={{ marginLeft: 4 }}>{nameString}</Typography>
          )}
        </Box>
      );
    }
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Avatar style={{ height: size, width: size }}>
          <Typography style={{ fontSize: 14 }}>{nameInitials}</Typography>
        </Avatar>
        {showName && (
          <Typography style={{ marginLeft: 4 }}>{nameString}</Typography>
        )}
      </Box>
    );
  },
);
