import React from "react";

import imageAll from "../assets/muscle_groups/all.png";
import imageAbs from "../assets/muscle_groups/abs.png";
import imageArms from "../assets/muscle_groups/arms.png";
import imageBack from "../assets/muscle_groups/back.png";
import imageChest from "../assets/muscle_groups/chest.png";
import imageLegs from "../assets/muscle_groups/legs.png";
import imageShoulders from "../assets/muscle_groups/shoulders.png";

import imageAllWhite from "../assets/muscle_groups/all_white.png";
import imageAbsWhite from "../assets/muscle_groups/abs_white.png";
import imageArmsWhite from "../assets/muscle_groups/arms_white.png";
import imageBackWhite from "../assets/muscle_groups/back_white.png";
import imageChestWhite from "../assets/muscle_groups/chest_white.png";
import imageLegsWhite from "../assets/muscle_groups/legs_white.png";
import imageShouldersWhite from "../assets/muscle_groups/shoulders_white.png";

const ExerciseGroupImage = (props) => {
  function getImage(id, selected, width = 45) {
    switch (id) {
      case "all":
        return selected ? (
          <img alt="all" width={width} src={imageAllWhite} />
        ) : (
          <img alt="all" width={width} src={imageAll} />
        );
      case "abs":
        return selected ? (
          <img alt="abs" width={width} src={imageAbsWhite} />
        ) : (
          <img alt="abs" width={width} src={imageAbs} />
        );
      case "arms":
        return selected ? (
          <img alt="arms" width={width} src={imageArmsWhite} />
        ) : (
          <img alt="arms" width={width} src={imageArms} />
        );
      case "back":
        return selected ? (
          <img alt="back" width={width} src={imageBackWhite} />
        ) : (
          <img alt="back" width={width} src={imageBack} />
        );
      case "chest":
        return selected ? (
          <img alt="chest" width={width} src={imageChestWhite} />
        ) : (
          <img alt="chest" width={width} src={imageChest} />
        );
      case "legs":
        return selected ? (
          <img alt="legs" width={width} src={imageLegsWhite} />
        ) : (
          <img alt="legs" width={width} src={imageLegs} />
        );
      case "shoulders":
        return selected ? (
          <img alt="shoulders" width={width} src={imageShouldersWhite} />
        ) : (
          <img alt="shoulders" width={width} src={imageShoulders} />
        );
      default:
        return selected ? (
          <img alt="all" width={width} src={imageAllWhite} />
        ) : (
          <img alt="all" width={width} src={imageAll} />
        );
    }
  }
  return getImage(props.id, props.selected, props.width ? props.width : 45);
};

export default ExerciseGroupImage;
