import React from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useRecoilValue } from "recoil";
import organizationIdSelector from "../../../../atoms/organizationIdSelector";
import MealPlanDataGrid from "./MealPlanDataGrid";
import MealPlanDialog from "./MealPlanDialog";
import useRecipes from "../../../../hooks/useRecipes";
import useEvents from "../../../../hooks/useEvents";

const EditMealPlans = ({ onShowDetails }) => {
  const confirm = useConfirm();
  const [selectedMealPlan, setSelectedMealPlan] = React.useState(null);
  const [showMealPlanDialog, setShowMealPlanDialog] = React.useState(false);
  const events = useEvents();
  const organizationId = useRecoilValue(organizationIdSelector);
  const { recipes, mealPlans, archiveMealPlan, getRecipeById, editMealPlan } =
    useRecipes({
      organizationId,
    });

  return (
    <>
      <Grid container style={{ marginTop: 0 }}>
        <Grid item xs={8}>
          <Typography variant="h6">Meal Plans</Typography>
          <Typography
            style={{ fontSize: 16, fontWeight: "normal", marginBottom: 12 }}
          >
            Create meal plans utilizing the recipes stored in your recipes
            database.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="row" justifyContent={"flex-end"}>
            <Button
              style={{ marginTop: 12 }}
              variant="contained"
              color="primary"
              onClick={() => {
                setSelectedMealPlan(null);
                setShowMealPlanDialog(true);
                events.logButtonClick({
                  buttonName: "Add Meal Plan",
                  pageName: "Nutrition",
                  tabName: "Meal Plans",
                });
              }}
            >
              Add Meal Plan
            </Button>
          </Box>
        </Grid>
      </Grid>

      <MealPlanDataGrid
        mealPlans={mealPlans}
        onClick={(mealPlan) => {
          setSelectedMealPlan(mealPlan);
          if (onShowDetails) {
            onShowDetails(mealPlan);
          }
          // setMode("details-meal-plan");
        }}
        onEdit={(mealPlan) => {
          setSelectedMealPlan(mealPlan);
          setShowMealPlanDialog(true);
          events.logButtonClick({
            buttonName: "Edit Meal Plan",
            pageName: "Nutrition",
            tabName: "Meal Plans",
          });
        }}
        onDelete={(mealPlan) => {
          confirm({
            title: "Archive this meal plan?",
            description: "This will archive the meal plan. Continue?",
          }).then(async () => {
            await archiveMealPlan(mealPlan.id);
            setSelectedMealPlan(null);
            setShowMealPlanDialog(false);
            events.logButtonClick({
              buttonName: "Delete Meal Plan",
              pageName: "Nutrition",
              tabName: "Meal Plans",
            });
          });
        }}
      />

      {showMealPlanDialog && (
        <MealPlanDialog
          open={showMealPlanDialog}
          mealPlan={selectedMealPlan}
          recipes={recipes}
          getRecipeById={getRecipeById}
          onClose={(mealPlan) => {
            if (mealPlan) {
              editMealPlan(mealPlan);
            }
            setShowMealPlanDialog(false);
          }}
        />
      )}
    </>
  );
};

export default EditMealPlans;
