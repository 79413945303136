import { Typography, Box, Button, Grid, Chip, Stack } from "@mui/material";
import React from "react";
import { nanoid } from "nanoid";
import { confirmAlert } from "react-confirm-alert";
import { useTheme } from "@mui/material/styles";

import { DataGrid } from "@mui/x-data-grid";

import LabelEditDialog from "./LabelEditDialog";
import useLabels from "../../../../hooks/useLabels";
import ReorderDialog from "./ReorderDialog";
import { consola } from "consola";

import { EditButton, DeleteButton } from "../../../../atoms/EditButton";
import useEvents from "../../../../hooks/useEvents";

const EditLabels = () => {
  const { labels, editLabelData, deleteLabelData, moveLabel, orderLabels } =
    useLabels();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [reorderDialogOpen, setReorderDialogOpen] = React.useState(false);
  const theme = useTheme();
  const events = useEvents();

  const selectedLabel = React.useRef();
  const columns = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        headerAlign: "left",
        align: "left",
        flex: 1,
        type: "string",
        hideable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Chip
              variant="outlined"
              style={{
                color: params.row.color,
                borderWidth: 2,
                borderColor: params.row.backgroundColor,
                marginRight: 6,
              }}
              label={params.row.name}
            />
          );
        },
      },

      {
        field: "icons",
        headerName: "",
        align: "left",
        width: 120,
        hideable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Box
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "flex-start",
              }}
            >
              {/* <ArrowUpButton
                onClick={() => {
                  moveLabel(params.row.id, "up");
                }}
              />
              <ArrowDownButton
                onClick={() => {
                  moveLabel(params.row.id, "down");
                }}
              /> */}
              <EditButton
                onClick={() => {
                  selectedLabel.current = params.row;
                  setDialogOpen(true);
                  const editLabelEvent = {
                    buttonName: "Edit Label",
                    pageName: "Admin",
                    tabName: "Labels ",
                  };
                  events.logButtonClick(editLabelEvent);
                }}
              />
              <DeleteButton
                onClick={() => {
                  confirmAlert({
                    title: `Delete this label?`,
                    message:
                      "This cannot be undone. The label will be removed everywhere it is being used.",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: async () => {
                          deleteLabelData(params.row.id);
                          const deleteLabelEvent = {
                            buttonName: "Delete Label",
                            pageName: "Admin",
                            tabName: "Labels ",
                          };
                          events.logButtonClick(deleteLabelEvent);
                        },
                      },
                      {
                        label: "No",
                        onClick: () => {
                          const cancelDeleteEvent = {
                            buttonName: "Cancel Label Delete",
                            pageName: "Admin",
                            tabName: "Labels ",
                          };
                          events.logButtonClick(cancelDeleteEvent);
                        },
                      },
                    ],
                  });
                }}
              />
            </Box>
          );
        },
      },
    ];
  }, [moveLabel, deleteLabelData, editLabelData]);

  if (labels) {
    return (
      <Box style={{ marginBottom: 54 }}>
        <Grid container style={{ marginTop: 4 }}>
          <Grid item xs={6}>
            <Typography
              style={{ fontSize: 20, fontWeight: "500", marginBottom: 12 }}
            >
              Labels
            </Typography>
            <Typography
              style={{ fontSize: 16, fontWeight: "normal", marginBottom: 12 }}
            >
              Labels are used throught the system to organize and filter.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"} spacing={3} justifyContent={"flex-end"}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"flex-end"}
              >
                <Button
                  style={{ marginTop: 12 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    selectedLabel.current = null;
                    setReorderDialogOpen(true);
                    const reorderLabelEvent = {
                      buttonName: "Reorder Labels",
                      pageName: "Admin",
                      tabName: "Labels ",
                    };
                    events.logButtonClick(reorderLabelEvent);
                  }}
                >
                  Reorder Labels
                </Button>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"flex-end"}
              >
                <Button
                  style={{ marginTop: 12 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    selectedLabel.current = null;
                    setDialogOpen(true);
                    const addLabelEvent = {
                      buttonName: "Add Label",
                      pageName: "Admin",
                      tabName: "Labels ",
                    };
                    events.logButtonClick(addLabelEvent);
                  }}
                >
                  Add Label
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Box style={{ margin: 12 }}>
          <Box sx={{ marginTop: 1, height: window.innerHeight - 420 }}>
            <DataGrid
              sx={{
                ".MuiDataGrid-sortIcon": {
                  color: theme.palette.primary.main,
                },
                ".MuiDataGrid-filterIcon": {
                  color: theme.palette.primary.main,
                },
                ".MuiDataGrid-menuIconButton": {
                  color: theme.palette.primary.main,
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
              disableSelectionOnClick
              rows={labels}
              columns={columns}
              getRowHeight={() => {
                return "auto";
              }}
            />
          </Box>
        </Box>

        <LabelEditDialog
          open={dialogOpen}
          label={selectedLabel.current}
          onDialogClosed={async (data, label) => {
            if (!data && !label) {
              setDialogOpen(false);
              return;
            }
            setDialogOpen(false);

            if (data && label) {
              label.name = data.name;
              label.color = data.color;
              label.backgroundColor = data.backgroundColor;
              label.updatedOn = new Date();

              editLabelData(label);
            } else {
              const newLabel = {
                id: nanoid(),
                name: data.name,
                color: data.color,
                backgroundColor: data.backgroundColor,
                createdOn: new Date(),
                updatedOn: new Date(),
                index: labels.length,
              };

              editLabelData(newLabel);
            }
          }}
        />
        <ReorderDialog
          open={reorderDialogOpen}
          reorderTitle={"Reorder Labels"}
          list={labels}
          onDialogClosed={async (data) => {
            if (!data) {
              consola.log("Not saving labels :(");
              setReorderDialogOpen(false);
              return;
            }
            setReorderDialogOpen(false);
            if (data) {
              consola.log("saving labels!");
              orderLabels(data);
            }
          }}
          // onDialogClosed={(questions, list) => {
          //   if (list) {
          //     ProtocolData.setSplitOrder(protocolId, list);
          //   }
          //   setQuestionReorderDialogOpen(false);
          // }}
        />
      </Box>
    );
  }
};
export default EditLabels;
