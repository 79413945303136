import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  ImageListItem,
  Grid,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircleText from "../../../../components/CircleText";

import exerciseDBKeysSelector from "../../../../atoms/exerciseDBkeysSelector";

const SingleExerciseTile = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const exerciseKeys = useRecoilValue(exerciseDBKeysSelector);

  const handleClick = (event) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getRepsString(exercise) {
    if (!exercise.repsMax) {
      return <Typography>{`Reps: ${exercise.reps}`}</Typography>;
    }
    return (
      <Typography>{`Reps: ${exercise.reps}-${exercise.repsMax}`}</Typography>
    );
  }

  function getSetsToFailureString(exercise) {
    if (exercise.setsToFailure) {
      return (
        <Typography>{`Sets to Failure: ${exercise.setsToFailure}`}</Typography>
      );
    }
    return null;
  }

  function getTempo(exercise, t) {
    if (exercise.tempo) {
      return (
        <Typography>{`${t("exercise_columns.tempo")}: ${
          exercise.tempo
        }`}</Typography>
      );
    }
    return null;
  }

  function getRest(exercise) {
    if (exercise.rest) {
      return <Typography>{`Rest: ${exercise.rest}`}</Typography>;
    }
    return null;
  }

  return (
    <Box
      boxShadow={3}
      component={ImageListItem}
      key={props.exercise.id}
      style={{
        padding: 23,
        width: 375,

        backgroundColor: "white",
        marginTop: 8,
        marginLeft: 2,
        marginBottom: 8,
        marginRight: 12,
        borderRadius: 8,
      }}
    >
      <MoreVertIcon
        style={{ position: "absolute", right: 0 }}
        onClick={handleClick}
      />
      <CircleText
        circleText={props.exercise.index + 1}
        title={
          exerciseKeys[props.exercise.exercise]
            ? exerciseKeys[props.exercise.exercise].name
            : ""
        }
      />
      <Box style={{ width: "100%", marginTop: 20 }}>
        <Box>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Typography
                style={{ fontSize: 16 }}
              >{`Sets: ${props.exercise.sets}`}</Typography>
            </Grid>
            <Grid item>{getSetsToFailureString(props.exercise)}</Grid>
          </Grid>
        </Box>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />
        <Box>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item>{getRepsString(props.exercise)}</Grid>
            <Grid item>{getTempo(props.exercise, t)}</Grid>
            <Grid item>{getRest(props.exercise)}</Grid>
          </Grid>
        </Box>
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            if (props.onExerciseEdit) {
              props.onExerciseEdit(props.exercise);
            }
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            //
            setAnchorEl(null);
            if (props.onExerciseDelete) {
              props.onExerciseDelete(props.exercise);
            }
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default SingleExerciseTile;
