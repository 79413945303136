import React from "react";
import { Box, Typography, Stack, Grid } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import MacroSelectSimpleDialog from "./MacroSelectSimpleDialog";
import NutritionGoalRow from "./NutritionGoalRow";
import BlueBorderButton from "../../../../components/BlueBorderButton";
import { DropDownButton } from "../../../../atoms/EditButton";
import { nanoid } from "nanoid";
import moment from "moment";
// import useHistory from "../../../../hooks/useHistory";
import RecentHistory from "../../../../components/molecules/RecentHistory";
import { getHistoryString } from "../../../../components/LibraryFunctions";
import useEvents from "../../../../hooks/useEvents";
import NutritionTitleRow from "./NutritionTitleRow";
function NutritionGoals({ style, onEdit, onDelete, onActivate, client }) {
  const confirm = useConfirm();
  const [macroSelectOpen, setMacroSelectOpen] = React.useState(false);
  const [historyOpen, setHistoryOpen] = React.useState(false);
  // editingGoal tracks if the macro dialogue is for editing or adding a goal
  const [editingGoal, setEditingGoal] = React.useState(true);
  const [showGoals, setShowGoals] = React.useState(true);
  // const { history } = useHistory({
  //   uid: client.uid,
  //   historyCollection: "nutritionGoalHistory",
  //   limit: 10,
  // });

  const selectedNutritionGoal = React.useRef(null);
  const events = useEvents();
  function ago(date) {
    return moment(date).calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "[Last] dddd",
      sameElse: function (now) {
        const diff = now.diff(this, "days");
        const result = `[${diff} days ago]`;

        return result;
      },
    });
  }

  function getNutritionGoalChanged(client) {
    const { nutritionGoalChanged } = client;

    if (nutritionGoalChanged) {
      const date = nutritionGoalChanged.toDate();
      const agoTime = ago(date);
      return agoTime;
    }
    return "";
  }

  return (
    <>
      <Box
        display="flex"
        boxShadow={3}
        borderRadius={2}
        borderBottom={showGoals ? 1 : 0}
        style={{ marginTop: 20, backgroundColor: "#F6F7FB" }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          paddingLeft={3}
        >
          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="40"
                  viewBox="2 0 29 40"
                  fill="none"
                  display={"inline-flex"}
                >
                  <path
                    d="M24.0279 11.5666L24.4192 13.6126L18.4542 16.6605C18.1103 16.8363 17.8284 17.0986 17.6706 17.3899C17.5127 17.6811 17.4918 17.9774 17.6125 18.2136C17.7332 18.4498 17.9855 18.6065 18.314 18.6492C18.6426 18.6919 19.0203 18.6172 19.3642 18.4415L24.8105 15.6586L26.7671 25.8887L21.3208 28.6716C20.9769 28.8473 20.695 29.1096 20.5372 29.4009C20.3793 29.6921 20.3584 29.9885 20.4791 30.2246C20.5998 30.4608 20.8521 30.6175 21.1806 30.6602C21.5092 30.703 21.8869 30.6283 22.2308 30.4525L27.1584 27.9347L27.9411 32.0267C28.4238 32.9714 28.3402 34.1566 27.7088 35.3217C27.0774 36.4867 25.9498 37.5361 24.5742 38.239C23.1985 38.9419 21.6874 39.2408 20.3734 39.0698C19.0593 38.8989 18.0499 38.2721 17.5672 37.3274L10.4236 29.7477L14.3139 27.7599C14.6578 27.5842 14.9397 27.3219 15.0975 27.0306C15.2554 26.7393 15.2763 26.443 15.1556 26.2069C15.0349 25.9707 14.7826 25.814 14.454 25.7713C14.1255 25.7285 13.7478 25.8032 13.4038 25.979L8.99493 28.2317L3.2801 22.168C2.18809 20.0308 3.6627 16.964 6.75302 14.3069L1.31897 12.5691C0.0963852 12.1607 -0.18254 10.9107 0.728354 9.74902C1.60421 8.58278 3.32137 7.97489 4.56079 8.35227L6.32846 8.93139L4.69044 5.72563C4.44909 5.25329 4.49086 4.66068 4.80657 4.07816C5.12228 3.49565 5.68607 2.97095 6.3739 2.61949C7.06173 2.26803 7.81727 2.11861 8.4743 2.20409C9.13133 2.28957 9.63604 2.60295 9.87739 3.0753L11.9522 7.13592L14.259 2.04927C14.7326 0.863954 16.347 -0.0732036 17.7638 0.0113773C19.1716 0.0781475 19.8746 1.1339 19.3322 2.37679L16.0923 9.66965C19.841 8.87717 22.9814 9.5185 24.0279 11.5666Z"
                    fill="black"
                  />
                </svg>
              </Box>
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "normal",
                  fontFamily: "Lato",
                  verticalAlign: "Center",
                  paddingLeft: 20,
                }}
              >
                Nutrition Goals
              </Typography>
              <Typography
                flexShrink={0}
                style={{
                  fontFamily: "Lato",
                  fontSize: 12,
                  fontWeight: "500",
                  verticalAlign: "baseline",
                  paddingTop: 10,
                  paddingLeft: 60,
                }}
              >
                {`Last Changed: ${getNutritionGoalChanged(client)}`}
              </Typography>
            </Box>
          </Grid>

          <Grid item textAlign="center" style={{}}>
            <DropDownButton
              onClick={() => {
                setShowGoals(!showGoals);
              }}
              show={showGoals}
            />
          </Grid>
        </Grid>
      </Box>
      {showGoals && (
        <Box display="flex">
          <Box
            boxShadow={3}
            borderRadius={2}
            style={{
              width: "100%",
              backgroundColor: "#F6F7FB",
              padding: 20,
              ...style,
            }}
          >
            <Box>
              <Box style={{ paddingInline: 75 }}>
                <NutritionTitleRow></NutritionTitleRow>
                {client.nutritionGoals &&
                  client.nutritionGoals.map((nutritionGoal) => {
                    return (
                      <NutritionGoalRow
                        key={nutritionGoal.id}
                        nutritionGoal={nutritionGoal}
                        onEdit={(nutritionGoal) => {
                          selectedNutritionGoal.current = nutritionGoal;
                          setEditingGoal(true);
                          setMacroSelectOpen(true);
                        }}
                        onDelete={(nutritionGoal) => {
                          //
                          confirm({
                            title: "Delete the nutrition goal?",
                            description:
                              "Deleting this nutrition goal cannot be undone. Continue?",
                          }).then(async () => {
                            if (onDelete) {
                              onDelete(nutritionGoal);
                            }
                          });
                        }}
                        onActivate={(nutritionGoal) => {
                          if (onActivate) {
                            onActivate(nutritionGoal);
                          }
                        }}
                      />
                    );
                  })}
              </Box>
              {historyOpen && (
                <RecentHistory
                  client={client}
                  historyCollection={"nutritionGoalHistory"}
                />
              )}
              {/* <Box style={{ marginTop: 12 }}> */}
              <Stack
                style={{ marginTop: 12 }}
                direction={"row"}
                spacing={3}
                justifyContent={"center"}
              >
                <BlueBorderButton
                  style={{
                    paddingInline: 150,
                    marginTop: 12,
                    marginBottom: 12,
                  }}
                  onClick={() => {
                    selectedNutritionGoal.current = {
                      id: nanoid(),
                      name: "New Goal",
                      proteins: 0,
                      carbs: 0,
                      fats: 0,
                      water: 0,
                    };
                    setEditingGoal(false);
                    setMacroSelectOpen(true);
                  }}
                >
                  Add Nutrition Goal
                </BlueBorderButton>
                <BlueBorderButton
                  style={{
                    paddingInline: 150,
                    marginTop: 12,
                    marginBottom: 12,
                  }}
                  onClick={() => {
                    if (!historyOpen) {
                      const logEvent = {
                        buttonName: "Display Nutrition History",
                        pageName: "Clients",
                        tabName: client.uid,
                      };
                      events.logButtonClick(logEvent);
                    }
                    setHistoryOpen(!historyOpen);
                  }}
                >
                  {getHistoryString(historyOpen)}
                </BlueBorderButton>
              </Stack>
              {/* </Box> */}
            </Box>
          </Box>
        </Box>
      )}

      {macroSelectOpen && (
        <MacroSelectSimpleDialog
          client={client}
          open={macroSelectOpen}
          nutritionGoal={selectedNutritionGoal.current}
          isEdit={editingGoal}
          onDialogClosed={(nutritionGoal) => {
            setMacroSelectOpen(false);

            if (nutritionGoal) {
              //
              if (onEdit) {
                onEdit(nutritionGoal);
              }
            }
          }}
        />
      )}
    </>
  );
}

export default NutritionGoals;
