import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from "@mui/material";

import BlueButton from "../../../../components/BlueButton";

const MealPlanSelectDialog = ({ open, client, mealPlans, onClosed }) => {
  const [selectedMealPlan, setSelectedMealPlan] = React.useState(
    client.mealPlanId || null,
  );

  function DisplayEmpty() {
    if (mealPlans.length === 0) {
      return (
        <MenuItem key={"none"} value="">
          There are no meal plans defined. Go to the Meals tab to define some.
        </MenuItem>
      );
    }
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (onClosed) {
          onClosed();
        }
      }}
    >
      <DialogTitle>Select Meal Plan</DialogTitle>

      <DialogContent>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Meal Plan:</Typography>
          <Select
            style={{ width: 512 }}
            variant="outlined"
            value={selectedMealPlan}
            onChange={(event) => {
              setSelectedMealPlan(event.target.value);
            }}
          >
            {<DisplayEmpty />}
            {mealPlans.map((mealPlan) => {
              return (
                <MenuItem key={mealPlan.id} value={mealPlan.id}>
                  {mealPlan.name}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (onClosed) {
              onClosed();
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (onClosed) {
              onClosed(selectedMealPlan);
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default MealPlanSelectDialog;
