import React from "react";
import { Box, Typography, Grid, Button, Divider } from "@mui/material";

import SplitExerciseList from "./SplitExerciseList";
import SplitButton from "../../../../components/atoms/SplitButton";
import LabelChips from "../../../../components/molecules/LabelChips";

import useSplits from "../../../../hooks/useSplits";
import EmptySplits from "./EmptySplits";

const SplitEditButton = ({
  split,
  splits,
  editSplit,
  duplicateSplit,
  reorderSplitExercises,
  deleteSplit,
}) => {
  if (split.data().isRestSplit) {
    return (
      <SplitButton
        options={["Edit Workout Details", "Duplicate", "-", "Delete"]}
        onClick={(selection) => {
          if (selection.index === 0) {
            if (editSplit) {
              editSplit(split.data());
            }
          }
          if (selection.index === 1) {
            if (duplicateSplit) {
              duplicateSplit(split.data(), splits.length);
            }
          }
          if (selection.index === 3) {
            if (deleteSplit) {
              deleteSplit(split.data());
            }
          }
        }}
      />
    );
  }

  return (
    <SplitButton
      options={[
        "Edit Workout Details",
        "Duplicate",
        "Reorder Exercises",
        "-",
        "Delete",
      ]}
      onClick={(selection) => {
        if (selection.index === 0) {
          if (editSplit) {
            editSplit(split.data());
          }
        }
        if (selection.index === 1) {
          if (duplicateSplit) {
            duplicateSplit(split.data(), splits.length);
          }
        }
        if (selection.index === 2) {
          if (reorderSplitExercises) {
            reorderSplitExercises(split.data());
          }
        }
        if (selection.index === 4) {
          if (deleteSplit) {
            deleteSplit(split.data());
          }
        }
      }}
    />
  );
};

const SplitList = ({
  protocol,
  labels,
  mode,
  addExerciseToSplit,
  editSplit,
  duplicateSplit,
  reorderSplitExercises,
  deleteSplit,
}) => {
  const { splits } = useSplits({ protocolId: protocol.id });

  if (splits && splits.length === 0) {
    return <EmptySplits />;
  }

  return (
    <Box>
      {splits &&
        splits.map((split) => {
          return (
            <Box key={split.data().id}>
              <Box
                style={{
                  borderRadius: 30,
                  padding: 20,
                  marginBottom: 0,
                  backgroundColor: split.data().isRestSplit ? "#D9D9D9" : null,
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginBottom: 20 }}
                >
                  <Grid item>
                    <Box>
                      <Typography style={{ fontSize: 26, fontWeight: "bold" }}>
                        {split.data().name}
                      </Typography>
                      <Typography style={{ fontSize: 12 }}>
                        ID: {split.data().id}
                      </Typography>

                      <Typography
                        style={{ fontSize: 16, whiteSpace: "pre-line" }}
                      >
                        {split.data().description}
                      </Typography>
                      <Box style={{ marginTop: 12 }}>
                        <LabelChips
                          labels={split.data().labels}
                          allLabels={labels}
                        />
                      </Box>
                      {split.data().isRestSplit && (
                        <Typography style={{ fontSize: 18 }}>{`Rest for ${
                          split.data().restDays
                        } day(s)`}</Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item>
                    <SplitEditButton
                      split={split}
                      splits={splits}
                      addExerciseToSplit={addExerciseToSplit}
                      editSplit={editSplit}
                      duplicateSplit={duplicateSplit}
                      reorderSplitExercises={reorderSplitExercises}
                      deleteSplit={deleteSplit}
                    />
                  </Grid>
                </Grid>
                {!split.data().isRestSplit && (
                  <Box>
                    <SplitExerciseList
                      mode={mode}
                      split={split.data()}
                      labels={labels}
                    />
                  </Box>
                )}
                {!split.data().isRestSplit && (
                  <Box>
                    <Button
                      style={{ marginLeft: 24 }}
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        addExerciseToSplit(split.data());
                      }}
                    >
                      Add an Exercise
                    </Button>
                  </Box>
                )}
              </Box>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </Box>
          );
        })}
    </Box>
  );
};

export default SplitList;
