import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import _ from "lodash";

import BlueButton from "../BlueButton";

const CardioGoalDialog = ({ cardioGoal, open, onDialogClosed }) => {
  const [openDialog, setOpenDialog] = React.useState(open);

  const [data, setData] = React.useState(_.cloneDeep(cardioGoal));
  const [error, setError] = React.useState(null);

  function validate() {
    if (data.name.length === 0) {
      return false;
    }
    return true;
  }

  React.useEffect(() => {
    setData(_.cloneDeep(cardioGoal));
    setOpenDialog(open);
  }, [open, cardioGoal]);

  if (!data || !open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      open={openDialog}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Cardio Goal</DialogTitle>

      <DialogContent>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Name:</Typography>
          <TextField
            value={data.name}
            style={{ width: 300 }}
            variant="outlined"
            onChange={(event) => {
              setData({
                ...data,
                name: event.target.value,
              });
            }}
          ></TextField>
        </Box>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Sessions:</Typography>
          <TextField
            value={data.sessions}
            style={{ width: 300 }}
            variant="outlined"
            onChange={(event) => {
              setData({
                ...data,
                sessions: event.target.value,
              });
            }}
          ></TextField>
        </Box>

        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Duration:</Typography>
          <TextField
            value={data.duration}
            style={{ width: 300 }}
            variant="outlined"
            onChange={(event) => {
              setData({
                ...data,
                duration: event.target.value,
              });
            }}
          ></TextField>
        </Box>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Intensity:</Typography>
          <TextField
            value={data.intensity}
            style={{ width: 300 }}
            variant="outlined"
            onChange={(event) => {
              setData({
                ...data,
                intensity: event.target.value,
              });
            }}
          ></TextField>
        </Box>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Target HR:</Typography>
          <TextField
            value={data.hr}
            style={{ width: 300 }}
            variant="outlined"
            onChange={(event) => {
              setData({
                ...data,
                hr: event.target.value,
              });
            }}
          ></TextField>
        </Box>
      </DialogContent>

      {error && (
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ width: 200, color: "red" }}>
              {error}
            </Typography>
          </Box>
        </DialogContent>
      )}

      <DialogActions>
        <Button
          onClick={() => {
            setOpenDialog(false);
            if (onDialogClosed) {
              onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (validate()) {
              setOpenDialog(false);
              if (onDialogClosed) {
                onDialogClosed({
                  id: data.id,
                  name: data.name,
                  duration: data.duration,
                  intensity: data.intensity,
                  hr: data.hr,
                  sessions: data.sessions,
                  active: data.active ? data.active : false,
                });
              }
            } else {
              setError("Please fill out all fields");
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default CardioGoalDialog;
