import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import colors from "../../themes/Colors";

// const useStyles = makeStyles(() => ({
//   red: {
//     backgroundColor: colors.primaryRed,
//   },
//   blue: {
//     backgroundColor: colors.primaryBlue,
//   },
//   green: {
//     backgroundColor: colors.primaryGreen,
//   },
// }));

const NutritionProgress = ({
  title = "",
  target = "",
  actual = null,
  value = 0,
  showTarget = true,
  measure = "g",
  showPercentage = true,
}) => {
  // const classes = useStyles();

  let styleClass = colors.primaryBlue;

  const actualString = actual ? `${Math.round(actual)}` : "0";

  let finalValue = value;
  if (!isNaN(finalValue)) {
    if (finalValue > 100) {
      styleClass = colors.primaryRed;
      finalValue = 100;
    } else if (finalValue === 100) {
      styleClass = colors.primaryGreen;
    }
  } else {
    finalValue = 0;
  }
  function getTargetString() {
    if (!target || isNaN(target)) {
      return "";
    }
    const actual = `${actualString}${showPercentage ? measure : ""}`;
    const targetString = `${target.toFixed(2)}${showPercentage ? measure : ""}`;

    return `${actual} / ${targetString}`;
  }

  return (
    <Box style={{ marginBottom: 25 }}>
      <Box display="flex" alignItems="center">
        <Typography
          display="inline"
          style={{ fontSize: 16, fontWeight: "400", fontFamily: "Lato", lineHeight: "normal"  }}
        >
          {title}
        </Typography>
        <Typography display="inline" style={{ marginLeft: 8 }}>
          {showTarget ? getTargetString() : ``}
        </Typography>
      </Box>
      <LinearProgress
        classes={{colorPrimary: styleClass}}
        variant="determinate"
        value={finalValue}
      />
    </Box>
  );
};

export default NutritionProgress;
