import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Grid,
} from "@mui/material";
import _ from "underscore";
import Bugsnag from "@bugsnag/js";
import BlueButton from "../../../../components/BlueButton";
import OrganizationData from "../../../../data/OrganizationData";

export default React.memo((props) => {
  const [open, setOpen] = React.useState(false);
  const [coaches, setCoaches] = React.useState([]);
  const [selectedCoaches, setSelectedCoaches] = React.useState([]);

  React.useEffect(() => {
    async function getData() {
      try {
        const coaches = await OrganizationData.getCoaches(
          props.client.organizationId,
        );
        setSelectedCoaches(props.client.coaches);
        setCoaches(coaches);
      } catch (e) {
        Bugsnag.notify(e);
      }
    }
    setOpen(props.open);
    getData();
  }, [props.open, props.client.organizationId, props.client.coaches]);

  function validate() {
    if (selectedCoaches.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function addSelected(coach) {
    const found = _.find(selectedCoaches, (coachId) => {
      return coachId === coach.uid;
    });
    if (!found) {
      const newSelected = [].concat(selectedCoaches);
      newSelected.push(coach.uid);
      setSelectedCoaches(newSelected);
    }
  }

  function removeSelected(coach) {
    const newSelected = _.reject(selectedCoaches, (coachId) => {
      return coachId === coach.uid;
    });
    setSelectedCoaches(newSelected);
  }

  function isSelected(coach) {
    const found = _.find(selectedCoaches, (coachId) => {
      return coachId === coach.uid;
    });
    return found !== undefined;
  }

  if (!props.open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Select Coach</DialogTitle>

      <DialogContent>
        <Box style={{ marginTop: 18 }}>
          {coaches.map((coach) => {
            return (
              <Box key={coach.uid} display="flex">
                <Grid container>
                  <Grid item style={{ width: 400 }}>
                    <Typography>{`${coach.firstName} ${coach.lastName}`}</Typography>
                  </Grid>
                  <Grid item>
                    <Checkbox
                      indeterminate={false}
                      checked={isSelected(coach)}
                      onChange={() => {
                        if (isSelected(coach)) {
                          removeSelected(coach);
                        } else {
                          addSelected(coach);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            if (props.onDialogClosed) {
              props.onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (validate()) {
              setOpen(false);
              if (props.onDialogClosed) {
                props.onDialogClosed({
                  coaches: selectedCoaches,
                });
              }
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
});
