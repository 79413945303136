import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import colors from "../../../../themes/Colors";

const ChartLegend = () => {
  return (
    <Box style={{ marginTop: 12, paddingInLine: 75 }}>
      <Box display={"inline-flex"}>
        <Typography width={600} marginLeft={50} marginTop={4.5} fontSize={18}>
          Select a day to view client progress
        </Typography>

        <Grid
          item
          container
          spacing={1}
          direction={"column"}
          justifyItems={"right"}
          style={{ marginLeft: 50 }}
        >
          <Grid item display={"inline-flex"} alignItems={"center"}>
            <Box
              style={{
                width: 16,
                height: 16,
                backgroundColor: colors.calendarGreen,
                borderRadius: 25,
              }}
            ></Box>
            <Typography marginLeft={2} fontSize={18}>
              Exercise and Nutrition Data
            </Typography>
          </Grid>

          <Grid
            item
            display={"inline-flex"}
            alignItems={"center"}
            paddingLeft={60}
          >
            <Box
              style={{
                width: 16,
                height: 16,
                backgroundColor: colors.calendarBlue,
                borderRadius: 25,
              }}
            ></Box>
            <Typography marginLeft={2} fontSize={18}>
              Nutrition Data
            </Typography>
          </Grid>

          <Grid
            item
            display={"inline-flex"}
            alignItems={"center"}
            paddingLeft={60}
          >
            <Box
              style={{
                width: 16,
                height: 16,
                backgroundColor: colors.calendarYellow,
                borderRadius: 25,
              }}
            ></Box>
            <Typography marginLeft={2} fontSize={18}>
              Exercise
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChartLegend;
