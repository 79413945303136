import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
} from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { nanoid } from "nanoid";

import BlueButton from "../../../../components/BlueButton";
import SplitDialogTitle from "../../../../components/atoms/SplitDialogTitle";
import NumberField from "../../../../components/atoms/NumberField";
import LabelSelect from "../../../../components/molecules/LabelSelect";

const SplitWarmUpBox = ({ warmup, isRestSplit, onChange }) => {
  const [warmupState, setWarmupState] = React.useState(warmup);

  if (isRestSplit) {
    return null;
  }

  return (
    <Stack>
      <Typography style={{ width: 200 }}>Warmup</Typography>
      <div data-color-mode="light">
        <MDEditor
          preview="edit"
          style={{ width: "100%", minWidth: 800 }}
          placeholder="Enter healthy recipe description or video link here"
          value={warmupState}
          onChange={(value) => {
            setWarmupState(value);
            if (onChange) {
              onChange(value);
            }
          }}
        />
      </div>
    </Stack>
  );
};

const SplitDialog = (props) => {
  const [id, setId] = React.useState(props.id);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(
    props.selectedSplit ? props.selectedSplit.name : "",
  );
  const [description, setDescription] = React.useState(
    props.selectedSplit ? props.selectedSplit.description : "",
  );
  const [warmup, setWarmUp] = React.useState(
    props.selectedSplit ? props.selectedSplit.warmup : "",
  );
  const [isRestSplit, setIsRestSplit] = React.useState(
    props.selectedSplit ? props.selectedSplit.isRestSplit : false,
  );
  const [restDays, setRestDays] = React.useState(
    props.selectedSplit ? props.selectedSplit.restDays : 1,
  );

  const [labels, setLabels] = React.useState(
    props.selectedSplit ? props.selectedSplit.labels : [],
  );

  React.useEffect(() => {
    setOpen(props.open);
    if (props.selectedSplit) {
      setName(props.selectedSplit.name);
      setDescription(props.selectedSplit.description);
      setWarmUp(props.selectedSplit.warmup ? props.selectedSplit.warmup : "");
      setIsRestSplit(props.selectedSplit.isRestSplit);
      setRestDays(props.selectedSplit.restDays);
      setLabels(props.selectedSplit.labels);
    } else {
      setName("");
      setDescription("");
      setWarmUp("");
      setIsRestSplit(false);
      setRestDays(1);
      setLabels([]);
    }
    if (!props.id) {
      setId(nanoid());
    } else {
      setId(props.id);
    }
  }, [props.open]); // eslint-disable-line

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
        }
      }}
    >
      <SplitDialogTitle
        isEdit={props.selectedSplit ? true : false}
        isRestSplit={isRestSplit}
      />

      <DialogContent>
        <Stack spacing={1}>
          <Stack>
            <Typography>Workout name:</Typography>
            <TextField
              variant="outlined"
              style={{ width: "100%" }}
              value={name}
              onChange={(event) => {
                const value = event.target.value;
                setName(value.trimStart());
              }}
            />
          </Stack>
          <Stack>
            <Typography>Description:</Typography>
            <div data-color-mode="light">
              <MDEditor
                preview="edit"
                style={{ minWidth: 800 }}
                value={description}
                onChange={(value) => {
                  setDescription(value);
                }}
              />
            </div>
          </Stack>
          <Stack>
            <Typography>Labels:</Typography>
            <LabelSelect
              width={"100%"}
              id="protocol-dialog"
              currentLabels={labels}
              onLabelsChanged={(changes) => {
                setLabels(changes.protocolLabels);
              }}
            />
          </Stack>
          <SplitWarmUpBox
            warmup={warmup}
            isRestSplit={isRestSplit}
            onChange={(warmup) => {
              setWarmUp(warmup);
            }}
          />
          {isRestSplit && (
            <Stack>
              <Typography style={{ width: 200 }}>Rest Period:</Typography>
              <NumberField
                value={restDays}
                variant="outlined"
                style={{ width: 512 }}
                onChange={(value) => {
                  setRestDays(value);
                }}
              />
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions style={{ marginTop: 20 }}>
        <Button
          onClick={() => {
            if (props.onDialogClosed) {
              props.onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (props.onDialogClosed) {
              const values = {
                id: id,
                protocolId: props.protocolId,
                name: name.trim(),
                description: description.trim(),
                warmup: warmup.trim(),
                restDays: restDays ? restDays : 0,
                labels: labels ? labels : [],
              };
              if (props.selectedSplit) {
                values.index = props.selectedSplit.index;
              }
              props.onDialogClosed(values);
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default SplitDialog;
