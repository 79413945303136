import { useState, useEffect } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { consola } from "consola";

const useClientsLastSeen = ({
  organizationId,
  uid,
  enableSubscription = true,
}) => {
  const [lastSeen, setLastSeen] = useState([]);
  const [lastSeenUsers, setLastSeenUsers] = useState({});
  const [userLastSeen, setUserLastSeen] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!enableSubscription) return;

    const unsubscribe = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("userLastSeen")
      .onSnapshot((snapshot) => {
        consola.info("++++++ SNAPSHOT -> useClientsLastSeen: enableSubscription")
        const docs = snapshot.docs.map((doc) => doc.data());
        // create dictionary of by user id
        const docsDict = {};
        docs.forEach((doc) => {
          docsDict[doc.uid] = doc;
        });
        setLastSeenUsers(docsDict);
        setLastSeen(docs);
        setLoading(false);
      });
    return () => unsubscribe();
  }, [organizationId, enableSubscription]);

  useEffect(() => {
    if (!uid) return;

    const unsubscribe = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("userLastSeen")
      .doc(uid)
      .onSnapshot((snapshot) => {
        consola.info("++++++ SNAPSHOT -> useClientsLastSeen: uid")
        if (snapshot.exists) {
          setUserLastSeen(snapshot.data());
        }
      });
    return () => unsubscribe();
  }, [organizationId, uid]);

  return { lastSeen, lastSeenUsers, loading, userLastSeen };
};

export default useClientsLastSeen;
