import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import Colors from "../../../../themes/Colors";
import { RoleString, uploadUserAvatar } from "../../../../data/UserData";
import RedBorderButton from "../../../../components/RedBorderButton";

const CoachDescription = (props) => {
  const [coach, setCoach] = React.useState(props.coach);

  React.useEffect(() => {
    setCoach(props.coach);
  }, [props.coach]);

  const handleChangeAvatar = async (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      await uploadUserAvatar({
        uid: coach.uid,
        file: image,
        progressFunction: () => {
          //
        },
      });

      // Refresh somehow
      if (props.avatarUpdated) {
        props.avatarUpdated();
      }
    }
  };

  return (
    <Box>
      {props.userData &&
        props.userData.role &&
        props.userData.role === "admin" && (
          <Box style={{ position: "absolute", right: 70 }}>
            <RedBorderButton
              onClick={() => {
                if (props.editClicked) {
                  props.editClicked(props.client);
                }
              }}
            >
              Edit Coach
            </RedBorderButton>
          </Box>
        )}
      <Box
        display="flex"
        style={{ marginTop: 20, width: 500, marginRight: 12 }}
        justifyContent="space-between"
      >
        <Box>
          <Avatar
            src={`${props.coach.avatarUrl}`}
            style={{ width: 120, height: 120, zIndex: 0 }}
          ></Avatar>
          <Box
            style={{
              zIndex: 2,
              borderRadius: 50,
              height: 40,
              width: 40,
              backgroundColor: Colors.lightGray,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              paddingTop: 2,
            }}
          >
            <label>
              <AddAPhotoIcon color="primary" style={{ color: "black" }} />
              <input
                hidden={true}
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={handleChangeAvatar}
              />
            </label>
          </Box>
        </Box>
        <Box style={{ marginLeft: 24, marginRight: 12, width: "95%" }}>
          <Box display="flex" style={{ justifyContent: "space-between" }}>
            <Typography>{`${props.coach.firstName} ${props.coach.lastName}`}</Typography>
          </Box>

          <Box display="flex" style={{ justifyContent: "space-between" }}>
            <Typography>{`Email: ${props.coach.email || ""}`}</Typography>
          </Box>

          <Box display="flex" style={{ justifyContent: "space-between" }}>
            <Typography>{`Role: ${RoleString[props.coach.role]}`}</Typography>
          </Box>

          <Box display="flex" style={{ justifyContent: "space-between" }}>
            <Typography>{`UID: ${
              props.coach.uid ? props.coach.uid : "none"
            }`}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CoachDescription;
