import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Select,
  MenuItem,
  Divider,
  CircularProgress,
} from "@mui/material";
import { consola } from "consola";

import BlueButton from "../../../../components/BlueButton";
import { getChatGPTResponse } from "../../../../data/GlobalData";
import { RecipeGenerationMessage } from "../../../../data/ChatGPTMessages";

const MacroInputStack = ({
  label,
  defaultValues,
  onMinChanged,
  onMaxChanged,
}) => {
  return (
    <Stack style={{ marginTop: 18 }} direction="row" spacing={1}>
      <Stack>
        <Typography>{`Min ${label}:`}</Typography>
        <TextField
          size="small"
          value={defaultValues.min}
          variant="outlined"
          onChange={(event) => {
            if (event.target.value.length === 0) {
              onMinChanged(0);
              return;
            }
            onMinChanged(
              isNaN(event.target.value) ? 0 : parseInt(event.target.value, 10),
            );
          }}
        />
      </Stack>

      <Stack>
        <Typography>{`Max ${label}:`}</Typography>
        <TextField
          size="small"
          value={defaultValues.max}
          variant="outlined"
          onChange={(event) => {
            if (event.target.value.length === 0) {
              onMaxChanged(0);
              return;
            }
            onMaxChanged(
              isNaN(event.target.value) ? 0 : parseInt(event.target.value, 10),
            );
          }}
        />
      </Stack>
    </Stack>
  );
};

const RecipeGeneratorDialog = ({ recipes, onDialogClosed }) => {
  const [kCals, setKCals] = React.useState({ min: 0, max: 0 });
  const [proteins, setProteins] = React.useState({ min: 0, max: 0 });
  const [carbs, setCarbs] = React.useState({ min: 0, max: 0 });
  const [fats, setFats] = React.useState({ min: 0, max: 0 });
  const [diet, setDiet] = React.useState("balanced");

  const [foundRecipe, setFoundRecipe] = React.useState(null);
  const [searching, setSearching] = React.useState(false);

  const generateRecipes = () => {
    setSearching(true);
    // create object of mins and maxes for each macro but only if they are not 0
    const macros = {};
    if (kCals.min !== 0 || kCals.max !== 0) {
      macros.kCals = kCals;
    }
    if (proteins.min !== 0 || proteins.max !== 0) {
      macros.proteins = proteins;
    }
    if (carbs.min !== 0 || carbs.max !== 0) {
      macros.carbs = carbs;
    }
    if (fats.min !== 0 || fats.max !== 0) {
      macros.fats = fats;
    }

    // make a string of the macros
    let macrosString = "";
    Object.keys(macros).forEach((key) => {
      macrosString += `min ${key} per serving is ${macros[key].min} max is ${macros[key].max}, `;
    });

    const messages = RecipeGenerationMessage({ macrosString, diet, recipes });

    getChatGPTResponse({ messages: messages, temperature: 0.5 })
      .then((response) => {
        const contentObject = JSON.parse(response.response[0].message.content);

        setFoundRecipe(contentObject);
        setSearching(false);
      })
      .catch((error) => {
        consola.log("error", error);
        setSearching(false);
      });
  };

  const getMacroPerServing = (macro, servings) => {
    return (macro / parseInt(servings, 10)).toFixed(1);
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={true}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Recipe Generator</DialogTitle>
      <DialogContent>
        Select your criteria, and AI will generate a recipe for you.
      </DialogContent>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h5">Criteria</Typography>
            <MacroInputStack
              label="kCals"
              defaultValues={kCals}
              onMinChanged={(min) => {
                setKCals({ ...kCals, min });
              }}
              onMaxChanged={(max) => {
                setKCals({ ...kCals, max });
              }}
            />
            <MacroInputStack
              label="Proteins"
              defaultValues={proteins}
              onMinChanged={(min) => {
                setProteins({ ...proteins, min });
              }}
              onMaxChanged={(max) => {
                setProteins({ ...proteins, max });
              }}
            />
            <MacroInputStack
              label="Carbs"
              defaultValues={carbs}
              onMinChanged={(min) => {
                setCarbs({ ...carbs, min });
              }}
              onMaxChanged={(max) => {
                setCarbs({ ...carbs, max });
              }}
            />
            <MacroInputStack
              label="Fats"
              defaultValues={fats}
              onMinChanged={(min) => {
                setFats({ ...fats, min });
              }}
              onMaxChanged={(max) => {
                setFats({ ...fats, max });
              }}
            />

            <Stack style={{ marginTop: 8 }}>
              <Typography>Dietary Restrictions</Typography>
              <Select
                size="small"
                defaultValue={"balanced"}
                value={diet}
                variant="outlined"
                onChange={(event) => {
                  setDiet(event.target.value);
                }}
              >
                <MenuItem value="balanced">Balanced</MenuItem>
                <MenuItem value="keto">Keto</MenuItem>
                <MenuItem value="atkins">Atkins</MenuItem>
                <MenuItem value="high carb">High Carb</MenuItem>
                <MenuItem value="fat free">Fat Free</MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={9}>
            {foundRecipe && !searching && (
              <Box style={{ height: 500, overflow: "auto" }}>
                <Typography variant="h5">Recipe</Typography>
                <Typography variant="h6">
                  {foundRecipe.n} (Serves: {foundRecipe.ns} )
                </Typography>
                <Typography>{foundRecipe.d}</Typography>
                <Divider />
                <Typography style={{ fontWeight: "bold", marginTop: 8 }}>
                  Macros per serving
                </Typography>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>
                      {getMacroPerServing(foundRecipe.mac.p, foundRecipe.ns)}g
                      Proteins
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      {getMacroPerServing(foundRecipe.mac.c, foundRecipe.ns)}g
                      Carbs
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      {getMacroPerServing(foundRecipe.mac.f, foundRecipe.ns)}g
                      Fats
                    </Typography>
                  </Grid>
                </Grid>

                <Divider />

                <Typography style={{ fontWeight: "bold", marginTop: 8 }}>
                  Ingredients
                </Typography>
                {foundRecipe.ing.map((ingredient) => {
                  return (
                    <Typography key={ingredient.n}>
                      {ingredient.q} {ingredient.u} - {ingredient.n}
                    </Typography>
                  );
                })}

                <Divider />
                <Typography style={{ fontWeight: "bold", marginTop: 8 }}>
                  Instructions
                </Typography>
                {foundRecipe.i.map((instruction, index) => {
                  return (
                    <Typography key={instruction}>
                      Step: {index + 1} - {instruction}
                    </Typography>
                  );
                })}
              </Box>
            )}
            {searching && (
              <Stack
                spacing={3}
                style={{
                  height: 500,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={100} />
                <Typography variant="h5">Searching for recipe...</Typography>
              </Stack>
            )}
            {!searching && !foundRecipe && (
              <Stack
                spacing={3}
                style={{
                  height: 500,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h5" textAlign={"center"} width="80%">
                  Recipe will show up here. Fill in your criteria and press the
                  &apos;Find me a Recipe&apos; button to start.
                </Typography>
              </Stack>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent>
        <BlueButton onClick={generateRecipes} color="primary">
          Find me a recipe
        </BlueButton>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            if (onDialogClosed) {
              onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (onDialogClosed) {
              onDialogClosed(foundRecipe);
            }
          }}
          color="primary"
        >
          Use this Recipe
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default RecipeGeneratorDialog;
