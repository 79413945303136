import { selector } from "recoil";
import moment from "moment";
import organizationDataState from "./organizationDataAtom";

import TrialDays from "../data/TrialDays";
import { AccountType } from "../data/Types";

const trialDaysSelector = selector({
  key: "trialDaysSelector",
  default: TrialDays,
  get: ({ get }) => {
    const organizationData = get(organizationDataState);
    if (!organizationData) {
      return {
        daysLeft: TrialDays,
        accountType: AccountType.trial,
        expired: false,
      };
    }

    const { account } = organizationData;

    const trialDays = account?.trialPeriod || TrialDays;
    const start = account?.dateCreated?.toDate();

    let daysLeft = 0;

    if (start) {
      const startMod = moment(start).startOf("day").toDate();
      const now = moment().startOf("day").toDate();
      const diff = now.getTime() - startMod.getTime();
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      daysLeft = trialDays - days;
    }

    return {
      daysLeft,
      trialDays,
      accountType: account?.accountType || AccountType.trial,
      expired: daysLeft <= 0 && account?.accountType === AccountType.trial,
    };
  },
});

export default trialDaysSelector;
