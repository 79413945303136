import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import { DeleteButton } from "../../atoms/EditButton";

const APIKeyList = ({ snapshot, onDelete }) => {
  if (!snapshot || snapshot.length === 0) {
    return (
      <Box
        style={{
          marginBottom: 12,
          maxHeight: 400,
          width: "100%",
          overflow: "auto",
        }}
      >
        <Typography>No API keys found</Typography>
      </Box>
    );
  }

  return (
    <Box
      style={{
        marginBottom: 12,
        maxHeight: 400,
        width: "100%",
        overflow: "auto",
      }}
    >
      <>
        {snapshot.map((doc) => {
          return (
            <Box key={doc.key} style={{ marginBottom: 12 }}>
              <Box>
                <Grid
                  container
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Grid item xs={10}>
                    <Typography>{doc.key}</Typography>
                  </Grid>
                  <Grid item>
                    <DeleteButton
                      onClick={() => {
                        if (onDelete) {
                          onDelete(doc.key);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          );
        })}
      </>
    </Box>
  );
};

export default APIKeyList;
