import { useRef, useState, useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";
import firebase from "firebase/compat/app";
import { consola } from "consola";
import moment from "moment";
import { getkCal } from "../components/LibraryFunctions";

export const ClientNutritionChartDataState = atom({
  key: "ClientNutritionChartDataState",
  default: null,
});

function useClientNutritionChartData({ uid, organizationId, duration, count }) {
  const subscription = useRef(null);

  const [data, setData] = useState(null);
  const setClientNutritionChartData = useSetRecoilState(
    ClientNutritionChartDataState,
  );

  useEffect(() => {
    let ref = null;
    if (duration) {
      const start = moment().subtract(duration, "days").startOf("day").toDate();
      const end = moment().endOf("day").toDate();

      ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients")
        .doc(uid)
        .collection("reportHealth")
        .where("createdOn", ">=", start)
        .where("createdOn", "<=", end)
        .orderBy("createdOn");
    } else {
      ref = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients")
        .doc(uid)
        .collection("reportHealth")
        .limit(count)
        .orderBy("createdOn", "desc");
    }

    if (subscription.current) {
      subscription.current();
    }

    subscription.current = ref.onSnapshot((snapshot) => {
      consola.info("++++++ SNAPSHOT -> useClientNutritionChartData");

      const finalData = snapshot.docs.map((d) => {
        const value = d.data();
        // calculate kCal using getKCal and report data macros
        let kCal = 0;
        if (
          value.reportData.carbs > 0 ||
          value.reportData.fats > 0 ||
          value.reportData.proteins > 0
        ) {
          kCal = getkCal({
            carbs: value.reportData.carbs,
            fats: value.reportData.fats,
            proteins: value.reportData.proteins,
          });
        }

        return {
          ...d.data().reportData,
          kCal: parseFloat(kCal.toFixed(2)),
          carbs: parseFloat(d.data().reportData.carbs.toFixed(2)),
          fats: parseFloat(d.data().reportData.fats.toFixed(2)),
          proteins: parseFloat(d.data().reportData.proteins.toFixed(2)),
          weight: parseFloat(d.data().reportData.weight.toFixed(2)),
          createdOn: d.data().createdOn.toDate(),
          createdOnString: moment(d.data().createdOn.toDate()).format("D MMM"),
        };
      });

      const sorted = finalData.sort((a, b) => {
        return a.createdOn - b.createdOn;
      });
      setData(sorted);
      setClientNutritionChartData(sorted);
    });

    return () => {
      if (subscription.current) {
        subscription.current();
      }
    };
  }, [uid, organizationId]);

  return { nutritionChartData: data };
}

export default useClientNutritionChartData;
