import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import { useRecoilValue } from "recoil";
import organizationIdState from "../atoms/organizationIdAtom";
import { consola } from "consola";

async function getProtocolById(organizationId, protocolId, history) {
  const ref = firebase
    .firestore()
    .collection("organizations")
    .doc(organizationId)
    .collection("protocols")
    .doc(protocolId);

  consola.info("++++++ READ -> useProtocolHistory: getProtocolById");
  const data = await ref.get();
  if (data.exists) {
    return {
      ...history,
      ...data.data(),
    };
  }
  return null;
}

const useProtocolHistory = ({ uid, limit = 5 }) => {
  const [protocolHistory, setProtocolHistory] = React.useState([]);
  const subscriptionIngredients = React.useRef(null);
  const organizationId = useRecoilValue(organizationIdState);

  useEffect(() => {
    if (subscriptionIngredients.current) {
      subscriptionIngredients.current();
    }

    const ref = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocolHistory")
      .where("uid", "==", uid)
      .limit(limit)
      .orderBy("protocolChanged", "desc");

    subscriptionIngredients.current = ref.onSnapshot((snapshot) => {
      consola.info("++++++ SNAPSHOT -> useProtocolHistory");
      const data = snapshot.docs;

      const mapped = data.map((d) => {
        const protocolId = d.data().protocolId; // Assuming you have the property 'protocolId' in each 'd' object.

        // Replace the following line with the code to fetch the protocol object by its ID asynchronously.
        // For example, you might use an API call or fetch the data from a database.
        return getProtocolById(organizationId, protocolId, d.data());
      });

      Promise.all(mapped).then((values) => {
        consola.info("++++++ PROMISE -> useProtocolHistory", values);
        // filter out null values
        values = values.filter((v) => v !== null);
        setProtocolHistory(values);
      });
    });
  }, [organizationId, uid]);

  return { protocolHistory };
};

export default useProtocolHistory;
