import React from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Divider,
  Container,
  Link,
} from "@mui/material";

import { getReportPeriod } from "../../../../components/LibraryFunctions";

import MessageData from "../../../../data/MessageData";
import CaloriesIntake from "./CaloriesIntake";
import Macros from "./Macros";
import AdditionalData from "./AdditionalData";
import WeeklyData from "./WeeklyData";
import ProgressPhotos from "./ProgressPhotos";
import QuickReply from "./QuickReply";
import CardioGoals from "./CardioGoals";
import SupplementHistory from "./SupplementHistory";
import ExerciseData from "./ExerciseData";
import HealthCharts from "./HealthCharts";
import ClientSummary from "../clients/ClientSummary";

import useEvents from "../../../../hooks/useEvents";
import { parentBoxStyle } from "../clients/BoxStyles";
import ReportDescription from "./ReportDescription";

const ReportSummaryDrawer = ({
  update,
  report,
  breadCrumb,
  onClose,
  handleMarkProcessed,
}) => {
  const [group, setGroup] = React.useState();
  const [mode, setMode] = React.useState("report");
  const events = useEvents();

  React.useEffect(() => {
    async function getData() {
      if (!report) {
        return;
      }
      const group = await MessageData.getMessageGroup(report.clientData.uid);

      setGroup(group[0]);
    }
    getData();
  }, [update, report, update]);

  if (!report) {
    return null;
  }

  return (
    <>
      {mode === "report" && (
        <Container
          maxWidth={false}
          sx={{
            margin: 1,
          }}
        >
          <Box style={parentBoxStyle}>
            <Breadcrumbs separator=">">
              <Link
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                }}
              >
                {breadCrumb ? breadCrumb : "Reports"}
              </Link>
              <Link
                onClick={() => {
                  setMode("client");
                }}
              >
                <Typography>{`${report.clientFirstName} ${report.clientLastName}`}</Typography>
              </Link>
            </Breadcrumbs>
            <Divider style={{ marginTop: 5 }}></Divider>

            <Box style={parentBoxStyle}>
              <Box
                display="flex"
                boxShadow={3}
                borderRadius={2}
                style={{ backgroundColor: "#F6F7FB", padding: 20 }}
              >
                <ReportDescription
                  report={report}
                  handleMarkProcessed={handleMarkProcessed}
                />
              </Box>

              <Box
                style={{
                  display: "flex",
                  alignItems: "left",
                  marginTop: 24,
                  flexDirection: "column",
                }}
              >
                <CaloriesIntake report={report} />
                <Macros report={report} />
                <HealthCharts clientUID={report.clientData.uid} />
                <CardioGoals report={report} />
                <SupplementHistory report={report} />
                <AdditionalData report={report} update={update} />
                <WeeklyData report={report} />
                <ExerciseData report={report} />
                <ProgressPhotos
                  title="Progress Photos"
                  showStarting={true}
                  report={report}
                  dateRange={getReportPeriod(report)}
                  photoGroupTitle="Report Progress Photos"
                  noPhotosMessage="No photos for this report period"
                />
                <QuickReply report={report} group={group} />
              </Box>
            </Box>
          </Box>
        </Container>
      )}
      {mode === "client" && (
        <ClientSummary
          breadCrumb={`Report`}
          uid={report.clientData.uid}
          onBackClick={() => {
            setMode("report");
            events.logButtonClick({
              buttonName: "Clicked Client",
              pageName: "Protocols",
              tabName: report.clientData.uid,
            });
          }}
        />
      )}
    </>
  );
};

export default ReportSummaryDrawer;
