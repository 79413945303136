import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { ago } from "../../../../components/LibraryFunctions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

function ProtocolHistory({ history, title = "History", type }) {
  if (title) {
    return (
      <Box backgroundColor={"#D2E6F447"} borderRadius={4} marginTop={2}>
        {/* <Typography
          textAlign={"left"}
          style={{
            marginLeft: 15,
            marginTop: 12,
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {title}
        </Typography> */}
        <TableContainer padding={5}>
          <Table aria-label="history table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ borderBottomColor: "black" }}>
                  <Typography fontSize={14} fontWeight={600}>
                    {type}
                  </Typography>
                </TableCell>
                <TableCell
                  width={100}
                  align="left"
                  style={{ borderBottomColor: "black" }}
                >
                  <Typography fontSize={14} fontWeight={600}>
                    Last Changed
                  </Typography>
                </TableCell>
                <TableCell
                  width={100}
                  align="center"
                  style={{ borderBottomColor: "black" }}
                >
                  <Typography fontSize={14} fontWeight={600}>
                    Date
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {history.length === 0 && (
                <TableRow>
                  <TableCell
                    style={{ width: 150, borderBottom: "None", marginTop: 0 }}
                  >
                    <Typography fontSize={12} textAlign={"left"}>
                      No historic data to display
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {history.map((h) => {
                return (
                  <TableRow key={h.id}>
                    <TableCell style={{ borderBottom: "None", marginTop: 0 }}>
                      <Typography fontSize={12} textAlign={"left"}>
                        {h?.name || "No Name"}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "None", marginTop: 0 }}>
                      <Typography fontSize={12} textAlign={"left"}>
                        {ago(h?.protocolChanged?.toDate())}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "None", marginTop: 0 }}>
                      <Typography fontSize={12} textAlign={"left"}>
                        {moment(h?.protocolChanged?.toDate()).format(
                          "MMMM D, YYYY",
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
  return (
    <Box backgroundColor={"#D2E6F447"} marginTop={2}>
      <Typography
        style={{ marginLeft: 20, marginTop: 12, fontSize: 16, fontWeight: 600 }}
      >
        {title}
      </Typography>
      <Stack direction={"row"} spacing={8}>
        <Typography
          style={{
            marginLeft: 20,
            marginTop: 12,
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          {type}
        </Typography>
        <Typography
          style={{
            marginLeft: 20,
            marginTop: 12,
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Last Changed
        </Typography>
        <Typography
          style={{
            marginLeft: 20,
            marginTop: 12,
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Date
        </Typography>
      </Stack>
      <Divider></Divider>

      {history.map((h) => {
        return (
          <Typography
            key={h.id}
            style={{
              fontSize: 12,
              fontWeight: "normal",
            }}
          >
            {`${h?.name || "No Name"}: ${ago(h?.protocolChanged?.toDate())}`}
          </Typography>
        );
      })}
    </Box>
  );
}
export default ProtocolHistory;
