import React from "react";
import {
  Container,
  Box,
  Typography,
  Divider,
  Grid,
  Breadcrumbs,
  Link,
  Stack,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { animateScroll as scroll } from "react-scroll";

import { nanoid } from "nanoid";
import moment from "moment";
import _ from "underscore";
import lodash from "lodash";

import ProtocolData from "../../../../data/ProtocolData";
import UserData from "../../../../data/UserData";
import ClientData, {
  deleteGoal,
  deleteCardioGoal,
  activateNutritionGoal,
} from "../../../../data/ClientData";

import {
  sendMessageToUsersGroup,
  getMessageGroupWithUID,
} from "../../../../data/MessageData";

import ClientDescription from "./ClientDescription";
import TitledBox from "./TitledBox";
import NutritionGoals from "./NutritionGoals";
import CardioGoals from "../../../../components/organisms/CardioGoals";
import {
  ClientMessages,
  ClientNotes,
  ClientProgressPhotos,
  ClientSharedVideos,
  ClientTrainingData,
} from "./ClientSummaryComponentModules";
import SharedFiles from "../../../../components/organisms/SharedFiles";
import ProtocolHistory from "./ProtocolHistory";
import ProtocolSelectDialog from "./ProtocolSelectDialog";
import ClientProtocolDialog from "./ClientProtocolDialog";
import MealPlanSelectDialog from "./MealPlanSelectDialog";
import CoachSelectDialog from "./CoachSelectDialog";
import ClientEditDialog from "./ClientEditDialog";
import BodyMeasurementsEditDialog from "./BodyMeasurementsEditDialog";
import ProtocolDetails from "../protocols/ProtocolDetails";
import AvatarList from "../../../../components/molecules/AvatarList";
import { getHistoryString } from "../../../../components/LibraryFunctions";
import organizationDataState from "../../../../atoms/organizationDataAtom";

import colors from "../../../../themes/Colors";
// import ChartLegend from "./ChartLegend";
import "../../../../styles/daypicker.css";
import SupplementPrescriptions from "./SupplementPrescriptions";
import useRecipes from "../../../../hooks/useRecipes";
import useProtocolHistory from "../../../../hooks/useProtocolHistory";
import BlueBorderButton from "../../../../components/BlueBorderButton";
import { parentBoxStyle } from "./BoxStyles";
import useClientExerciseReports from "../../../../hooks/useClientExerciseReports";
import useClientNutritionChartData from "../../../../hooks/useClientNutritionChartData";
import useRenderCount from "../../../../hooks/useRenderCount";
import useEvents from "../../../../hooks/useEvents";
// import ClientCalendar from "./DailyProgressReports";
import DailyProgressReports from "./DailyProgressReports";
import consola from "consola";

const ClientSummary = ({ uid, breadCrumb, onBackClick }) => {
  const organization = useRecoilValue(organizationDataState);
  const { mealPlans, getMealPlanById } = useRecipes({
    organizationId: organization.id,
  });
  const { protocolHistory } = useProtocolHistory({ uid });

  useRenderCount({ name: "ClientSummary" });

  const subscription = React.useRef(undefined);
  const coachesBuffer = React.useRef([]);

  const [protocol, setProtocol] = React.useState(undefined);
  const [coaches, setCoaches] = React.useState();
  const [selectedDay, setSelectedDay] = React.useState(new Date());
  const [coachesUpdated, setCoachesUpdated] = React.useState();
  const [dailyReport, setDailyReport] = React.useState(null);
  // const [exerciseData, setExerciseData] = React.useState([]);
  // const [, setHealthData] = React.useState([]);
  const [calendarModifiers, setCalendarModifiers] = React.useState({});
  const [modifierStyles, setModifierStyles] = React.useState({});
  const [client, setClient] = React.useState(null);
  const [photoUpdate, setPhotoUpdate] = React.useState(new Date());
  const [messageGroup, setMessageGroup] = React.useState(null);

  const [protocolSelectOpen, setProtocolSelectOpen] = React.useState(false);
  const [clientProtocolSelectOpen, setClientProtocolSelectOpen] =
    React.useState(false);
  const [mealPlanSelectOpen, setMealPlanSelectOpen] = React.useState(false);
  const [coachSelectOpen, setCoachSelectOpen] = React.useState(false);
  const [clientEditOpen, setClientEditOpen] = React.useState(false);
  const [bodyMeasurementOpen, setBodyMeasurementOpen] = React.useState(false);
  const [chartType, setChartType] = React.useState();
  const [historyOpen, setHistoryOpen] = React.useState(false);

  const [protocolUpdated, setProtocolUpdated] = React.useState(new Date());

  const [mode, setMode] = React.useState("client");

  const selectedProtocol = React.useRef();
  const events = useEvents();

  const { exerciseData } = useClientExerciseReports({
    uid: uid,
    organizationId: organization.id,
    start: moment().subtract(90, "days").toDate(),
    end: moment().toDate(),
  });

  const { nutritionChartData } = useClientNutritionChartData({
    uid: uid,
    organizationId: organization.id,
    count: 90,
  });

  React.useEffect(() => {
    scroll.scrollToTop({
      smooth: "linear",
      duration: 50,
    });
  }, []);

  React.useEffect(() => {
    if (subscription.current) {
      subscription.current();
    }

    ClientData.getClientSubscription(
      uid,
      (client) => {
        if (!_(client.coaches).isEqual(coachesBuffer.current)) {
          coachesBuffer.current = client.coaches;
          setCoachesUpdated(new Date());
        }
        setClient(client);
      },
      (sub) => {
        subscription.current = sub;
      },
    );

    return () => {
      if (subscription.current) {
        subscription.current();
      }
    };
  }, [uid]); // eslint-disable-line

  React.useEffect(() => {
    window.addEventListener("beforeunload", function () {
      consola.info("****** UNLOAD ClientSummary - beforeunload");
      if (subscription.current) {
        subscription.current();
      }
    });
  }, []);

  React.useEffect(() => {
    async function getExerciseData() {
      const modifierStylesLocal = {};

      const exerciseDataCompleted = _.filter(exerciseData, (d) => {
        return d.completed === true;
      });

      const reportDataAvailable = _.filter(nutritionChartData, (r) => {
        if (r.carbs > 0 || r.fats > 0 || r.proteins > 0 || r.water > 0) {
          return true;
        }
        return false;
      });

      const modifiers = exerciseDataCompleted.map((d) => {
        const date = moment(d.completedOn).startOf("day").toDate();
        modifierStylesLocal[date] = { exercise: true };
        return date;
      });

      const healthModifers = reportDataAvailable.map((d) => {
        const date = moment(d.createdOn).startOf("day").toDate();
        if (date in modifierStylesLocal) {
          modifierStylesLocal[date].health = true;
        } else {
          modifierStylesLocal[date] = { health: true };
        }
        return date;
      });

      // Filter exercises by completed.
      let merged = modifiers.concat(healthModifers);
      merged = _.uniq(merged, false, function (date) {
        return date.getTime();
      });

      const final = {};
      merged.forEach((m) => {
        final[m] = m;
      });

      const modifiersFinal = final;

      const styleObject = {};
      _.mapObject(modifierStylesLocal, function (val, key) {
        if (key in styleObject) {
          if (val.exercise && val.health) {
            styleObject[key] = {
              color: colors.calendarGreen,
              fontWeight: "bold",
            };
          } else if (val.exercise) {
            styleObject[key] = {
              color: colors.calendarYellow,
              fontWeight: "bold",
            };
          } else if (val.health) {
            styleObject[key] = {
              color: colors.calendarBlue,
              fontWeight: "bold",
            };
          }
        } else {
          if (val.exercise && val.health) {
            styleObject[key] = {
              color: colors.calendarGreen,
              fontWeight: "bold",
            };
          } else if (val.exercise) {
            styleObject[key] = {
              color: colors.calendarYellow,
              fontWeight: "bold",
            };
          } else if (val.health) {
            styleObject[key] = {
              color: colors.calendarBlue,
              fontWeight: "bold",
            };
          }
        }
      });

      setCalendarModifiers(modifiersFinal);
      setModifierStyles(styleObject);
    }
    getExerciseData();
  }, [uid, exerciseData, nutritionChartData]);

  React.useEffect(() => {
    async function getCoaches(coachIds) {
      const promises = coachIds.map((coachId) => {
        return UserData.getUserData(coachId);
      });
      const coaches = await Promise.all(promises);
      const sorted = _.sortBy(coaches, "firstName");
      setCoaches(sorted);
    }
    getCoaches(coachesBuffer.current);
  }, [coachesUpdated]);

  React.useEffect(() => {
    async function getMessageData() {
      const group = await getMessageGroupWithUID([client.uid]);

      if (group.length >= 1) {
        setMessageGroup(group[0]);
      } else {
        setMessageGroup(null);
      }
    }
    if (client) {
      getMessageData();
    }
  }, [client, coaches]);

  React.useEffect(() => {
    async function getProtocol(protocolId) {
      const protocol = await ProtocolData.getProtocol(protocolId);
      setProtocol(protocol);
    }
    if (client) {
      getProtocol(client.protocolId);
    }
  }, [protocolUpdated, client]);

  React.useEffect(() => {
    async function getDailyReport() {
      const report = await ClientData.getClientReport(
        client.uid,
        selectedDay,
        "complete",
      );
      setDailyReport(report);
    }

    if (client) {
      const subscription = ClientData.getHealthReportSubscription({
        uid: client.uid,
        date: moment(selectedDay).toDate(),
        onSnapshot: () => {
          getDailyReport();
        },
      });

      return () => {
        if (subscription) {
          subscription();
        }
      };
    }
  }, [selectedDay, client]);

  const coachSummary = React.useMemo(() => {
    let coachSummary = "";
    if (coaches) {
      // filter out null coaches
      const filteredCoaches = coaches.filter((coach) => {
        return coach !== null;
      });
      filteredCoaches.forEach((coach) => {
        coachSummary =
          coachSummary + `${coach?.firstName} ${coach?.lastName}, `;
      });
      coachSummary = coachSummary.slice(0, -2);
    }
    return coachSummary;
  }, [coaches]);

  const coachesArray = React.useMemo(() => {
    if (!coaches) {
      return null;
    }

    const filteredCoaches = coaches.filter((coach) => {
      return coach !== null;
    });

    return filteredCoaches.map((coach) => {
      return {
        key: coach.uid,
        url: coach.avatarUrl,
        firstName: coach.firstName,
        lastName: coach.lastName,
      };
    });
  }, [coaches]);

  async function createVariantProtocol() {
    const result = await ProtocolData.duplicateProtocol({
      protocolId: protocol.data().id,
      name: `${protocol.data().name} - ${moment().format("YYYY-MM-DD")}`,
      clientUID: client.uid,
    });

    selectedProtocol.current = result;
    setMode("protocol");
  }

  async function resetProtocolChangeDate() {
    await ClientData.updateClient(client.uid, {
      protocolChanged: new Date(),
    });
  }

  async function removeProtocolFromClient() {
    await ClientData.updateClient(client.uid, {
      protocolChanged: null,
      protocolId: null,
      splitId: null,
      currentExerciseReportId: null,
    });
  }

  function handleButtonEvent(ButtonName, PageName, Tab) {
    const logEvent = { buttonName: ButtonName, pageName: PageName, tab: Tab };
    events.logButtonClick(logEvent);
  }

  if (!client) {
    return null;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        margin: 1,
      }}
    >
      {mode === "client" && (
        <Box style={parentBoxStyle}>
          <Breadcrumbs separator=">">
            <Link
              onClick={() => {
                if (onBackClick) {
                  onBackClick();
                }
              }}
            >
              {breadCrumb ? breadCrumb : "Clients"}
            </Link>
            <Typography>{`${client.firstName} ${client.lastName}`}</Typography>
          </Breadcrumbs>

          <Divider style={{ marginTop: 5 }}></Divider>

          <Box style={parentBoxStyle}>
            <Box
              display="flex"
              boxShadow={3}
              borderRadius={2}
              style={{ backgroundColor: "#F6F7FB", padding: 20 }}
            >
              <ClientDescription
                client={client}
                orgData={organization}
                editClicked={() => {
                  setClientEditOpen(true);
                  handleButtonEvent("Edit Client", "Clients", client.uid);
                }}
                avatarUpdated={async () => {
                  await ClientData.updateClient(client.uid, {
                    avatarChangedOn: new Date(),
                  });
                  handleButtonEvent(
                    "Update Client Avatar",
                    "Clients",
                    client.uid,
                  );
                }}
              />
            </Box>
            {/* <Divider style={{ marginTop: 20 }}></Divider> */}
            {/* Consider refactoring this, requires a hook to manage shared states */}
            <Grid container spacing={1} style={{ marginTop: 12 }}>
              <Grid item xs={4}>
                <TitledBox
                  id="protocol"
                  title="Current Training Plan"
                  editName="Change users training plan"
                  // summary={
                  //   protocol
                  //     ? protocol.data().name
                  //     : "The client does not have a training plan"
                  // }
                  summaryComponent={() => {
                    return (
                      <Stack>
                        <Typography
                          textAlign={"center"}
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            marginTop: 25,
                            justifyItems: "center",
                          }}
                        >
                          {protocol
                            ? protocol.data().name
                            : "The client does not have a training plan"}
                        </Typography>
                        {historyOpen && (
                          <ProtocolHistory
                            history={protocolHistory}
                            type={"Training Plan"}
                          />
                        )}
                      </Stack>
                    );
                  }}
                  // subTitle={getProtocolChanged(client)}
                  style={{ marginRight: 40 }}
                  onEdit={() => {
                    setProtocolSelectOpen(true);
                  }}
                  callToAction={function () {
                    return (
                      <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent={"center"}
                        style={{ marginTop: 25, marginBottom: 12 }}
                      >
                        <Box>
                          <BlueBorderButton
                            onClick={() => {
                              setProtocolSelectOpen(true);
                              handleButtonEvent(
                                "Select Client Training Plan",
                                "Clients",
                                client.uid,
                              );
                            }}
                          >
                            Select a Plan
                          </BlueBorderButton>
                        </Box>
                        <BlueBorderButton
                          onClick={() => {
                            if (historyOpen) {
                              handleButtonEvent(
                                "Get Training PLan History",
                                "Clients",
                                client.uid,
                              );
                            }
                            setHistoryOpen(!historyOpen);
                          }}
                        >
                          {getHistoryString(historyOpen)}
                        </BlueBorderButton>
                      </Stack>
                    );
                  }}
                  menuItems={[
                    {
                      type: "menu",
                      menuName: "View client training plans",
                      menuFunction: () => {
                        setClientProtocolSelectOpen(true);
                        handleButtonEvent(
                          "View Client Training Plans",
                          "Clients",
                          client.uid,
                        );
                      },
                    },
                    {
                      type: "divider",
                    },
                    {
                      type: "menu",
                      menuName: "Create a new training plan",
                      menuFunction: () => {
                        const protocolId = nanoid();
                        handleButtonEvent(
                          "Create New Training Plan",
                          "Clients",
                          client.uid,
                        );

                        ProtocolData.addProtocol({
                          id: protocolId,
                          name: `New Training Plan - ${moment().format(
                            "YYYY-MM-DD",
                          )}`,
                          clientUID: client.uid,
                          description: "",
                          privacy: "private",
                          type: "general",
                          difficulty: "beginner",
                          protocolLabels: [],
                        }).then((result) => {
                          if (result) {
                            selectedProtocol.current = result;
                            setMode("protocol");
                          }
                        });
                      },
                    },
                    {
                      type: "menu",
                      menuName: "Create a variant plan",
                      menuFunction: () => {
                        confirmAlert({
                          title: `Create training plan variant?`,
                          message:
                            "Do you want to create a variant of the current training plan?",
                          buttons: [
                            {
                              label: "Yes",
                              onClick: async () => {
                                createVariantProtocol();
                                handleButtonEvent(
                                  "Create Variant Plan",
                                  "Clients",
                                  client.uid,
                                );
                              },
                            },
                            {
                              label: "No",
                              onClick: () => {
                                handleButtonEvent(
                                  "Cancel Create Variant",
                                  "Clients",
                                  client.uid,
                                );
                              },
                            },
                          ],
                        });
                      },
                    },

                    {
                      type: "divider",
                    },
                    {
                      type: "menu",
                      menuName: "Edit the current training plan",
                      menuFunction: () => {
                        handleButtonEvent(
                          "Edit Current Training Plan",
                          "Clients",
                          client.uid,
                        );
                        const protocolVal = protocol.data();
                        if (!protocolVal) return;

                        if (
                          protocolVal.clientUID &&
                          protocolVal.clientUID !== client.uid
                        ) {
                          alert(
                            "This training plan cannot be edited from here. It is shared with other clients.",
                          );
                          return;
                        }

                        selectedProtocol.current = protocolVal;
                        setMode("protocol");
                      },
                    },

                    {
                      type: "divider",
                    },
                    {
                      type: "menu",
                      menuName: "Reset last update date",
                      menuFunction: async () => {
                        handleButtonEvent(
                          "Reset Last Update Date",
                          "Clients",
                          client.uid,
                        );
                        await resetProtocolChangeDate();
                      },
                    },
                    {
                      type: "menu",
                      menuName: "Remove plan from client",
                      menuFunction: async () => {
                        handleButtonEvent(
                          "Remove Plan From Client",
                          "Clients",
                          client.uid,
                        );
                        removeProtocolFromClient();
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <TitledBox
                  id="mealplan"
                  title="Current Meal Plan"
                  editName="Change users meal plan"
                  summary={
                    client.mealPlanId
                      ? getMealPlanById(client.mealPlanId)?.name
                      : "No Meal Plan Assigned"
                  }
                  // subTitle={getMealPlanChanged(client)}
                  style={{ marginRight: 40 }}
                  onEdit={() => {
                    handleButtonEvent(
                      "Change Meal Plan",
                      "Clients",
                      client.uid,
                    );
                    setMealPlanSelectOpen(true);
                  }}
                  callToAction={function () {
                    return (
                      <Box
                        style={{
                          textAlign: "center",
                          marginTop: 25,
                          marginBottom: 0,
                        }}
                      >
                        <BlueBorderButton
                          onClick={() => {
                            setMealPlanSelectOpen(true);
                            handleButtonEvent(
                              "Select Meal Plan",
                              "Clients",
                              client.uid,
                            );
                          }}
                        >
                          Select Meal Plan
                        </BlueBorderButton>
                      </Box>
                    );
                  }}
                  menuItems={[
                    {
                      type: "divider",
                    },
                    {
                      type: "menu",
                      menuName: "Clear the meal plan",
                      menuFunction: () => {
                        confirmAlert({
                          title: `Clear the meal plan?`,
                          message:
                            "This will remove the meal plan from the client?",
                          buttons: [
                            {
                              label: "Yes",
                              onClick: async () => {
                                handleButtonEvent(
                                  "Clear Meal Plan",
                                  "Clients",
                                  client.uid,
                                );
                                await ClientData.updateClient(client.uid, {
                                  mealPlanId: null,
                                  mealPlanChanged: new Date(),
                                });
                              },
                            },
                            {
                              label: "No",
                              onClick: () => {
                                handleButtonEvent(
                                  "Cancel Clear Meal Plan",
                                  "Clients",
                                  client.uid,
                                );
                              },
                            },
                          ],
                        });
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <TitledBox
                  id="coach"
                  title="Coaches"
                  editName="Change users coaches"
                  summary={coachSummary}
                  summaryComponent={() => {
                    return (
                      <Box style={{ marginTop: 12, marginLeft: 20 }}>
                        <AvatarList
                          users={coachesArray}
                          size={56}
                          borderWidth={1}
                        />
                      </Box>
                    );
                  }}
                  onEdit={() => {
                    setCoachSelectOpen(true);
                    handleButtonEvent(
                      "Change User Coaches",
                      "Clients",
                      client.uid,
                    );
                  }}
                  callToAction={function () {
                    return (
                      <Box textAlign={"center"} style={{ marginTop: 8 }}>
                        <BlueBorderButton
                          onClick={() => {
                            setCoachSelectOpen(true);
                            handleButtonEvent(
                              "Change User Coaches",
                              "Clients",
                              client.uid,
                            );
                          }}
                        >
                          Assign Coach
                        </BlueBorderButton>
                      </Box>
                    );
                  }}
                />
              </Grid>
            </Grid>
            <NutritionGoals
              id="nutrition"
              client={client}
              onDelete={async (nutritionGoal) => {
                handleButtonEvent(
                  "Delete Nutrition Goal",
                  "Clients",
                  client.uid,
                );
                await deleteGoal({
                  nutritionGoal: nutritionGoal,
                  client: client,
                });
              }}
              onActivate={async (nutritionGoal) => {
                handleButtonEvent(
                  "Set Active Nutrition Goal",
                  "Clients",
                  client.uid,
                );
                await activateNutritionGoal(client, nutritionGoal);
                await sendMessageToUsersGroup({
                  uid: client.uid,
                  message: `Your coach changed your active nutrition goal to "${nutritionGoal.name}".`,
                });
              }}
              onEdit={async (nutritionGoal) => {
                if (client.nutritionGoals) {
                  handleButtonEvent(
                    "Edit Nutrition Goal",
                    "Clients",
                    client.uid,
                  );
                  const newGoals = [].concat(client.nutritionGoals);
                  const index = _.findIndex(newGoals, {
                    id: nutritionGoal.id,
                  });

                  let changeMessage = "";
                  if (index !== -1) {
                    newGoals[index] = nutritionGoal;
                    changeMessage = `Your coach changed the "${
                      nutritionGoal.name
                    }" nutrition goal. Proteins: ${
                      nutritionGoal?.proteins
                        ? nutritionGoal.proteins.toFixed(0)
                        : "0"
                    }g, carbs: ${
                      nutritionGoal?.carbs
                        ? nutritionGoal.carbs.toFixed(0)
                        : "0"
                    }g, and fats: ${
                      nutritionGoal?.fats ? nutritionGoal.fats.toFixed(0) : "0"
                    }g.`;
                  } else {
                    // Add a new one.
                    handleButtonEvent(
                      "Add Nutrition Goal",
                      "Clients",
                      client.uid,
                    );
                    newGoals.push(nutritionGoal);
                    changeMessage = `Your coach added the "${
                      nutritionGoal.name
                    }" nutrition goal. Proteins: ${
                      nutritionGoal?.proteins
                        ? nutritionGoal.proteins.toFixed(0)
                        : "0"
                    }g, carbs: ${
                      nutritionGoal?.carbs
                        ? nutritionGoal.carbs.toFixed(0)
                        : "0"
                    }g, and fats: ${
                      nutritionGoal?.fats ? nutritionGoal.fats.toFixed(0) : "0"
                    }g.`;
                  }
                  await ClientData.updateClient(client.uid, {
                    nutritionGoals: newGoals,
                    nutritionGoalChanged: new Date(),
                  });

                  await sendMessageToUsersGroup({
                    mustBeRegistered: true,
                    uid: client.uid,
                    message: changeMessage,
                  });
                }
              }}
            />

            <CardioGoals
              id="cardio"
              client={client}
              onDelete={async (cardioGoal) => {
                handleButtonEvent("Delete Cardio Goal", "Clients", client.uid);
                await deleteCardioGoal({
                  cardioGoal: cardioGoal,
                  client: client,
                });
              }}
              onActivate={async (cardioGoal) => {
                if (client.cardioGoals) {
                  const goals = lodash.cloneDeep(client.cardioGoals);
                  goals.forEach((goal) => {
                    goal.active = false;
                  });

                  goals.forEach((goal) => {
                    if (goal.id === cardioGoal.id) {
                      goal.active = true;
                    }
                  });

                  handleButtonEvent(
                    "Set Active Cardio Goal",
                    "Clients",
                    client.uid,
                  );

                  await ClientData.updateClient(client.uid, {
                    cardioGoals: goals,
                    cardioGoalChanged: new Date(),
                  });

                  await sendMessageToUsersGroup({
                    uid: client.uid,
                    message: `Your coach changed your active cardio goal to "${cardioGoal.name}".`,
                  });
                }
              }}
              onEdit={async (cardioGoal) => {
                let goals = [];
                if (client.cardioGoals) {
                  goals = client.cardioGoals;
                }

                if (goals) {
                  handleButtonEvent("Edit Cardio Goal", "Clients", client.uid);

                  const newGoals = [].concat(goals);
                  const index = _.findIndex(newGoals, {
                    id: cardioGoal.id,
                  });

                  let changeMessage = "";
                  if (index !== -1) {
                    newGoals[index] = cardioGoal;
                    changeMessage = `Your coach changed the ${cardioGoal.name} cardio goal.`;
                  } else {
                    // Add a new one.
                    handleButtonEvent("Add Cardio Goal", "Clients", client.uid);
                    if (newGoals.length === 0) {
                      cardioGoal.active = true;
                    }
                    newGoals.push(cardioGoal);
                    changeMessage = `Your coach added the ${cardioGoal.name} cardio goal.`;
                  }

                  const result = await ClientData.updateClient(client.uid, {
                    cardioGoals: newGoals,
                    cardioGoalChanged: new Date(),
                  });
                  consola.log("updateClient result", result);
                  if (result) {
                    await sendMessageToUsersGroup({
                      mustBeRegistered: true,
                      uid: client.uid,
                      message: changeMessage,
                    });
                  }
                }
              }}
            />

            <SupplementPrescriptions client={client} />

            <SharedFiles uid={client.uid} />

            <ClientMessages uid={client?.uid} groupId={messageGroup?.id} />

            <DailyProgressReports
              client={client}
              calendarModifiers={calendarModifiers}
              modifierStyles={modifierStyles}
              selectedDay={selectedDay}
              dailyReport={dailyReport}
              setBodyMeasurementOpen={setBodyMeasurementOpen}
              onDayClick={(day, modifiers = {}) => {
                if (modifiers.disabled) {
                  return;
                }
                events.logDayClick({ clientId: client.uid, day: day });
                setSelectedDay(day);
              }}
            ></DailyProgressReports>

            <ClientTrainingData
              client={client}
              dailyReport={dailyReport}
              exerciseData={exerciseData}
              chartType={chartType}
              setChartType={setChartType}
            />

            <ClientProgressPhotos
              client={client}
              photoUpdate={photoUpdate}
              setPhotoUpdate={setPhotoUpdate}
            />
            <ClientNotes client={client} />

            <ClientSharedVideos client={client} />
          </Box>
        </Box>
        // </Box>
      )}

      {mode === "protocol" && (
        <ProtocolDetails
          breadCrumb={`${client.firstName} ${client.lastName}`}
          protocol={selectedProtocol.current}
          onBackClick={() => {
            setMode("client");
          }}
          protocolEdited={() => {
            setProtocolUpdated(new Date());
          }}
        />
      )}

      {clientEditOpen && (
        <ClientEditDialog
          client={client}
          onDialogClosed={async (data) => {
            setClientEditOpen(false);

            if (data) {
              await UserData.updateUser(client.uid, {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
              });

              await ClientData.updateClient(client.uid, {
                statistics: {
                  experience: data.experience,
                  height: data.height,
                  sex: data.sex,
                  goal: data.goal,
                  weight: data.weight,
                  startingWeight: data.startingWeight,
                  birthDate: data.birthDate,
                  activityLevel: data.activityLevel,
                  untrackedMeals: data.untrackedMeals,
                },
                language: data.language,
                status: data.status,
                tier: data.tier,
                statisticsChanged: new Date(),
                renewDate: data.renewDate,
                checkInDay: data.checkInDay,
              });
            }
          }}
        />
      )}
      {mealPlanSelectOpen && (
        <MealPlanSelectDialog
          open={mealPlanSelectOpen}
          client={client}
          mealPlans={mealPlans}
          onClosed={async (mealPlanId) => {
            if (!mealPlanId || mealPlanId.length === 0) {
              setMealPlanSelectOpen(false);
              return;
            }
            setMealPlanSelectOpen(false);
            await ClientData.updateClient(client.uid, {
              mealPlanId,
              mealPlanChanged: new Date(),
            });

            // get meal plan by id from mealPlans
            const mealPlan = getMealPlanById(mealPlanId);
            if (mealPlan) {
              await sendMessageToUsersGroup({
                mustBeRegistered: true,
                uid: client.uid,
                message: `Your coach changed your meal plan to ${mealPlan.name}`,
              });
            }
          }}
        />
      )}
      {protocolSelectOpen && (
        <ProtocolSelectDialog
          open={protocolSelectOpen}
          client={client}
          onDialogClosed={async (data) => {
            setProtocolSelectOpen(false);

            if (data && data.protocolId && data.protocolId.length > 0) {
              await ClientData.updateClient(client.uid, {
                protocolId: data.protocolId,
                protocolChanged: new Date(),
              });

              await sendMessageToUsersGroup({
                uid: client.uid,
                mustBeRegistered: true,
                message: `Your coach changed your training plan to ${
                  data.protocol.data().name
                }`,
              });
            }
          }}
        />
      )}
      {clientProtocolSelectOpen && (
        <ClientProtocolDialog
          open={clientProtocolSelectOpen}
          client={client}
          onDialogClosed={() => {
            setClientProtocolSelectOpen(false);
          }}
          onEdit={(protocol) => {
            selectedProtocol.current = protocol;
            setClientProtocolSelectOpen(false);
            setMode("protocol");
          }}
          onMakeActive={async (protocol) => {
            setClientProtocolSelectOpen(false);

            if (protocol && protocol.id && protocol.id.length > 0) {
              await ClientData.updateClient(client.uid, {
                protocolId: protocol.id,
                protocolChanged: new Date(),
              });

              await sendMessageToUsersGroup({
                uid: client.uid,
                mustBeRegistered: true,
                message: `Your coach changed your training plan to ${protocol.name}`,
              });
            }
          }}
        />
      )}
      {coachSelectOpen && (
        <CoachSelectDialog
          open={coachSelectOpen}
          client={client}
          onDialogClosed={(data) => {
            setCoachSelectOpen(false);

            if (data) {
              ClientData.updateClient(client.uid, {
                coaches: data.coaches,
                coachesChanged: new Date(),
              });

              ClientData.updateClientMessageGroup(client.uid, data.coaches);
            }
          }}
        />
      )}
      {bodyMeasurementOpen && (
        <BodyMeasurementsEditDialog
          client={client}
          report={dailyReport}
          onDialogClosed={() => {
            setBodyMeasurementOpen(false);
          }}
        />
      )}
    </Container>
  );
};

export default ClientSummary;
