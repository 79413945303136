import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import Bugsnag from "@bugsnag/js";
import {
  Box,
  Typography,
  TextField,
  Snackbar,
  Container,
  Button,
} from "@mui/material";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import Alert from "@mui/material/Alert";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import RedButton from "../../components/RedButton";
import UserData from "../../data/UserData";
import CoachData from "../../data/CoachData";
import { UserType } from "../../data/OrganizationData";

import organizationIdState from "../../atoms/organizationIdAtom";
import userDataState from "../../atoms/userDataAtom";

import logoIcon from "../../assets/Round-Logo-Design.png";
// import backgroundImage from "../../assets/sign-in-background.jpg";
import logoImage from "../../assets/Logo.png";
import AppStore from "../../assets/ios/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import colors from "../../themes/Colors";
import useEvents from "../../hooks/useEvents";

// import googleButtonImage from "../../assets/google/btn_google_signin_dark_normal_web@2x.png";

// const googleProvider = new firebase.auth.GoogleAuthProvider();

const SignInPage = () => {
  const navigate = useNavigate();
  const setOrganizationIdState = useSetRecoilState(organizationIdState);
  const setUserDataState = useSetRecoilState(userDataState);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const events = useEvents();

  React.useEffect(() => {
    const email = localStorage.getItem("signInEmail");
    if (email) {
      setEmail(email);
    }
  }, []);

  function showError(message) {
    setErrorMessage(message);
    setErrorOpen(true);
  }

  function clearError() {
    setErrorMessage("");
    setErrorOpen(false);
  }

  async function handleSignInCheck(credentials) {
    await firebase.auth().currentUser.getIdTokenResult(true);

    localStorage.setItem("signInEmail", email);

    const userData = await UserData.getUserDataByAuth(credentials.user.uid);
    if (userData && userData.uid) {
      if (userData.type === UserType.client) {
        // No clients allowed.
        showError("This account cannot access the coach console.");
        navigate("/signin");
        return;
      }

      // If they aren't in an org yet, then go to the onboarding screen.
      if (!userData.organizationId) {
        navigate("/onboarding");
        return;
      }

      // If they are, then test the coach status.
      const coach = await CoachData.getCoach(userData.uid);
      if (coach && coach.status === "disabled") {
        showError("This account is disabled. Contact the administrator.");
        navigate("/signin");
        return;
      }

      // set the uid to the users uid, not the auth uid.
      localStorage.setItem("uid", userData.uid);
      localStorage.setItem("credentials.user.uid", credentials.user.uid);
      localStorage.setItem("email", credentials.user.email);
      localStorage.setItem("organizationId", userData.organizationId);

      setUserDataState(userData);
      setOrganizationIdState(userData.organizationId);

      navigate("/home");
    } else {
      showError(
        `There is a problem signing in with that email address. No account exists with that email address`,
      );
    }
  }

  async function handleSignIn() {
    try {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const credentials = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      await handleSignInCheck(credentials);
    } catch (e) {
      Bugsnag.notify(e);
      showError(`There is a problem signing in. Try again. (${e.message})`);
    }
  }

  function validate() {
    if (email.length && password.length > 0) {
      return true;
    }
    return false;
  }

  const handleOnClick = React.useCallback(() => {
    if (email && email.length > 0) {
      events.logButtonClick({
        buttonName: "Password Reset",
        pageName: "Signin",
      });
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          showError("A password reset email was sent to your email address.");
        })
        .catch((e) => {
          Bugsnag.notify(e);
          showError("There was a problem sending the password reset email.");
        });
    }
  }, [email]);

  return (
    <>
      <BrowserView>
        <>
          <Box
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                if (validate()) {
                  await handleSignIn();
                } else {
                  showError("A password is required.");
                }
              }
            }}
            style={{
              // backgroundImage: isTablet ? null : `url(${backgroundImage})`,
              backgroundColor: colors.lightGray,
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              style={{
                opacity: 0.92,
                width: "913px",
                height: "732px",
                backgroundColor: "white",
                borderRadius: 12,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <a href="https://mighty45.com">
                <img
                  alt="Mighty45 Logo"
                  width="146px"
                  height="78px"
                  src={logoImage}
                />
              </a>
              <Typography
                style={{ marginBottom: 25, marginTop: 25 }}
                variant={"h3"}
              >
                Sign In
              </Typography>
              <Box>
                <Typography variant={"h6"}>Email address</Typography>
                <TextField
                  variant="outlined"
                  inputProps={{
                    autoCapitalize: "none",
                  }}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  style={{ width: 475 }}
                />
              </Box>
              <Box style={{ marginTop: 25 }}>
                <Typography variant={"h6"}>Password</Typography>
                <TextField
                  variant="outlined"
                  type="password"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  style={{ width: 475 }}
                />

                <Box
                  style={{
                    marginTop: 16,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <Button
                      style={{
                        textTransform: "none",
                        textDecoration: "underline",
                      }}
                      onClick={handleOnClick}
                    >
                      Forgot password
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box style={{ marginTop: 20, opacity: "100%" }}>
                <RedButton
                  style={{
                    width: 268,
                    height: 60,
                    fontSize: 24,
                  }}
                  variant="outlined"
                  onClick={async () => {
                    if (validate()) {
                      await handleSignIn();
                    } else {
                      showError("A password is required.");
                    }
                  }}
                >
                  Sign In
                </RedButton>
              </Box>
              {/* <Box style={{ marginTop: 6, opacity: "100%" }}>
                <Button
                  style={{
                    width: 268,
                    height: 60,
                  }}
                  variant="text"
                  onClick={() => {
                    handleGoogleSignIn();
                  }}
                >
                  <img
                    src={googleButtonImage}
                    width={268}
                    alt="Sign In Button"
                  />
                </Button>
              </Box> */}
              <Box style={{ marginTop: 24 }}>
                <Typography style={{ textAlign: "right", fontSize: 24 }}>
                  Want to start an Organization?{" "}
                  <Link
                    style={{ textDecoration: "underline" }}
                    to={"/onboarding"}
                    onClick={() => {
                      events.logHyperlink({
                        objectName: "New Organization",
                        hyperlink: "/onboarding",
                      });
                    }}
                  >
                    Get started here.
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Snackbar
            open={errorOpen}
            autoHideDuration={6000}
            onClose={() => {
              clearError();
            }}
          >
            <Alert
              onClose={() => {
                clearError();
              }}
              severity="error"
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </>
      </BrowserView>
      <MobileOnlyView>
        <Container maxWidth="lg">
          <Box style={{ textAlign: "center", marginTop: 34 }}>
            <Box>
              <img width="85%" src={logoIcon} alt="Mighty45 Logo" />
            </Box>
            <Box>
              <Typography variant="h3">Sign in to Mighty45</Typography>
            </Box>
            <Box>
              <Typography variant="h6">
                Install the mobile app to manage your clients or sign in to your
                organization as a client.
              </Typography>
            </Box>
            <Box style={{ marginTop: 20 }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/mighty45/id1594939720"
              >
                <img width={200} src={AppStore} alt="App Store Button" />
              </a>
            </Box>
          </Box>
        </Container>
      </MobileOnlyView>
    </>
  );
};

export default SignInPage;
