import React from "react";

import MessageData from "../data/MessageData";

const useMessagesByGroup = ({ groupId, filter }) => {
  const [messages, setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const firstLoad = React.useRef(true);
  const unsubscribe = React.useRef(null);

  React.useEffect(() => {
    async function getData() {
      if (firstLoad.current) {
        setMessages([]);
        setLoading(true);
      }

      if (unsubscribe.current) {
        unsubscribe.current();
      }

      unsubscribe.current = await MessageData.getMessagesSubscription({
        groupId: groupId,
        limit: 100,
        filter: filter,
        onMessages: (messages) => {
          setLoading(false);
          setMessages(
            messages
              .map((message) => {
                return message.data();
              })
              .reverse(),
          );
          firstLoad.current = false;
        },
      });
    }

    getData();

    return () => {
      if (unsubscribe.current) {
        unsubscribe.current();
      }
    };
  }, [groupId, filter]);

  return { messages, loading };
};

export default useMessagesByGroup;
