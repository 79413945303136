import { selector } from "recoil";
import organizationDataState from "./organizationDataAtom";

const defaultLimit = 1000000;

const fileUploadLimitSelector = selector({
  key: "fileUploadLimitSelector",
  default: defaultLimit,
  get: ({ get }) => {
    const organizationData = get(organizationDataState);
    let limit = defaultLimit;
    const {
      account: { fileUploadLimit },
    } = organizationData;
    if (fileUploadLimit) {
      limit = fileUploadLimit;
    }
    return limit;
  },
});

export default fileUploadLimitSelector;
