/* eslint-disable react/no-children-prop */
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Grid,
} from "@mui/material";
import Bugsnag from "@bugsnag/js";
import { useRecoilValue } from "recoil";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import moment from "moment";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import RecipeDialog from "./RecipeDialog";
import organizationIdSelector from "../../../../atoms/organizationIdSelector";
import fileUploadLimitSelector from "../../../../atoms/fileUploadLimitSelector";
import useRecipes from "../../../../hooks/useRecipes";

import { EditButton } from "../../../../atoms/EditButton";

const RecipeDetails = ({ recipe, uploadImage }) => {
  const { t } = useTranslation();

  const organizationId = useRecoilValue(organizationIdSelector);
  const fileUploadLimit = useRecoilValue(fileUploadLimitSelector);

  const { ingredients, editRecipe, editIngredient } = useRecipes({
    organizationId,
  });

  const [showDialog, setShowDialog] = React.useState(false);
  const [selectedRecipe, setSelectedRecipe] = React.useState(null);

  const handleCustomImageUpload = async (image) => {
    if (image.size > fileUploadLimit) {
      alert(`Image must be less than ${fileUploadLimit / 1000000}MB`);
      return;
    }

    uploadImage({
      image,
      recipe,
      onStart: () => {},
      onProgress: () => {
        //
      },
      onCompleted: () => {},
    });
  };

  const handleImage1Upload = async (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      await handleCustomImageUpload(image);
    }
  };

  const url = `${recipe?.imageUrl}&${moment(
    recipe?.updatedOn.toDate(),
  ).toISOString()}`;

  return (
    <>
      <Box style={{ marginRight: 24, marginBottom: 12 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h4">{recipe.name}</Typography>
          <EditButton
            onClick={() => {
              setSelectedRecipe(recipe);
              setShowDialog(true);
            }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="h6">{t(recipe.mealType)}: </Typography>
          <Typography variant="h6">Serves: {recipe.servings || 0}</Typography>
          <Typography variant="h6">kCal: {recipe.kCals}</Typography>
          <Typography variant="h6">Protein: {recipe.proteins}</Typography>
          <Typography variant="h6">Carbs: {recipe.carbs}</Typography>
          <Typography variant="h6">Fats: {recipe.fats}</Typography>
        </Stack>
        <Grid container>
          <Grid item xs={7}>
            <Box>
              <Stack>
                <Box sx={{ mt: 1, mb: 1 }}>
                  <Typography variant="h5">Description</Typography>
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    children={recipe.description}
                  />
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />

                <Typography variant="h5" sx={{ mb: 1 }}>
                  Ingredients
                </Typography>
                {recipe.ingredients.map((ingredient) => (
                  <Stack key={ingredient.id} direction="row" spacing={2}>
                    <Typography sx={{ width: "25%" }}>
                      {ingredient.amount} {ingredient.unit}
                    </Typography>
                    <Typography>{ingredient.name}</Typography>
                  </Stack>
                ))}
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Typography variant="h5" sx={{ mb: 1 }}>
                  Instructions
                </Typography>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  children={recipe.instructions}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box>
              <ImageList>
                <ImageListItem
                  style={{
                    width: 400,
                    margin: 8,
                    backgroundColor: "white",
                    borderRadius: 8,
                  }}
                >
                  {recipe.imageUrl && <img src={url} alt={recipe.name} />}
                  {!recipe.imageUrl && (
                    <Box
                      style={{
                        height: 400,
                        marginRight: 20,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></Box>
                  )}
                  <ImageListItemBar
                    actionIcon={
                      <Box style={{ height: 25, marginRight: 20 }}>
                        <label>
                          <AddAPhotoIcon
                            color="primary"
                            style={{ color: "white" }}
                          />
                          <input
                            hidden={true}
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleImage1Upload}
                          />
                        </label>
                      </Box>
                    }
                  ></ImageListItemBar>
                </ImageListItem>
              </ImageList>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {showDialog && (
        <RecipeDialog
          open={showDialog}
          recipe={selectedRecipe}
          ingredientsDB={ingredients}
          editIngredient={editIngredient}
          onClose={(recipe, imageURL) => {
            if (recipe) {
              editRecipe(recipe).then(() => {
                if (imageURL && imageURL.size < fileUploadLimit) {
                  uploadImage({ image: imageURL, recipe });
                } else {
                  Bugsnag.notify("Image too large");
                }
              });
            }
            setShowDialog(false);
          }}
        />
      )}
    </>
  );
};

export default RecipeDetails;
