const firebaseConfig = {
  apiKey: "AIzaSyAzNT8--umi_mweya4f8wvjWz20vIHnzlQ",
  authDomain: "mighty45-6b4f8.firebaseapp.com",
  databaseURL: "https://mighty45-6b4f8.firebaseio.com",
  projectId: "mighty45-6b4f8",
  storageBucket: "mighty45-6b4f8.appspot.com",
  messagingSenderId: "739491044548",
  appId: "1:739491044548:web:6bc18291af31a328d17d91",
  measurementId: "G-CQZ7WYL15Y",
};

export default firebaseConfig;
