import React from "react";
import { TableCell, Box, Grid, Typography } from "@mui/material";

import SuperSetExerciseRowPrintable from "./SuperSetExerciseRowPrintable";

const ExerciseNameTableCellPrintable = ({ exercise, exerciseKeys }) => {
  if (exercise.type === "super_set") {
    return (
      <TableCell>
        <Box>
          <Grid container alignItems="center">
            <Grid item>
              <Typography style={{ fontSize: "1.15em", fontWeight: "600" }}>
                {exercise.superSetName ? exercise.superSetName : "Super Set"}
              </Typography>
            </Grid>
          </Grid>
          <SuperSetExerciseRowPrintable
            exercise={exercise}
            exerciseKeys={exerciseKeys}
          />
        </Box>
      </TableCell>
    );
  }
  if (exercise.exercise in exerciseKeys) {
    return (
      <TableCell>
        {exerciseKeys[exercise.exercise]
          ? exerciseKeys[exercise.exercise].name
          : ""}
      </TableCell>
    );
  }
  return <TableCell>-</TableCell>;
};

export default ExerciseNameTableCellPrintable;
