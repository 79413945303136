import React from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  TableCell,
  Box,
  Collapse,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Stack,
} from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import ImageIcon from "@mui/icons-material/Image";

import FieldValue from "./FieldValue";

const SuperSetExerciseRow = ({
  exercise,
  exerciseKeys,
  open,
  onFieldClick = undefined,
}) => {
  const { t } = useTranslation();

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Exercises</TableCell>
              <TableCell>Reps</TableCell>
              {/* <TableCell>Reps Max</TableCell> */}
              <TableCell>{t("exercise_columns.tempo")}</TableCell>
              <TableCell>Rest</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exercise &&
              exercise.exercises &&
              exercise.exercises.map((e, index) => {
                if (e.exercise in exerciseKeys) {
                  return (
                    <TableRow key={e.id}>
                      <TableCell style={{ borderBottom: 0 }}>
                        <Stack>
                          <Typography>
                            {exerciseKeys[e.exercise].name}
                          </Typography>

                          <Stack direction="row">
                            {exerciseKeys[e.exercise].videos &&
                              exerciseKeys[e.exercise].videos.length > 0 && (
                                <VideocamIcon />
                              )}

                            {exerciseKeys[e.exercise].images &&
                              exerciseKeys[e.exercise].images.length > 0 && (
                                <ImageIcon />
                              )}
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell style={{ borderBottom: 0, width: 120 }}>
                        {onFieldClick && (
                          <FieldValue
                            type="normal_set"
                            field="reps"
                            width={120}
                            delay={1000}
                            value={e.reps}
                            isSuperSet={true}
                            id={e.id}
                            onChange={(newValue, field, isSuperSet, id) => {
                              onFieldClick({
                                field: field,
                                fieldValue: newValue,
                                value: exercise,
                                id,
                                index,
                                isSuperSet,
                              });
                            }}
                          />
                        )}
                      </TableCell>

                      <TableCell style={{ borderBottom: 0, width: 100 }}>
                        {onFieldClick && (
                          <FieldValue
                            type="normal_set"
                            field="tempo"
                            width={100}
                            value={e.tempo}
                            isSuperSet={true}
                            id={e.id}
                            validator={(value) => {
                              return value;
                            }}
                            onChange={(newValue, field, isSuperSet, id) => {
                              onFieldClick({
                                field: field,
                                fieldValue: newValue,
                                value: exercise,
                                id,
                                index,
                                isSuperSet,
                              });
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell style={{ borderBottom: 0, width: 65 }}>
                        {onFieldClick && (
                          <FieldValue
                            type="normal_set"
                            field="rest"
                            value={e.rest}
                            isSuperSet={true}
                            id={e.id}
                            validator={(value) => {
                              return value;
                            }}
                            onChange={(newValue, field, isSuperSet, id) => {
                              onFieldClick({
                                field: field,
                                fieldValue: newValue,
                                value: exercise,
                                id,
                                index,
                                isSuperSet,
                              });
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  );
};

export default SuperSetExerciseRow;
