import React from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";

// const macroWidth = 120;

export default React.memo(() => {
  return (
    <Box>
      <Box style={{ paddingTop: 8, paddingLeft: 8 }}>
        <Grid container justifyContent="space-between" alignItems={"center"}>
          <Grid item style={{ width: 200 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: "normal", marginTop: 0 }}
            >
              Name
            </Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: 200 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: "normal", marginTop: 0 }}
            >
              Date Shared
            </Typography>
          </Grid>

          <Grid item textAlign="center" style={{ width: 150 }}>
            <Typography
              style={{ fontSize: 18, fontWeight: "normal", marginTop: 0 }}
            >
              Shared By
            </Typography>
          </Grid>

          <Grid
            item
            style={{
              width: 20,
              marginLeft: 100,
            }}
          >
            <Box paddingLeft={100} justifyContent={"right"} />
          </Grid>
        </Grid>
      </Box>
      <Divider
        style={{
          marginBottom: 8,
          borderRadius: 1,
          color: "black",
        }}
      />
    </Box>
  );
});
