import React from "react";
import Button from "@mui/material/Button";

const BlueButton = ({ children, style, onClick }) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      style={{
        ...style,
        fontWeight: "600",
        borderRadius: 10,
      }}
    >
      {children}
    </Button>
  );
};

export default BlueButton;
