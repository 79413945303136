import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import _ from "underscore";
import WeeklyNote from "./WeeklyNote";
import WeeklyOptional from "./WeeklyOptional";
import { ExpandMore } from "@mui/icons-material";

export default React.memo((props) => {
  const [expanded, setExpanded] = React.useState(false);

  const optionals = React.useMemo(() => {
    const keys = _.keys(props.report.extraData.optionals);

    const finalOptionals = keys.map((key) => {
      return props.report.extraData.optionals[key];
    });
    const sortedOptionals = _.sortBy(finalOptionals, "index");
    return sortedOptionals;
  }, [props.report]);

  const data = _.filter(optionals, (o) => {
    return o.value > 0 || o.value.length > 0;
  });

  let hasData = false;
  if (
    props.report.extraData &&
    props.report.extraData.note &&
    props.report.extraData.note.length > 0
  ) {
    hasData = true;
  }
  if (data.length > 0) {
    hasData = true;
  }

  if (!hasData) {
    return null;
  }

  return (
    <Box style={{ marginTop: 20 }}>
      <Accordion
        display="flex"
        boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
        >
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/ios-filled/50/question-mark.png"
            alt="question-mark"
          />

          <Typography
            style={{
              color: "black",
              fontSize: 24,
              fontWeight: "600",
              marginLeft: 10,
            }}
          >
            Questions and Answers
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <WeeklyNote report={props.report} />

            {optionals.map((optional) => {
              if (optional.value === 0 || optional.value.length === 0) {
                return null;
              }
              return (
                <Box key={optional.id} style={{ marginTop: 12 }}>
                  <WeeklyOptional optional={optional} />
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});
