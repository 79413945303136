var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// import { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import consola from "consola";
var useEvents = function () {
    //     // Initialize Firebase analytics
    //     firebase.analytics();
    //   }, []);
    function logMenuComponent(event) { var logString = "Clicked " + event.objectName + " Menu Button with id " + event.objectId; consola.log(logString); firebase.analytics().logEvent("clicked_menu_component", __assign({}, event)); }
    function logHyperlink(event) { var logString = "Clicked " + event.objectName + " Hyperlink"; consola.log(logString); firebase.analytics().logEvent("clicked_link", __assign({}, event)); }
    function logSignout(event) { var logString = "User signed out with " + event.method; consola.log(logString); firebase.analytics().logEvent("signout", __assign({}, event)); }
    function logButtonClick(event) { var logString = "User clicked " + event.buttonName + " on the " + event.pageName + " page"; consola.log(logString); firebase.analytics().logEvent("button_click", __assign({}, event)); }
    function logTabSwitch(event) { var logString = "User switched to " + event.tabName; consola.log(logString); firebase.analytics().logEvent("tab_switch", __assign({}, event)); }
    function logTextInput(event) { var logString = "User edited " + event.textboxName; consola.log(logString); firebase.analytics().logEvent("text_input", __assign({}, event)); }
    function logSubpageAccess(event) { var logString = "User accessed " + event.pageName; consola.log(logString); firebase.analytics().logEvent("subpage_access", __assign({}, event)); }
    function logMessage(event) { var logString = "User sent a message"; consola.log(logString); firebase.analytics().logEvent("sent_message", __assign({}, event)); }
    function logDayClick(event) { var logString = "User selected " + event.day + " for client " + event.clientId; consola.log(logString); firebase.analytics().logEvent("sent_message", __assign({}, event)); }
    return { logMenuComponent: logMenuComponent, logHyperlink: logHyperlink, logSignout: logSignout, logButtonClick: logButtonClick, logTabSwitch: logTabSwitch, logTextInput: logTextInput, logSubpageAccess: logSubpageAccess, logMessage: logMessage, logDayClick: logDayClick };
};
export default useEvents;
