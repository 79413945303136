import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { nanoid } from "nanoid";
import BlueButton from "../../../../components/BlueButton";

import GlobalData from "../../../../data/GlobalData";

const ExerciseDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const [exerciseId, setExerciseId] = React.useState(props.id);
  const [idEdit, setIdEdit] = React.useState(true);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [instructions, setInstructions] = React.useState("");
  const [difficulty, setDifficulty] = React.useState("beginner");
  const [icon, setIcon] = React.useState("");
  const [mainMuscleGroup, setMainMuscleGroup] = React.useState("");
  const [isCustom, setIsCustom] = React.useState(false);
  const [secondaryMuscleGroup, setSeceondaryMuscleGroup] = React.useState("");
  const [exerciseEquipment, setExerciseEquipment] = React.useState("");

  const [muscleGroups, setMuscleGroups] = React.useState([]);
  const [equipment, setEquipment] = React.useState([]);

  React.useEffect(() => {
    setOpen(props.open);

    if (!props.exercise) {
      setExerciseId(nanoid());
      setIdEdit(false);
      setName("");
      setDescription("");
      setInstructions("");
      setDifficulty("beginner");
      setIcon("all");
      setMainMuscleGroup("all");
      setSeceondaryMuscleGroup("all");
      setExerciseEquipment("");
      setIsCustom(false);
    } else {
      setExerciseId(props.exercise.id);
      setIdEdit(false);
      setName(props.exercise.name);
      setDescription(props.exercise.description);
      setInstructions(props.exercise.instructions);
      setDifficulty(props.exercise.difficulty);
      setIcon(props.exercise.icon);
      setMainMuscleGroup(props.exercise.mainMuscleGroups[0]);
      setSeceondaryMuscleGroup(props.exercise.secondaryMuscleGroups[0]);
      setExerciseEquipment(
        props.exercise.equipment && props.exercise.equipment.length > 0
          ? props.exercise.equipment[0]
          : "",
      );
      setIsCustom(props.exercise.isCustom);
    }
  }, [props.open, props.exercise, muscleGroups, equipment]); // eslint-disable-line

  React.useMemo(() => {
    async function asyncLoad() {
      const muscleGroups = await GlobalData.getMuscleGroups();
      const equipment = await GlobalData.getEquipment();

      setMuscleGroups(muscleGroups);
      setEquipment(equipment);
    }
    asyncLoad();
  }, [props.exercise]); // eslint-disable-line

  const isEdit = React.useMemo(() => {
    if (props.exercise) {
      return true;
    }
    return false;
  }, [props.exercise]);

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
          setOpen(false);
        }
      }}
    >
      <DialogTitle>{isEdit ? `Edit Exercise` : `Create Exercise`}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Stack>
            <Typography>Unique ID:</Typography>
            <TextField
              disabled={!idEdit}
              variant="outlined"
              style={{ width: "100%" }}
              value={exerciseId}
              onChange={(event) => {
                setExerciseId(event.target.value);
              }}
            />
          </Stack>
          <Stack>
            <Typography style={{ width: 200 }}>Exercise name</Typography>
            <TextField
              variant="outlined"
              style={{ width: "100%" }}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </Stack>
          <Stack>
            <Typography style={{ width: 200 }}>Add Description</Typography>
            <div data-color-mode="light">
              <MDEditor
                preview="edit"
                height={150}
                style={{ width: "100%", minWidth: 800 }}
                placeholder="Enter healthy recipe description or video link here"
                value={description}
                onChange={(value) => {
                  setDescription(value);
                }}
              />
            </div>
          </Stack>
          <Stack>
            <Typography style={{ width: 200 }}>Add Instructions</Typography>
            <div data-color-mode="light">
              <MDEditor
                preview="edit"
                height={150}
                style={{ width: "100%", minWidth: 800 }}
                placeholder="Enter healthy recipe description or video link here"
                value={instructions}
                onChange={(value) => {
                  setInstructions(value);
                }}
              />
            </div>
          </Stack>
          <Stack>
            <Typography style={{ width: 200 }}>Difficulty</Typography>
            <Select
              size="small"
              defaultValue={difficulty}
              value={difficulty}
              style={{ width: "100%" }}
              variant="outlined"
              onChange={(event) => {
                setDifficulty(event.target.value);
              }}
            >
              <MenuItem value="beginner">Beginner</MenuItem>
              <MenuItem value="intermediate">Intermediate</MenuItem>
              <MenuItem value="advanced">Advanced</MenuItem>
              <MenuItem value="expert">Expert</MenuItem>
            </Select>
          </Stack>
          <Stack>
            <Typography style={{ width: 200 }}>Icon</Typography>
            <Select
              size="small"
              value={muscleGroups.length > 0 ? icon : ""}
              style={{ width: "100%" }}
              variant="outlined"
              onChange={(event) => {
                setIcon(event.target.value);
              }}
            >
              {muscleGroups.map((mg) => {
                return (
                  <MenuItem key={mg.data().id} value={mg.data().id}>
                    {mg.data().name}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Stack>
            <Typography style={{ width: 200 }}>Main Muscle Group</Typography>
            <Select
              size="small"
              value={muscleGroups.length > 0 ? mainMuscleGroup : ""}
              style={{ width: "100%" }}
              variant="outlined"
              onChange={(event) => {
                setMainMuscleGroup(event.target.value);
              }}
            >
              {muscleGroups.map((mg) => {
                return (
                  <MenuItem key={mg.data().id} value={mg.data().id}>
                    {mg.data().name}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Stack>
            <Typography style={{ width: 200 }}>
              Secondary Muscle Group
            </Typography>
            <Select
              size="small"
              value={muscleGroups.length > 0 ? secondaryMuscleGroup : ""}
              style={{ width: "100%" }}
              variant="outlined"
              onChange={(event) => {
                setSeceondaryMuscleGroup(event.target.value);
              }}
            >
              {muscleGroups.map((mg) => {
                return (
                  <MenuItem key={mg.data().id} value={mg.data().id}>
                    {mg.data().name}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Stack>
            <Typography>Equipment</Typography>
            <Select
              size="small"
              defaultValue={exerciseEquipment}
              value={exerciseEquipment}
              style={{ width: "100%" }}
              variant="outlined"
              onChange={(event) => {
                setExerciseEquipment(event.target.value);
              }}
            >
              <MenuItem key={null} value={null}>
                None
              </MenuItem>
              <MenuItem key={"all"} value={"all"}>
                All
              </MenuItem>
              {equipment.map((mg) => {
                return (
                  <MenuItem key={mg.data().id} value={mg.data().id}>
                    {mg.data().name}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (props.onDialogClosed) {
              props.onDialogClosed(null);
            }
            setOpen(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (props.onDialogClosed) {
              props.onDialogClosed({
                id: exerciseId,
                name: name,
                description: description,
                instructions: instructions,
                difficulty: difficulty,
                icon: icon,
                isCustom: isCustom,
                mainMuscleGroups: [mainMuscleGroup],
                secondaryMuscleGroups: [secondaryMuscleGroup],
                equipment:
                  exerciseEquipment && exerciseEquipment.length > 0
                    ? [exerciseEquipment]
                    : [],
              });
            }
            setOpen(false);
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExerciseDialog;
