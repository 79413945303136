import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
// import ClientMessages from "../clients/ClientMessages";
// import SendMessage from "../../../../components/SendMessage";
import { ExpandMore } from "@mui/icons-material";
import ChatComponent from "../../../../components/organisms/ChatComponent";

export default React.memo((props) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Box style={{ marginTop: 20, marginBottom: 50 }}>
      <Accordion
        display="flex"
        boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
          >
            <path
              d="M17.0603 1H4.81085C3.68798 1 2.76927 1.9 2.76927 3V17H4.81085V3H17.0603V1ZM16.0395 5L22.1643 11V21C22.1643 22.1 21.2456 23 20.1227 23H8.8838C7.76093 23 6.85243 22.1 6.85243 21L6.86264 7C6.86264 5.9 7.77114 5 8.89401 5H16.0395ZM15.0187 12H20.6331L15.0187 6.5V12Z"
              fill="black"
            />
          </svg>
          <Typography
            style={{
              color: "black",
              fontSize: 24,
              fontWeight: "600",
              marginLeft: 10,
            }}
          >
            Messages
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <ChatComponent
              uid={localStorage.getItem("uid")}
              groupId={props.group ? props.group.id : null}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});
