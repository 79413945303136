import React from "react";

import {
  getFirestore,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";

/**
 * Gets the message group for this uid and organization
 * @param {*} { uid, organizationId }
 * @returns
 */
const useClientMessageGroupId = ({ uid, organizationId }) => {
  const [groupId, setGroupId] = React.useState(null);

  React.useEffect(() => {
    if (uid && organizationId) {
      const db = getFirestore();
      const groupCollection = collection(
        db,
        "organizations",
        organizationId,
        "messageGroups",
      );

      const myQuery = query(
        groupCollection,
        where("userId", "==", uid),
        where("type", "==", "single"),
      );

      getDocs(myQuery).then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          const group = querySnapshot.docs[0].data();
          setGroupId(group.id);
        }
      });
    }
  }, [uid, organizationId]);

  return groupId;
};

export default useClientMessageGroupId;
