import React from "react";
import "firebase/compat/auth";
import {
  Box,
  Typography,
  TextField,
  Snackbar,
  Link,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Bugsnag from "@bugsnag/js";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

// import backgroundImage from "../../assets/sign-in-background.jpg";
import RedButton from "../../components/RedButton";
import OrganizationData, {
  checkConnectCode,
  getConnectCodeUserCount,
} from "../../data/OrganizationData";
import { registerNewUser } from "../../data/UserData";
import colors from "../../themes/Colors";
// import CircleNumberText from "../../components/molecules/CircleNumberText";
import logoImage from "../../assets/Logo.png";
import loginImage from "../../assets/onboarding/noun-login-2347597-CD202A.png";
import padLockImage from "../../assets/onboarding/noun-padlock-of-number-code-1905110-CD202A.png";
import useEvents from "../../hooks/useEvents";

const styles = {
  pageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 24,
    backgroundColor: colors.lightGray,
  },
  dialogContainer: {
    opacity: 0.92,
    width: "913px",
    height: "auto",
    backgroundColor: "white",
    borderRadius: 12,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: 50,
  },
};

const ConnectCodePage = () => {
  const navigate = useNavigate();
  const [connectCode, setConnectCode] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const validate = async () => {
    if (email.trim().length <= 0) {
      return "Enter your email address to continue.";
    }

    if (password.trim().length <= 0) {
      return "You need a password to continue.";
    }

    if (connectCode.trim().length <= 0) {
      return "Enter the connect code provided by the organization owner to continue.";
    }

    // Check the validity of the connect code.
    const response = await getConnectCodeUserCount({
      connectCode: connectCode.trim(),
    });
    if (response.count <= 0 || response.organizationIds.length === 0) {
      return "That connect code could not be found. Contact the organization owner.";
    }
    if (response.count > 1 && response.organizationIds.length > 1) {
      return "That connect code is invalid. Contact the organization owner and have them create a new one.";
    }

    return null;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  async function handleOnboarding() {
    const validation = await validate();
    if (validation) {
      showError(validation);
      return;
    }

    try {
      const isValidConnectCode = await checkConnectCode({
        connectCode: connectCode.trim(),
      });

      if (!isValidConnectCode) {
        showError(
          "That connect code is invalid. Please check with the organizations owner for a new connect code.",
        );
        return;
      }

      const credentials = await registerNewUser(email, password);
      const uid = credentials.user.uid;

      // check connect code and get organization.
      const result = await OrganizationData.addToOrganization(uid, connectCode);
      if (result && result.uid) {
        localStorage.setItem("uid", result.uid);
        navigate("/home");
      } else {
        // Show some kind of error.
        showError(
          "That connect code is invalid. Please check with the organizations owner for a new connect code.",
        );
        return;
      }
    } catch (e) {
      showError(e.message);
      Bugsnag.notify(e);
    }
  }

  function showError(message) {
    setErrorMessage(message);
    setErrorOpen(true);
  }

  function clearError() {
    setErrorMessage("");
    setErrorOpen(false);
  }

  return (
    <>
      <Box style={styles.pageContainer}>
        <Box style={styles.dialogContainer}>
          <a href="https://mighty45.com">
            <img
              alt="Mighty45 Logo"
              style={{}}
              width="146px"
              height="78px"
              src={logoImage}
            />
          </a>
          <Typography
            style={{ marginBottom: 12, marginTop: 12 }}
            variant={"h3"}
          >
            Register with the Organization
          </Typography>
          <Typography
            style={{ marginBottom: 25, marginTop: 0, textAlign: "center" }}
            variant={"h6"}
          >
            The organization owner provided you with a connect code. Create a
            new account and enter the provided connect code below.
          </Typography>

          <Box style={{ marginTop: 12 }}>
            <img
              alt="Mighty45 Logo"
              style={{}}
              width="100px"
              height="100px"
              src={loginImage}
            />
          </Box>
          <Typography style={{ marginBottom: 0, marginTop: 20 }} variant={"h3"}>
            Create your Account
          </Typography>
          {/* <Typography style={{ marginBottom: 0, marginTop: 20 }} variant={"h3"}>
            Provide account information.
          </Typography> */}

          <Box style={{ marginTop: 30 }}>
            <Typography variant={"h6"}>Email address</Typography>
            <TextField
              variant="outlined"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              style={{ width: 600 }}
            />
          </Box>
          <Box style={{ marginTop: 30, marginBottom: 25 }}>
            <Typography variant={"h6"}>Password</Typography>
            <TextField
              variant="outlined"
              type={showPassword ? "text" : "password"}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ width: 600 }}
            />
          </Box>
          <Box style={{ marginTop: 24 }}>
            <img
              alt="Mighty45 Logo"
              style={{}}
              width="100px"
              height="100px"
              src={padLockImage}
            />
          </Box>
          <Typography
            style={{ marginBottom: 30, marginTop: 12 }}
            variant={"h3"}
          >
            Enter the Connect Code
          </Typography>
          <Box>
            <Typography variant={"h6"} style={{ marginBottom: 6 }}>
              Enter the connect code provided by the organization owner.
            </Typography>
            <TextField
              variant="outlined"
              value={connectCode}
              placeholder="example: 6ved9fl9"
              onChange={(event) => {
                setConnectCode(event.target.value);
              }}
              inputProps={{
                style: { textAlign: "center", fontSize: 25, fontWeight: "500" },
              }}
              style={{ width: 600 }}
            />
          </Box>

          <Box style={{ marginTop: 50, opacity: "100%", marginBottom: 20 }}>
            <RedButton
              style={{
                width: 268,
                height: 60,
              }}
              variant="outlined"
              onClick={() => {
                handleOnboarding();
                useEvents().logButtonClick({
                  buttonName: "Join Organization",
                  pageName: "Connect Code",
                });
              }}
            >
              Continue
            </RedButton>
          </Box>

          <Typography
            style={{ marginBottom: 50, marginTop: 24, textAlign: "center" }}
            variant={"h6"}
          >
            Don&lsquo;t have a connect code? Contact the organization owner to
            get one.
          </Typography>

          <Typography
            style={{ marginBottom: 50, marginTop: 24, textAlign: "center" }}
            variant={"h6"}
          >
            Already have an account?{" "}
            <Link style={{ textDecoration: "underline" }} href="/signin">
              Sign in.
            </Link>
          </Typography>
        </Box>
      </Box>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => {
          clearError();
        }}
      >
        <Alert
          onClose={() => {
            clearError();
          }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ConnectCodePage;
