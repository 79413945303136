import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

import BlueButton from "../../../../components/BlueButton";

function AddVideoDialog(props) {
  const [open, setOpen] = React.useState(false);

  const [videoURL, setVideoURL] = React.useState("");

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open, props.exercise]);

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
          setOpen(false);
        }
      }}
    >
      <DialogTitle>Add Video Link</DialogTitle>
      <DialogContent>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ width: 200 }}>Video URL:</Typography>
          <TextField
            variant="outlined"
            style={{ width: 512 }}
            value={videoURL}
            onChange={(event) => {
              setVideoURL(event.target.value);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (props.onDialogClosed) {
              props.onDialogClosed(null);
            }
            setOpen(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (props.onDialogClosed) {
              props.onDialogClosed(videoURL);
            }
            setOpen(false);
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddVideoDialog;
