import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Chip,
} from "@mui/material";
import { SliderPicker } from "react-color";
import BlueButton from "../../../../components/BlueButton";

const LabelEditDialog = ({ label, open = false, onDialogClosed = null }) => {
  const [dialogOpen, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [name, setName] = React.useState("");
  const [color, setColor] = React.useState("");
  const [backgroundColor, setBackgroundColor] = React.useState("");

  const nameLength = 100;

  React.useEffect(() => {
    setOpen(open);
  }, [open]); // eslint-disable-line

  React.useEffect(() => {
    if (label) {
      setName(label.name);
      setColor(label.color);
      setBackgroundColor(label.backgroundColor);
    } else {
      setName("");
      setColor("#000000");
      setBackgroundColor("#C40F0D");
    }
  }, [label]);

  function validate() {
    if (!name) {
      return ["A label name is required."];
    }
    if (!color) {
      return ["A label foreground color is required."];
    }
    if (!backgroundColor) {
      return ["A label background color is required."];
    }
    return null;
  }

  if (!dialogOpen) {
    return null;
  }

  return (
    <Dialog
      key="LabelEditDialog"
      maxWidth="lg"
      open={dialogOpen}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>{!label ? "Add Label" : "Edit Label"}</DialogTitle>

      <DialogContent>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Typography style={{ width: 200 }}>Label:</Typography>
          <TextField
            value={name}
            style={{ width: 512 }}
            variant="outlined"
            onChange={(event) => {
              if (event.target.value.length > nameLength) {
                return false;
              }
              setName(event.target.value);
            }}
          ></TextField>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Grid container spacing={3}>
            <Grid item>
              <Typography style={{ width: 200 }}>Foreground Color:</Typography>
              <TextField
                value={color}
                style={{ width: 512 }}
                variant="outlined"
                onChange={(event) => {
                  setColor(event.target.value);
                }}
              />
              <Box style={{ marginTop: 12 }}>
                <SliderPicker
                  color={color}
                  onChangeComplete={(color) => {
                    setColor(color.hex);
                  }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  marginTop: 12,
                  flexDirection: "row",
                }}
              >
                <Box
                  onClick={() => {
                    setColor("#FFFFFF");
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    width: 101.1,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "black",
                    marginRight: 2,
                  }}
                />
                <Box
                  onClick={() => {
                    setColor("#000000");
                  }}
                  style={{
                    backgroundColor: "#000000",
                    width: 101.1,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "black",
                  }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Typography style={{ width: 200 }}>Background Color:</Typography>
              <TextField
                value={backgroundColor}
                style={{ width: 512 }}
                variant="outlined"
                onChange={(event) => {
                  setBackgroundColor(event.target.value);
                }}
              />
              <Box style={{ marginTop: 12 }}>
                <SliderPicker
                  color={backgroundColor}
                  onChangeComplete={(color) => {
                    setBackgroundColor(color.hex);
                  }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  marginTop: 12,
                  flexDirection: "row",
                }}
              >
                <Box
                  onClick={() => {
                    setBackgroundColor("#FFFFFF");
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    width: 101.1,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "black",
                    marginRight: 2,
                  }}
                />
                <Box
                  onClick={() => {
                    setBackgroundColor("#000000");
                  }}
                  style={{
                    backgroundColor: "#000000",
                    width: 101.1,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "black",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Chip
            label={name ? name : "Sample"}
            variant="outlined"
            style={{
              color: color,
              borderWidth: 2,
              borderColor: backgroundColor,
              marginRight: 6,
            }}
          />
        </Box>
      </DialogContent>

      {error && (
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ width: 200, color: "red" }}>
              {error}
            </Typography>
          </Box>
        </DialogContent>
      )}

      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            if (onDialogClosed) {
              onDialogClosed(null, null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            const validateResult = validate();
            if (!validateResult) {
              setOpen(false);
              if (onDialogClosed) {
                onDialogClosed(
                  {
                    id: label ? label.id : null,
                    name,
                    color,
                    backgroundColor,
                  },
                  label,
                );
              }
            } else {
              setError(validateResult[0]);
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default LabelEditDialog;
