import React from "react";
import { Typography } from "@mui/material";
import colors from "../../themes/Colors";

export default React.memo(
  ({ title, tier, count, showAlertCount, colorAlertCount = true }) => {
    const tierString = React.useMemo(() => {
      if (tier === "tier1") {
        return "Tier 1";
      }
      if (tier === "tier2") {
        return "Tier 2";
      }
      if (tier === "tier3") {
        return "Tier 3";
      }
      return "";
    }, [tier]);
    if (showAlertCount && count > 0) {
      return (
        <Typography
          style={{ color: colorAlertCount ? colors.mainTitleColor : "black" }}
          variant="h5"
        >{`${title} ${tierString} (${count})`}</Typography>
      );
    }
    return <Typography variant="h5">{`${title} ${tierString}`}</Typography>;
  },
);
