import React from "react";
import { Box, Typography } from "@mui/material";

export default React.memo((props) => {
  if (
    props.report.extraData &&
    props.report.extraData.note &&
    props.report.extraData.note.length > 0
  ) {
    return (
      <Box style={{ marginTop: 0, marginInline: 10 }}>
        <Typography style={{ color: "black", fontSize: 24, fontWeight: "500" }}>
          Note or comment to coach
        </Typography>
        <Typography>{props.report.extraData.note}</Typography>
      </Box>
    );
  }
  return null;
});
