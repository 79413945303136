const colors = {
  mainTitleColor: "#C40F0D",
  buttonTextColor: "#FFFFFF",
  secondaryBackground: "#F6F7FB",
  buttonBlueColor: "#0077C8",
  primaryOrange: "#E28413",
  primaryBlue: "#0077C8",
  primaryRed: "#C40F0D",
  primaryGreen: "#BFC146",
  secondaryRed: "#CE3A39",
  lightGray: "#8A91AE",
  darkGray: "#646472",
  backgroundRed: "#c121213d",
  backgroundBlue: "#0077c83d",
  parentBoxGray: "#F5F5F5",
  calendarGreen: "#008000",
  calendarBlue: "#43B3FC",
  calendarYellow: "#FFEC42",
};

export const primaryRedBase = {
  50: "#ffe3e3",
  100: "#ffb6b5",
  200: "#f98886",
  300: "#f55857",
  400: "#f12a28",
  500: "#d7100e",
  600: "#a80a0a",
  700: "#790506",
  800: "#4b0102",
  900: "#200000",
};

export const primaryRedHueUp = {
  50: "#ffe8e3",
  100: "#ffc0b5",
  200: "#f99386",
  300: "#f56357",
  400: "#f13128",
  500: "#d7100e",
  600: "#a8150a",
  700: "#791305",
  800: "#4b0e01",
  900: "#200800",
};

export const primaryRedHueDown = {
  50: "#ffe3e8",
  100: "#ffb5be",
  200: "#f98690",
  300: "#f55761",
  400: "#f1282d",
  500: "#d7100e",
  600: "#a80a15",
  700: "#790516",
  800: "#4b0111",
  900: "#200008",
};

export const primaryBlueBase = {
  50: "#e6efff",
  100: "#c1cef3",
  200: "#9aade6",
  300: "#728cd9",
  400: "#4b6bcd",
  500: "#3252b4",
  600: "#26408d",
  700: "#1a2d66",
  800: "#0d1b40",
  900: "#02091b",
};

export const primaryBlueHueUp = {
  50: "#e6ecff",
  100: "#c1c9f3",
  200: "#9aa8e6",
  300: "#7289d9",
  400: "#4b6bcd",
  500: "#3249b4",
  600: "#26328d",
  700: "#1a1e66",
  800: "#0d0d40",
  900: "#05021b",
};

export const primaryBlueHueDown = {
  50: "#e6f2ff",
  100: "#c1d3f3",
  200: "#9ab2e6",
  300: "#728fd9",
  400: "#4b6bcd",
  500: "#325bb4",
  600: "#264e8d",
  700: "#1a3c66",
  800: "#0d2840",
  900: "#02111b",
};

export function getColor(username) {
  let sumChars = 0;
  for (let i = 0; i < username.length; i++) {
    sumChars += username.charCodeAt(i);
  }

  const colors = [
    "#4667CC",
    "#83206F",
    "#E05118",
    "#DD920F",
    "#008C45",
    "#A2A13F",
    "#008AAE",
    "#008984",
    "#5071B4",
    "#7B2680",
  ];
  return colors[sumChars % colors.length];
}

export default colors;
