import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { consola } from "consola";

import ClientData from "../data/ClientData";
import OnboardingEmails from "../locales/emails/onboarding-client";

const useEmail = ({ uid }) => {
  async function getBaseData(uid) {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> useEmail: userData")
    const userData = await ref.get();
    if (userData.exists) {
      const userDataValues = userData.data();
      const organizationId = userDataValues.organizationId;

      consola.info("++++++ READ -> useEmail: orgData")
      const orgRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId);
      const orgData = await orgRef.get();

      if (!orgData.exists) {
        return null;
      }

      const clientRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients")
        .doc(uid);

        consola.info("++++++ READ -> useEmail: clientData")
        const clientData = await clientRef.get();

      if (clientData.exists) {
        const clientDataValues = clientData.data();
        const orgDataValues = orgData.data();

        return {
          userDataValues,
          orgDataValues,
          clientDataValues,
          clientRef,
          orgRef,
          userRef: ref,
        };
      }
    }
    return null;
  }

  const sendConnectCodeEmailTemplate = async () => {
    const baseData = await getBaseData(uid);
    if (baseData === null) {
      return false;
    }

    const { userDataValues, orgDataValues, clientDataValues, clientRef } =
      baseData;

    const mailRef = firebase
      .firestore()
      .collection("organizations")
      .doc(userDataValues.organizationId)
      .collection("mails")
      .doc();

    if (
      userDataValues.connectCode === null ||
      userDataValues.connectCode === ""
    ) {
      return false;
    }

    if (
      !userDataValues.email ||
      userDataValues.email === null ||
      userDataValues.email === ""
    ) {
      return false;
    }

    const result = await ClientData.getDynamicLink({
      connectCode: userDataValues.connectCode,
      organization: orgDataValues,
      type: "client",
    });

    let language = "en";

    if (clientDataValues.language && clientDataValues.language.length > 0) {
      language = clientDataValues.language;
    }
    const templateId = OnboardingEmails[language].templateId;
    consola.info("+++++ WRITE => useEmail: sendConnectCodeEmailTemplate")
    await mailRef.set({
      id: mailRef.id,
      uid: uid,
      fromName: orgDataValues.name,
      toName: `${userDataValues.firstName} ${userDataValues.lastName}`,
      to: userDataValues.email,
      templateId: templateId,
      language: language,
      shortLink: result.shortLink,
      connectCode: userDataValues.connectCode,
      createdOn: new Date(),
    });

    await clientRef.set(
      {
        connectCodeEmailSentOn: new Date(),
      },
      { merge: true },
    );

    return true;
  };

  return { sendConnectCodeEmailTemplate };
};

export default useEmail;
