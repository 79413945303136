import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import PhotoSummary from "../clients/PhotoSummary";
import { ExpandMore } from "@mui/icons-material";

function ProgressPhotos({
  report,
  dateRange,
  showStarting,
  title,
  photoGroupTitle,
  noPhotosMessage,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const client = React.useMemo(() => {
    return {
      uid: report.clientData.uid,
      initialUrl: report.clientData.initialUrl,
    };
  }, [report.clientData]);

  return (
    <Box style={{ marginTop: 20 }}>
      <Accordion
        display="flex"
        boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M11.2 0L8.272 3.55556H3.2C1.44 3.55556 0 5.15556 0 7.11111V28.4444C0 30.4 1.44 32 3.2 32H28.8C30.56 32 32 30.4 32 28.4444V7.11111C32 5.15556 30.56 3.55556 28.8 3.55556H23.728L20.8 0H11.2ZM16 26.6667C11.584 26.6667 8 22.6844 8 17.7778C8 12.8711 11.584 8.88889 16 8.88889C20.416 8.88889 24 12.8711 24 17.7778C24 22.6844 20.416 26.6667 16 26.6667Z"
              fill="black"
            />
          </svg>
          <Typography
            style={{
              color: "black",
              fontSize: 24,
              fontWeight: "600",
              marginLeft: 10,
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box style={{ marginLeft: 40 }}>
            <PhotoSummary
              showStarting={showStarting}
              showPrevious={true}
              limit={10}
              dateRange={dateRange}
              photoGroupTitle={photoGroupTitle}
              noPhotosMessage={noPhotosMessage}
              client={client}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default ProgressPhotos;
