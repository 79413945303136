import React from "react";
import { Box, Typography, Stack, Divider } from "@mui/material";
import moment from "moment";

import { ago } from "../../../../data/GlobalData";

const ProtocolSummary = ({ report }) => {
  if (!report) {
    return null;
  }

  return (
    <Box sx={{ mt: 1 }}>
      <Stack>
        <Typography style={{ color: "black", fontSize: 24, fontWeight: "600" }}>
          Training Plan
        </Typography>
        <Typography>
          {report.protocol
            ? report.protocol.name
            : "No training plan is assigned"}
        </Typography>
        {report.protocol && report.protocol.protocolChanged && (
          <Typography>
            Last Changed:{" "}
            {moment(report.protocol.protocolChanged.toDate()).format(
              "dddd, MMMM Do YYYY",
            )}{" "}
            ({ago(report.protocol.protocolChanged.toDate())})
          </Typography>
        )}
      </Stack>
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
};

export default ProtocolSummary;
