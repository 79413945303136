import React from "react";
import { Box, Container, Typography, Tabs, Tab, Divider } from "@mui/material";
import EditFiles from "./library/EditFiles";

const LibraryPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mode, setMode] = React.useState("list");

  const tabIndexValue = localStorage.getItem("libraryPage_TabIndex");
  const [tab, setTab] = React.useState(tabIndexValue || "files");

  if (mode === "list") {
    return (
      <>
        <Container
          maxWidth={false}
          sx={{
            marginTop: 1,
            display: "flex",
            height: "95%",
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 1, mb: 1 }}>
            <Box>
              <Typography variant="h5">Library</Typography>
              <Divider style={{ marginTop: 20 }}></Divider>
            </Box>
            <Tabs
              value={tab}
              onChange={(event, newValue) => {
                localStorage.setItem("libraryPage_TabIndex", newValue);
                setTab(newValue);
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Files" value="files" />
              {/* <Tab label="Images" value="images" />
              <Tab label="Videos" value="videos" />
              <Tab label="Posts" value="posts" /> */}
            </Tabs>
          </Box>

          {tab === "files" && (
            <Box>
              <EditFiles />
            </Box>
          )}
        </Container>
      </>
    );
  } else if (mode === "details") {
    return (
      <Container
        maxWidth={false}
        sx={{
          marginTop: 1,
          display: "flex",
          height: "95%",
          flexDirection: "column",
        }}
      >
        <Box />
      </Container>
    );
  }
};

export default LibraryPage;
