import React from "react";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Checkbox,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import _ from "lodash";

// const inactiveStyle = {
//   padding: 8,
// };

const width = 120;

const SupplementPrescriptionRow = ({
  prescription,
  supplement,
  onEdit,
  onDelete,
  onUpdate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const reducedInstructions = React.useMemo(() => {
    if (prescription.instructions.length > 80) {
      return prescription.instructions.substring(0, 80) + "...";
    } else {
      return prescription.instructions;
    }
  }, [prescription.instructions]);

  const menuItems = [
    {
      type: "menu",
      menuName: "Edit Supplement",
      menuFunction: () => {
        if (onEdit) {
          onEdit(prescription);
        }
      },
    },
    {
      type: "menu",
      menuName: "Delete Supplement",
      menuFunction: () => {
        if (onDelete) {
          onDelete(prescription);
        }
      },
    },
  ];

  return (
    // grayed out box color: #E6E6E640
    <Box mt={2}>
      <Box
        borderRadius={4}
        style={{
          paddingInline: 8,
          backgroundColor: "#F6F7FB",
        }}
      >
        <Grid container justifyContent="space-between" alignItems={"center"}>
          <Grid item style={{ width: 200 }}>
            <Typography>
              <a
                style={{ color: prescription.isActive ? "#0077C8" : "gray" }}
                target="_blank"
                href={
                  supplement && supplement.salesLink
                    ? supplement.salesLink
                    : undefined
                }
                rel="noreferrer"
              >
                {supplement ? supplement.name : ""}
              </a>
            </Typography>
          </Grid>
          <Grid item textAlign="center" style={{ width: width }}>
            <Typography color={prescription.isActive ? "black" : "gray"}>
              {prescription.dosage}
            </Typography>
          </Grid>
          <Grid item textAlign="center" style={{ width: width }}>
            <Typography color={prescription.isActive ? "black" : "gray"}>
              {prescription.frequency}
            </Typography>
          </Grid>
          <Grid item textAlign="center" style={{ width: width }}>
            <Typography color={prescription.isActive ? "black" : "gray"}>
              {reducedInstructions}
            </Typography>
          </Grid>
          <Grid item textAlign="center" style={{ width: width }}>
            <Checkbox
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "black",
                },
              }}
              checked={prescription.isActive}
              onChange={() => {
                onUpdate(prescription);
              }}
              name="isActive"
            />
          </Grid>
          <Grid
            item
            style={{
              width: 20,
              marginLeft: 100,
            }}
          >
            <MoreVertIcon onClick={handleClick} />
          </Grid>
        </Grid>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuItems &&
            menuItems.map((menuItem, index) => {
              if (menuItem.type === "menu") {
                return (
                  <MenuItem
                    key={menuItem.menuName}
                    onClick={() => {
                      setAnchorEl(null);
                      if (menuItem.menuFunction) {
                        menuItem.menuFunction(prescription);
                      }
                    }}
                  >
                    {`${menuItem.menuName}`}
                  </MenuItem>
                );
              } else {
                return <Divider key={index} />;
              }
            })}
        </Menu>
      </Box>
    </Box>
  );
};

export default SupplementPrescriptionRow;
