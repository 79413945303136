import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";

import LabelSelect from "../molecules/LabelSelect";

const ProtocolFilters = ({
  currentLabels,
  onFilterChange,
  onFilterCleared,
}) => {
  const setSelectedLabelsClosedCallback = React.useCallback(
    (changes) => {
      if (onFilterChange) {
        onFilterChange(changes);
      }
    },
    [onFilterChange],
  );

  const onFilterClearedCallback = React.useCallback(() => {
    if (onFilterCleared) {
      onFilterCleared();
    }
  }, [onFilterCleared]);

  if (!currentLabels) {
    return null;
  }

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ marginRight: 12 }}>Labels</Typography>
          <LabelSelect
            id="ProtocolFilters"
            useTimer
            currentLabels={currentLabels}
            onLabelsChanged={setSelectedLabelsClosedCallback}
            width={400}
          />
        </Box>
      </Grid>
      <Grid item>
        <Button variant="text" onClick={onFilterClearedCallback}>
          Clear Filters
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProtocolFilters;
