import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Box, Typography, IconButton } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import CoachData from "../../../../data/CoachData";
import ClientData from "../../../../data/ClientData";
import OrganizationData, { UserType } from "../../../../data/OrganizationData";
import useEvents from "../../../../hooks/useEvents";

import RedBorderButton from "../../../../components/RedBorderButton";

const CoachConnectCode = ({ coach, style }) => {
  const [connectCodeLink, setConnectCodeLink] = React.useState();
  const events = useEvents();

  function handleCopy() {}

  React.useEffect(() => {
    async function getConnectCodeLink() {
      const organizationData = await OrganizationData.getOrganization(
        coach.uid,
      );

      const result = await ClientData.getDynamicLink({
        connectCode: coach.connectCode,
        organization: organizationData,
        type: UserType.coach,
      });
      setConnectCodeLink(result ? result : undefined);
    }

    getConnectCodeLink();
  }, [coach.connectCode, coach.organizationId, coach.uid]);

  return (
    <Box style={{ ...style }}>
      {/* <Typography>{`uid: ${client.uid}`}</Typography> */}
      <Box style={{ marginBotton: 24 }}>
        <Typography style={{ fontWeight: "bold" }}>Connect Code</Typography>
        <Typography style={{ fontSize: 12 }}>
          Share with your coach. They use this code when signing up to connect
          to your organization.
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        style={{ marginTop: 8 }}
      >
        <Typography
          style={{
            marginRight: 12,
            fontWeight: "bold",
          }}
        >{`${
          coach.connectCode || "Press Get new Code to generate"
        }`}</Typography>

        {coach.connectCode && (
          <CopyToClipboard text={`${coach.connectCode}`}>
            <IconButton
              style={{ marginRight: 12 }}
              onClick={() => {
                events.logButtonClick({
                  buttonName: "Copy Connect Code",
                  pageName: "Coaches",
                  tabName: coach.uid,
                });
                handleCopy();
              }}
            >
              <FileCopyIcon />
            </IconButton>
          </CopyToClipboard>
        )}

        {coach.connectCode && (
          <Typography
            style={{
              marginRight: 24,
              fontWeight: "bold",
            }}
          >
            {`${connectCodeLink ? connectCodeLink.shortLink : ""}`}
          </Typography>
        )}
        <Box>
          {coach.connectCode && (
            <CopyToClipboard
              text={`${connectCodeLink ? connectCodeLink.shortLink : ""}`}
            >
              <RedBorderButton
                style={{ marginRight: 8 }}
                onClick={() => {
                  events.logButtonClick({
                    buttonName: "Copy Invite Link",
                    pageName: "Coaches",
                    tabName: coach.uid,
                  });
                  handleCopy();
                }}
              >
                Copy Invite Link
              </RedBorderButton>
            </CopyToClipboard>
          )}
          <RedBorderButton
            onClick={() => {
              CoachData.resetConnectCode(coach.uid);
              events.logButtonClick({
                buttonName: "Get New Code",
                pageName: "Coaches",
                tabName: coach.uid,
              });
            }}
          >
            Get new Code
          </RedBorderButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CoachConnectCode;
