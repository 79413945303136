import React from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  ImageListItem,
  Grid,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircleText from "../../../../components/CircleText";
import ExercisesList from "../../../../components/organisms/ExercisesList";

const SuperSetExerciseTile = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getSetsToFailureString(exercise) {
    if (exercise.setsToFailure) {
      return (
        <Typography>{`Sets to Failure: ${exercise.setsToFailure}`}</Typography>
      );
    }
    return null;
  }

  return (
    <Box
      boxShadow={3}
      component={ImageListItem}
      key={props.exercise.id}
      style={{
        padding: 23,
        width: 375,

        backgroundColor: "white",
        marginTop: 8,
        marginLeft: 2,
        marginBottom: 8,
        marginRight: 12,
        borderRadius: 8,
      }}
    >
      <MoreVertIcon
        style={{ position: "absolute", right: 0 }}
        onClick={handleClick}
      />
      <CircleText
        circleText={props.exercise.index + 1}
        title={
          props.exercise.superSetName && props.exercise.superSetName.length > 0
            ? props.exercise.superSetName
            : "Super Set"
        }
      />
      <ExercisesList exercises={props.exercise.exercises} />

      <Box style={{}}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <Typography
              style={{ fontSize: 16 }}
            >{`Sets: ${props.exercise.sets}`}</Typography>
          </Grid>
          <Grid item>{getSetsToFailureString(props.exercise)}</Grid>
        </Grid>
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            if (props.onExerciseEdit) {
              props.onExerciseEdit(props.exercise);
            }
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            //
            setAnchorEl(null);
            if (props.onExerciseDelete) {
              props.onExerciseDelete(props.exercise);
            }
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default SuperSetExerciseTile;
