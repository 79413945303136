import React from "react";
import { Stack, CircularProgress } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import Bugsnag from "@bugsnag/js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useRecoilValue } from "recoil";
import { getHost } from "../../data/StripeData";
import { updateSubscription } from "../../data/StripeData";
import organizationIdSelector from "../../atoms/organizationIdSelector";
import { consola } from "consola";
import useEvents from "../../hooks/useEvents";

const stripePromise = loadStripe(
  "pk_test_51KajLpBIBLRwE5jUKeYnhhl6BOUjdWtLaXmU25tYH55u23gSYyfEH97LcexqBxp6HmcaM3efX8F1I3SzKgfrynbQ00jFnJnVla",
);

const StripeForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const events = useEvents();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const basePath = getHost();

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:
          // eslint-disable-next-line no-undef
          process.env.NODE_ENV === "production"
            ? `${basePath}/subscribe`
            : "http://localhost:3000/subscribe",
      },
    });

    if (error) {
      Bugsnag.notify(new Error(error.message));
      confirmAlert({
        title: `Problem with payment method`,
        message: `Sorry. There was a problem with your payment method. ${error.message} Please try again. If the problem persists, please contact support at support@mighty45.com.`,
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    } else {
      consola.log("success");
      const paymentProcessedEvent = {
        buttonName: "Submitted Payment Method",
        pageName: "Admin",
        tabName: "View Subscription ",
      };
      events.logButtonClick(paymentProcessedEvent);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button>Submit</button>
    </form>
  );
};

const StripeFormWrapper = () => {
  const organizationId = useRecoilValue(organizationIdSelector);

  const [stripeData, setStripeData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    async function getStripeData() {
      setLoading(true);
      if (!organizationId) {
        return;
      }
      const stripeData = await updateSubscription({
        organizationId: organizationId,
      });

      setStripeData(stripeData);
      setLoading(false);
    }
    getStripeData();
  }, [organizationId]);

  const options = React.useMemo(() => {
    if (!stripeData || !stripeData.clientSecret) {
      return null;
    }
    return {
      clientSecret: stripeData.clientSecret,
    };
  }, [stripeData]);

  if (loading) {
    return (
      <Stack
        style={{ height: 330, justifyContent: "center", alignItems: "center" }}
      >
        <CircularProgress
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </Stack>
    );
  }

  if (options && stripeData && stripeData.stripeStatus !== "active") {
    return (
      <Elements stripe={stripePromise} options={options}>
        <StripeForm />
      </Elements>
    );
  } else {
    return null;
  }
};

export default StripeFormWrapper;
