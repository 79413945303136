import React from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import exerciseDBKeysSelector from "../../../../atoms/exerciseDBkeysSelector";
import colors from "../../../../themes/Colors";

import FieldValue from "../../../../components/molecules/FieldValue";
import ExerciseNameTableCell from "../../../../components/molecules/ExerciseNameTableCell";
import LabelChips from "../../../../components/molecules/LabelChips";

import { EditButton, DeleteButton } from "../../../../atoms/EditButton";

const SplitExerciseTable = ({
  exercises,
  labels,
  onEdit,
  onDelete,
  onFieldClick,
  onSuperSetFieldClick,
}) => {
  const exerciseKeys = useRecoilValue(exerciseDBKeysSelector);
  const { t } = useTranslation();

  return (
    <Box style={{ margin: 12 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell>Exercise/Super Set</TableCell>
              <TableCell>Set Type</TableCell>
              <TableCell>Labels</TableCell>
              <TableCell>Reps</TableCell>
              {/* <TableCell>Reps Max</TableCell> */}
              <TableCell>{t("exercise_columns.tempo")}</TableCell>
              <TableCell>Rest</TableCell>
              <TableCell>Sets</TableCell>
              <TableCell>Sets to Failure</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exercises &&
              exercises.map((e, index) => {
                const value = e.data();

                return (
                  <TableRow key={value.id}>
                    <TableCell
                      style={{ width: 45, backgroundColor: colors.lightGray }}
                    >
                      {index + 1}
                    </TableCell>
                    <ExerciseNameTableCell
                      exercise={value}
                      exerciseKeys={exerciseKeys}
                      onFieldClick={(data) => {
                        onSuperSetFieldClick(data);
                      }}
                    />
                    <TableCell>
                      {value.type === "super_set"
                        ? t("super_set")
                        : t("normal_set")}
                    </TableCell>
                    <TableCell>
                      <LabelChips labels={value.labels} allLabels={labels} />
                    </TableCell>
                    <TableCell>
                      <FieldValue
                        type={value.type}
                        field="reps"
                        width={120}
                        delay={1000}
                        value={value.reps}
                        // validator={(value) => {
                        //   return value;
                        // }}
                        onChange={(newValue, field) => {
                          onFieldClick({
                            field: field,
                            fieldValue: newValue,
                            value: value,
                          });
                        }}
                      />
                    </TableCell>

                    <TableCell style={{ width: 100 }}>
                      <FieldValue
                        type={value.type}
                        field="tempo"
                        width={100}
                        value={value.tempo}
                        validator={(value) => {
                          return value;
                        }}
                        onChange={(newValue, field) => {
                          onFieldClick({
                            field: field,
                            fieldValue: newValue,
                            value: value,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <FieldValue
                        type={value.type}
                        field="rest"
                        value={value.rest}
                        validator={(value) => {
                          return value;
                        }}
                        onChange={(newValue, field) => {
                          onFieldClick({
                            field: field,
                            fieldValue: newValue,
                            value: value,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <FieldValue
                        type={value.type}
                        field="sets"
                        value={value.sets}
                        validator={(value) => {
                          const parsedValue = parseInt(value);
                          if (isNaN(parsedValue)) {
                            return 0;
                          }
                          return parsedValue;
                        }}
                        onChange={(newValue, field) => {
                          onFieldClick({
                            field: field,
                            fieldValue: newValue,
                            value: value,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <FieldValue
                        type={value.type}
                        field="setsToFailure"
                        value={value.setsToFailure}
                        validator={(value) => {
                          const parsedValue = parseInt(value);
                          if (isNaN(parsedValue)) {
                            return 0;
                          }
                          return parsedValue;
                        }}
                        onChange={(newValue, field) => {
                          onFieldClick({
                            field: field,
                            fieldValue: newValue,
                            value: value,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <EditButton
                          onClick={() => {
                            if (onEdit) {
                              onEdit(value);
                            }
                          }}
                        />

                        <DeleteButton
                          onClick={() => {
                            if (onDelete) {
                              onDelete(value);
                            }
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SplitExerciseTable;
