import React from "react";
import { Box, Typography, Divider, Grid } from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";

import TrainingDataSuperSet from "./TrainingData/TrainingDataSuperSet";
import TrainingDataNormalSet from "./TrainingData/TrainingDataNormalSet";

const TrainingData = (props) => {
  const [reportExercises, setReportExercises] = React.useState([]);

  React.useEffect(() => {
    if (
      props.report &&
      props.report.exercises &&
      props.report.exercises.length > 0
    ) {
      let allExercises = [];
      props.report.exercises.forEach((rd) => {
        allExercises = allExercises.concat(rd.reportData.exercises);
      });
      setReportExercises(allExercises);
    }
  }, [props.report]);

  if (!props.report) {
    return null;
  }

  if (!props.report || !props.report.exercises) {
    return (
      <Box style={{ backgroundColor: "#F6F7FB" }}>
        <Typography style={{ fontSize: 24, fontWeight: "500" }}>
          No training data for this day.
        </Typography>
      </Box>
    );
  }

  return (
    <Box style={{ backgroundColor: "#F6F7FB" }}>
      <Box style={{ marginTop: 12 }}>
        {reportExercises.map((reportExercise) => {
          if (reportExercise.type === "super_set") {
            return (
              <Box key={reportExercise.id}>
                <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
                  Super Set
                </Typography>
                <TrainingDataSuperSet
                  exercise={reportExercise}
                  chartClicked={(report) => {
                    if (props.chartClicked) {
                      props.chartClicked(report);
                    }
                  }}
                />
                <Divider style={{ marginBottom: 12, marginTop: 12 }} />
              </Box>
            );
          }
          return (
            <Box key={reportExercise.id}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
                    {reportExercise.exerciseName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    marginLeft: 12,
                    width: 100,
                  }}
                >
                  <TimelineIcon
                    onClick={() => {
                      if (props.chartClicked) {
                        props.chartClicked(reportExercise);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <TrainingDataNormalSet exercise={reportExercise} />
              <Divider style={{ marginBottom: 12, marginTop: 12 }} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default TrainingData;
