import React from "react";
import { Box, Select, MenuItem } from "@mui/material";
import { useRecoilValue } from "recoil";
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import _ from "underscore";
import useClientNutritionChartData from "../../../../hooks/useClientNutritionChartData";
import organizationIdSelector from "../../../../atoms/organizationIdSelector";
import useEvents from "../../../../hooks/useEvents";

const NutritionCharts = ({ client }) => {
  const [chartType, setChartType] = React.useState("kCal");

  const organizationId = useRecoilValue(organizationIdSelector);
  const events = useEvents();
  const { nutritionChartData } = useClientNutritionChartData({
    uid: client.uid,
    organizationId: organizationId,
    count: 90,
  });

  function filterByChartType(chartType, data) {
    if (chartType === "kCal") {
      events.logButtonClick({
        buttonName: "kCal Chart Selected",
        pageName: "Clients",
        tabName: client.uid,
      });
      return _.filter(data, (d) => {
        return d.kCal > 0;
      });
    }

    if (chartType === "proteins") {
      events.logButtonClick({
        buttonName: "Proteins Chart Selected",
        pageName: "Clients",
        tabName: client.uid,
      });
      return _.filter(data, (d) => {
        return d.proteins > 0;
      });
    }

    if (chartType === "carbs") {
      events.logButtonClick({
        buttonName: "Carbs Chart Selected",
        pageName: "Clients",
        tabName: client.uid,
      });
      return _.filter(data, (d) => {
        return d.carbs > 0;
      });
    }

    if (chartType === "fats") {
      events.logButtonClick({
        buttonName: "Fats Chart Selected",
        pageName: "Clients",
        tabName: client.uid,
      });
      return _.filter(data, (d) => {
        return d.fats > 0;
      });
    }

    if (chartType === "weight") {
      events.logButtonClick({
        buttonName: "Weight Chart Selected",
        pageName: "Clients",
        tabName: client.uid,
      });
      return _.filter(data, (d) => {
        return d.weight > 0;
      });
    }

    return data;
  }

  const filteredData = React.useMemo(() => {
    if (!nutritionChartData) return null;

    const filtered = filterByChartType(chartType, nutritionChartData);
    return filtered;
  }, [nutritionChartData, chartType]);

  return (
    <Box style={{ padding: 12, backgroundColor: "white" }}>
      <Select
        fullWidth={true}
        style={{ width: "100%", marginBottom: 20 }}
        variant="outlined"
        value={chartType}
        onChange={(event) => {
          setChartType(event.target.value);
        }}
      >
        <MenuItem value="kCal">kCal</MenuItem>
        <MenuItem value="proteins">Proteins</MenuItem>
        <MenuItem value="carbs">Carbs</MenuItem>
        <MenuItem value="fats">Fats</MenuItem>
        <MenuItem value="weight">Weight</MenuItem>
      </Select>

      <ResponsiveContainer
        minHeight={200}
        maxHeight={300}
        aspect={4.0 / 3.0}
        width={"100%"}
      >
        <LineChart
          data={filteredData}
          margin={{ top: 0, right: 0, left: -20, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="createdOnString" style={{ fontSize: 12 }} />
          <YAxis domain={[0, "dataMax + 20"]} style={{ fontSize: 12 }} />
          <Tooltip />

          <Line
            dot={false}
            type="monotone"
            dataKey={chartType}
            stroke="#8884d8"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default NutritionCharts;
