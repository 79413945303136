import React from "react";
import { Box, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { EditButton, DeleteButton } from "../../../../atoms/EditButton";

const MealPlanDataGrid = ({ mealPlans, onClick, onEdit, onDelete }) => {
  const theme = useTheme();

  const storedModel = localStorage.getItem("mealPlansDataGridColumns");
  const [visibleModel, setVisibilityModel] = React.useState(
    storedModel ? JSON.parse(storedModel) : {},
  );

  const columns = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
      },
      {
        field: "kCals",
        headerName: "kCals",
        width: 150,
        valueGetter: (params) => {
          return params.row.totals?.totalkCals;
        },
      },
      {
        field: "proteins",
        headerName: "Proteins",
        width: 150,
        valueGetter: (params) => {
          return params.row.totals?.totalProteins;
        },
      },
      {
        field: "carbs",
        headerName: "Carbs",
        width: 150,
        valueGetter: (params) => {
          return params.row.totals?.totalCarbs;
        },
      },
      {
        field: "fats",
        headerName: "Fats",
        width: 150,
        valueGetter: (params) => {
          return params.row.totals?.totalFats;
        },
      },
      {
        field: "recipes",
        headerName: "Recipes",
        width: 150,
        valueGetter: (params) => {
          return params.row.selectedRecipes.length;
        },
      },
      {
        headerAlign: "center",
        align: "right",
        minWidth: 120,
        field: "action",
        headerName: "",
        sortable: false,
        hideable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Stack spacing={1} direction="row">
              <EditButton
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(params.row);
                }}
              />
              <DeleteButton
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(params.row);
                }}
              />
            </Stack>
          );
        },
      },
    ];
  }, []);

  return (
    <Box sx={{ flexGrow: 1, height: 200 }}>
      <DataGrid
        sx={{
          ".MuiDataGrid-sortIcon": {
            color: theme.palette.primary.main,
          },
          ".MuiDataGrid-filterIcon": {
            color: theme.palette.primary.main,
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          ".MuiDataGrid-menuIconButton": {
            color: theme.palette.primary.main,
          },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
          height: "100%",
        }}
        columnVisibilityModel={visibleModel}
        disableSelectionOnClick
        rows={mealPlans}
        columns={columns}
        getRowHeight={() => {
          return "auto";
        }}
        onRowClick={(params) => {
          onClick(params.row);
        }}
        onColumnVisibilityModelChange={(model) => {
          localStorage.setItem(
            "mealPlansDataGridColumns",
            JSON.stringify(model),
          );
          setVisibilityModel(model);
        }}
      />
    </Box>
  );
};

export default MealPlanDataGrid;
