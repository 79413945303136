import React from "react";
import { SurroundingBox } from "./BoxStyles";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import NutritionCharts from "./NutritionCharts";
import NutritionData from "./NutritionData";
import BlueBorderButton from "../../../../components/BlueBorderButton";
import ClientLog from "../../../../components/organisms/ClientLog";
import ChatComponent from "../../../../components/organisms/ChatComponent";
import OptionalData from "./OptionalData";
import TrainingData from "./TrainingData";
import SharedVideos from "./SharedVideos";
import ExerciseCharts from "./ExerciseCharts";
import PhotoSummary from "./PhotoSummary";
import ClientData from "../../../../data/ClientData";
import useEvents from "../../../../hooks/useEvents";
import { DropDownButton } from "../../../../atoms/EditButton";
const events = useEvents();
export const ClientNutrition = ({
  client,
  dailyReport,
  setBodyMeasurementOpen,
}) => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={8}>
        <Stack>
          <Box style={{ backgroundColor: "#F6F7FB" }}>
            <NutritionData client={client} report={dailyReport} />
            <BlueBorderButton
              style={{ margin: 0 }}
              onClick={() => {
                events.logButtonClick({
                  buttonName: "Edit Body Measurements",
                  pageName: "Clients",
                  tabName: client.uid,
                });
                setBodyMeasurementOpen(true);
              }}
            >
              Edit Body Measurements
            </BlueBorderButton>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Box
          style={{
            maxHeight: 400,
            width: "100%",
          }}
          boxShadow={1}
        >
          <NutritionCharts client={client} />
        </Box>
      </Grid>
    </Grid>
  );
};

export const ClientDailyData = ({ client, dailyReport }) => {
  return (
    <SurroundingBox>
      <OptionalData client={client} report={dailyReport} />
    </SurroundingBox>
  );
};

export const ClientNotes = ({ client }) => {
  const [showNotes, setShowNotes] = React.useState(true);

  return (
    <>
      <Box
        boxShadow={3}
        display="flex"
        borderRadius={2}
        borderBottom={showNotes ? 1 : 0}
        style={{
          width: "100%",
          backgroundColor: "#F6F7FB",
          marginTop: 20,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          paddingLeft={3}
        >
          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M0 14.25V18H3.75L14.81 6.94004L11.06 3.19004L0 14.25ZM17.71 4.04004C18.1 3.65004 18.1 3.02004 17.71 2.63004L15.37 0.290044C15.2775 0.197329 15.1676 0.12377 15.0466 0.0735815C14.9257 0.0233925 14.796 -0.00244141 14.665 -0.00244141C14.534 -0.00244141 14.4043 0.0233925 14.2834 0.0735815C14.1624 0.12377 14.0525 0.197329 13.96 0.290044L12.13 2.12004L15.88 5.87004L17.71 4.04004Z"
                    fill="black"
                  />
                </svg>
              </Box>
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "normal",
                  fontFamily: "Lato",
                  verticalAlign: "Center",
                  paddingLeft: 20,
                }}
              >
                Shared Notes
              </Typography>
            </Box>
          </Grid>

          <Grid item textAlign="center" style={{}}>
            <DropDownButton
              onClick={() => {
                setShowNotes(!showNotes);
              }}
              show={showNotes}
            />
          </Grid>
        </Grid>
      </Box>
      {showNotes && (
        <Box display={"flex"}>
          <Box
            boxShadow={3}
            borderRadius={2}
            style={{
              width: "100%",
              backgroundColor: "#F6F7FB",
              padding: 20,
            }}
          >
            <Box style={{ paddingInline: 75 }}>
              <ClientLog clientId={client.uid}></ClientLog>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export const ClientSharedVideos = ({ client }) => {
  const [showProgress, setShowProgress] = React.useState(true);

  return (
    <>
      <Box
        boxShadow={3}
        display="flex"
        borderRadius={2}
        borderBottom={showProgress ? 1 : 0}
        style={{
          width: "100%",
          backgroundColor: "#F6F7FB",
          marginTop: 20,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          paddingLeft={3}
        >
          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="24"
                  viewBox="0 0 32 24"
                  fill="none"
                >
                  <path
                    d="M24.8889 9L32 1V23L24.8889 15V22C24.8889 22.5304 24.7016 23.0391 24.3682 23.4142C24.0348 23.7893 23.5826 24 23.1111 24H1.77778C1.30628 24 0.854097 23.7893 0.520699 23.4142C0.187301 23.0391 0 22.5304 0 22V2C0 1.46957 0.187301 0.96086 0.520699 0.585787C0.854097 0.210714 1.30628 0 1.77778 0H23.1111C23.5826 0 24.0348 0.210714 24.3682 0.585787C24.7016 0.96086 24.8889 1.46957 24.8889 2V9ZM19.5556 20V18C19.5556 15.34 14.8089 14 12.4444 14C10.08 14 5.33333 15.34 5.33333 18V20H19.5556ZM12.4444 4C11.5015 4 10.5971 4.42143 9.93029 5.17157C9.26349 5.92172 8.88889 6.93913 8.88889 8C8.88889 9.06087 9.26349 10.0783 9.93029 10.8284C10.5971 11.5786 11.5015 12 12.4444 12C13.3874 12 14.2918 11.5786 14.9586 10.8284C15.6254 10.0783 16 9.06087 16 8C16 6.93913 15.6254 5.92172 14.9586 5.17157C14.2918 4.42143 13.3874 4 12.4444 4Z"
                    fill="black"
                  />
                </svg>
              </Box>
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "normal",
                  fontFamily: "Lato",
                  verticalAlign: "Center",
                  paddingLeft: 20,
                }}
              >
                Shared Videos
              </Typography>
            </Box>
          </Grid>

          <Grid item textAlign="center" style={{}}>
            <DropDownButton
              onClick={() => {
                setShowProgress(!showProgress);
              }}
              show={showProgress}
            />
          </Grid>
        </Grid>
      </Box>
      {showProgress && (
        <Box display={"flex"}>
          <Box
            boxShadow={3}
            borderRadius={2}
            style={{
              width: "100%",
              backgroundColor: "#F6F7FB",
              padding: 20,
            }}
          >
            <Box style={{ paddingInline: 75 }}>
              <SharedVideos client={client} />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export const ClientProgressPhotos = ({
  client,
  photoUpdate,
  setPhotoUpdate,
}) => {
  const [showProgress, setShowProgress] = React.useState(true);
  const confirm = useConfirm();
  return (
    <>
      <Box
        boxShadow={3}
        display="flex"
        borderRadius={2}
        borderBottom={showProgress ? 1 : 0}
        style={{
          width: "100%",
          backgroundColor: "#F6F7FB",
          marginTop: 20,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          paddingLeft={3}
        >
          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M11.2 0L8.272 3.55556H3.2C1.44 3.55556 0 5.15556 0 7.11111V28.4444C0 30.4 1.44 32 3.2 32H28.8C30.56 32 32 30.4 32 28.4444V7.11111C32 5.15556 30.56 3.55556 28.8 3.55556H23.728L20.8 0H11.2ZM16 26.6667C11.584 26.6667 8 22.6844 8 17.7778C8 12.8711 11.584 8.88889 16 8.88889C20.416 8.88889 24 12.8711 24 17.7778C24 22.6844 20.416 26.6667 16 26.6667Z"
                    fill="black"
                  />
                </svg>
              </Box>
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "normal",
                  fontFamily: "Lato",
                  verticalAlign: "Center",
                  paddingLeft: 20,
                }}
              >
                Progress Photos
              </Typography>
            </Box>
          </Grid>

          <Grid item textAlign="center" style={{}}>
            <DropDownButton
              onClick={() => {
                setShowProgress(!showProgress);
              }}
              show={showProgress}
            />
          </Grid>
        </Grid>
      </Box>
      {showProgress && (
        <Box display={"flex"}>
          <Box
            boxShadow={3}
            borderRadius={2}
            style={{
              width: "100%",
              backgroundColor: "#F6F7FB",
              padding: 20,
            }}
          >
            <Box style={{ paddingInline: 75 }}>
              <PhotoSummary
                client={client}
                update={photoUpdate}
                makeStartingGroup={async (group) => {
                  events.logButtonClick({
                    buttonName: "Set Starting Progress Photo Group",
                    pageName: "Clients",
                    tabName: client.uid,
                  });
                  await ClientData.setStartingPhotoGroup(client.uid, group);
                  setPhotoUpdate(new Date());
                }}
                deletePhotoGroup={async (groupId) => {
                  confirm({
                    title: "Delete the progress photos?",
                    description:
                      "Deleting this progress photo group cannot be reversed. Continue?",
                  }).then(async () => {
                    await ClientData.deleteProgressPhotos(client.uid, groupId);
                    setPhotoUpdate(new Date());
                    events.logButtonClick({
                      buttonName: "Delete Progress Photo Group",
                      pageName: "Clients",
                      tabName: client.uid,
                    });
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export const ClientTrainingData = ({
  client,
  dailyReport,
  exerciseData,
  chartType,
  setChartType,
}) => {
  const [showTraining, setShowTraining] = React.useState(true);

  return (
    <>
      <Box
        boxShadow={3}
        display="flex"
        borderRadius={2}
        borderBottom={showTraining ? 1 : 0}
        style={{
          width: "100%",
          backgroundColor: "#F6F7FB",
          marginTop: 20,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          paddingLeft={3}
        >
          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M16 5.81818C14.224 5.81818 12.8 7.11273 12.8 8.72727C12.8 10.3418 14.224 11.6364 16 11.6364C17.776 11.6364 19.2 10.3418 19.2 8.72727C19.2 7.11273 17.776 5.81818 16 5.81818ZM32 0V7.27273H28.8V4.36364H3.2V7.27273H0V0H3.2V2.90909H28.8V0H32ZM20.8 14.9236V32H17.6V24.7273H14.4V32H11.2V14.9236C7.888 13.3382 5.6 10.1818 5.6 6.54545V5.81818H8.8V6.54545C8.8 10.1818 12 13.0909 16 13.0909C20 13.0909 23.2 10.1818 23.2 6.54545V5.81818H26.4V6.54545C26.4 10.1818 24.112 13.3382 20.8 14.9236Z"
                    fill="black"
                  />
                </svg>
              </Box>
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "normal",
                  fontFamily: "Lato",
                  verticalAlign: "Center",
                  paddingLeft: 20,
                }}
              >
                Training
              </Typography>
            </Box>
          </Grid>

          <Grid item textAlign="center" style={{}}>
            <DropDownButton
              onClick={() => {
                setShowTraining(!showTraining);
              }}
              show={showTraining}
            />
          </Grid>
        </Grid>
      </Box>
      {showTraining && (
        <Box display={"flex"}>
          <Box
            boxShadow={3}
            borderRadius={2}
            style={{
              width: "100%",
              backgroundColor: "#F6F7FB",
              padding: 20,
            }}
          >
            <Box style={{ paddingInline: 75 }}>
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <TrainingData
                    client={client}
                    report={dailyReport}
                    chartClicked={(report) => {
                      setChartType(report.exercise);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box
                    style={{
                      maxHeight: 400,
                      width: "100%",
                    }}
                    boxShadow={1}
                  >
                    <ExerciseCharts
                      client={client}
                      exerciseData={exerciseData}
                      chartType={chartType}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export const ClientMessages = ({ uid, groupId }) => {
  const [showMessages, setShowMessages] = React.useState(true);
  if (!groupId) {
    return null;
  }
  if (!uid) {
    return null;
  }
  return (
    <>
      <Box
        display="flex"
        boxShadow={3}
        borderRadius={2}
        borderBottom={showMessages ? 1 : 0}
        style={{ marginTop: 20, backgroundColor: "#F6F7FB" }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          paddingLeft={3}
        >
          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                >
                  <path
                    d="M17.0603 1H4.81085C3.68798 1 2.76927 1.9 2.76927 3V17H4.81085V3H17.0603V1ZM16.0395 5L22.1643 11V21C22.1643 22.1 21.2456 23 20.1227 23H8.8838C7.76093 23 6.85243 22.1 6.85243 21L6.86264 7C6.86264 5.9 7.77114 5 8.89401 5H16.0395ZM15.0187 12H20.6331L15.0187 6.5V12Z"
                    fill="black"
                  />
                </svg>
              </Box>
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "normal",
                  fontFamily: "Lato",
                  verticalAlign: "Center",
                  paddingLeft: 20,
                }}
              >
                Messages
              </Typography>
            </Box>
          </Grid>

          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="29"
                viewBox="0 0 24 29"
                fill="none"
              >
                <path
                  d="M21.3333 20.6667V13.7334C20.6667 13.8667 20 14.0001 19.3333 14.0001H18.6667V22.0001H5.33333V12.6667C5.33333 8.93342 8.26667 6.00008 12 6.00008C12.1333 4.26675 12.9333 2.80008 14 1.60008C13.6 1.06675 12.8 0.666748 12 0.666748C10.5333 0.666748 9.33333 1.86675 9.33333 3.33341V3.73342C5.33333 4.93341 2.66667 8.53341 2.66667 12.6667V20.6667L0 23.3334V24.6667H24V23.3334L21.3333 20.6667ZM9.33333 26.0001C9.33333 27.4667 10.5333 28.6667 12 28.6667C13.4667 28.6667 14.6667 27.4667 14.6667 26.0001H9.33333ZM24 6.66675C24 9.20008 21.8667 11.3334 19.3333 11.3334C16.8 11.3334 14.6667 9.20008 14.6667 6.66675C14.6667 4.13341 16.8 2.00008 19.3333 2.00008C21.8667 2.00008 24 4.13341 24 6.66675Z"
                  fill="black"
                />
              </svg>
              <DropDownButton
                onClick={() => {
                  setShowMessages(!showMessages);
                }}
                show={showMessages}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {showMessages && (
        <Box display={"flex"}>
          <Box
            boxShadow={3}
            borderRadius={2}
            style={{
              width: "100%",
              backgroundColor: "#F6F7FB",
              padding: 20,
            }}
          >
            {/* <RaisedBox> */}
            <ChatComponent uid={uid} groupId={groupId} />
            {/* </RaisedBox> */}
          </Box>
        </Box>
      )}
    </>
  );
};
