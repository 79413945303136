import React from "react";
import ProtocolData from "../data/ProtocolData";

const useSplitExercises = ({ protocolId, splitId }) => {
  const subscription = React.useRef();
  const [exercises, setExercises] = React.useState([]);

  React.useEffect(() => {
    async function getDataFlat() {
      if (subscription.current) {
        subscription.current();
      }

      subscription.current =
        await ProtocolData.getSplitExerciseSubscriptionFlat(
          splitId,
          (snapshot) => {
            setExercises(snapshot.docs);
          },
        );
    }

    getDataFlat();

    return () => {
      if (subscription.current) {
        subscription.current();
      }
    };
  }, [splitId, protocolId]);

  return { exercises };
};

export default useSplitExercises;
