import React from "react";
import {
  Box,
  Grid,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import BlueButton from "../../../../components/BlueButton";
import RedButton from "../../../../components/RedButton";
import ProtocolData from "../../../../data/ProtocolData";

import { DeleteButton } from "../../../../atoms/EditButton";

const ClientProtocolDialog = ({
  open,
  client,
  onDialogClosed,
  onEdit,
  onMakeActive,
}) => {
  const [protocols, setProtocols] = React.useState([]);
  const confirm = useConfirm();

  React.useEffect(() => {
    async function getData() {
      const protocols = await ProtocolData.getProtocols({
        clientUID: client.uid,
      });
      setProtocols(protocols.map((p) => p.data()));
    }
    getData();
  }, [open, client]); // eslint-disable-line

  // function DisplayEmpty() {
  //   if (protocols.length === 0) {
  //     return <Box></Box>;
  //   }
  //   return null;
  // }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>Client Training Plans</DialogTitle>

      <DialogContent>
        <Box style={{ marginTop: 18 }}>
          {protocols.map((p) => {
            return (
              <Box key={p.id} sx={{ mt: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    {p.name}
                  </Grid>
                  <Grid item xs={4}>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ alignItems: "flex-end" }}
                    >
                      <BlueButton
                        sx={{ width: 150 }}
                        onClick={() => {
                          if (onMakeActive) {
                            onMakeActive(p);
                          }
                        }}
                        color="primary"
                      >
                        Set as Active
                      </BlueButton>

                      <RedButton
                        sx={{ width: 150 }}
                        onClick={() => {
                          if (onEdit) {
                            onEdit(p);
                          }
                        }}
                        color="primary"
                      >
                        Edit Training Plan
                      </RedButton>
                      <DeleteButton
                        onClick={() => {
                          confirm({
                            title: `Delete ${p.name}?`,
                            description:
                              "Deleting this training plan cannot be reversed. Continue?",
                          }).then(async () => {
                            await ProtocolData.deleteProtocol(p.id);
                            const protocols = await ProtocolData.getProtocols({
                              clientUID: client.uid,
                            });
                            setProtocols(protocols.map((p) => p.data()));
                          });
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: 12 }} />
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <BlueButton
          onClick={() => {
            if (onDialogClosed) {
              onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Close
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClientProtocolDialog;
