import React from "react";
import { Typography } from "@mui/material";

const PrintableFieldValue = ({ type, value, field, width = 25 }) => {
  const stringValue = React.useMemo(() => {
    if (!value) {
      return "-";
    }
    if (value && value.length === 0) {
      return "-";
    }
    return value;
  }, [value]);

  if (type === "super_set" && field !== "sets" && field !== "setsToFailure") {
    return <Typography style={{ minWidth: width }}>-</Typography>;
  }

  return <Typography style={{ minWidth: width }}>{stringValue}</Typography>;
};

export default PrintableFieldValue;
