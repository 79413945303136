import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

import {
  Box,
  Typography,
  Divider,
  Breadcrumbs,
  Link,
  Tabs,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import GroupTable from "./messages/GroupTable";
import MessageData, {
  GroupType,
  updateMessageGroupUserActivity,
} from "../../../data/MessageData";
import AddGroupDialog from "./messages/AddGroupDialog";
import useOrganizationUsers from "../../../hooks/useOrganizationUsers";
import organizationIdSelector from "../../../atoms/organizationIdSelector";
import useMessageGroupData from "../../../hooks/useMessageGroupData";
import TabPanel from "../../../components/molecules/TabPanel";
import ChatComponent from "../../../components/organisms/ChatComponent";
import useEvents from "../../../hooks/useEvents";

const MessagesPage = () => {
  const navigate = useNavigate();
  const events = useEvents();

  const organizationId = useRecoilValue(organizationIdSelector);

  const [mode, setMode] = React.useState("list");
  const [groups, setGroups] = React.useState([]);
  // const [questionGroups, setQuestionGroups] = React.useState([]);
  const [groupGroups, setGroupGroups] = React.useState([]);
  const [alertGroups, setAlertGroups] = React.useState([]);
  const [editGroupOpen, setEditGroupOpen] = React.useState(false);
  const [editGroupMode, setEditGroupMode] = React.useState("add");
  const [tabIndex, setTabIndex] = React.useState(0);

  const usersArray = React.useMemo(() => {
    return [localStorage.getItem("uid")];
  }, []);

  const onMessageGroupSnapshot = React.useCallback(
    (snapshot) => {
      if (mode === "list") {
        setGroups(snapshot);
      }
    },
    [mode],
  );

  const onGroupMessageGroupSnapshot = React.useCallback(
    (snapshot) => {
      if (mode === "list") {
        setGroupGroups(snapshot);
      }
    },
    [mode],
  );

  const onAlertMessageGroupSnapshot = React.useCallback(
    (snapshot) => {
      if (mode === "list") {
        setAlertGroups(snapshot);
      }
    },
    [mode],
  );

  useMessageGroupData({
    users: usersArray,
    order: "lastDate",
    direction: "desc",
    groupType: GroupType.single,
    onSnapshot: onMessageGroupSnapshot,
  });

  useMessageGroupData({
    users: usersArray,
    order: "lastDate",
    direction: "desc",
    groupType: GroupType.group,
    onSnapshot: onGroupMessageGroupSnapshot,
  });

  useMessageGroupData({
    users: usersArray,
    order: "lastDate",
    direction: "desc",
    groupType: GroupType.alert,
    onSnapshot: onAlertMessageGroupSnapshot,
  });

  const users = useOrganizationUsers({
    organizationId,
  });

  const selectedGroup = React.useRef();

  React.useEffect(() => {
    return () => {
      if (navigate.action === "POP") {
        navigate("/home");
      }
    };
  }, [navigate]);

  React.useEffect(() => {
    firebase.analytics().logEvent("page_view", {
      page_title: "Messages Page",
      page_location: "/messages",
    });
  }, []);

  const handleChange = (event, newValue) => {
    events.logTabSwitch({
      pageName: "Messages",
      tabName: newValue,
      lastTab: tabIndex,
    });

    setTabIndex(newValue);
    localStorage.setItem("messagePage_TabIndex", newValue);
  };

  if (mode === "list") {
    return (
      <>
        <Box style={{ marginTop: 20 }}>
          <Typography variant="h5">Messages</Typography>

          <Divider style={{ marginTop: 20 }}></Divider>
        </Box>
        <Tabs value={tabIndex} onChange={handleChange}>
          <Tab label="Client Groups"></Tab>
          <Tab label="Custom Groups"></Tab>
          <Tab label="Alert Groups"></Tab>
        </Tabs>

        <TabPanel value={tabIndex} index={0} style={{ height: "100%" }}>
          <GroupTable
            id="clientGroups"
            title="Client Groups"
            users={users}
            groups={groups}
            onGroupClicked={(group) => {
              selectedGroup.current = group;

              events.logButtonClick({
                buttonName: "Select Message Group",
                pageName: "Messages",
                tabName: "Client Groups",
              });

              updateMessageGroupUserActivity(
                localStorage.getItem("uid"),
                group.id,
              );

              setMode("detail");
            }}
            editClicked={(group) => {
              events.logButtonClick({
                buttonName: "Edit Message Group",
                pageName: "Messages",
                tabName: "Client Groups",
              });

              selectedGroup.current = group;
              setEditGroupMode("edit");
              setEditGroupOpen(true);
              // Show edit dialog
            }}
          />
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <GroupTable
            id="customGroups"
            title="Custom Groups"
            users={users}
            groups={groupGroups}
            onAddGroupClicked={() => {
              events.logButtonClick({
                buttonName: "Add Message Group",
                pageName: "Messages",
                tabName: "Custom Groups",
              });
              selectedGroup.current = undefined;
              setEditGroupMode("add");
              setEditGroupOpen(true);
            }}
            onGroupClicked={(group) => {
              selectedGroup.current = group;
              events.logButtonClick({
                buttonName: "Select Message Group",
                pageName: "Messages",
                tabName: "Custom Groups",
              });

              updateMessageGroupUserActivity(
                localStorage.getItem("uid"),
                group.id,
              );

              setMode("detail");
            }}
            editClicked={(group) => {
              selectedGroup.current = group;
              setEditGroupMode("edit");
              setEditGroupOpen(true);
              // Show edit dialog
            }}
          />
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <GroupTable
            id="alertGroups"
            title="Alert Groups"
            users={users}
            groups={alertGroups}
            onGroupClicked={(group) => {
              events.logButtonClick({
                buttonName: "Select Message Group",
                pageName: "Messages",
                tabName: "Alert Groups",
              });
              selectedGroup.current = group;
              setMode("detail");
            }}
            onAddGroupClicked={() => {
              events.logButtonClick({
                buttonName: "Add Message Group",
                pageName: "Messages",
                tabName: "Alert Group",
              });
              selectedGroup.current = undefined;
              setEditGroupMode("add");
              setEditGroupOpen(true);
            }}
            editClicked={(group) => {
              events.logButtonClick({
                buttonName: "Edit Message Group",
                pageName: "Messages",
                tabName: "Alert Group",
              });
              selectedGroup.current = group;
              setEditGroupMode("edit");
              setEditGroupOpen(true);
              // Show edit dialog
            }}
          />
        </TabPanel>

        {editGroupOpen && (
          <AddGroupDialog
            open={editGroupOpen}
            mode={editGroupMode}
            group={selectedGroup.current}
            onDialogClosed={async (groupInput, mode, editedGroup) => {
              setEditGroupOpen(false);
              if (groupInput) {
                if (mode === "edit") {
                  await MessageData.editMessageGroup(
                    editedGroup.id,
                    groupInput,
                  );
                } else {
                  const data = {
                    ...groupInput,
                    lastMessage: "No messages yet",
                    lastDate: new Date(),
                    type: groupInput.type,
                  };
                  await MessageData.addMessageGroup(data);
                }
              }
            }}
          />
        )}
      </>
    );
  } else {
    return (
      <Box
        style={{
          marginLeft: 20,
          marginRight: 20,
          marginTop: 20,
        }}
      >
        <Box>
          <Breadcrumbs separator=">">
            <Link
              onClick={() => {
                setMode("list");
              }}
            >
              Message Groups
            </Link>
            <Typography>{`${selectedGroup.current.name}`}</Typography>
          </Breadcrumbs>

          <Divider style={{ marginTop: 20 }}></Divider>
          <ChatComponent
            groupId={selectedGroup.current.id}
            uid={localStorage.getItem("uid")}
            width="auto"
            maxHeight={window.innerHeight - 250}
            minHeight={window.innerHeight - 250}
          />
        </Box>
      </Box>
    );
  }
};

export default MessagesPage;
