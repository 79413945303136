import { Typography, Box, Button, Stack, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useConfirm } from "material-ui-confirm";
import { useRecoilValue } from "recoil";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import organizationIdSelector from "../../../../atoms/organizationIdSelector";
import useSharedFiles from "../../../../hooks/useSharedFiles";
import { consola } from "consola";

import { DeleteButton, EditButton } from "../../../../atoms/EditButton";
import FileEditDialog from "./FileEditDialog";
import useEvents from "../../../../hooks/useEvents";

const EditFiles = () => {
  const theme = useTheme();
  const confirm = useConfirm();
  const events = useEvents();

  const organizationId = useRecoilValue(organizationIdSelector);
  const { sharedFiles, uploadOrganizationFile, deleteFile, updateFile } =
    useSharedFiles({
      uid: localStorage.getItem("uid"),
      clientUID: undefined,
      organizationId,
      global: true,
    });

  const storedModel = localStorage.getItem("filesGridColumns");
  const [visibleModel, setVisibilityModel] = React.useState(
    storedModel ? JSON.parse(storedModel) : {},
  );
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const onEdit = (row) => {
    consola.log(row);
    setSelectedFile(row);
    setDialogOpen(true);
    consola.log(row);
    events.logButtonClick({
      buttonName: "Edit File",
      pageName: "Library",
      tabName: "Files",
    });
  };

  const onDelete = (row) => {
    confirm({
      title: "Delete this file?",
      description:
        "This will permanently delete this file and clients will not be able to view it anymore. Continue?",
    }).then(async () => {
      deleteFile({ id: row.id });
      events.logButtonClick({
        buttonName: "Delete File",
        pageName: "Library",
        tabName: "Files",
      });
    });
  };

  const columns = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: "File Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
      },
      {
        minWidth: 35,
        headerAlign: "center",
        align: "right",
        field: "delete",
        headerName: "",
        sortable: false,
        hideable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Stack
              spacing={0}
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <EditButton onClick={() => onEdit(params.row)} />
              <DeleteButton onClick={() => onDelete(params.row)} />
            </Stack>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <Grid container style={{ marginTop: 0 }}>
        <Grid item xs={8}>
          <Typography variant="h6">Organization Files</Typography>
          <Typography
            style={{ fontSize: 16, fontWeight: "normal", marginBottom: 12 }}
          >
            These are your organizations shared files. These files will be
            available to all clients in your organization.
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Box display="flex" flexDirection="row" justifyContent={"flex-end"}>
            <Button component="label" variant="outlined" color="primary">
              Upload a File
              <input
                hidden={true}
                type="file"
                accept=".pdf,.csv,.doc,.docx,.txt,.xls,.xlsx,.zip,.rtf,.rar,.ppt,.pptx,.mp4,.mp3,.png,.jpg,.jpeg"
                onChange={(e) => {
                  uploadOrganizationFile({ file: e.target.files[0] });
                  events.logButtonClick({
                    buttonName: "Upload File",
                    pageName: "Library",
                    tabName: "Files",
                  });
                }}
              />
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1, height: window.innerHeight - 350 }}>
        <DataGrid
          sx={{
            ".MuiDataGrid-sortIcon": {
              color: theme.palette.primary.main,
            },
            ".MuiDataGrid-filterIcon": {
              color: theme.palette.primary.main,
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-menuIconButton": {
              color: theme.palette.primary.main,
            },
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
          columnVisibilityModel={visibleModel}
          disableSelectionOnClick
          rows={sharedFiles}
          columns={columns}
          getRowHeight={() => {
            return "auto";
          }}
          onColumnVisibilityModelChange={(model) => {
            localStorage.setItem(
              "clientDataGridColumns",
              JSON.stringify(model),
            );
            setVisibilityModel(model);
          }}
        />
      </Box>

      {dialogOpen && (
        <FileEditDialog
          open={dialogOpen}
          file={selectedFile}
          onDialogClosed={(changes, file) => {
            setDialogOpen(false);
            setSelectedFile(null);
            consola.log(changes);
            consola.log(file);

            if (changes) {
              updateFile({
                id: file.id,
                name: changes.name,
                description: changes.description,
              });
            }
          }}
        />
      )}
    </>
  );
};

export default EditFiles;
