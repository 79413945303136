import React, { useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
  Card,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import createTrend from "trendline";
import _ from "underscore";
import colors from "../../../../themes/Colors";
import useClientNutritionChartData from "../../../../hooks/useClientNutritionChartData";
import organizationIdSelector from "../../../../atoms/organizationIdSelector";
import { ExpandMore } from "@mui/icons-material";

const HealthCharts = ({ clientUID }) => {
  const organizationId = useRecoilValue(organizationIdSelector);
  const [expanded, setExpanded] = React.useState(false);

  const { nutritionChartData } = useClientNutritionChartData({
    uid: clientUID,
    organizationId: organizationId,
    count: 90,
  });

  const kCalData = useMemo(() => {
    return _.filter(nutritionChartData, (d) => {
      return d.kCal > 0;
    }).map((d, index) => {
      return { ...d, index };
    });
  }, [nutritionChartData]);

  const proteinsData = useMemo(() => {
    return _.filter(nutritionChartData, (d) => {
      return d.proteins > 0;
    }).map((d, index) => {
      return { ...d, index };
    });
  }, [nutritionChartData]);

  const carbsData = useMemo(() => {
    return _.filter(nutritionChartData, (d) => {
      return d.carbs > 0;
    }).map((d, index) => {
      return { ...d, index };
    });
  }, [nutritionChartData]);

  const fatsData = useMemo(() => {
    return _.filter(nutritionChartData, (d) => {
      return d.fats > 0;
    }).map((d, index) => {
      return { ...d, index };
    });
  }, [nutritionChartData]);

  const weightData = useMemo(() => {
    return _.filter(nutritionChartData, (d) => {
      return d.weight > 0;
    }).map((d, index) => {
      return { ...d, index };
    });
  }, [nutritionChartData]);

  const kCalLinear = useMemo(() => {
    const trend = createTrend(kCalData, "index", "kCal");
    const final = kCalData.map((data) => {
      return {
        kCalTrend: trend.calcY(data.index).toFixed(2),
        kCal: data.kCal,
        createdOnString: data.createdOnString,
      };
    });

    return final;
  }, [kCalData]);

  const proteinsLinear = useMemo(() => {
    const trend = createTrend(proteinsData, "index", "proteins");

    const final = proteinsData.map((data) => {
      return {
        proteinsL: trend.calcY(data.index).toFixed(2),
        proteins: data.proteins,
        createdOnString: data.createdOnString,
      };
    });

    return final;
  }, [proteinsData]);

  const carbsLinear = useMemo(() => {
    const trend = createTrend(carbsData, "index", "carbs");

    const final = carbsData.map((data) => {
      return {
        carbsL: trend.calcY(data.index).toFixed(2),
        carbs: data.carbs,
        createdOnString: data.createdOnString,
      };
    });

    return final;
  }, [carbsData]);

  const fatsLinear = useMemo(() => {
    const trend = createTrend(fatsData, "index", "fats");
    const final = fatsData.map((data) => {
      return {
        fatsL: trend.calcY(data.index).toFixed(2),
        fats: data.fats,
        createdOnString: data.createdOnString,
      };
    });

    return final;
  }, [fatsData]);

  const weightLinear = useMemo(() => {
    const trend = createTrend(weightData, "index", "weight");
    const final = weightData.map((data) => {
      return {
        weightL: trend.calcY(data.index).toFixed(2),
        weight: data.weight,
        createdOnString: data.createdOnString,
      };
    });
    return final;
  }, [weightData]);

  return (
    <Box
      // display="flex"
      // boxShadow={3}
      // borderRadius={2}
      // style={{ backgroundColor: "#F6F7FB", padding: 20 }}
      style={{ marginTop: 20 }}
    >
      <Accordion
        display="flex"
        boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="34"
            viewBox="2 0 29 40"
            fill="none"
            display={"inline-flex"}
          >
            <path
              d="M24.0279 11.5666L24.4192 13.6126L18.4542 16.6605C18.1103 16.8363 17.8284 17.0986 17.6706 17.3899C17.5127 17.6811 17.4918 17.9774 17.6125 18.2136C17.7332 18.4498 17.9855 18.6065 18.314 18.6492C18.6426 18.6919 19.0203 18.6172 19.3642 18.4415L24.8105 15.6586L26.7671 25.8887L21.3208 28.6716C20.9769 28.8473 20.695 29.1096 20.5372 29.4009C20.3793 29.6921 20.3584 29.9885 20.4791 30.2246C20.5998 30.4608 20.8521 30.6175 21.1806 30.6602C21.5092 30.703 21.8869 30.6283 22.2308 30.4525L27.1584 27.9347L27.9411 32.0267C28.4238 32.9714 28.3402 34.1566 27.7088 35.3217C27.0774 36.4867 25.9498 37.5361 24.5742 38.239C23.1985 38.9419 21.6874 39.2408 20.3734 39.0698C19.0593 38.8989 18.0499 38.2721 17.5672 37.3274L10.4236 29.7477L14.3139 27.7599C14.6578 27.5842 14.9397 27.3219 15.0975 27.0306C15.2554 26.7393 15.2763 26.443 15.1556 26.2069C15.0349 25.9707 14.7826 25.814 14.454 25.7713C14.1255 25.7285 13.7478 25.8032 13.4038 25.979L8.99493 28.2317L3.2801 22.168C2.18809 20.0308 3.6627 16.964 6.75302 14.3069L1.31897 12.5691C0.0963852 12.1607 -0.18254 10.9107 0.728354 9.74902C1.60421 8.58278 3.32137 7.97489 4.56079 8.35227L6.32846 8.93139L4.69044 5.72563C4.44909 5.25329 4.49086 4.66068 4.80657 4.07816C5.12228 3.49565 5.68607 2.97095 6.3739 2.61949C7.06173 2.26803 7.81727 2.11861 8.4743 2.20409C9.13133 2.28957 9.63604 2.60295 9.87739 3.0753L11.9522 7.13592L14.259 2.04927C14.7326 0.863954 16.347 -0.0732036 17.7638 0.0113773C19.1716 0.0781475 19.8746 1.1339 19.3322 2.37679L16.0923 9.66965C19.841 8.87717 22.9814 9.5185 24.0279 11.5666Z"
              fill="black"
            />
          </svg>
          <Typography
            style={{
              color: "black",
              fontSize: 24,
              fontWeight: "600",
              marginLeft: 10,
            }}
          >
            Health and Nutrition Data
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Card variant="elevation" style={{ padding: 12 }}>
                <Stack style={{ alignItems: "center" }} spacing={2}>
                  <Typography>kCal</Typography>
                  <ResponsiveContainer aspect={4.0 / 3.0} width={"100%"}>
                    <LineChart
                      data={kCalLinear}
                      margin={{ top: 0, right: 0, left: -20, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="createdOnString"
                        style={{ fontSize: 12 }}
                      />
                      <YAxis
                        domain={[0, "dataMax + 20"]}
                        style={{ fontSize: 12 }}
                      />
                      <Tooltip />

                      <Line
                        dot={false}
                        type="monotone"
                        dataKey="kCal"
                        stroke="#8884d8"
                        strokeWidth={3}
                      />

                      <Line
                        dot={false}
                        type="basis"
                        dataKey="kCalTrend"
                        name="kCal Trend"
                        stroke={colors.secondaryRed}
                        strokeWidth={1}
                        strokeDasharray="4"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={4}>
              <Card variant="elevation" style={{ padding: 12 }}>
                <Stack style={{ alignItems: "center" }} spacing={2}>
                  <Typography>Proteins</Typography>
                  <ResponsiveContainer aspect={4.0 / 3.0} width={"100%"}>
                    <LineChart
                      data={proteinsLinear}
                      margin={{ top: 0, right: 0, left: -20, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="createdOnString"
                        style={{ fontSize: 12 }}
                      />
                      <YAxis
                        domain={[0, "dataMax + 20"]}
                        style={{ fontSize: 12 }}
                      />
                      <Tooltip />

                      <Line
                        dot={false}
                        type="monotone"
                        dataKey="proteins"
                        stroke="#8884d8"
                        strokeWidth={3}
                      />

                      <Line
                        dot={false}
                        type="basis"
                        dataKey="proteinsL"
                        name="Proteins Trend"
                        stroke={colors.secondaryRed}
                        strokeWidth={1}
                        strokeDasharray="4"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={4}>
              <Card variant="elevation" style={{ padding: 12 }}>
                <Stack style={{ alignItems: "center" }} spacing={2}>
                  <Typography>Carbs</Typography>
                  <ResponsiveContainer aspect={4.0 / 3.0} width={"100%"}>
                    <LineChart
                      data={carbsLinear}
                      margin={{ top: 0, right: 0, left: -20, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="createdOnString"
                        style={{ fontSize: 12 }}
                      />
                      <YAxis
                        domain={[0, "dataMax + 20"]}
                        style={{ fontSize: 12 }}
                      />
                      <Tooltip />

                      <Line
                        dot={false}
                        type="monotone"
                        dataKey="carbs"
                        stroke="#8884d8"
                        strokeWidth={3}
                      />

                      <Line
                        dot={false}
                        type="basis"
                        dataKey="carbsL"
                        name="Carbs Trend"
                        stroke={colors.secondaryRed}
                        strokeWidth={1}
                        strokeDasharray="4"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={4}>
              <Card variant="elevation" style={{ padding: 12 }}>
                <Stack style={{ alignItems: "center" }} spacing={2}>
                  <Typography>Fats</Typography>
                  <ResponsiveContainer aspect={4.0 / 3.0} width={"100%"}>
                    <LineChart
                      data={fatsLinear}
                      margin={{ top: 0, right: 0, left: -20, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="createdOnString"
                        style={{ fontSize: 12 }}
                      />
                      <YAxis
                        domain={[0, "dataMax + 20"]}
                        style={{ fontSize: 12 }}
                      />
                      <Tooltip />

                      <Line
                        dot={false}
                        type="monotone"
                        dataKey="fats"
                        stroke="#8884d8"
                        strokeWidth={3}
                      />

                      <Line
                        dot={false}
                        type="basis"
                        dataKey="fatsL"
                        name="Fats Trend"
                        stroke={colors.secondaryRed}
                        strokeWidth={1}
                        strokeDasharray="4"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={4}>
              <Card variant="elevation" style={{ padding: 12 }}>
                <Stack style={{ alignItems: "center" }} spacing={2}>
                  <Typography>Weight</Typography>
                  <ResponsiveContainer aspect={4.0 / 3.0} width={"100%"}>
                    <LineChart
                      data={weightLinear}
                      margin={{ top: 0, right: 0, left: -20, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="createdOnString"
                        style={{ fontSize: 12 }}
                      />
                      <YAxis
                        domain={[0, "dataMax + 20"]}
                        style={{ fontSize: 12 }}
                      />
                      <Tooltip />

                      <Line
                        dot={false}
                        type="monotone"
                        dataKey="weight"
                        stroke="#8884d8"
                        strokeWidth={3}
                      />

                      <Line
                        dot={false}
                        type="basis"
                        dataKey="weightL"
                        stroke={colors.secondaryRed}
                        strokeWidth={1}
                        strokeDasharray="4"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default HealthCharts;
