import React from "react";
import { Box, Typography } from "@mui/material";

const NutritionValue = ({ title = "", value = 0 }) => {
  return (
    <Box style={{ marginBottom: 25 }}>
      <Box display="flex" alignItems="center">
        <Typography
          display="inline"
          style={{ fontSize: 24, fontWeight: "500" }}
        >
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography display="inline" style={{ marginLeft: 0, fontSize: 18 }}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default NutritionValue;
