import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Grid,
} from "@mui/material";
import _ from "underscore";
import BlueButton from "../../../../components/BlueButton";
import { DataType, DataTypeString } from "../../../../data/GlobalData";
import { QuestionType } from "../../../../data/GlobalData";

const QuestionEditDialog = ({
  questionType,
  titleStrings,
  question,
  open = false,
  onDialogClosed = null,
}) => {
  const [dialogOpen, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [name, setName] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [minValue, setMinValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(10);
  const [isRequired, setIsRequired] = React.useState(false);
  const [type, setType] = React.useState(DataType.number);

  const [tier1, setTier1] = React.useState(false);
  const [tier2, setTier2] = React.useState(false);
  const [tier3, setTier3] = React.useState(false);

  const nameLength = questionType === QuestionType.progress ? 100 : 100;

  React.useEffect(() => {
    setOpen(open);
  }, [open]); // eslint-disable-line

  React.useEffect(() => {
    if (question) {
      setName(question.name);
      setSubTitle(question.subTitle ? question.subTitle : "");
      setMinValue(question.minValue !== undefined ? question.minValue : null);
      setMaxValue(question.maxValue !== undefined ? question.maxValue : null);
      setIsRequired(question.isRequired ? question.isRequired : false);

      setType(question.type);
    } else {
      setName("");
      setSubTitle("");
      setMinValue(0);
      setMaxValue(10);
      setIsRequired(false);

      setType(DataType.number);
    }

    setTier1(question?.tier1 !== undefined ? question.tier1 : true);
    setTier2(question?.tier2 !== undefined ? question.tier2 : true);
    setTier3(question?.tier3 !== undefined ? question.tier3 : true);
  }, [question]);

  function validate() {
    if (!name) {
      return ["A title is required."];
    }

    if (minValue !== null && maxValue !== null && minValue > maxValue) {
      return ["Minimum must be less than maximum."];
    }
    if (maxValue !== null && maxValue < 0) {
      return ["Maximum must be greater than 0."];
    }
    if (maxValue !== null && minValue !== null && maxValue < minValue) {
      return ["Maximum must be greater than minimum."];
    }
    return null;
  }

  function showEdit() {
    if (
      questionType === QuestionType.weekly ||
      questionType === QuestionType.daily
    ) {
      return true;
    }
    return false;
  }

  if (!dialogOpen) {
    return null;
  }

  return (
    <Dialog
      key="QuestionEditDialog"
      maxWidth="lg"
      open={dialogOpen}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>{titleStrings.editTitle}</DialogTitle>

      <DialogContent>
        <Typography style={{ width: "80%" }}>
          {titleStrings.description}
        </Typography>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Name:</Typography>
          <TextField
            value={name}
            style={{ width: 512 }}
            variant="outlined"
            onChange={(event) => {
              if (event.target.value.length > nameLength) {
                return false;
              }
              setName(event.target.value);
            }}
          ></TextField>
        </Box>
        {showEdit() && (
          <>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 200 }}>Subtitle:</Typography>
              <TextField
                multiline
                maxRows={3}
                minRows={3}
                value={subTitle}
                style={{ width: 512 }}
                variant="outlined"
                onChange={(event) => {
                  setSubTitle(event.target.value);
                }}
              />
            </Box>

            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 12 }}
            >
              <Typography style={{ width: 200 }}>Required:</Typography>
              <Checkbox
                checked={isRequired}
                onChange={(event) => {
                  const checked = event.target.checked;
                  setIsRequired(checked);
                }}
              />
            </Box>

            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 250 }}>Choose the Tiers:</Typography>
              <Grid container>
                <Grid item>
                  <Typography style={{ width: 200 }}>Tier 1:</Typography>
                  <Checkbox
                    checked={tier1}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setTier1(checked);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ width: 200 }}>Tier 2:</Typography>
                  <Checkbox
                    checked={tier2}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setTier2(checked);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ width: 200 }}>Tier 3:</Typography>
                  <Checkbox
                    checked={tier3}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setTier3(checked);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </DialogContent>

      {showEdit() && (
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center", marginTop: 12 }}>
            <Typography style={{ width: 200 }}>Question Type:</Typography>
            <Select
              style={{ width: 512 }}
              variant="outlined"
              defaultValue={DataType.number}
              value={type}
              onChange={(event) => {
                setType(event.target.value);

                if (event.target.value === DataType.number) {
                  setMinValue(0);
                  setMaxValue(10);
                }
                if (event.target.value === DataType.rating) {
                  setMinValue(0);
                  setMaxValue(5);
                }
                if (event.target.value === DataType.multiline) {
                  setMinValue(null);
                  setMaxValue(null);
                }
                if (event.target.value === DataType.scale) {
                  setMinValue(0);
                  setMaxValue(10);
                }
              }}
            >
              {_.keys(DataType).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {DataTypeString[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </DialogContent>
      )}

      {((showEdit() && type === DataType.number) ||
        type === DataType.scale) && (
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
            <Typography style={{ width: 200 }}>Minimum Value:</Typography>
            <TextField
              value={minValue !== null ? minValue : ""}
              style={{ width: 100, marginRight: 12 }}
              variant="outlined"
              type="number"
              onChange={(event) => {
                if (event.target.value.length > 5) {
                  return false;
                }

                const valueString = event.target.value.trim();

                if (valueString.length === 0) {
                  setMinValue(null);
                }
                let value = parseInt(valueString);

                if (isNaN(value)) {
                  return;
                }

                if (value > maxValue) {
                  value = maxValue;
                }
                setMinValue(value);
              }}
            ></TextField>
            <Typography style={{ width: 90 }}>Maximum Value:</Typography>
            <TextField
              value={maxValue !== null ? maxValue : ""}
              style={{ width: 100 }}
              type="number"
              variant="outlined"
              onChange={(event) => {
                if (event.target.value.length > 5) {
                  return false;
                }

                const valueString = event.target.value.trim();

                if (valueString.length === 0) {
                  setMaxValue(null);
                }
                const value = parseInt(valueString);
                if (isNaN(value)) {
                  return;
                }

                // if (value < minValue) {
                //   value = minValue;
                // }
                setMaxValue(value);
              }}
            ></TextField>
          </Box>
        </DialogContent>
      )}

      <DialogContent>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ width: 400, color: "red" }}>{error}</Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            if (onDialogClosed) {
              onDialogClosed(null, null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            const validateResult = validate();
            if (!validateResult) {
              setOpen(false);
              if (onDialogClosed) {
                if (questionType === QuestionType.progress) {
                  onDialogClosed(
                    {
                      id: question ? question.id : null,
                      name: name,
                    },
                    question,
                    questionType,
                  );
                  return;
                }
                onDialogClosed(
                  {
                    id: question ? question.id : null,
                    name: name,
                    subTitle: subTitle,
                    minValue: minValue,
                    maxValue: maxValue,
                    isRequired: isRequired,
                    tier1: tier1,
                    tier2: tier2,
                    tier3: tier3,
                    type: type,
                  },
                  question,
                  questionType,
                );
              }
            } else {
              setError(validateResult[0]);
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionEditDialog;
