import React from "react";
import { Chip, Grid } from "@mui/material";
import _ from "underscore";

const LabelChips = ({ labels, allLabels }) => {
  function getLabel(id) {
    if (!allLabels) {
      return null;
    }
    const value = _.findWhere(allLabels, { id: id });
    return value;
  }

  if (!labels || !allLabels) {
    return null;
  }

  const chips = labels.map((label) => {
    const labelObject = getLabel(label);
    if (!labelObject) {
      return null;
    }
    return (
      <Grid item key={labelObject.id}>
        <Chip
          variant="outlined"
          style={{
            color: labelObject.color,
            borderWidth: 2,
            borderColor: labelObject.backgroundColor,
            marginRight: 6,
          }}
          key={labelObject.id}
          label={labelObject.name}
        />
      </Grid>
    );
  });

  return (
    <Grid container spacing={1}>
      {chips}
    </Grid>
  );
};

export default LabelChips;
