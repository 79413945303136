import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

import {
  Box,
  Stack,
  Typography,
  Divider,
  Grid,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import Image from "react-cool-img";
import moment from "moment";
import { useConfirm } from "material-ui-confirm";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

import useSharedVideos from "../../../hooks/useSharedVideos";
import useOrganizationUsers from "../../../hooks/useOrganizationUsers";
import colors from "../../../themes/Colors";
import SendMessageDialog from "../../../components/organisms/SendMessageDialog";
import useOrganizationData from "../../../hooks/useOrganizationData";
import completion from "../../../assets/noun-completed-2350074-FFFFFF.svg";
import useEvents from "../../../hooks/useEvents";

const SharedVideo = ({ video }) => {
  const [feedback, setFeedback] = React.useState(video.feedback || "");

  React.useEffect(() => {
    video.feedback = feedback;
  }, [feedback]);

  return (
    <Stack style={{ alignItems: "center" }}>
      <ReactPlayer controls={true} width="100%" url={video.url} />
      <Typography variant="h6" style={{ textAlign: "center" }}>
        {moment(video.createdOn.toDate()).format("LLL")}
      </Typography>
      <TextField
        placeholder="Enter your feedback for this video here"
        value={feedback}
        style={{ width: "100%" }}
        multiline
        rows={4}
        variant="outlined"
        onChange={(event) => {
          setFeedback(event.target.value);
        }}
      ></TextField>
      {video.processed && (
        <Typography
          style={{ color: colors.primaryRed, textAlign: "center" }}
        >{`Video processed by ${video.processedBy.firstName} ${video.processedBy.lastName}`}</Typography>
      )}
      {video.metadata && video.metadata.notes && (
        <Typography style={{ textAlign: "center" }}>
          {video.metadata.notes}
        </Typography>
      )}
    </Stack>
  );
};

const SharedVideoClients = ({
  date,
  hour,
  clientsData,
  users,
  setVideoProcessedState,
  setLastUpdated,
  setSelectedClientId,
  setSendMessageOpen,
}) => {
  const confirm = useConfirm();
  const events = useEvents();

  const [localUsers, setUsers] = React.useState(users);

  React.useEffect(() => {
    setUsers(users);
  }, [users]);

  // find user by uid
  const findUser = (uid) => {
    return localUsers.find((user) => user.uid === uid);
  };
  return (
    <Box>
      {clientsData.map((client) => {
        const user = findUser(client.clientUID);
        // sort client.videos by date/time created ascending
        const sortedVideos = client.videos.sort((a, b) => {
          return a.createdOn.toDate() - b.createdOn.toDate();
        });
        return (
          <Box
            key={`${date}_${hour}_${client.clientUID}`}
            style={{
              marginTop: 12,
              backgroundColor: "white",
              padding: 24,
              borderRadius: 8,
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography
                variant="h6"
                style={{
                  marginBottom: 12,
                  fontWeight: "bold",
                  color: colors.primaryBlue,
                }}
              >
                {user ? `${user.firstName} ${user.lastName}` : ""}
              </Typography>
              <Box>
                <Button
                  style={{ marginRight: 8 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSelectedClientId(client.clientUID);
                    setSendMessageOpen(true);
                    events.logButtonClick({
                      buttonName: "Send Message",
                      pageName: "Shared Videos",
                    });
                  }}
                >
                  Send a Message
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    confirm({
                      title: "Mark all videos as processed?",
                      description:
                        "This will mark all videos as processed and remove them from the list. You can view them again in the Client Summary. Continue?",
                    })
                      .then(async () => {
                        const promises = sortedVideos.map((video) => {
                          return setVideoProcessedState({
                            id: video.id,
                            feedback: video.feedback,
                            uid: video.clientId,
                            coachUID: localStorage.getItem("uid"),
                            processed: true,
                          });
                        });
                        await Promise.all(promises);
                        setLastUpdated(new Date());
                        events.logButtonClick({
                          buttonName: "Process Videos",
                          pageName: "Shared Videos",
                        });
                      })
                      .catch(() => {});
                  }}
                >
                  Mark as Processed
                </Button>
              </Box>
            </Box>

            <Grid container spacing={4}>
              {sortedVideos.map((video) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={video.id}>
                    <SharedVideo
                      key={`${date}_${hour}_${client.clientUID}_${video.id}`}
                      video={video}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};

const SharedVideoPage = () => {
  const navigate = useNavigate();

  const organization = useOrganizationData({
    uid: localStorage.getItem("uid"),
  });
  const users = useOrganizationUsers({
    organizationId: organization ? organization.id : null,
  });

  const [selectedClientId, setSelectedClientId] = React.useState(null);
  const [sendMessageOpen, setSendMessageOpen] = React.useState(false);

  const {
    videos,
    loading,
    loadingClients,
    isRefreshing,
    setVideoProcessedState,
    setLastUpdated,
  } = useSharedVideos({
    showAll: false,
    coachUID: localStorage.getItem("uid"),
    organizationId: organization ? organization.id : null,
  });

  React.useEffect(() => {
    return () => {
      if (navigate.action === "POP") {
        navigate("/home");
      }
    };
  }, [navigate]);

  React.useEffect(() => {
    firebase.analytics().logEvent("page_view", {
      page_title: "Shard Video Page",
      page_location: "/shared-video",
    });
  }, []);

  if (loading || (loadingClients && !isRefreshing)) {
    return (
      <Box
        style={{
          marginTop: 20,
        }}
      >
        <Box>
          <Typography variant="h5">Shared Videos</Typography>

          <Divider style={{ marginTop: 20 }}></Divider>
        </Box>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: 500,
          }}
        >
          <CircularProgress style={{ marginTop: 24 }}></CircularProgress>
        </Box>
      </Box>
    );
  }

  if (videos.length === 0 && !isRefreshing && !loading && !loadingClients) {
    return (
      <Box
        style={{
          marginTop: 20,
        }}
      >
        <Box>
          <Typography variant="h5">Shared Videos</Typography>
          <Divider style={{ marginTop: 20 }}></Divider>
        </Box>
        <Box
          style={{
            marginTop: 24,
            backgroundColor: colors.primaryBlue,
            padding: 50,
            borderRadius: 24,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image src={completion} alt="completion" height={200} width={200} />
            <Typography
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: 24,
                maxWidth: 450,
              }}
            >
              Congrats! All shared videos are processed. Check back later.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      style={{
        margin: 20,
      }}
    >
      <Box>
        <Typography variant="h5">Shared Videos</Typography>

        <Divider style={{ marginTop: 20 }}></Divider>

        {videos.map((video) => {
          return (
            <Box key={video.date} style={{ marginTop: 20, marginBottom: 20 }}>
              <Typography variant="h5" style={{ color: colors.primaryRed }}>
                {video.date}
              </Typography>
              {video.hours.map((hour) => {
                // convert hour to 12 hour format
                const hour12 = moment(hour.hour, ["HH"]).format("h A");
                return (
                  <Box
                    key={`${video.date}_${hour.hour}`}
                    style={{ marginTop: 12, marginBottom: 12 }}
                  >
                    <Typography
                      style={{ color: colors.primaryRed }}
                      variant="h6"
                    >
                      {hour12}
                    </Typography>
                    <SharedVideoClients
                      key={`shared_video_clients_${video.date}_${hour.hour}`}
                      date={video.date}
                      hour={hour.hour}
                      clientsData={hour.clients}
                      users={users}
                      setVideoProcessedState={setVideoProcessedState}
                      setLastUpdated={setLastUpdated}
                      setSelectedClientId={setSelectedClientId}
                      setSendMessageOpen={setSendMessageOpen}
                    />
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>

      {selectedClientId && sendMessageOpen && (
        <SendMessageDialog
          coachId={localStorage.getItem("uid")}
          uid={selectedClientId}
          open={sendMessageOpen}
          onDialogClosed={() => {
            setSendMessageOpen(false);
            setSelectedClientId(null);
          }}
        />
      )}
    </Box>
  );
};

export default SharedVideoPage;
