import React from "react";
import { Box, Typography } from "@mui/material";

export default React.memo(({ optional }) => {
  if (optional) {
    return (
      <Box style={{ marginTop: 0, marginInline: 40 }}>
        <Typography style={{ color: "black", fontSize: 24, fontWeight: "500" }}>
          {optional.name}
        </Typography>
        <Typography
          style={{ color: "black", fontSize: 12, fontWeight: "normal" }}
        >
          {optional.subTitle}
        </Typography>
        <Typography>{optional.value}</Typography>
      </Box>
    );
  }
  return null;
});
