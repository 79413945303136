import React from "react";
import { Box, Stack, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { CSVLink } from "react-csv";
import useProtocols from "data/hooks/useProtocols";
import {
  EditButton,
  DeleteButton,
  RestoreButton,
} from "../../../../atoms/EditButton";

const ExerciseDataGrid = ({
  exercises,
  muscleGroupsKey,
  equipmentKey,
  allProtocols,
  onClick,
  onDelete,
  onRestore,
  onEdit,
}) => {
  const theme = useTheme();

  const { findExercise } = useProtocols();

  const storedModel = localStorage.getItem("exerciseDataGridColumns");
  const [visibleModel, setVisibilityModel] = React.useState(
    storedModel ? JSON.parse(storedModel) : {},
  );

  const csvData = React.useMemo(() => {
    if (!exercises) {
      return null;
    }
    const data = exercises.map((e) => {
      return {
        id: e.id,
        name: e.name,
        description: e.description,
        instructions: e.instructions,
        difficulty: e.difficulty,
        icon: e.icon,
        videos: e.videos ? "true" : "false",
        images: e.images ? "true" : "false",
      };
    });
    const headers = [
      { label: "id", key: "id" },
      { label: "name", key: "name" },
      { label: "description", key: "description" },
      { label: "instructions", key: "instructions" },
      { label: "difficulty", key: "difficulty" },
      { label: "icon", key: "icon" },
      { label: "videos", key: "videos" },
      { label: "images", key: "images" },
    ];

    return {
      data: data,
      headers: headers,
      filename: "exercises.csv",
    };
  }, [exercises]);

  const columns = React.useMemo(() => {
    return [
      // { field: "id", headerName: "ID", width: 150 },
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        maxWidth: 200,
        flex: 1,
        hideable: false,
      },
      {
        field: "description",
        headerName: "Description",
        minWidth: 200,
        flex: 1,
        valueGetter: (params) => {
          // return only 300 characters if longer than 300 characters
          if (params.row.description.length > 100) {
            return params.row.description.substring(0, 100) + "...";
          }
        },
      },
      {
        field: "instructions",
        headerName: "Instructions",
        width: 90,
      },
      // { field: "complexity", headerName: "Complexity", width: 100 },
      {
        field: "mainMuscleGroups",
        headerName: "Main Muscles",
        width: 150,
      },
      {
        field: "secondaryMuscleGroups",
        headerName: "Secondary Muscles",
        width: 150,
      },
      // { field: "equipment", headerName: "Equipment", width: 150 },
      { field: "videos", headerName: "Videos", width: 90 },
      { field: "images", headerName: "Images", width: 90 },
      { field: "plans", headerName: "# Plans", width: 90 },
      { field: "isCustom", headerName: "Custom", width: 90 },
      { field: "isDeleted", headerName: "Archived", width: 90 },
      {
        headerAlign: "center",
        align: "left",
        minWidth: 120,
        field: "action",
        headerName: "",
        sortable: false,
        hideable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Stack spacing={1} direction="row">
              <EditButton
                onClick={(event) => {
                  event.ignore = true;
                  event.stopPropagation();
                  onEdit(params.row.exercise);
                }}
              />

              {params.row.exercise.isCustom &&
                !params.row.exercise.isDeleted && (
                  <DeleteButton
                    onClick={(event) => {
                      event.ignore = true;
                      event.stopPropagation();
                      onDelete(params.row.exercise);
                    }}
                  />
                )}
              {params.row.exercise.isCustom &&
                params.row.exercise.isDeleted && (
                  <RestoreButton
                    onClick={(event) => {
                      event.ignore = true;
                      event.stopPropagation();
                      onRestore(params.row.exercise);
                    }}
                  />
                )}
            </Stack>
          );
        },
      },
    ];
  }, []);

  const rows = React.useMemo(() => {
    if (!exercises || !muscleGroupsKey || !equipmentKey) {
      return [];
    }
    return exercises.map((exercise) => {
      return {
        id: exercise.id,
        exercise: exercise,
        name: exercise.name,
        description: exercise.description,
        instructions:
          exercise.instructions && exercise.instructions.length > 0
            ? "Yes"
            : "No",
        complexity: exercise.difficulty,
        plans: findExercise({
          exerciseId: exercise.id,
          protocols: allProtocols,
        }).length,
        isCustom: exercise.isCustom ? "Yes" : "No",
        isDeleted: exercise.isDeleted ? "Yes" : "No",
        mainMuscleGroups: exercise.mainMuscleGroups
          ? exercise.mainMuscleGroups
              .map((id) => muscleGroupsKey[id])
              .join(", ")
          : "",
        secondaryMuscleGroups: exercise.secondaryMuscleGroups
          ? exercise.secondaryMuscleGroups
              .map((id) => muscleGroupsKey[id])
              .join(", ")
          : "",
        equipment: exercise.equipment
          ? exercise.equipment.map((id) => equipmentKey[id]).join(", ")
          : "",
        videos: exercise.videos && exercise.videos.length > 0 ? "Yes" : "No",
        images:
          (exercise.images &&
            exercise.images.length > 0 &&
            exercise.images?.[0] &&
            exercise.images?.[0].length > 0) ||
          (exercise.images?.[1] && exercise.images?.[1].length > 0)
            ? "Yes"
            : "No",
      };
    });
  }, [exercises, muscleGroupsKey, equipmentKey, allProtocols]);

  return (
    <Box sx={{ flexGrow: 1, marginTop: 2, marginBottom: 0, width: "100%" }}>
      <DataGrid
        sx={{
          ".MuiDataGrid-sortIcon": {
            color: theme.palette.primary.main,
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          ".MuiDataGrid-filterIcon": {
            color: theme.palette.primary.main,
          },
          ".MuiDataGrid-menuIconButton": {
            color: theme.palette.primary.main,
          },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
          height: "100%",
        }}
        columnVisibilityModel={visibleModel}
        disableSelectionOnClick
        rows={rows}
        columns={columns}
        getRowHeight={() => {
          return "auto";
        }}
        onRowClick={(params) => {
          onClick(params.row.exercise);
        }}
        onColumnVisibilityModelChange={(model) => {
          localStorage.setItem(
            "exerciseDataGridColumns",
            JSON.stringify(model),
          );
          setVisibilityModel(model);
        }}
      />
      {csvData && (
        <Grid container justifyContent="center" marginTop={2}>
          <Box>
            <CSVLink {...csvData}>Export to CSV</CSVLink>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default ExerciseDataGrid;
