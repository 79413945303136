import { Typography, Box, Button, Grid, Stack } from "@mui/material";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTheme } from "@mui/material/styles";

import { DataGrid } from "@mui/x-data-grid";

import useQuestions from "../../../../hooks/useQuestions";

import { EditButton, DeleteButton } from "../../../../atoms/EditButton";
import QuestionEditDialog from "./QuestionEditDialog";
import { DataTypeString } from "../../../../data/GlobalData";
import ReorderDialog from "./ReorderDialog";

const editQuestions = ({ type }) => {
  const {
    questions,
    saveQuestions,
    deleteQuestion,
    moveQuestion,
    addQuestion,
  } = useQuestions(type);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [questionReorderDialogOpen, setQuestionReorderDialogOpen] =
    React.useState(false);
  const theme = useTheme();

  const selectedQuestion = React.useRef();

  const titleStrings = React.useMemo(() => {
    let button = "unknown";
    let title = "unknown";
    let editTitle = "unknown";
    let addTitle = "unknown";
    let reorderTitle = "unknown";
    let description = "unknown";

    switch (type) {
      case "weekly":
        button = "Add Report Question";
        title = "Report Questions";
        addTitle = "Add Report Question";
        editTitle = "Edit Report Question";
        reorderTitle = "Reorder Report Questions";
        description =
          "Report questions are asked when your client submits a report to the coach.";
        break;
      case "daily":
        button = "Add Daily Question";
        title = "Daily Report Questions";
        addTitle = "Add Daily Question";
        editTitle = "Edit Daily Question";
        reorderTitle = "Reorder Daily Questions";
        description =
          "Daily questions are a part of the daily report submitted by your client using the Reports tab in the Mighty45 app.";
        break;
      default:
        button = "unknown";
        title = "unknown";
        description = "unknown";
        addTitle = "unknown";
        editTitle = "unknown";
        reorderTitle = "unknown";
        break;
    }
    return { button, title, description, editTitle, addTitle, reorderTitle };
  }, [type]);

  function getTierString(data) {
    let tiers = "";
    if (data.tier1 && data.tier2 && data.tier3) {
      return "All tiers";
    }

    if (data.tier1 || data.tier1 === undefined) {
      tiers = tiers.concat("Tier 1 ");
    }
    if (data.tier2 || data.tier2 === undefined) {
      tiers = tiers.concat("Tier 2 ");
    }
    if (data.tier3 || data.tier3 === undefined) {
      tiers = tiers.concat("Tier 3 ");
    }
    return tiers.trim();
  }

  const columns = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        headerAlign: "left",
        align: "left",
        flex: 1,
        width: 150,
        type: "string",
        hideable: false,
        sortable: false,
        filterable: false,
      },
      {
        field: "type",
        headerName: "Type",
        width: 150,
        sortable: false,
        valueGetter: (params) => {
          return DataTypeString[params.row.type];
        },
      },
      {
        field: "subtitle",
        headerName: "Subtitle",
        width: 150,
        sortable: false,
        valueGetter: (params) => {
          return params.row.subTitle;
        },
      },
      {
        field: "minimum",
        headerName: "Minimum",
        width: 150,
        sortable: false,
        valueGetter: (params) => {
          if (!params.row.minValue) {
            return "-";
          } else {
            return params.row.minValue;
          }
        },
      },
      {
        field: "maximum",
        headerName: "Maximum",
        width: 150,
        sortable: false,
        valueGetter: (params) => {
          if (!params.row.maxValue) {
            return "-";
          } else {
            return params.row.maxValue;
          }
        },
      },
      {
        field: "tiers",
        headerName: "Tiers",
        width: 150,
        sortable: false,
        valueGetter: (params) => {
          return getTierString(params.row);
        },
      },
      {
        field: "required",
        headerName: "Required?",
        width: 150,
        sortable: false,
        type: "singleSelect",
        valueOptions: [true, false],
        valueGetter: (params) => {
          return params.row.isRequired;
        },
      },
      {
        field: "icons",
        headerName: "",
        align: "right",
        // fit width properly
        width: 120,
        hideable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {/* <ArrowUpButton
                onClick={() => {
                  moveQuestion(params.row.id, "up");
                }}
              />
              <ArrowDownButton
                onClick={() => {
                  moveQuestion(params.row.id, "down");
                }}
              /> */}
              <EditButton
                onClick={() => {
                  selectedQuestion.current = params.row;
                  setDialogOpen(true);
                }}
              />
              <DeleteButton
                onClick={() => {
                  confirmAlert({
                    title: `Delete this question?`,
                    message:
                      "This cannot be undone. The question will be removed everywhere it is being used.",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: async () => {
                          deleteQuestion(params.row.id);
                        },
                      },
                      {
                        label: "No",
                        onClick: () => {},
                      },
                    ],
                  });
                }}
              />
            </Box>
          );
        },
      },
    ];
  }, [moveQuestion, saveQuestions, deleteQuestion]);

  if (questions) {
    return (
      <Box style={{ marginBottom: 54 }}>
        <Grid container style={{ marginTop: 4 }}>
          <Grid item xs={8}>
            <Typography
              style={{ fontSize: 20, fontWeight: "500", marginBottom: 12 }}
            >
              {titleStrings.title}
            </Typography>
            <Typography
              style={{ fontSize: 16, fontWeight: "normal", marginBottom: 12 }}
            >
              {titleStrings.description}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Stack direction={"row"} spacing={3} justifyContent={"flex-end"}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"flex-end"}
              >
                <Button
                  style={{ marginTop: 12 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    selectedQuestion.current = null;
                    setQuestionReorderDialogOpen(true);
                  }}
                >
                  Reorder Questions
                </Button>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"flex-end"}
              >
                <Button
                  style={{ marginTop: 12 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    selectedQuestion.current = null;
                    setDialogOpen(true);
                  }}
                >
                  {titleStrings.button}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Box style={{ margin: 12 }}>
          <Box sx={{ marginTop: 1, height: window.innerHeight - 420 }}>
            <DataGrid
              sx={{
                ".MuiDataGrid-sortIcon": {
                  color: theme.palette.primary.main,
                },
                ".MuiDataGrid-filterIcon": {
                  color: theme.palette.primary.main,
                },
                ".MuiDataGrid-menuIconButton": {
                  color: theme.palette.primary.main,
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
              disableSelectionOnClick
              rows={questions}
              columns={columns}
              getRowHeight={() => {
                return "auto";
              }}
            />
          </Box>
        </Box>

        {dialogOpen && (
          <QuestionEditDialog
            open={dialogOpen}
            titleStrings={titleStrings}
            question={selectedQuestion.current}
            questionType={type}
            onDialogClosed={async (data, question) => {
              if (!data && !question) {
                setDialogOpen(false);
                return;
              }
              setDialogOpen(false);

              if (data && question) {
                question.name = data.name;
                question.subTitle = data.subTitle;
                question.minValue = data.minValue;
                question.maxValue = data.maxValue;
                question.isRequired = data.isRequired;
                question.tier1 = data.tier1;
                question.tier2 = data.tier2;
                question.tier3 = data.tier3;
                saveQuestions(questions);
              } else {
                addQuestion(
                  data.name,
                  data.subTitle,
                  data.minValue,
                  data.maxValue,
                  data.isRequired,
                  data.tier1,
                  data.tier2,
                  data.tier3,
                );
              }
            }}
          />
        )}
        {questionReorderDialogOpen && (
          <ReorderDialog
            open={questionReorderDialogOpen}
            reorderTitle={titleStrings.reorderTitle}
            list={questions}
            onDialogClosed={async (data) => {
              if (!data) {
                setQuestionReorderDialogOpen(false);
                return;
              }
              setQuestionReorderDialogOpen(false);
              if (data) {
                saveQuestions(data);
              }
            }}
            // onDialogClosed={(questions, list) => {
            //   if (list) {
            //     ProtocolData.setSplitOrder(protocolId, list);
            //   }
            //   setQuestionReorderDialogOpen(false);
            // }}
          />
        )}
      </Box>
    );
  }
};
export default editQuestions;
