import { createTheme } from "@mui/material";
import colors from "./Colors";

const mainTheme = createTheme({
  palette: {
    primary: {
      main: colors.primaryRed,
    },
    secondary: {
      main: colors.primaryBlue,
    },
    primaryTransparent: {
      main: colors.secondaryRed,
    },
    editButton: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: ["Lato"].join(","),
    h3: {
      color: colors.mainTitleColor,
      fontSize: 30,
      fontWeight: "bold",
    },
    h4: {
      fontSize: 25,
      fontWeight: "bold",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiDialogTitle: {
      defaultProps: {
        textAlign: "left",
        fontSize: 30,
        fontWeight: "bold",
      },
    },
    MuiList: {
      defaultProps: {
        padding_top: 0,
      },
    },
  },
  overrides: {
    MuiList: {
      padding: {
        paddingTop: 0,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
    },
    MuiTableCell: {
      root: {
        padding: 6,
        fontSize: 14,
      },
      body: {
        padding: 6,
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
    MuiDialogActions: {
      root: {
        marginTop: 20,
        marginBottom: 20,
        marginRight: 20,
      },
    },
    MuiDialogTitle: {
      root: {
        "& h2": {
          textAlign: "left",
          fontSize: 30,
          fontWeight: "bold",
        },
      },
    },
    MuiTypography: {
      root: {
        "& a:-webkit-any-link": {
          color: colors.mainTitleColor,
        },
      },
    },
  },
});

export default mainTheme;
