import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

import {
  Container,
  Box,
  Typography,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import { useRecoilValue } from "recoil";
import _ from "underscore";
import GlobalData from "../../../data/GlobalData";
import useProtocols from "data/hooks/useProtocols";
import MuscleGroupButtonGroup from "./exercises/MuscleGroupButtonGroup";
import ExerciseDetails from "./exercises/ExerciseDetails";
import ExerciseDataGrid from "./exercises/ExerciseDataGrid";
import ExerciseDialog from "./exercises/ExerciseDialog";
import { consola } from "consola";

import organizationIdSelector from "../../../atoms/organizationIdSelector";
import useEvents from "../../../hooks/useEvents";

const ExercisesPage = (props) => {
  const organizationId = useRecoilValue(organizationIdSelector);
  const { getAllProtocols } = useProtocols();

  const confirm = useConfirm();
  const events = useEvents();

  const navigate = useNavigate();
  const subscription = React.useRef(null);
  const customSubscription = React.useRef(null);

  const [mode, setMode] = React.useState("list");
  const [muscleGroups, setMuscleGroups] = React.useState([]);
  const [search, setSearch] = React.useState("");

  const storedShowCustom = localStorage.getItem("showCustomOnly") === "true";
  const storedShowDeleted = localStorage.getItem("showDeleted") === "true";

  const [showCustomOnly, setShowCustomOnly] = React.useState(
    storedShowCustom || false,
  );
  const [showDeleted, setShowDeleted] = React.useState(
    storedShowDeleted || false,
  );

  const [muscleGroupsKey, setMuscleGroupsKey] = React.useState({});
  const [equipmentKey, setEquipmentKey] = React.useState({});
  const [difficultyKey, setDifficultyKey] = React.useState({});
  const [exercises, setExercises] = React.useState([]);
  const [filter, setFilter] = React.useState("all");
  const [selected, setSelected] = React.useState(undefined);
  const [update, setUpdate] = React.useState(new Date());

  const [allProtocols, setAllProtocols] = React.useState([]);

  const [exerciseDialogOpen, setExerciseDialogOpen] = React.useState(false);
  const selectedExercise = React.useRef(null);

  const textInputRef = React.useRef(null);
  const searchTimeout = React.useRef();
  const currentFilter = React.useRef("all");

  React.useEffect(() => {
    return () => {
      if (navigate.action === "POP") {
        navigate("/home");
      }
    };
  }, [navigate]);

  React.useEffect(() => {
    firebase.analytics().logEvent("page_view", {
      page_title: "Exercises Page",
      page_location: "/exercises",
    });
  }, []);

  React.useEffect(() => {
    getAllProtocols({ organizationId }).then((protocols) => {
      setAllProtocols(protocols);
    });
  }, []);

  React.useEffect(() => {
    async function getData() {
      // get the reports
      GlobalData.getMuscleGroups().then((muscleGroups) => {
        setMuscleGroups(muscleGroups);

        const groups = {};
        muscleGroups.forEach((g) => {
          groups[g.data().id] = g.data().name;
        });
        setMuscleGroupsKey(groups);
      });

      GlobalData.getEquipmentKey().then((equipmentKey) => {
        setEquipmentKey(equipmentKey);
      });
      GlobalData.getDifficultyKey().then((difficultyKey) => {
        setDifficultyKey(difficultyKey);
      });
    }
    getData();
  }, [props.update]);

  React.useEffect(() => {
    if (subscription.current) {
      subscription.current();
    }
    if (search && search.length > 0) {
      currentFilter.current = filter;
      setFilter(null);
    } else {
      if (currentFilter.current) {
        setFilter(currentFilter.current);
      }
    }
    subscription.current = GlobalData.getExercisesSubscription({
      filter,
      showDeleted,
      onChange: (snapshot) => {
        let docs = snapshot;
        if (search && search.length > 0) {
          docs = _.filter(docs, (d) => {
            const name = d.name.toLowerCase();
            return name.includes(search.toLowerCase());
          });
        }
        if (showCustomOnly) {
          docs = _.filter(docs, (d) => {
            return d.isCustom;
          });
        }
        if (filter && filter.length > 0) {
          docs = _.filter(docs, (d) => {
            return d.mainMuscleGroups.includes(filter);
          });
        }
        setExercises(docs);
      },
    });

    localStorage.setItem("showCustomOnly", showCustomOnly);
    localStorage.setItem("showDeleted", showDeleted);

    return () => {
      if (subscription.current) {
        subscription.current();
      }
    };
  }, [filter, update, search, showCustomOnly, showDeleted]);

  React.useEffect(() => {
    GlobalData.getCustomExercisesSubscription(
      () => {
        setUpdate(new Date());
      },
      (subscription) => {
        customSubscription.current = subscription;
      },
    );
    return () => {
      if (customSubscription.current) {
        customSubscription.current();
      }
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (mode === "list") {
    return (
      <Container
        maxWidth={false}
        sx={{
          marginTop: "20px",
          display: "flex",
          height: "95%",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5">Exercises</Typography>
        <Divider style={{ marginTop: 20, marginBottom: 20 }}></Divider>

        <Grid container alignItems="center" spacing={8}>
          <Grid
            item
            style={{
              justifyContent: "flex-end",
              marginBottom: 12,
              marginTop: 12,
            }}
          >
            <Box>
              <TextField
                inputRef={textInputRef}
                placeholder="Search by exercise name"
                variant="outlined"
                style={{ width: 500 }}
                onChange={(event) => {
                  const value = event.target.value;
                  if (searchTimeout.current) {
                    clearTimeout(searchTimeout.current);
                  }
                  searchTimeout.current = setTimeout(() => {
                    setSearch(value);
                    events.logTextInput({
                      pageName: "Exercises",
                      textboxName: "Search Exercises",
                      text: value,
                    });
                  }, 500);
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showCustomOnly}
                  onChange={() => {
                    events.logButtonClick({
                      buttonName: "Show Customized Only",
                      pageName: "Exercises",
                    });
                    setShowCustomOnly(!showCustomOnly);
                  }}
                  name="showCustomOnly"
                />
              }
              label="Show Customized Only"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDeleted}
                  onChange={() => {
                    setShowDeleted(!showDeleted);
                    events.logButtonClick({
                      buttonName: "Show Archived Exercises",
                      pageName: "Exercises",
                    });
                  }}
                  name="showArchived"
                />
              }
              label="Show Archived Exercises"
            />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              onClick={() => {
                setSearch("");
                textInputRef.current.value = "";
                setShowCustomOnly(false);
                setShowDeleted(false);

                localStorage.removeItem("showCustomOnly");
                localStorage.removeItem("showDeleted");
                events.logButtonClick({
                  buttonName: "Clear Search",
                  pageName: "Exercises",
                });
              }}
            >
              Clear search
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                selectedExercise.current = null;
                setExerciseDialogOpen(true);
                events.logButtonClick({
                  buttonName: "Add Exercise",
                  pageName: "Exercises",
                });
              }}
            >
              Add Exercise
            </Button>
          </Grid>
        </Grid>

        <MuscleGroupButtonGroup
          muscleGroups={muscleGroups}
          filter={filter}
          onClick={(id) => {
            if (id === "all") {
              currentFilter.current = "all";
              setFilter("all");
              events.logButtonClick({
                buttonName: "Removed Muscle Group Filter",
                pageName: "Exercises",
              });
            } else {
              currentFilter.current = id;
              setFilter(id);
              events.logButtonClick({
                buttonName: "Filtered Muscle Group",
                pageName: "Exercises",
              });
            }
          }}
        />

        <ExerciseDataGrid
          exercises={exercises}
          muscleGroupsKey={muscleGroupsKey}
          equipmentKey={equipmentKey}
          allProtocols={allProtocols}
          onClick={(e) => {
            setSelected(e);
            window.scrollTo(0, 0);
            setMode("detail");

            // const found = findExercise({
            //   exerciseId: e.id,
            //   protocols: allProtocols,
            // });
          }}
          onEdit={(e) => {
            // edit the exercise
            selectedExercise.current = e;
            setExerciseDialogOpen(true);
            events.logButtonClick({
              buttonName: "Edit Exercise",
              pageName: "Exercises",
            });
          }}
          onRestore={(e) => {
            // restore the exercise
            confirm({
              title: `Restore ${e.name}?`,
              description:
                "This will restore the exercise and make it available for workouts. Continue?",
            }).then(async () => {
              GlobalData.restoreCustomExercise(e);
              events.logButtonClick({
                buttonName: "Restore Exercise",
                pageName: "Exercises",
              });
            });
          }}
          onDelete={async (e) => {
            // delete the exercise
            if (e.plans > 0) {
              confirm({
                cancellationText: "",
                title: "Cannot delete the exercise.",
                description:
                  "This exercise is used in training plans. Please remove it from the plans before deleting.",
              });
              events.logButtonClick({
                buttonName: "Attempted to Delete Exercise",
                pageName: "Exercises",
              });
              return;
            }

            const checked = await GlobalData.checkIfCustomHasGlobal(e.id);

            if (checked) {
              confirm({
                title: `Delete ${e.name}?`,
                description:
                  "Deleting this custom exercise cannot be undone. It will revert to the original configuration. Continue?",
              })
                .then(async () => {
                  await GlobalData.deleteCustomExercise(e);
                  events.logButtonClick({
                    buttonName: "Delete Exercise",
                    pageName: "Exercises",
                  });
                })
                .catch(() => {
                  consola.log("cancel");
                });
            } else {
              confirm({
                title: `Delete ${e.name}?`,
                description:
                  "Deleting this custom exercise cannot be undone. There is no default for this exercise. Continue?",
              })
                .then(async () => {
                  await GlobalData.deleteCustomExercise(e);
                  events.logButtonClick({
                    buttonName: "Delete Exercise",
                    pageName: "Exercises",
                  });
                })
                .catch(() => {
                  consola.log("cancel");
                });
            }
          }}
        />

        {exerciseDialogOpen && (
          <ExerciseDialog
            exercise={selectedExercise.current}
            open={exerciseDialogOpen}
            onDialogClosed={(exercise) => {
              if (exercise) {
                GlobalData.updateCustomExercise({ exercise: exercise });
              }
              setExerciseDialogOpen(false);
            }}
          />
        )}
      </Container>
    );
  } else {
    return (
      <ExerciseDetails
        exercise={selected}
        muscleGroupsKey={muscleGroupsKey}
        equipmentKey={equipmentKey}
        difficultyKey={difficultyKey}
        allProtocols={allProtocols}
        onBackClick={() => {
          setSelected("undefined");
          window.scrollTo(0, 0);
          setMode("list");
          events.logButtonClick({
            buttonName: "Back Click",
            pageName: "Exercises",
            tabName: selected.id,
          });
        }}
      />
    );
  }
};

export default ExercisesPage;
