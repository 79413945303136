import {
  Typography,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import useEvents from "../../../../hooks/useEvents";

const EditOrganization = ({ userData, orgData, saveOrgData }) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [welcomeMessage, setWelcomeMessage] = React.useState("");
  const [unitSystem, setUnitSystem] = React.useState("imperial");
  const [unsaved, setUnsaved] = React.useState(false);
  const events = useEvents();

  React.useEffect(() => {
    if (orgData) {
      setName(orgData.name);
      setDescription(orgData.description);
      setUnitSystem(orgData.unitSystem ? orgData.unitSystem : "imperial");
      setWelcomeMessage(orgData.welcomeMessage ? orgData.welcomeMessage : "");
    }
  }, [orgData, userData]);

  return (
    <>
      <Box>
        <Typography
          style={{ fontSize: 20, fontWeight: "500", marginBottom: 12 }}
        >
          Organization Information
        </Typography>
        <Typography
          style={{ fontSize: 12, fontWeight: "normal", marginBottom: 12 }}
        >
          {orgData ? orgData.id : ""}
        </Typography>
        <Typography>Organization Name</Typography>
        <TextField
          style={{ width: 350 }}
          fullWidth={false}
          variant="outlined"
          value={name}
          onChange={(event) => {
            let value = event.target.value;
            if (value.length > 40) {
              value = value.substring(0, 40);
            }
            setName(value);
            setUnsaved(true);
            const editOrgNameEvent = {
              pageName: "Admin",
              textboxName: "Organization Name",
              text: value,
            };
            events.logTextInput(editOrgNameEvent);
          }}
        />
        <Typography style={{ marginTop: 12 }}>Organization Slogan</Typography>
        <TextField
          style={{ width: 400 }}
          fullWidth={false}
          variant="outlined"
          value={description}
          onChange={(event) => {
            let value = event.target.value;
            if (value.length > 50) {
              value = value.substring(0, 50);
            }
            const editOrgNameEvent = {
              pageName: "Admin",
              textboxName: "Organization Slogan",
              text: value,
            };
            events.logTextInput(editOrgNameEvent);
            setDescription(value);
            setUnsaved(true);
          }}
        />

        <Typography style={{ marginTop: 12 }}>
          Organization Welcome Chat Message
        </Typography>
        <Typography style={{ marginTop: 0, fontSize: 14, marginBottom: 4 }}>
          This message appears in a new users chat group when they are added to
          your organization.
        </Typography>
        <TextField
          multiline
          maxRows={6}
          minRows={6}
          fullWidth={true}
          variant="outlined"
          value={welcomeMessage}
          onChange={(event) => {
            setWelcomeMessage(event.target.value);
            setUnsaved(true);
            const editOrgNameEvent = {
              pageName: "Admin",
              textboxName: "Organization Slogan",
              text: event.target.value,
            };
            events.logTextInput(editOrgNameEvent);
          }}
        />

        <Typography style={{ marginTop: 12 }}>Measurement Units</Typography>
        <Select
          style={{ width: 300 }}
          variant="outlined"
          value={unitSystem}
          onChange={(event) => {
            setUnitSystem(event.target.value);
            setUnsaved(true);
            const editOrgNameEvent = {
              pageName: "Admin",
              textboxName: "Measurement Units",
              text: event.target.value,
            };
            events.logTextInput(editOrgNameEvent);
          }}
        >
          <MenuItem value="imperial">Imperial</MenuItem>
          <MenuItem value="metric">Metric</MenuItem>
        </Select>

        <Typography style={{ color: "red", marginTop: 8, height: 24 }}>
          {unsaved ? `There are unsaved changes!` : " "}
        </Typography>

        <Button
          style={{ marginTop: 12 }}
          variant="outlined"
          onClick={() => {
            if (saveOrgData) {
              saveOrgData({
                name: name,
                description: description,
                welcomeMessage: welcomeMessage,
                unitSystem: unitSystem,
              });
              setUnsaved(false);
              const saveOrgEvent = {
                buttonName: "Save Organization Changes",
                pageName: "Admin",
                tabName: "General",
              };
              events.logButtonClick(saveOrgEvent);
            }
          }}
        >
          Save Changes
        </Button>
      </Box>
    </>
  );
};

export default EditOrganization;
