import React from "react";
import { useTranslation } from "react-i18next";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { useRecoilValue, useRecoilState } from "recoil";
import CreatableSelect from "react-select/creatable";

import {
  DeleteButton,
  ArrowDownButton,
  ArrowUpButton,
} from "../../../../atoms/EditButton";
import GlobalData from "../../../../data/GlobalData";

import exerciseDBState from "../../../../atoms/exerciseDBAtom";
import exerciseDBKeysSelector from "../../../../atoms/exerciseDBkeysSelector";

const SplitExerciseRow = ({
  exerciseData,
  titlePadding,
  showDelete = false,
  onChange,
  handleDeleteExercise,
  onMoveUp,
  onMoveDown,
}) => {
  const { t } = useTranslation();

  const [exercisesDBState, setExerciseDBState] =
    useRecoilState(exerciseDBState);
  const exercisesDBKeys = useRecoilValue(exerciseDBKeysSelector);

  const [exercises, setExercises] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [exerciseDataState, setExerciseDataState] =
    React.useState(exerciseData);

  React.useEffect(() => {
    function getData() {
      let exercises = exercisesDBState.map((exercise) => {
        if (exercise.isDeleted) {
          return null;
        }
        const value = {
          value: exercise.id,
          label: exercise.name,
        };
        return value;
      });
      // filter out nulls
      exercises = exercises.filter((exercise) => exercise !== null);

      setExercises(exercises);
      setIsLoading(false);
    }
    getData();
  }, [exercisesDBState]); // eslint-disable-line

  const value = React.useMemo(() => {
    if (exercisesDBKeys[exerciseDataState.exercise]) {
      return {
        value: exercisesDBKeys[exerciseDataState.exercise].id,
        label: exercisesDBKeys[exerciseDataState.exercise].name,
      };
    }
    return {
      value: null,
      label: "Click to choose exercise",
    };
  }, [exerciseDataState, exercisesDBKeys]);

  const customStyle = {
    control: (provided) => ({
      ...provided,
      minHeight: 55,
      width: 350,
    }),
  };

  return (
    <>
      <Stack spacing={1}>
        <Stack direction={"row"} spacing={1}>
          <Stack>
            <Typography style={{ width: titlePadding }}>Exercise</Typography>
            <CreatableSelect
              isLoading={isLoading}
              defaultValue={value}
              value={value}
              maxMenuHeight={100}
              styles={customStyle}
              options={exercises}
              onChange={(event) => {
                const state = {
                  ...exerciseDataState,
                  exercise: event.value,
                };
                setExerciseDataState(state);
                onChange(state);
              }}
              onCreateOption={async (inputValue) => {
                setExerciseDataState({
                  ...exerciseDataState,
                  exercise: null,
                });
                const newExercise = await GlobalData.createExerciseWithNameOnly(
                  {
                    name: inputValue,
                  },
                );
                const exercises = await GlobalData.getExercises();
                setExerciseDBState(exercises);

                const state = {
                  ...exerciseDataState,
                  exercise: newExercise.id,
                };

                setExerciseDataState(state);
                onChange(state);
              }}
            />
          </Stack>
          <Stack>
            <Typography style={{ width: 50 }}>Reps</Typography>
            <TextField
              value={exerciseDataState.reps}
              style={{ width: 120, marginRight: 4 }}
              variant="outlined"
              onChange={(event) => {
                let state = {
                  ...exerciseDataState,
                  reps: "",
                };

                if (event.target.value.length === 0) {
                  setExerciseDataState(state);
                  onChange(state);
                  return;
                }
                const reps = event.target.value;
                state = {
                  ...exerciseDataState,
                  reps: reps,
                };
                setExerciseDataState(state);
                onChange(state);
              }}
            />
          </Stack>

          <Stack>
            <Typography style={{ marginLeft: 4, marginRight: 4 }}>
              {`${t("exercise_columns.tempo")}`}
            </Typography>
            <TextField
              value={exerciseDataState.tempo}
              style={{ width: 120 }}
              variant="outlined"
              onChange={(event) => {
                let state = {
                  ...exerciseDataState,
                  tempo: "",
                };
                if (event.target.value.length === 0) {
                  setExerciseDataState(state);
                  onChange(state);
                  return;
                }
                state = {
                  ...exerciseDataState,
                  tempo: event.target.value,
                };
                setExerciseDataState(state);
                onChange(state);
              }}
            />
          </Stack>
          <Stack>
            <Typography style={{ marginLeft: 4, marginRight: 4 }}>
              Rest
            </Typography>
            <TextField
              value={exerciseDataState.rest}
              style={{ width: 80 }}
              variant="outlined"
              onChange={(event) => {
                let state = {
                  ...exerciseDataState,
                  rest: "",
                };
                if (event.target.value.length === 0) {
                  setExerciseDataState(state);
                  onChange(state);
                  return;
                }
                state = {
                  ...exerciseDataState,
                  rest: event.target.value,
                };
                setExerciseDataState(state);
                onChange(state);
              }}
            />
          </Stack>

          {showDelete && (
            <Stack direction="row">
              <ArrowUpButton onClick={onMoveUp} />
              <ArrowDownButton onClick={onMoveDown} />
              <DeleteButton
                onClick={() => {
                  handleDeleteExercise(exerciseData);
                }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default SplitExerciseRow;
