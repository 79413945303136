import React from "react";
import { Box, Grid, Typography, Stack } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { nanoid } from "nanoid";
import moment from "moment";
import BlueBorderButton from "../BlueBorderButton";

import CardioGoalDialog from "./CardioGoalDialog";
import CardioGoalRow from "./CardioGoalRow";
import { ago } from "../../data/GlobalData";
import { DropDownButton } from "../../atoms/EditButton";

import RecentHistory from "../molecules/RecentHistory";
import useEvents from "../../hooks/useEvents";
import CardioTitleRow from "../../pages/home/children/clients/CardioTitleRow";

const CardioGoals = ({ style, onActivate, onEdit, onDelete, client }) => {
  const confirm = useConfirm();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [historyOpen, setHistoryOpen] = React.useState(false);
  const events = useEvents();
  const selectedCardioGoal = React.useRef(null);
  const [showGoals, setShowGoals] = React.useState(true);

  function getCardioGoalChanged(client) {
    const { cardioGoalChanged } = client;

    if (cardioGoalChanged) {
      const date = cardioGoalChanged.toDate();
      const agoTime = ago(date);
      return `${moment(date).format("MM/DD/YYYY")} - ${agoTime}`;
    }
    return "";
  }
  function getHistoryString(historyOpen) {
    if (historyOpen) {
      return "Hide History";
    } else {
      return "Show History";
    }
  }

  return (
    <>
      <Box
        display="flex"
        boxShadow={3}
        borderRadius={2}
        borderBottom={showGoals ? 1 : 0}
        style={{ marginTop: 20, backgroundColor: "#F6F7FB" }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          paddingLeft={3}
        >
          <Grid item textAlign="center" style={{}}>
            <Box display={"inline-flex"} alignItems={"center"}>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M16 2.66663C8.62666 2.66663 2.66666 8.66663 2.66666 16C2.66666 19.5362 4.07141 22.9276 6.5719 25.428C7.81001 26.6662 9.27987 27.6483 10.8975 28.3184C12.5152 28.9884 14.249 29.3333 16 29.3333C19.5362 29.3333 22.9276 27.9285 25.4281 25.428C27.9286 22.9276 29.3333 19.5362 29.3333 16C29.3333 14.249 28.9884 12.5152 28.3184 10.8975C27.6483 9.27984 26.6662 7.80998 25.4281 6.57187C24.19 5.33375 22.7201 4.35163 21.1024 3.68157C19.4848 3.0115 17.7509 2.66663 16 2.66663ZM13 10.4266C14.16 10.4266 15.2667 10.9733 16 11.8266C16.7333 10.9733 17.84 10.4266 19 10.4266C21.0533 10.4266 22.6667 12.04 22.6667 14.0933C22.6667 16.6133 20.4 18.6666 16.96 21.7866L16 22.6666L15.04 21.7866C11.6 18.6666 9.33332 16.6133 9.33332 14.0933C9.33332 12.04 10.9467 10.4266 13 10.4266Z"
                    fill="black"
                  />
                </svg>
              </Box>
              <Typography
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "normal",
                  fontFamily: "Lato",
                  verticalAlign: "Center",
                  paddingLeft: 20,
                }}
              >
                Cardio Goals
              </Typography>
              <Typography
                flexShrink={0}
                style={{
                  fontFamily: "Lato",
                  fontSize: 12,
                  fontWeight: "500",
                  verticalAlign: "center",
                  paddingLeft: 60,
                  paddingTop: 10,
                }}
              >
                {`Last Changed: ${getCardioGoalChanged(client)}`}
              </Typography>
            </Box>
          </Grid>

          <Grid item textAlign="center" style={{}}>
            <DropDownButton
              onClick={() => {
                setShowGoals(!showGoals);
              }}
              show={showGoals}
            />
          </Grid>
        </Grid>
      </Box>
      {showGoals && (
        <Box display="flex">
          <Box
            boxShadow={3}
            borderRadius={2}
            style={{
              width: "100%",
              backgroundColor: "#F6F7FB",
              padding: 20,
              ...style,
            }}
          >
            <Box>
              <Box style={{ paddingInline: 75 }}>
                <CardioTitleRow></CardioTitleRow>
                {client.cardioGoals &&
                  client.cardioGoals.map((cardioGoal) => {
                    return (
                      <CardioGoalRow
                        key={cardioGoal.id}
                        cardioGoal={cardioGoal}
                        onActivate={(cardioGoal) => {
                          confirm({
                            title: "Set this goal active?",
                            description:
                              "You will be making this the active cardio goal. Continue?",
                          }).then(async () => {
                            if (onActivate) {
                              onActivate(cardioGoal);
                            }
                          });
                        }}
                        onEdit={(cardioGoal) => {
                          selectedCardioGoal.current = cardioGoal;
                          setDialogOpen(true);
                        }}
                        onDelete={(cardioGoal) => {
                          confirm({
                            title: "Delete the cardio goal?",
                            description:
                              "Deleting this cardio goal cannot be undone. Continue?",
                          }).then(async () => {
                            if (onDelete) {
                              onDelete(cardioGoal);
                            }
                          });
                        }}
                      />
                    );
                  })}
              </Box>
              {historyOpen && (
                <RecentHistory
                  client={client}
                  historyCollection={"cardioGoalHistory"}
                />
              )}

              <Stack
                style={{ marginTop: 12 }}
                direction={"row"}
                spacing={3}
                justifyContent={"center"}
              >
                <BlueBorderButton
                  style={{
                    paddingInline: 150,
                    marginTop: 12,
                    marginBottom: 12,
                  }}
                  onClick={() => {
                    selectedCardioGoal.current = {
                      id: nanoid(),
                      duration: "",
                      intensity: "",
                      hr: "",
                      sessions: "",
                      name: "New Goal",
                      active: false,
                    };
                    setDialogOpen(true);
                  }}
                >
                  Add Cardio Goal
                </BlueBorderButton>
                <BlueBorderButton
                  style={{
                    paddingInline: 150,
                    marginTop: 12,
                    marginBottom: 12,
                  }}
                  onClick={() => {
                    if (!historyOpen) {
                      const logEvent = {
                        buttonName: "Display Nutrition History",
                        pageName: "Clients",
                        tabName: client.uid,
                      };
                      events.logButtonClick(logEvent);
                    }
                    setHistoryOpen(!historyOpen);
                  }}
                >
                  {getHistoryString(historyOpen)}
                </BlueBorderButton>
              </Stack>
            </Box>
          </Box>
        </Box>
      )}
      <CardioGoalDialog
        open={dialogOpen}
        cardioGoal={selectedCardioGoal.current}
        onDialogClosed={(cardioGoal) => {
          setDialogOpen(false);

          if (cardioGoal) {
            if (onEdit) {
              onEdit(cardioGoal);
            }
          }
        }}
      />
    </>
  );
};

export default CardioGoals;
