import React, { useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import MDEditor from "@uiw/react-md-editor";

import CreatableSelect from "react-select/creatable";
import { nanoid } from "nanoid";
import BlueButton from "../../../../components/BlueButton";

import { DeleteButton } from "../../../../atoms/EditButton";

const MealPlanDialog = ({
  open,
  mealPlan,
  recipes,
  onClose,
  getRecipeById,
}) => {
  // const { t } = useTranslation();

  const [id, setId] = React.useState(mealPlan ? mealPlan.id : nanoid());
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [selectedRecipes, setSelectedRecipes] = React.useState([]);

  const selectRecipes = useMemo(() => {
    return recipes.map((recipe) => {
      return {
        value: recipe.id,
        label: recipe.name,
      };
    });
  }, [recipes]);

  React.useEffect(() => {
    if (!mealPlan) {
      setId(nanoid());
      setName("");
      setDescription("");
      setSelectedRecipes([]);
    } else {
      setId(mealPlan.id);
      setName(mealPlan.name);
      setDescription(mealPlan.description);
      setSelectedRecipes(mealPlan.selectedRecipes);
    }
  }, [mealPlan]); // eslint-disable-line

  const totalkCalsByRecipe = React.useMemo(() => {
    const total = selectedRecipes.reduce((acc, recipe) => {
      const recipeObj = getRecipeById(recipe.recipeId);
      if (recipeObj) {
        return acc + (recipeObj.kCals || 0) * (recipe.servingsPerDay || 1);
      }
      return acc;
    }, 0);
    return total;
  }, [selectedRecipes, getRecipeById]);

  const totalCarbsByRecipe = React.useMemo(() => {
    const total = selectedRecipes.reduce((acc, recipe) => {
      const recipeObj = getRecipeById(recipe.recipeId);
      if (recipeObj) {
        return acc + (recipeObj.carbs || 0) * (recipe.servingsPerDay || 1);
      }

      return acc;
    }, 0);
    return total;
  }, [selectedRecipes, getRecipeById]);

  const totalProteinsByRecipe = React.useMemo(() => {
    const total = selectedRecipes.reduce((acc, recipe) => {
      const recipeObj = getRecipeById(recipe.recipeId);
      if (recipeObj) {
        return acc + (recipeObj.proteins || 0) * (recipe.servingsPerDay || 1);
      }

      return acc;
    }, 0);
    return total;
  }, [selectedRecipes, getRecipeById]);

  const totalFatsByRecipe = React.useMemo(() => {
    const total = selectedRecipes.reduce((acc, recipe) => {
      const recipeObj = getRecipeById(recipe.recipeId);
      if (recipeObj) {
        return acc + (recipeObj.fats || 0) * (recipe.servingsPerDay || 1);
      }

      return acc;
    }, 0);
    return total;
  }, [selectedRecipes, getRecipeById]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
    >
      <DialogTitle>{"Meal Plan"}</DialogTitle>
      <DialogContent>
        <Box style={{ marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Name:</Typography>
          <TextField
            variant="outlined"
            style={{ width: "100%" }}
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </Box>

        <Box style={{ marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Add Description:</Typography>

          <div data-color-mode="light">
            <MDEditor
              preview="edit"
              placeholder="Enter healthy recipe description or video link here"
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>
        </Box>

        <Divider sx={{ mt: 2 }} />

        <Box>
          <Typography style={{ width: 200 }}>Add Recipes:</Typography>
          {selectedRecipes.map((recipe) => {
            if (!recipe.days) {
              recipe.days = [];
            }
            const recipeObj = getRecipeById(recipe.recipeId);
            const value = {
              label: recipeObj ? recipeObj.name : "",
              value: recipe.recipeId,
            };
            return (
              <Box
                key={recipe.id}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Box style={{ width: "80%", marginRight: 8 }}>
                  <CreatableSelect
                    options={selectRecipes}
                    value={value}
                    maxMenuHeight={100}
                    onChange={(event) => {
                      recipe.recipeId = event.value;
                      recipe.name = event.label;
                      recipe.days = [];
                      setSelectedRecipes([...selectedRecipes]);
                    }}
                  />
                </Box>

                <Grid container>
                  <Grid item xs={1}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography>All</Typography>
                      <Checkbox
                        checked={recipe.days.includes("all")}
                        onChange={() => {
                          if (recipe.days.includes("all")) {
                            recipe.days = [];
                          } else {
                            recipe.days = ["all"];
                          }
                          setSelectedRecipes([...selectedRecipes]);
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography>Mon</Typography>
                      <Checkbox
                        checked={recipe.days.includes("mon")}
                        onChange={() => {
                          // remove all

                          if (recipe.days.includes("mon")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "mon",
                            );
                          } else {
                            recipe.days.push("mon");
                          }

                          if (recipe.days.includes("all")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "all",
                            );
                          }
                          setSelectedRecipes([...selectedRecipes]);
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography>Tue</Typography>
                      <Checkbox
                        checked={recipe.days.includes("tue")}
                        onChange={() => {
                          if (recipe.days.includes("tue")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "tue",
                            );
                          } else {
                            recipe.days.push("tue");
                          }

                          if (recipe.days.includes("all")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "all",
                            );
                          }
                          setSelectedRecipes([...selectedRecipes]);
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography>Wed</Typography>
                      <Checkbox
                        checked={recipe.days.includes("wed")}
                        onChange={() => {
                          if (recipe.days.includes("wed")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "wed",
                            );
                          } else {
                            recipe.days.push("wed");
                          }

                          if (recipe.days.includes("all")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "all",
                            );
                          }
                          setSelectedRecipes([...selectedRecipes]);
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography>Thu</Typography>
                      <Checkbox
                        checked={recipe.days.includes("thu")}
                        onChange={() => {
                          if (recipe.days.includes("thu")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "thu",
                            );
                          } else {
                            recipe.days.push("thu");
                          }

                          if (recipe.days.includes("all")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "all",
                            );
                          }
                          setSelectedRecipes([...selectedRecipes]);
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography>Fri</Typography>
                      <Checkbox
                        checked={recipe.days.includes("fri")}
                        onChange={() => {
                          if (recipe.days.includes("fri")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "fri",
                            );
                          } else {
                            recipe.days.push("fri");
                          }

                          if (recipe.days.includes("all")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "all",
                            );
                          }
                          setSelectedRecipes([...selectedRecipes]);
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography>Sat</Typography>
                      <Checkbox
                        checked={recipe.days.includes("sat")}
                        onChange={() => {
                          if (recipe.days.includes("sat")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "sat",
                            );
                          } else {
                            recipe.days.push("sat");
                          }

                          if (recipe.days.includes("all")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "all",
                            );
                          }
                          setSelectedRecipes([...selectedRecipes]);
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography>Sun</Typography>
                      <Checkbox
                        checked={recipe.days.includes("sun")}
                        onChange={() => {
                          if (recipe.days.includes("sun")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "sun",
                            );
                          } else {
                            recipe.days.push("sun");
                          }

                          if (recipe.days.includes("all")) {
                            recipe.days = recipe.days.filter(
                              (day) => day !== "all",
                            );
                          }
                          setSelectedRecipes([...selectedRecipes]);
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>

                <Box style={{ width: 300, marginRight: 100 }}>
                  <Stack sx={{ alignItems: "flex-start" }}>
                    <Typography>Servings per Day</Typography>
                    <TextField
                      size="small"
                      value={recipe.servingsPerDay || ""}
                      variant="outlined"
                      placeholder="e.g. 2"
                      type={"number"}
                      onChange={(event) => {
                        // check if value is a number
                        if (isNaN(event.target.value)) {
                          return;
                        }
                        // set recipe servings per day
                        recipe.servingsPerDay = parseInt(event.target.value);
                        setSelectedRecipes([...selectedRecipes]);
                      }}
                    />
                  </Stack>
                </Box>

                <DeleteButton
                  onClick={() => {
                    setSelectedRecipes(
                      selectedRecipes.filter((item) => item.id !== recipe.id),
                    );
                  }}
                />
              </Box>
            );
          })}

          <Box
            style={{
              marginTop: 12,
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginRight: 20,
            }}
          >
            <Stack direction={"row"} spacing={3}>
              <Typography style={{ fontWeight: "bold" }}>
                Total Carbs: {totalCarbsByRecipe}
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                Total Proteins: {totalProteinsByRecipe}
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                Total Fats: {totalFatsByRecipe}
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                Total kCals: {totalkCalsByRecipe}
              </Typography>
            </Stack>
          </Box>

          <Box style={{ marginTop: 20 }}>
            <Button
              variant="outlined"
              onClick={() => {
                setSelectedRecipes([
                  ...selectedRecipes,
                  {
                    id: nanoid(),
                    recipeId: undefined,
                    days: [],
                    name: "",
                    servingsPerDay: 1,
                  },
                ]);
              }}
              color="primary"
            >
              Add Recipe
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (onClose) {
              onClose({
                id,
                name,
                description,
                selectedRecipes,
                totals: {
                  totalCarbs: totalCarbsByRecipe,
                  totalProteins: totalProteinsByRecipe,
                  totalFats: totalFatsByRecipe,
                  totalkCals: totalkCalsByRecipe,
                },
                isArchived: false,
                updatedOn: new Date(),
                createdOn: mealPlan ? mealPlan.createdOn : new Date(),
              });
            }
          }}
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default MealPlanDialog;
