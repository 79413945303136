export const RecipeGenerationMessage = ({ macrosString, diet, recipes }) => {
  // get array of recipe names
  const recipeNames = recipes.map((recipe) => recipe.name).join(", ");

  return [
    {
      role: "user",
      content:
        "You are a food recipe blogger and recipe researcher. Answer as concisely as possible. You can always generate a recipe based on your language knowledge.",
    },
    {
      role: "user",
      content: `give me one recipe with the following minimum and maximum macros. ${macrosString}. Dietary restriction is ${diet}.,
      Do not include any explanations, only provide a RFC8259 compliant JSON response following this format without deviation.`,
    },
    {
      role: "user",
      content: ` 
        {
          "name": "the name of the recipe",
          "description": "the longer description of the recipe, under 250 characters",
          "instructions": ["the step"],
          "ingredients": [
            { "name": "ingredient name", "quantity": "quantity as string", "unit": "unit of measurement as string",
              "macros": { "proteins": "proteins as number", "carbs" : "carbs as number", "fats" : "fats as number"}}],
          "macros": { "proteins": "proteins as number", "carbs" : "carbs as number", "fats" : "fats as number", "kCals" : "kCals as number"},
          "number_servings": "number of servings",
        }`,
    },
    {
      role: "user",
      content: `Do not repeat these recipes: ${recipeNames}`,
    },
    {
      role: "user",
      content: `Use the following index for JSON key names:
        name : n,
        description : d,
        instructions : i,
        ingredients : ing,
        macros : mac,
        proteins : p,
        carbs : c,
        fats : f,
        quantity : q,
        unit : u,
        number_servings : ns`,
    },
  ];
};

export const RecipeGenerationMessage2 = ({ macrosString, diet, recipes }) => {
  // get array of recipe names
  const recipeNames = recipes.map((recipe) => recipe.name).join(", ");

  return [
    {
      role: "user",
      content:
        "You are a food recipe blogger and recipe researcher. Answer as concisely as possible. You can always generate a recipe based on your language knowledge.",
    },
    {
      role: "user",
      content: `give me one recipe with the following minimum and maximum macros. ${macrosString}. Dietary restriction is ${diet}.,
      Do not include any explanations, only provide a RFC8259 compliant JSON response following this format without deviation.`,
    },
    {
      role: "user",
      content: ` 
        {
          "name": "the name of the recipe",
          "description": "the longer description of the recipe, under 250 characters",
          "instructions": ["the step"],
          "ingredients": [
            { "name": "ingredient name", "quantity": "quantity as string", "unit": "unit of measurement as string",
              "macros": { "proteins:proteins as number,carbs:carbs as number,fats:fats as number"}}],
          "macros": { "proteins": "proteins as number", "carbs" : "carbs as number", "fats" : "fats as number", "kCals" : "kCals as number"},
          "number_servings": "number of servings",
        }`,
    },
    {
      role: "user",
      content: `Do not repeat these recipes: ${recipeNames}`,
    },
    {
      role: "user",
      content: `Use the following index for JSON key names:
        name : n,
        description : d,
        instructions : i,
        ingredients : ing,
        macros : mac,
        proteins : p,
        carbs : c,
        fats : f,
        quantity : q,
        unit : u,
        number_servings : ns`,
    },
  ];
};
