import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

import loadable from "@loadable/component";

import { Box, Typography, Grid, Button, Divider } from "@mui/material";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import ProtocolData from "../../../data/ProtocolData";

import useLabels from "../../../hooks/useLabels";
import ProtocolFilters from "../../../components/organisms/ProtocolFilters";
import EmptyProtocols from "./protocols/EmptyProtocols";
import useEvents from "../../../hooks/useEvents";

const ProtocolDialog = loadable(() => import("./protocols/ProtocolDialog"));
const ProtocolGridList = loadable(() => import("./protocols/ProtocolGridList"));
const ProtocolDetails = loadable(() => import("./protocols/ProtocolDetails"));
const ProtocolTable = loadable(() => import("./protocols/ProtocolTable"));

const ViewMode = {
  list: "list",
  list_details: "list_details",
  details: "details",
  empty: "empty",
  loading: "loading",
};

const ProtocolsPage = () => {
  const navigate = useNavigate();
  const { labels } = useLabels();
  const events = useEvents();

  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [protocols, setProtocols] = React.useState([]);
  const [selectedProtocol, setSelectedProtocol] = React.useState(null);
  const [mode, setMode] = React.useState(ViewMode.loading);
  const [update, setUpdate] = React.useState(new Date());
  const selectedFilters = React.useRef();

  async function loadProtocols({ filters }) {
    setMode(ViewMode.loading);
    const protocolDocs = await ProtocolData.getProtocols({ filters });
    setProtocols(protocolDocs);
    if (protocolDocs.length === 0 && (!filters || filters.length === 0)) {
      setMode(ViewMode.empty);
    } else {
      setMode(ViewMode.list_details);
    }
  }

  React.useEffect(() => {
    return () => {
      if (navigate.action === "POP") {
        navigate("/home");
      }
    };
  }, [navigate]);

  React.useEffect(() => {
    firebase.analytics().logEvent("page_view", {
      page_title: "Protocol Page",
      page_location: "/protocols",
    });
  }, []);

  const getData = React.useCallback(async function () {
    let filters = undefined;
    if (selectedFilters.current) {
      filters = [];
      if (selectedFilters.current.protocolLabels.length > 0) {
        filters.push({
          field: "protocolLabels",
          operation: "array-contains-any",
          value: selectedFilters.current.protocolLabels,
        });
      }
    }
    await loadProtocols({ filters: filters });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getData();
  }, [update, getData]);

  function handleProtocolDuplicate(protocol) {
    confirmAlert({
      title: `Duplicate ${protocol.name}`,
      message: "Continue to duplicate this training plan?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await ProtocolData.duplicateProtocol({
              protocolId: protocol.id,
            });
            setUpdate(new Date());
            events.logButtonClick({
              buttonName: "Duplicate Protocol",
              pageName: "Protocols",
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  function handleProtocolDelete(protocol) {
    confirmAlert({
      title: `Delete ${protocol.name}`,
      message: "This cannot be undone. Continue with delete?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await ProtocolData.deleteProtocol(protocol.id);
            setUpdate(new Date());
            events.logButtonClick({
              buttonName: "Delete Protocol",
              pageName: "Protocols",
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  const onFilterChangeCallback = React.useCallback(
    (filters) => {
      selectedFilters.current = _.cloneDeep(filters);
      getData();
    },
    [getData],
  );

  const onFilterClearedCallback = React.useCallback(() => {
    selectedFilters.current = null;
    getData();
  }, [getData]);

  function DisplayMode({ mode }) {
    if (mode === ViewMode.loading) {
      return null;
    }

    if (mode === ViewMode.empty) {
      return (
        <EmptyProtocols
          onAddProtocol={() => {
            setEditDialogOpen(true);
          }}
        />
      );
    }

    if (mode === ViewMode.list || mode === ViewMode.list_details) {
      return (
        <>
          <Box>
            <Typography variant="h5">Training Plans</Typography>
            <Divider style={{ marginTop: 20, marginBottom: 20 }}></Divider>
            <Grid
              container
              spacing={3}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <ProtocolFilters
                  currentLabels={selectedFilters.current?.protocolLabels}
                  onFilterChange={onFilterChangeCallback}
                  onFilterCleared={onFilterClearedCallback}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEditDialogOpen(true);
                    events.logButtonClick({
                      buttonName: "Add Training Plan",
                      pageName: "Protocols",
                    });
                  }}
                >
                  Add Training Plan
                </Button>
              </Grid>
            </Grid>
          </Box>
          {mode === ViewMode.list && (
            <ProtocolGridList
              protocols={protocols}
              labels={labels}
              onProtocolClick={(protocol) => {
                setSelectedProtocol(protocol);
                setMode(ViewMode.details);
                events.logButtonClick({
                  buttonName: "Clicked Protocol",
                  pageName: "Protocols",
                  tabName: protocol,
                });
              }}
              onProtocolEdit={(protocol) => {
                //
                setSelectedProtocol(protocol);
                setEditDialogOpen(true);
                events.logButtonClick({
                  buttonName: "Edit Protocol",
                  pageName: "Protocols",
                });
              }}
              onProtocolDuplicate={(protocol) => {
                handleProtocolDuplicate(protocol);
              }}
              onProtocolDelete={(protocol) => {
                handleProtocolDelete(protocol);
              }}
            />
          )}
          {mode === ViewMode.list_details && (
            <ProtocolTable
              protocols={protocols}
              labels={labels}
              onProtocolClick={(protocol) => {
                setSelectedProtocol(protocol);
                setMode(ViewMode.details);
              }}
              onProtocolEdit={(protocol) => {
                //
                setSelectedProtocol(protocol);
                setEditDialogOpen(true);
                events.logButtonClick({
                  buttonName: "Edit Protocol",
                  pageName: "Protocols",
                });
              }}
              onProtocolDelete={(protocol) => {
                handleProtocolDelete(protocol);
              }}
              onProtocolDuplicate={(protocol) => {
                handleProtocolDuplicate(protocol);
              }}
            />
          )}
        </>
      );
    }

    if (mode === ViewMode.details) {
      return (
        <ProtocolDetails
          history={history}
          protocol={selectedProtocol}
          onBackClick={() => {
            // const storedGridMode = localStorage.getItem("protocolGridMode");
            events.logButtonClick({
              buttonName: "Back Button",
              pageName: "Protocols",
              tabName: selectedProtocol,
            });
            setSelectedProtocol(null);
            setMode(ViewMode.list_details);
            setUpdate(new Date());
          }}
        />
      );
    }

    return null;
  }

  return (
    <Box style={{ marginTop: 20 }}>
      <DisplayMode mode={mode} />

      {editDialogOpen && (
        <ProtocolDialog
          id={selectedProtocol ? selectedProtocol.id : null}
          selectedProtocol={selectedProtocol}
          allowClientSelection={true}
          onDialogClosed={async (values) => {
            setSelectedProtocol(null);
            setEditDialogOpen(false);

            if (values) {
              await ProtocolData.addProtocol(values);
              setUpdate(new Date());
            }
          }}
        />
      )}
    </Box>
  );
};

export default ProtocolsPage;
